.visible-xlg {
    display: none;
}
.hidden-xlg {
    display: block;
}

/*** Large screens ***/
@media (min-width: 1824px) {

  .visible-xlg {
        display: block !important;
    }
    .hidden-xlg {
        display: none !important;
    }
    

    .quickview-wrapper .tab-content #quickview-notes > .inner{
        width:570px;
    }

    .container-fluid{
         &.container-fixed-lg{
            width: @layout-container-fixed-lg;
            margin-right: auto;
            margin-left: auto;
        }
    }
    .menu-pin .container-fluid.container-fixed-lg{
        width: @layout-container-fixed-lg - @layout-sidepanel-pin-width;
    }
    .visible-xlg {
        display: block !important;
    }
    .hidden-xlg {
        display: none !important;
    }

     .col-xlg-1, .col-xlg-2, .col-xlg-3, .col-xlg-4, .col-xlg-5, .col-xlg-6, .col-xlg-7, .col-xlg-8, .col-xlg-9, .col-xlg-10, .col-xlg-11 {
        float: left;
        min-height: 1px;
        padding-left: 15px;
        padding-right: 15px;
        position: relative;
    }
    .col-xlg-12 {
        float: left;
        min-height: 1px;
        padding-left: 15px;
        padding-right: 15px;
        position: relative;
        width: 100%;
    }
    .col-xlg-11 {
        width: 91.6667%;
    }
    .col-xlg-10 {
        width: 83.3333%;
    }
    .col-xlg-9 {
        width: 75%;
    }
    .col-xlg-8 {
        width: 66.6667%;
    }
    .col-xlg-7 {
        width: 58.3333%;
    }
    .col-xlg-6 {
        width: 50%;
    }
    .col-xlg-5 {
        width: 41.6667%;
    }
    .col-xlg-4 {
        width: 33.3333%;
    }
    .col-xlg-3 {
        width: 25%;
    }
    .col-xlg-2 {
        width: 16.6667%;
    }
    .col-xlg-1 {
        width: 8.33333%;
    }
    .col-xlg-pull-12 {
        right: 100%;
    }
    .col-xlg-pull-11 {
        right: 91.6667%;
    }
    .col-xlg-pull-10 {
        right: 83.3333%;
    }
    .col-xlg-pull-9 {
        right: 75%;
    }
    .col-xlg-pull-8 {
        right: 66.6667%;
    }
    .col-xlg-pull-7 {
        right: 58.3333%;
    }
    .col-xlg-pull-6 {
        right: 50%;
    }
    .col-xlg-pull-5 {
        right: 41.6667%;
    }
    .col-xlg-pull-4 {
        right: 33.3333%;
    }
    .col-xlg-pull-3 {
        right: 25%;
    }
    .col-xlg-pull-2 {
        right: 16.6667%;
    }
    .col-xlg-pull-1 {
        right: 8.33333%;
    }
    .col-xlg-pull-0 {
        right: 0;
    }
    .col-xlg-push-12 {
        left: 100%;
    }
    .col-xlg-push-11 {
        left: 91.6667%;
    }
    .col-xlg-push-10 {
        left: 83.3333%;
    }
    .col-xlg-push-9 {
        left: 75%;
    }
    .col-xlg-push-8 {
        left: 66.6667%;
    }
    .col-xlg-push-7 {
        left: 58.3333%;
    }
    .col-xlg-push-6 {
        left: 50%;
    }
    .col-xlg-push-5 {
        left: 41.6667%;
    }
    .col-xlg-push-4 {
        left: 33.3333%;
    }
    .col-xlg-push-3 {
        left: 25%;
    }
    .col-xlg-push-2 {
        left: 16.6667%;
    }
    .col-xlg-push-1 {
        left: 8.33333%;
    }
    .col-xlg-push-0 {
        left: 0;
    }
    .col-xlg-offset-12 {
        margin-left: 100%;
    }
    .col-xlg-offset-11 {
        margin-left: 91.6667%;
    }
    .col-xlg-offset-10 {
        margin-left: 83.3333%;
    }
    .col-xlg-offset-9 {
        margin-left: 75%;
    }
    .col-xlg-offset-8 {
        margin-left: 66.6667%;
    }
    .col-xlg-offset-7 {
        margin-left: 58.3333%;
    }
    .col-xlg-offset-6 {
        margin-left: 50%;
    }
    .col-xlg-offset-5 {
        margin-left: 41.6667%;
    }
    .col-xlg-offset-4 {
        margin-left: 33.3333%;
    }
    .col-xlg-offset-3 {
        margin-left: 25%;
    }
    .col-xlg-offset-2 {
        margin-left: 16.6667%;
    }
    .col-xlg-offset-1 {
        margin-left: 8.33333%;
    }
    .col-xlg-offset-0 {
        margin-left: 0;
    }

}
/*** Desktops ***/
@media (min-width: 1600px) {
}
/*** Medium Size Screen ***/
@media only screen and (max-width: 1400px) {
    
    .page-sidebar .page-sidebar-inner .sidebar-slide {
        .sidebar-menu{
            bottom:50px;
        }
        .sidebar-widgets{
        display: none
        }
    }
    .footer-widget{
        padding: 11px 21px !important;
    }
}
/*** Desktops & Laptops ***/
@media only screen and (min-width: 980px) {
    body{
        &.ie9.menu-pin{
            .page-sidebar{
                 transform: none !important;
                -webkit-transform: none !important;
                -ms-transform: none !important;          
            }
        }
        &.menu-pin{
            overflow-x: hidden;
            .header .brand{
                width: 245px;
            }
            .page-container{
                .padding-left(0);
                .page-content-wrapper {
                    .content{
                        .padding-left(@layout-sidepanel-pin-width);
                    } 
                    .footer{
                        .left(@layout-sidepanel-pin-width);
                    }
                }
            }
            [data-toggle-pin="sidebar"]{
                & > i:before{
                    content: "\f192";
                }
            }
            .page-sidebar when not (@rtl){
                transform: translate(@layout-sidepanel-width - @layout-sidepanel-width-collapsed , 0) !important;
                -webkit-transform: translate(@layout-sidepanel-width - @layout-sidepanel-width-collapsed, 0) !important;
                -ms-transform: translate(@layout-sidepanel-width - @layout-sidepanel-width-collapsed, 0) !important;
            }
            .page-sidebar when (@rtl){
                transform: translate((@layout-sidepanel-width - @layout-sidepanel-width-collapsed) * -1 , 0) !important;
                -webkit-transform: translate((@layout-sidepanel-width - @layout-sidepanel-width-collapsed) * -1, 0) !important;
                -ms-transform: translate((@layout-sidepanel-width - @layout-sidepanel-width-collapsed) * -1, 0) !important;
            }
            .page-sidebar {
                width: @layout-sidepanel-pin-width;
                .sidebar-header .sidebar-header-controls {
                    .translateX(18px);
                }
                .menu-items .icon-thumbnail {
                    .translate3d(-14px, 0, 0); 
                }
            }
        }
        &.menu-behind{
            .page-sidebar{
                z-index: @zIndex-navbar - 1;
            }
            .header .brand{
                width: 200px;
                .text-align(left);
                .padding-left(20px);
            }
        }
        &.box-layout{
            background-color:@color-white;
            & > .container,
            & > .full-height > .container{
            height: 100%;
            padding: 0;
            background-color: @color-master-lightest;
            }
            .header{
            background-color:transparent;
            border: 0;
            padding: 0;
                & > .container {
                background-color: #fff;
                border-bottom: 1px solid rgba(230, 230, 230, 0.7);
                padding: 0 20px 0 0;
              }
            }
            .page-sidebar{
                left: auto;
                transform: none !important;
                -webkit-transform: none !important;
            }
            .page-container .page-content-wrapper .footer{
                  width: auto;
            }

        }
    }


     .header {
        .brand{
            position: relative;
        }
       .user-info-wrapper {
            .user-details {
                .user-name {
                    font-size: 16px;
                }
                .user-other {
                    font-size: 10px;
                }
            }
            .user-pic {
                position: relative;
                top: -6px;
            }
        }
    }

    .notification-panel{
        width: 400px;
    }

}
/*** General Small Screen Desktops ***/


/*** General tablets and phones ***/
@media (max-width: 991px) {

    .page-container{
        .padding-left(0); 
    }

    body{
        &.sidebar-open{
            .page-container{
                .translate3d(250px,0,0);
                overflow: hidden;
                position: fixed;
            }
        }
        &.box-layout{
            & > .container{
            padding: 0;
            height: 100%;
            }
            .header{
                & > .container {
                    padding: 0;
                    .pull-right .sm-action-bar{
                        .right(0);
                    }
              }
            }
        }
        &.menu-opened.horizontal-menu{
            overflow-y: hidden;
            .bar {
                .translate3d(0%, 0, 0);
            }
        }
    }
    .header {
        padding: 0 10px;
        width: 100%;
        border-bottom: 1px solid rgba(0,0,0,.07);
        background: #fff !important;
        .header-inner{
            text-align: center;
            .mark-email{
                left: 45px;
                position: absolute;
                top: 23px;
            }
            .quickview-link{
                position: absolute;
                right: 0;
                top: 12px;
            }
        }
        .brand{
            width: auto;
        }
        .notification-list, .search-link{
            display: none;
        } 

        & > .pull-left,  & > .pull-right{
            position: relative;
        }
         & > .pull-right{
            .sm-action-bar{
                .right(0);   
            }
         }
    }
    .sm-action-bar{
      position: absolute;
      top: 50%;
      .translateY(-50%);
      z-index: 10;
      & > a{
        padding: 10px;
        display: inline-block;
      }
    }
    .pace .pace-activity{
        top: 60px;
    }
    .page-sidebar {
        width: 250px;
        z-index: auto;
        .left(0) !important;
        -webkit-transform: translate3d(0, 0px, 0px) !important;
        transform: translate3d(0, 0px, 0px) !important;
        .sidebar-menu .menu-items > li ul.sub-menu > li{
            padding: 0px 30px 0 36px;
        }
    }

    .page-container{
        padding-left: 0;
        // z-index: 100;
        position: relative;
        transition: transform .25s ease;
        -webkit-transition: -webkit-transform .25s ease;
        .page-content-wrapper .content{
            overflow-x: hidden;
            height: auto; // minimizes address bar on scroll
        }
    }

    .icon-thumbnail{
        margin-right: 24px;   
    }
     .page-sidebar {
        display: none;
        z-index: auto;
        &.visible {
        display: block;
        }
        .page-sidebar-inner{
            z-index: 1;
            left: 0 !important;
            width: 260px;
            .sidebar-slide{
                .sidebar-menu{
                    overflow: scroll;
                    -webkit-overflow-scrolling: touch;
                    top:40px;
                    bottom: 0;
                    ul > li > a > .badge{
                        display: inline-block;
                    }
                }
            }
        }
     }
    .secondary-sidebar{
        float: none;
        height: auto;
        left: 50%;
        margin-left: -125px;
        padding: 20px;
        position: absolute;
        right: 0;
        top: 60px;
        z-index: 9999;
        display: none;
        border-radius: 10px;
        .btn-compose{
            display: none;
        }
    }
    .inner-content{
        margin: 0;
    }
     .breadcrumb{
        padding-left:15px; 
        padding-right:15px; 
     }
     .copyright{
        padding-left:15px; 
        padding-right:15px;  
     }

    // Pages Notification customizations
    body > .pgn-wrapper[data-position="top"] {
        top: @layout-mobile-header-height;
        left: 0;
    }
    body > .pgn-wrapper[data-position="bottom"] {
        left: 0;
    }

    body > .pgn-wrapper[data-position$='-left'] {
        left:  20px;
        right: auto;
    }

    .sm-table{
        display: table;
        width: 100%;
    }
    .user-profile-wrapper{
        position: absolute;
        right: 50px;
        top: -9px;
    }
      /*** Horizontal Menu **/

    .horizontal-menu .bar {
        display: block;
        position: fixed;
        top:0;
        bottom: 0;
        height: 100%;
        width: 250px;
        background-color: #fff;
        z-index: 10;
        overflow-y: auto;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
        right: 0;
        z-index: @zIndex-horizontal-menu-mobile;
        box-shadow:0 0 9px rgba(191,191,191,.36);
        border-left: 1px solid rgba(222,227,231,.56);
        .translate3d(100%, 0, 0);
        .transition(all .4s cubic-bezier(.19, 1, .22, 1));
        .bar-inner {
            & > ul > li {
                display: block;
                & > a .arrow{
                    float: right;
                    padding-right: 25px;
                }
                .classic {
                    position: relative;
                    background-color: transparent;
                    top: 0;
                }
                & .mega,
                &.horizontal {
                    position: relative;
                }
                & > .horizontal {
                    position: relative;
                    top: 0;
                    border-bottom: 0;
                    li {
                        display: block;
                    }
                }
            }
        }
    }
}

/* Landscape view of all tablet devices */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){
    .page-sidebar .sidebar-menu .menu-items ul{
        -webkit-transform:translateZ(0);
        -webkit-backface-visibility: hidden;
        -webkit-perspective: 1000;
    }
    .page-container{
        .page-content-wrapper{
            .content {
                padding-top: @layout-header-height;
            }
        }
    }
    body.box-layout > .container{
        width: 100%;
    }
    .error-page{
        .error-container{
            width: auto;
        }
    }

    .secondary-sidebar{
        float: none;
        height: auto;
        left: 50%;
        .margin-left(-155px);
        padding: 20px;
        position: absolute;
        right: 0;
        top: @layout-header-height;
        z-index: 9999;
        display: none;
        border-radius: 10px;
        .btn-compose{
            display: none;
        }
    }

    .split-details{
        .margin-left(0);
    }

    .toggle-secondary-sidebar{
        font-size: 18px;
        display: block;
        font-size: 18px;
        left: 50%;
        .margin-left(-36px);
        position: absolute;
        & ~ .brand{
            display: none !important;
        }
    }
}

// Resolution specific borders
@media (max-width:991px){
  .sm-b-r,
  .sm-b-l,
  .sm-b-t,
  .sm-b-b{
    border-width: 0;
  }
  .sm-b-r{
    border-right-width:1px;
  }
  .sm-b-l{
    border-left-width:1px;
  }
  .sm-b-t{
    border-top-width:1px;
  }
  .sm-b-b{
    border-bottom-width:1px;
  }
}

// Make all columns equal height
// (Not applied for resolutions lowers than mentioned ex:lg,md,sm
// because they stack up)
@media (min-width:1200px){
    .row-same-height{
      overflow: hidden; 
      & > [class*="col-lg"]{
        margin-bottom: -99999px;
        padding-bottom: 99999px;
      }
    }
}

@media (min-width:992px){
    .row-same-height{
      overflow: hidden; 
      & > [class*="col-md"]{
        margin-bottom: -99999px;
        padding-bottom: 99999px;
      }
    }
    .horizontal-menu .bar{
        display: table !important;
        & + div{
            padding-top: 50px;
        }
    }
}

@media (min-width:768px){
    .row-same-height{
      overflow: hidden; 
      & > [class*="col-sm"]{
        margin-bottom: -99999px;
        padding-bottom: 99999px;
      }
    }
    .box-layout .container .jumbotron, .container-fluid .jumbotron {
        padding: 0;
    }
    .dataTables_wrapper.form-inline .checkbox input[type=checkbox], .form-inline .radio input[type=radio]{
        position: absolute;
    }
}

    /* Portrait view of all tablet devices */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    
    .page-sidebar .sidebar-menu .menu-items > li{
        & > a{
            padding-left: 27px;
        }
        ul.sub-menu > li {
            padding: 0px 27px 0 31px;
        }
    }

    .icon-thumbnail {
        margin-right: 21px;
    }
    .error-page{
        .error-container{
            width: auto;
        }
    }
}

@media (max-width:979px){
    .toggle-secondary-sidebar{
          
            font-size: 18px;
            position: static;
            display: block;
            & ~ .brand{
                display: none !important;
            }
        }

    .secondary-sidebar{
        float: none;
        height: auto;
        .left(50%);
        .margin-left(-125px);
        padding: 20px;
        position: absolute;
        right: 0;
        top: @layout-header-height;
        z-index: 9999;
        display: none;
        border-radius: 10px;
        .btn-compose{
            display: none;
        }
    }

    .split-view {
        .split-list{
            width: 100%;
            height: auto;
            bottom: 50px;
           left: 0;
           top: 0;
             position: absolute;
            z-index: 2000;
            float: none;
            .item{
                padding: 8px;
                .inline{
                    .margin-left(5px);
                    width: 188px;
                }
            }
        }
        
        .split-details{
            margin-left: 0;
            width: 100%;

            .email-content-wrapper{
                overflow: auto;
                padding: 0;
                height: calc(~'100% - 50px');
                .email-content{
                    width: 90%;
                }
            }
        }

    }
    
    .compose-wrapper {
        display: block !important;
        .btn-compose{
            display: block !important;
        }
    }
}

@media (max-width: 767px) {
    
    .header{
        height: @layout-mobile-header-height;
        .notification-list, .search-link{
            display: none;
        } 
        .header-inner{
            height: @layout-mobile-header-height;
        }
    }
    .header .user-info-wrapper{
        display:none;
    }
    .header .search-link{
        height: 19px;
        width:16px;
        overflow:hidden;
    }

     .jumbotron,.container-fluid{
        padding-left: 0;
        padding-right: 0;

     }
   
     .page-container .page-content-wrapper .content {
        padding-top: @layout-mobile-header-height;
        padding-bottom: 100px;
    }
    .page-sidebar .sidebar-header{
        padding: 0 12px;
    }
    .page-sidebar .sidebar-menu .menu-items > li{
        & > a{
            padding-left: 20px;
        }
        ul.sub-menu > li {
            padding: 0px 25px 0 28px;
        }
    }

    .icon-thumbnail {
        margin-right: 20px;
    }
    .secondary-sidebar{
        top: @layout-mobile-header-height;
    }
    .split-details{
            margin-left: 0;
        }
        .email-composer{
            padding-left: 30px;
            padding-right: 30px;
        }

  .sm-pull-bottom, .sm-pull-up{
    position: relative !important;
  }

.generate-paddings-options-sm(10);

.generate-paddings-options-sm(@n, @i: 0) when (@i =< @n) {
  @step : @i*5; 
  .sm-p-t-@{step} {
    padding-top: (@step*1px) !important;
  }
  .sm-p-r-@{step} {
    padding-right: (@step*1px) !important;
  }
   .sm-p-l-@{step} {
    padding-left: (@step*1px) !important;
  }
   .sm-p-b-@{step} {
    padding-bottom: (@step*1px) !important;
  }
  .sm-padding-@{step} {
    padding: (@step*1px) !important;
  }
  .generate-paddings-options-sm(@n, (@i + 1));
}

.generate-margin-options-sm(10);

.generate-margin-options-sm(@n, @i: 1) when (@i =< @n) {
  @step : @i*5; 
  .sm-m-t-@{step} {
    margin-top: (@step*1px) !important;
  }
  .sm-m-r-@{step} {
    margin-right: (@step*1px) !important;
  }
   .sm-m-l-@{step} {
    margin-left: (@step*1px) !important;
  }
   .sm-m-b-@{step} {
    margin-bottom: (@step*1px) !important;
  }
  .generate-margin-options-sm(@n, (@i + 1));
}
  .sm-no-margin {
    margin: 0px;
  }
  .sm-no-padding {
    padding: 0px;
  }
  .sm-text-right{
  text-align: right !important;
  }
  .sm-text-left{
  text-align: left !important;
  }
  .sm-text-center{
  text-align: center !important;
  }
  .sm-pull-right{
  float: right !important;
  }
  .sm-pull-left{
  float: left !important;
  } 
  .sm-pull-reset{
    float: none !important;
  }
  .sm-block{
  display: block;
  }

  .error-container{
    width:auto;
  }
  .sm-image-responsive-height{
    width: 100%;
    height: auto;
  }
}



    /*** Phones ***/
@media (max-width: 480px) {
    body{
        width: 100%;
        .header {
            width: 100%;
            height: @layout-mobile-header-height;
            border-bottom: 1px solid rgba(0,0,0,.07);
            .header-inner{
                height: @layout-mobile-header-height;
                text-align: center;
                .toggle-secondary-sidebar{
                    font-size: 16px;
                    top: 12px;
                }
                .mark-email{
                    left: 35px;
                    top: 14px;
                }
                .quickview-link{
                    top: 14px;
                }

            }
            .notification-list, .search-link{
                display:none;
            }
            

            .dropdown-submenu{
                top:12px;
            }
            .notification-list, .search-link{
                display:none;
            }

        }

        #overlay-search{
            font-size: 48px;
            height: 118px;
            line-height: 46px;
        }
    }
    .page-sidebar .sidebar-header{
        height: @layout-mobile-header-height;
        line-height: @layout-mobile-header-height;
    }
    .panel {
        .panel-heading{
            padding-left: 15px;
        }
        .panel-body{
            padding: 15px;
            padding-top: 0;
        }
    }
    .error-page{
        padding: 15px;
        .error-container{
            margin-top: 30px;
            width: auto;
        }
        .pull-bottom{
            position: relative;
        }
    }

    .map-controls{
        left: 10px;
    }
    .register-container{
        height: auto !important;
    }
    .error-container-innner{
        width: auto;
    }
}


