/*------------------------------------------------------------------
Pages v2.1.2
Theme : Default
Generated From LESS
[Table of contents]

1. Layouts
2. View Ports
3. Chat
4. Panels
5. Typography
6. Buttons
7. Alerts
8. Notifications
9. Progress Indicators
10. Modals
11. Tabs & Accordians
12. Sliders
13. Treeview
14. Nestables
15. Form Elements
16. Tables & Datatables
17. Charts
18. Lists
19. Social App
20. Email App
21. Calendar App
22. Login
23. Lockscreen
24. Timeline
25. Gallery
26. Vector Map
27. Widgets
28. Misc
29. Print
30. Demo
-------------------------------------------------------------------*/

@pages-fonts-path: "../fonts";
@vccolor: #e67e22;
// Fonts
@import "pages-icons";

// Mixins tools
@import "mixins";
@import "modules/direction";

// Includes of Modules
@import "modules";

// Responsive Util
@import "responsive";

//Change your theme here
@theme-name:"default";
@direction: ltr; // use `rtl` or `ltr

.theme {
    @import "themes/@{theme-name}/theme";
}
.theme();

.header .brand {
  text-align: center;
}
    .sidebar-menu {
        .menu-items {
            & > li {
                &.always-open {
                    & > ul {
                        &.sub-menu {
                            display: block !important;
                        }
                    }
                }
            }
        }
    }

.b-dark-grey {
    border-color: #999;
}
