/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css?family=Play');
@import url('https://fonts.googleapis.com/css?family=Hind');
@import url('https://fonts.googleapis.com/css?family=Montserrat:700:600');
@import url('https://fonts.googleapis.com/css?family=Poppins');
/*------------------------------------------------------------------
Pages v2.1.2
Theme : Default
Generated From LESS
[Table of contents]

1. Layouts
2. View Ports
3. Chat
4. Panels
5. Typography
6. Buttons
7. Alerts
8. Notifications
9. Progress Indicators
10. Modals
11. Tabs & Accordians
12. Sliders
13. Treeview
14. Nestables
15. Form Elements
16. Tables & Datatables
17. Charts
18. Lists
19. Social App
20. Email App
21. Calendar App
22. Login
23. Lockscreen
24. Timeline
25. Gallery
26. Vector Map
27. Widgets
28. Misc
29. Print
30. Demo
-------------------------------------------------------------------*/
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);
@font-face {
  font-family: 'pages-icon';
  src: url('./libs/pages-theme/fonts/pages-icon/Pages-icon.eot?-u69vo5');
  src: url('./libs/pages-theme/fonts/pages-icon/Pages-icon.eot?#iefix-u69vo5') format('embedded-opentype'), url('./libs/pages-theme/fonts/pages-icon/Pages-icon.woff?-u69vo5') format('woff'), url('./libs/pages-theme/fonts/pages-icon/Pages-icon.ttf?-u69vo5') format('truetype'), url('./libs/pages-theme/fonts/pages-icon/Pages-icon.svg?-u69vo5#Pages-icon') format('svg');
  font-weight: normal;
  font-style: normal;
}
.pg {
  display: inline-block;
  font: normal normal normal 14px/1 pages-icon;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class^="pg-"],
[class*=" pg-"] {
  display: inline-block;
  font: normal normal normal 14px/1 pages-icon;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.pg-signals:before {
  content: "\e61c";
}
.pg-crop:before {
  content: "\e657";
}
.pg-folder_alt:before {
  content: "\e658";
}
.pg-folder:before {
  content: "\e659";
}
.pg-theme:before {
  content: "\e65a";
}
.pg-battery_empty:before {
  content: "\e65b";
}
.pg-battery:before {
  content: "\e65c";
}
.pg-note:before {
  content: "\e65d";
}
.pg-server_hard:before {
  content: "\e65e";
}
.pg-servers:before {
  content: "\e65f";
}
.pg-menu_justify:before {
  content: "\e660";
}
.pg-credit_card:before {
  content: "\e60f";
}
.pg-fullscreen_restore:before {
  content: "\e633";
}
.pg-fullscreen:before {
  content: "\e634";
}
.pg-minus:before {
  content: "\e635";
}
.pg-minus_circle:before {
  content: "\e63a";
}
.pg-plus_circle:before {
  content: "\e656";
}
.pg-refresh_new:before {
  content: "\e600";
}
.pg-close_line:before {
  content: "\e601";
}
.pg-close:before {
  content: "\e60a";
}
.pg-arrow_down:before {
  content: "\e60b";
}
.pg-arrow_left_line_alt:before {
  content: "\e628";
}
.pg-arrow_left:before {
  content: "\e629";
}
.pg-arrow_lright_line_alt:before {
  content: "\e62a";
}
.pg-arrow_maximize_line:before {
  content: "\e62b";
}
.pg-arrow_maximize:before {
  content: "\e62c";
}
.pg-arrow_minimize_line:before {
  content: "\e62f";
}
.pg-arrow_minimize:before {
  content: "\e630";
}
.pg-arrow_right:before {
  content: "\e631";
}
.pg-arrow_up:before {
  content: "\e632";
}
.pg-more:before {
  content: "\e655";
}
.pg-bag:before {
  content: "\e602";
}
.pg-bag1:before {
  content: "\e603";
}
.pg-bold:before {
  content: "\e604";
}
.pg-calender:before {
  content: "\e605";
}
.pg-camera:before {
  content: "\e606";
}
.pg-centeralign:before {
  content: "\e607";
}
.pg-charts:before {
  content: "\e608";
}
.pg-clock:before {
  content: "\e609";
}
.pg-comment:before {
  content: "\e60c";
}
.pg-contact_book:before {
  content: "\e60d";
}
.pg-credit_card_line:before {
  content: "\e60e";
}
.pg-cupboard:before {
  content: "\e610";
}
.pg-desktop:before {
  content: "\e611";
}
.pg-download:before {
  content: "\e612";
}
.pg-eraser:before {
  content: "\e613";
}
.pg-extra:before {
  content: "\e614";
}
.pg-form:before {
  content: "\e615";
}
.pg-grid:before {
  content: "\e616";
}
.pg-home:before {
  content: "\e617";
}
.pg-image:before {
  content: "\e618";
}
.pg-inbox:before {
  content: "\e619";
}
.pg-indent:before {
  content: "\e61a";
}
.pg-italic:before {
  content: "\e61b";
}
.pg-laptop:before {
  content: "\e61d";
}
.pg-layouts:before {
  content: "\e61e";
}
.pg-layouts2:before {
  content: "\e61f";
}
.pg-layouts3:before {
  content: "\e620";
}
.pg-layouts4:before {
  content: "\e621";
}
.pg-leftalign:before {
  content: "\e622";
}
.pg-like:before {
  content: "\e623";
}
.pg-like1:before {
  content: "\e624";
}
.pg-lock:before {
  content: "\e625";
}
.pg-mail:before {
  content: "\e626";
}
.pg-map:before {
  content: "\e627";
}
.pg-menu_lv:before {
  content: "\e62d";
}
.pg-menu:before {
  content: "\e62e";
}
.pg-movie:before {
  content: "\e636";
}
.pg-ordered_list:before {
  content: "\e637";
}
.pg-outdent:before {
  content: "\e638";
}
.pg-phone:before {
  content: "\e639";
}
.pg-plus:before {
  content: "\e63b";
}
.pg-power:before {
  content: "\e63c";
}
.pg-printer:before {
  content: "\e63d";
}
.pg-refresh:before {
  content: "\e63e";
}
.pg-resize:before {
  content: "\e63f";
}
.pg-right_align:before {
  content: "\e640";
}
.pg-save:before {
  content: "\e641";
}
.pg-search:before {
  content: "\e642";
}
.pg-sent:before {
  content: "\e643";
}
.pg-settings_small_1:before {
  content: "\e644";
}
.pg-settings_small:before {
  content: "\e645";
}
.pg-settings:before {
  content: "\e646";
}
.pg-shopping_cart:before {
  content: "\e647";
}
.pg-social:before {
  content: "\e648";
}
.pg-spam:before {
  content: "\e649";
}
.pg-suitcase:before {
  content: "\e64a";
}
.pg-tables:before {
  content: "\e64b";
}
.pg-tablet:before {
  content: "\e64c";
}
.pg-telephone:before {
  content: "\e64d";
}
.pg-text_style:before {
  content: "\e64e";
}
.pg-trash_line:before {
  content: "\e64f";
}
.pg-trash:before {
  content: "\e650";
}
.pg-ui:before {
  content: "\e651";
}
.pg-underline:before {
  content: "\e652";
}
.pg-unordered_list:before {
  content: "\e653";
}
.pg-video:before {
  content: "\e654";
}
/*!
* direction.less v0.1.0
*/
/*------------------------------------------------------------------
[1. Layouts]
*/
html {
  height: 100%;
}
/* Body Triggers for layout options
------------------------------------------------------------------
*/
body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #f7f7f7;
  direction: ltr;
}
body.fixed-header .header {
  position: fixed;
  left: 0;
  top: 0;
}
body.mobile .sidebar-menu {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}
body.mobile .sidebar-menu > ul {
  height: auto !important;
  overflow: visible !important;
  -webkit-overflow-scrolling: touch !important;
}
body.mobile .page-sidebar .sidebar-menu .menu-items li:hover > a {
  color: #788195;
}
body.mobile .page-sidebar .sidebar-menu .menu-items li:hover > .icon-thumbnail {
  color: #788195 !important;
  background-color: #e67e22;
}
body.mobile .page-sidebar .sidebar-menu .menu-items li.active > a,
body.mobile .page-sidebar .sidebar-menu .menu-items li.open > a {
  color: #fff;
}
body.mobile .page-sidebar .sidebar-menu .menu-items li.active > a > .icon-thumbnail,
body.mobile .page-sidebar .sidebar-menu .menu-items li.open > a > .icon-thumbnail {
  color: #fff;
  background-color: #e67e22;
}
body.mobile .page-sidebar .sidebar-menu .menu-items li.active > a > .icon-thumbnail i,
body.mobile .page-sidebar .sidebar-menu .menu-items li.open > a > .icon-thumbnail i {
  color: #fff;
}
body.mobile .drager {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
body.sidebar-visible .page-sidebar .scroll-element {
  visibility: visible;
}
body.sidebar-visible .page-sidebar .menu-items .icon-thumbnail {
  -webkit-transform: translate3d(14px, 0, 0);
  transform: translate3d(14px, 0, 0);
  -ms-transform: translate(14px, 0);
}
body.sidebar-visible .page-sidebar .sidebar-header .sidebar-header-controls {
  -webkit-transform: translate3d(-48px, 0, 0);
  transform: translate3d(-48px, 0, 0);
  -ms-transform: translate(-48px, 0);
}
body.no-header .page-container .page-content-wrapper .content {
  padding-top: 0px;
}
body.no-header .header {
  border-bottom-color: transparent;
}
body.dashboard {
  background: #f5f5f5;
}
body.dashboard .page-container {
  background: #f5f5f5;
}
body.rtl [class^="col-"],
body.rtl [class*="col-"] {
  float: right;
}
/* Page Loader
------------------------------------
*/
.pace .pace-progress {
  background: #10cfbd;
  height: 3px;
}
.pace .pace-progress-inner {
  box-shadow: none;
}
.pace .pace-activity {
  -webkit-animation: none;
  animation: none;
  top: 73px;
  background-size: 100% auto;
  margin: 0 auto;
  border-width: 0;
  border-radius: 0;
  width: 28px;
  height: 40px;
  left: 19px;
  right: auto;
}
/* Header
------------------------------------
*/
.header {
  position: relative;
  display: block;
  height: 60px;
  width: 100%;
  padding: 0 0 0 20px;
  z-index: 800;
  background-color: #fff;
  border-bottom: 1px solid rgba(230, 230, 230, 0.7);
}
.header a {
  color: #3b4751;
}
.header a.btn {
  color: #8b91a0;
}
.header a.btn-warning {
  color: #eaeef1;
}
.header .pull-left,
.header .pull-right {
  z-index: 10;
  position: relative;
}
.header .header-inner {
  height: 60px;
  width: 100%;
  vertical-align: middle;
  display: table-cell;
}
.header .header-inner .toggle-sidebar {
  display: none;
}
.header.transparent {
  background-color: transparent !important;
}
.header .brand {
  vertical-align: middle;
  width: 270px;
  text-align: center;
}
.header .bubble {
  border-radius: 100%;
  height: 14px;
  width: 14px;
  background-color: rgba(226, 32, 91, 0.77);
  color: #fff;
  position: relative;
  top: -6px;
  float: left;
  left: -5px;
}
.header .notification-list {
  display: inline-block;
}
.header .search-link {
  display: inline-block;
  margin-right: 15px;
  color: #626262;
  opacity: 0.7;
  font-size: 16px;
  font-family: wf_segoe-ui_light, wf_segoe-ui_normal, "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.header .search-link i {
  margin-left: 15px;
  font-size: 16px;
}
.header .search-link:hover {
  opacity: 1;
}
/* Bootstrap navbar
------------------------------------
*/
.navbar {
  top: -1px;
}
.navbar-nav > li > a {
  padding-top: 20px;
  padding-bottom: 20px;
}
.navbar-default {
  background-color: #fff;
}
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-default .navbar-nav > .active > a:focus {
  background-color: transparent;
}
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-default .navbar-nav > .active > a:focus {
  background-color: transparent;
}
.navbar-toggle {
  border-radius: 0;
  background-color: transparent !important;
}
/* Main Menu Sidebar
------------------------------------
*/
.page-sidebar {
  width: 270px;
  background-color: #2b303b;
  z-index: 1000;
  right: -200px;
  position: fixed;
  bottom: 0;
  top: 0;
  left: auto;
  overflow: hidden;
  -webkit-transition: -webkit-transform 400ms cubic-bezier(0.05, 0.74, 0.27, 0.99);
  -moz-transition: -moz-transform 400ms cubic-bezier(0.05, 0.74, 0.27, 0.99);
  -o-transition: -o-transform 400ms cubic-bezier(0.05, 0.74, 0.27, 0.99);
  transition: transform 400ms cubic-bezier(0.05, 0.74, 0.27, 0.99);
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
}
.page-sidebar a,
.page-sidebar button {
  color: #788195;
}
.page-sidebar a:hover,
.page-sidebar button:hover,
.page-sidebar a:active,
.page-sidebar button:active {
  color: #fff;
}
.page-sidebar a:visited,
.page-sidebar button:visited,
.page-sidebar a:focus,
.page-sidebar button:focus {
  color: #788195;
}
.page-sidebar .scroll-element {
  visibility: hidden;
}
.page-sidebar .sidebar-header {
  /* Side-bar header */
  display: block;
  height: 60px;
  line-height: 60px;
  background-color: #ddd;
  border-bottom: 1px solid #232730;
  color: #fff;
  width: 100%;
  padding: 0 20px;
  padding-right: 30px;
  clear: both;
  z-index: 10;
  position: relative;
}
.page-sidebar .sidebar-header .sidebar-header-controls {
  display: inline-block;
  -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.05, 0.74, 0.27, 0.99);
  transition: transform 0.4s cubic-bezier(0.05, 0.74, 0.27, 0.99);
  -webkit-backface-visibility: hidden;
}
.page-sidebar .sidebar-header .sidebar-slide-toggle i {
  -webkit-transition: all 0.12s ease;
  transition: all 0.12s ease;
}
.page-sidebar .sidebar-header .sidebar-slide-toggle.active i {
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.page-sidebar .close-sidebar {
  position: absolute;
  left: 19px;
  top: 14px;
  padding: 9px;
  z-index: 1;
}
.page-sidebar .close-sidebar > i {
  color: rgba(255, 255, 255, 0.57);
}
.page-sidebar .sidebar-overlay-slide {
  /* Side-bar Top Slider */
  width: 100%;
  height: 100%;
  background-color: #272b35;
  display: block;
  z-index: 9;
  padding: 80px 20px 20px 20px;
}
.page-sidebar .sidebar-overlay-slide.from-top {
  top: -100%;
  position: absolute;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.page-sidebar .sidebar-overlay-slide.from-top.show {
  -webkit-transform: translate(0, 100%);
  -ms-transform: translate(0, 100%);
  transform: translate(0, 100%);
}
.page-sidebar .sidebar-menu {
  /* Side-bar Menu */
  height: calc(100% - 50px);
  position: relative;
  width: 100%;
}
.page-sidebar .sidebar-menu .outer-tab-nav-section {
  display: inline-block;
  width: 45px;
  position: absolute;
  height: 100%;
  background-color: #0aa699 !important;
}
.page-sidebar .sidebar-menu .menu-items {
  /* Side-bar Menut Items */
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  height: calc(100% - 10px);
  width: 100%;
}
.page-sidebar .sidebar-menu .menu-items li:hover > a,
.page-sidebar .sidebar-menu .menu-items li.open > a,
.page-sidebar .sidebar-menu .menu-items li.active > a {
  color: #fff;
}
.page-sidebar .sidebar-menu .menu-items li:hover > a > .icon-thumbnail,
.page-sidebar .sidebar-menu .menu-items li.open > a > .icon-thumbnail,
.page-sidebar .sidebar-menu .menu-items li.active > a > .icon-thumbnail {
  color: #fff;
  background-color: #e67e22;
}
.page-sidebar .sidebar-menu .menu-items li > a {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.page-sidebar .sidebar-menu .menu-items > li {
  display: block;
  padding: 0;
  clear: right;
}
.page-sidebar .sidebar-menu .menu-items > li:after,
.page-sidebar .sidebar-menu .menu-items > li:before {
  display: table;
  content: " ";
  clear: both;
}
.page-sidebar .sidebar-menu .menu-items > li > a {
  font-family: Arial, sans-serif;
  display: inline-block;
  padding: 0 3px;
  padding-right: 32px;
  min-height: 40px;
  line-height: 40px;
  font-size: 14px;
  clear: both;
}
.page-sidebar .sidebar-menu .menu-items > li > a.open {
  background: #313131;
}
.page-sidebar .sidebar-menu .menu-items > li > a > .arrow {
  float: right;
}
.page-sidebar .sidebar-menu .menu-items > li > a > .arrow:before {
  float: right;
  display: inline;
  font-size: 16px;
  font-family: FontAwesome;
  height: auto;
  content: "\f104";
  font-weight: 300;
  text-shadow: none;
  -webkit-transition: all 0.12s ease;
  transition: all 0.12s ease;
}
.page-sidebar .sidebar-menu .menu-items > li > a > .arrow.open:before {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.page-sidebar .sidebar-menu .menu-items > li > a > .badge {
  margin-top: 12px;
}
.page-sidebar .sidebar-menu .menu-items > li > a > .title {
  float: right;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 62%;
}
.page-sidebar .sidebar-menu .menu-items > li > a > .details {
  font-size: 12px;
  opacity: 0.4;
  display: block;
  clear: both;
}
.page-sidebar .sidebar-menu .menu-items > li > a.detailed > .title {
  line-height: 28px;
}
.page-sidebar .sidebar-menu .menu-items > li > a.detailed > .details {
  line-height: 16px;
}
.page-sidebar .sidebar-menu .menu-items > li > a > .icon-thumbnail:first-letter {
  text-transform: uppercase;
}
.page-sidebar .sidebar-menu .menu-items > li.active > ul.sub-menu {
  display: block;
}
.page-sidebar .sidebar-menu .menu-items > li > ul.sub-menu > li a {
  width: 100%;
}
.page-sidebar .sidebar-menu .menu-items > li > ul.sub-menu > li ul.sub-menu li {
  padding-right: 10px;
  padding-left: 3px;
}
.page-sidebar .sidebar-menu .menu-items > li > ul.sub-menu > li ul.sub-menu .icon-thumbnail {
  width: 25px;
  height: 25px;
  line-height: 25px;
  font-size: 10px;
}
.page-sidebar .sidebar-menu .menu-items > li > ul.sub-menu li > a > .arrow:before {
  float: left;
  margin-top: 1px;
  margin-right: 20px;
  display: inline;
  font-size: 16px;
  font-family: FontAwesome;
  height: auto;
  content: "\f104";
  font-weight: 300;
  text-shadow: none;
  -webkit-transition: all 0.12s ease;
  transition: all 0.12s ease;
}
.page-sidebar .sidebar-menu .menu-items > li > ul.sub-menu li > a > .arrow.open:before {
  float: left;
  margin-top: 1px;
  margin-right: 18px;
  display: inline;
  font-family: FontAwesome;
  height: auto;
  font-size: 16px;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  font-weight: 300;
  text-shadow: none;
}
.page-sidebar .sidebar-menu .menu-items > li ul.sub-menu {
  display: none;
  list-style: none;
  clear: both;
  margin: 0 0 10px 0;
  background-color: #21252d;
  padding: 18px 0 10px 0;
}
.page-sidebar .sidebar-menu .menu-items > li ul.sub-menu > li {
  background: none;
  padding: 0px 40px 0 20px;
  margin-top: 1px;
}
.page-sidebar .sidebar-menu .menu-items > li ul.sub-menu > li > a {
  display: inline-block;
  padding: 5px 0px;
  font-size: 13px;
  font-family: Arial, sans-serif;
  white-space: normal;
}
.page-sidebar .sidebar-menu .menu-items > li ul.sub-menu > li > a:hover > .icon-thumbnail {
  color: #fff;
}
.page-sidebar .sidebar-menu .menu-items > li ul.sub-menu > li > a:hover > .icon-thumbnail i {
  color: #fff;
}
.page-sidebar .sidebar-menu .menu-items > li ul.sub-menu > li .icon-thumbnail {
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin: 0;
  background-color: #2b303b;
  font-size: 14px;
}
.page-sidebar .sidebar-menu .menu-items > li ul.sub-menu > li.active .icon-thumbnail,
.page-sidebar .sidebar-menu .menu-items > li ul.sub-menu li:hover .icon-thumbnail {
  background-color: #e67e22;
}
.page-sidebar .sidebar-menu .muted {
  color: #576775;
  opacity: 0.45;
}
.page-sidebar .icon-thumbnail [class^="bg-"],
.page-sidebar [class*="bg-"] {
  color: #fff;
}
[data-toggle-pin="sidebar"] > i:before {
  content: "\f10c";
}
/* Sidebar icon holder
------------------------------------
*/
.icon-thumbnail {
  display: inline-block;
  background: #21252d;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  vertical-align: middle;
  position: relative;
  left: 0;
  float: left;
  margin-left: 14px;
  color: #788195;
  font-size: 16px;
  -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.05, 0.74, 0.27, 0.99);
  transition: transform 0.4s cubic-bezier(0.05, 0.74, 0.27, 0.99);
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  font-family: "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-weight: bold;
}
.icon-thumbnail > i {
  font-size: 14px;
}
.nav-collapse.collapse {
  height: 100%  !important;
}
/* Secondary App Menu */
.toggle-secondary-sidebar {
  display: none;
}
.secondary-sidebar {
  background: #2d3446;
  width: 250px;
  float: right;
  padding-right: 47px;
  height: 100%;
  position: fixed;
}
.secondary-sidebar.not-fixed {
  position: inherit;
}
.secondary-sidebar .btn-compose {
  font-family: 'Montserrat';
  font-size: 13px;
  font-weight: normal;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}
.secondary-sidebar .menu-title {
  color: rgba(120, 129, 149, 0.5);
  opacity: 0.5;
  font-size: 10.8px;
  font-family: 'Montserrat';
  font-weight: normal;
  letter-spacing: 0.03em;
}
.secondary-sidebar .main-menu {
  padding-right: 0;
}
.secondary-sidebar .main-menu > li {
  list-style: none;
}
.secondary-sidebar .main-menu > li.active > a {
  color: #48b0f7;
}
.secondary-sidebar .main-menu > li.active > a:hover {
  color: #48b0f7;
}
.secondary-sidebar .main-menu > li.active > a > .title {
  position: relative;
}
.secondary-sidebar .main-menu > li.active > a > .title:after {
  background: #48b0f7;
  border-radius: 50%;
  content: "";
  height: 7px;
  position: absolute;
  left: -14px;
  top: 6.5px;
  width: 7px;
}
.secondary-sidebar .main-menu > li a {
  font-size: 14px;
  color: #788195;
  line-height: 37px;
}
.secondary-sidebar .main-menu > li a:hover {
  color: #fff;
}
.secondary-sidebar .main-menu > li a > .title i {
  margin-left: 6px;
  opacity: 0.9;
}
.secondary-sidebar .sub-menu {
  margin-right: 23px;
}
.secondary-sidebar .sub-menu li {
  list-style: none;
  padding: 0;
}
.secondary-sidebar .sub-menu li.active a {
  color: #fff !important;
}
.secondary-sidebar .sub-menu li a {
  color: rgba(120, 129, 149, 0.5);
  line-height: 25px;
}
.secondary-sidebar .sub-menu li a:hover {
  color: #fff;
}
.secondary-sidebar .sub-menu li a .badge,
.secondary-sidebar .main-menu li a .badge {
  background: transparent;
  font-size: 13px;
  color: #788195;
  line-height: 25px;
}
.split-view {
  position: relative;
  height: 100%;
}
.split-view .split-list {
  float: right;
  width: 360px;
  background: #fff;
  height: 100%;
  overflow-y: auto;
  position: relative;
  -webkit-overflow-scrolling: touch;
  border-right: 1px solid #e6e6e6;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.split-view .split-list.slideLeft {
  -webkit-transform: translate(-100%, 0);
  -ms-transform: translate(-100%, 0);
  transform: translate(-100%, 0);
}
.split-view .split-list .list-refresh {
  position: absolute;
  left: 18px;
  top: 5px;
  color: #626262;
  opacity: 0.34;
  z-index: 101;
}
.split-view .split-list .list-view-fake-header,
.split-view .split-list .list-view-group-header {
  background: #f0f0f0;
  height: 30px;
  color: rgba(98, 98, 98, 0.7);
  font-family: 'Montserrat';
  text-transform: uppercase;
  font-size: 10.8px;
  padding-right: 13px;
  padding-top: 6px;
  letter-spacing: 0.04em;
  width: 100%;
}
.split-view .split-list .item {
  height: 117px;
  list-style: none;
  position: relative;
  border-bottom: 1px solid rgba(230, 230, 230, 0.7);
  cursor: pointer;
}
.split-view .split-list .item .inline {
  width: 230px;
}
.split-view .split-list .item .inline > * {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: arial;
}
.split-view .split-list .item .recipients {
  letter-spacing: 0.01em;
}
.split-view .split-list .item .checkbox {
  float: right;
  clear: left;
  display: none;
}
.split-view .split-list .item .subject {
  font-family: 'Helvetica';
  font-size: 14.33px;
  color: #3b4752;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 36px;
  display: -webkit-box;
  white-space: normal;
  line-height: 18px;
}
.split-view .split-list .item .body {
  font-size: 12.6px;
  opacity: 0.52;
  height: 22px;
}
.split-view .split-list .item .datetime {
  color: #121212;
  font-family: arial;
  font-size: 11.1px;
  position: absolute;
  left: 20px;
  top: 15px;
}
.split-view .split-details {
  position: relative;
  overflow: auto;
  height: 100%;
}
.split-view .split-details .no-result {
  bottom: 0;
  left: 0;
  margin-top: -34px;
  opacity: 0.5;
  position: absolute;
  right: 0;
  text-align: center;
  top: 50%;
}
.split-view .split-details .actions {
  height: 50px;
  float: right;
}
.split-view .split-details .actions li {
  list-style: none;
  position: relative;
}
.split-view .split-details .actions li:last-child:after {
  display: none;
}
.split-view .split-details .actions li:after {
  content: "";
  height: 14px;
  position: absolute;
  left: -4px;
  top: 18px;
  width: 1px;
  background: rgba(0, 0, 0, 0.07);
}
.split-view .split-details .actions li a {
  font-size: 13.1px;
  color: #626262;
  font-weight: 600;
  padding: 0 13px;
  line-height: 50px;
  white-space: nowrap;
}
.inner-content {
  margin-top: 0px;
  padding: 0px;
  overflow: auto;
  margin-right: 250px;
  min-height: 100%;
}
/* Quick View
------------------------------------
*/
.quickview-wrapper {
  position: fixed;
  left: -285px;
  top: 0;
  width: 285px;
  background: #fff;
  bottom: 0;
  z-index: 1000;
  box-shadow: 0 0 9px rgba(191, 191, 191, 0.36);
  border-left: 1px solid rgba(222, 227, 231, 0.56);
  -webkit-transition: -webkit-transform 400ms cubic-bezier(0.05, 0.74, 0.27, 0.99);
  transition: transform 400ms cubic-bezier(0.05, 0.74, 0.27, 0.99);
  -webkit-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-perspective: 1000;
}
.quickview-wrapper.open {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  -ms-transform: translate(100%, 0);
}
.quickview-wrapper .quickview-toggle {
  position: absolute;
  left: 12px;
  top: 2px;
  color: #788195;
  padding: 6px;
  opacity: 0.4;
}
.quickview-wrapper .quickview-toggle:hover {
  opacity: 1;
}
.quickview-wrapper .nav-tabs {
  /* Quickview Tabs */
  background-color: #2b303b;
  position: relative;
  padding: 0 43px;
}
.quickview-wrapper .nav-tabs > li > a {
  padding: 11px;
  color: #788195;
  opacity: 0.6;
  border: 0;
  text-align: center;
  font-size: 11px;
  font-weight: bold;
  min-width: 62px;
}
.quickview-wrapper .nav-tabs > li > a:hover {
  color: #788195;
  opacity: 1;
}
.quickview-wrapper .nav-tabs > li.active > a,
.quickview-wrapper .nav-tabs > li.active > a:hover,
.quickview-wrapper .nav-tabs > li.active > a:focus {
  background-color: transparent;
  border: 0;
  color: #cdd0d8;
  opacity: 1;
}
.quickview-wrapper .nav-tabs ~ .tab-content {
  position: absolute;
  padding: 0;
  left: 0;
  right: 0;
  top: 38px;
  bottom: 0;
  height: auto;
}
.quickview-wrapper .nav-tabs ~ .tab-content > div {
  height: 100%;
}
.quickview-wrapper .nav-tabs ~ .tab-content ul {
  margin: 0;
  padding: 0;
}
.quickview-wrapper .nav-tabs ~ .tab-content ul li {
  list-style: none;
}
.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes {
  /* Quickview Notes */
  background: #fbf9e3;
}
.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes a,
.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes button {
  color: #968974;
}
.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .list {
  position: relative;
}
.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .list .toolbar {
  height: 50px;
  padding: 0 25px;
}
.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .list .toolbar ul {
  margin-top: 10px;
}
.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .list .toolbar ul > li {
  display: inline-block;
  height: auto;
}
.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .list .toolbar ul > li a {
  height: 22px;
  line-height: 22px;
  display: block;
  padding: 0 5px;
}
.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .list .toolbar ul > li a:hover,
.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .list .toolbar ul > li a.selected {
  background: #968974;
  color: #FBF9E3;
}
.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .list .toolbar .btn-remove-notes {
  background: transparent;
  bottom: 8px;
  display: block;
  right: 50%;
  margin-right: -40%;
  position: absolute;
  width: 83%;
  border: 1px solid #968974;
}
.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .list > ul {
  padding: 0;
}
.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .list > ul li {
  cursor: pointer;
  height: 42px;
  padding: 0 25px;
  display: block;
  clear: both;
}
.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .list > ul li .left {
  float: right;
  width: 65%;
  height: 100%;
  padding-top: 9px;
}
.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .list > ul li .left .checkbox {
  display: none;
  float: right;
}
.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .list > ul li .left .checkbox label {
  margin-left: 0;
  vertical-align: text-top;
}
.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .list > ul li .left p {
  margin: 0;
  font-size: 13px;
  font-weight: bold;
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  display: inline-block;
}
.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .list > ul li .right {
  font-size: 10.5px;
  text-align: left;
  width: 35%;
  line-height: 41px;
}
.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .list > ul li .right .date {
  margin-left: 10px;
}
.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .list > ul li:hover {
  background: #f4ecd1;
}
.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .note {
  /* Quickview Note */
  background-repeat: repeat-y;
  background-position: 27px top;
}
.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .note > div {
  display: table;
  height: 100%;
  width: 100%;
}
.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .note .toolbar {
  background: #fcfcfa;
  height: 55px;
  width: 100%;
  display: table-row;
  box-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.33);
}
.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .note .toolbar > li {
  display: inline-block;
  border-right: 1px solid #EDECEC;
  float: right;
  line-height: 55px;
  padding: 0;
  text-align: center;
  width: 55px;
  height: auto;
}
.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .note .toolbar > li > a {
  color: #a5a5a5;
  display: block;
}
.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .note .toolbar > li > a:hover {
  background: #fffaf6;
}
.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .note .toolbar > li > a.active {
  color: #333;
  background: #f9f1ea;
}
.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .note .toolbar:after {
  position: absolute;
  content: "";
  width: 100%;
}
.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .note .body {
  display: table-row;
  height: 100%;
}
.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .note .body > div {
  display: table;
  height: 100%;
  width: 100%;
  padding: 0 45px 0 20px;
  white-space: normal;
}
.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .note .body .top {
  height: 50px;
  display: table-row;
  clear: both;
  line-height: 50px;
  text-align: center;
}
.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .note .body .top > a {
  float: right;
  color: #b0b0a8;
  margin-right: 10px;
}
.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .note .body .top > span {
  font-style: italic;
  color: #b0b0a8;
  font-size: 11px;
}
.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .note .body .content {
  display: table-row;
  height: 100%;
}
.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .note .body .content form {
  height: 100%;
}
.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .note .body .content .quick-note-editor {
  font-size: 12px;
}
.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .note .body .content .quick-note-editor:focus {
  outline: none;
}
.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .note .body .content .quick-note-editor::-moz-selection {
  background: #fef8ae;
}
.quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .note .body .content .quick-note-editor::selection {
  background: #fef8ae;
}
/* Page Container
------------------------------------
*/
.page-container {
  width: 100%;
  height: 100%;
  padding-right: 70px;
}
.page-container .page-content-wrapper {
  min-height: 100%;
  position: relative;
}
.page-container .page-content-wrapper .content {
  /* Content holder */
  z-index: 10;
  padding-top: 60px;
  padding-bottom: 72px;
  min-height: 100%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.page-container .page-content-wrapper .content.full-width {
  width: 100%;
}
.page-container .page-content-wrapper .content .content-inner {
  display: inline-block;
  vertical-align: top;
  height: 100%;
  padding-right: 30px;
  position: relative;
}
.page-container .page-content-wrapper .content:only-child {
  padding-bottom: 0px;
}
.page-container .page-content-wrapper .content.overlay-footer {
  padding-bottom: 0px;
}
.page-container .page-content-wrapper .footer {
  /* Footer */
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: left 0.3s ease;
  transition: left 0.3s ease;
}
.page-container .page-content-wrapper .footer.fixed {
  position: fixed;
}
.page-title {
  margin-top: 0px;
}
/* Breadcrumbs
------------------------------------
*/
.breadcrumb {
  font-family: 'Montserrat';
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  box-shadow: none;
  background-color: transparent;
  padding: 15px 0;
  margin: 0;
  border: none;
  text-transform: uppercase;
  display: block;
}
.breadcrumb a {
  margin-left: 5px;
  margin-right: 5px;
  font-family: 'Montserrat';
  font-size: 11px !important;
  font-weight: 400;
  color: #7b7d82;
}
.breadcrumb a.active {
  font-weight: 600;
  color: #e67e22;
}
.breadcrumb li {
  padding-right: 0px;
}
.breadcrumb > li + li:before {
  padding: 0 5px;
  color: #515050;
  font-family: FontAwesome;
  content: "\f105";
  font-weight: bold;
}
.breadcrumb a,
.breadcrumb i,
.breadcrumb span,
.breadcrumb li {
  color: #7b7d82;
  font-weight: 300;
  text-shadow: none;
}
/* Overlay Search
------------------------------------
*/
.overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.95);
  z-index: 1010;
  padding-right: 64px;
  overflow: auto;
}
.overlay .inline-block {
  display: inline-block;
}
.overlay .overlay-brand {
  margin-right: 7px;
}
.overlay > div {
  width: 100%;
  height: 260px;
  display: block;
  overflow: hidden;
}
.overlay .has-results {
  overflow: visible;
}
.overlay .overlay-search {
  font-weight: 700;
  font-size: 77px;
  height: 100px;
  letter-spacing: -1.925px;
  line-height: 100px;
  width: 100%;
  padding-right: 0 !important;
}
.overlay .overlay-close {
  position: absolute;
  left: 20px;
  top: 25px;
}
.group-container {
  white-space: nowrap !important;
}
.group-container .single-group {
  vertical-align: top;
  display: inline-block;
  white-space: normal;
}
.jumbotron {
  position: relative;
  overflow: hidden;
  display: block;
  padding: 0;
  background-color: #f0f0f0;
}
.jumbotron .inner {
  position: relative;
  z-index: 2;
}
.jumbotron.lg {
  height: 600px;
}
.jumbotron .cover-photo {
  width: 100%;
  height: inherit;
  overflow: hidden;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
  background-size: cover;
  background-color: #10cfbd;
}
.jumbotron .cover-photo img {
  display: none;
}
.jumbotron h1 {
  font-size: 44px;
}
/* Pages Image Icon Sent - Pixel Perfect
------------------------------------
*/
.icon-set {
  position: relative;
  display: block;
}
.icon-set.globe-fill {
  background-position: -1px -1px;
  width: 17px;
  height: 17px;
  top: 2px;
}
.icon-set.clip {
  background-position: -20px -2px;
  width: 16px;
  height: 16px;
}
.icon-set.grid-box {
  background-position: -41px -2px;
  width: 14px;
  height: 14px;
}
.icon-set.menu-hambuger {
  background-position: -58px -3px;
  width: 15px;
  height: 13px;
}
.icon-set.menu-hambuger-plus {
  background-position: -77px -1px;
  width: 18px;
  height: 15px;
}
.dropzone {
  overflow: hidden;
}
.dropzone .dz-default.dz-message {
  width: 100%;
}
.scroll {
  position: relative;
  overflow: auto;
}
/* Pages Scroll bar
------------------------------------
*/
.scroll-wrapper > .scroll-element,
.scroll-wrapper > .scroll-element div {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 10;
}
.scroll-wrapper > .scroll-element div {
  display: block;
  height: 100%;
  right: 0;
  top: 0;
  width: 100%;
}
.scroll-wrapper > .scroll-element.scroll-x {
  bottom: 2px;
  height: 7px;
  right: 0;
  min-width: 100%;
  width: 100%;
}
.scroll-wrapper > .scroll-element.scroll-y {
  height: 100%;
  min-height: 100%;
  left: 2px;
  top: 0;
  width: 4px;
}
.scroll-wrapper > .scroll-element .scroll-element_outer {
  opacity: 0.3;
}
.scroll-wrapper > .scroll-element .scroll-element_size {
  background-color: rgba(0, 0, 0, 0.07);
  opacity: 0;
}
.scroll-wrapper > .scroll-element .scroll-bar {
  background-color: #697686;
}
.scroll-wrapper > .scroll-element.scroll-x .scroll-bar {
  bottom: 0;
  height: 4px;
  min-width: 24px;
  top: auto;
}
.scroll-wrapper > .scroll-element.scroll-x .scroll-element_outer {
  bottom: 0;
  top: auto;
  right: 2px;
  -webkit-transition: height 0.2s;
  transition: height 0.2s;
}
.scroll-wrapper > .scroll-element.scroll-x .scroll-element_size {
  right: -4px;
}
.scroll-wrapper > .scroll-element.scroll-y .scroll-bar {
  left: auto;
  min-height: 24px;
  left: 0;
  width: 4px;
}
.scroll-wrapper > .scroll-element.scroll-y .scroll-element_outer {
  left: auto;
  left: 0;
  top: 2px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.scroll-wrapper > .scroll-element.scroll-y .scroll-element_size {
  top: -4px;
}
.scroll-wrapper.auto-hide > .scroll-element .scroll-element_track {
  display: none;
}
.scroll-wrapper > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size {
  right: -11px;
}
.scroll-wrapper > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size {
  top: -11px;
}
/* hover & drag */
.scroll-wrapper > .scroll-element:hover .scroll-element_outer,
.scroll-wrapper > .scroll-element.scroll-draggable .scroll-element_outer {
  overflow: hidden;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  filter: alpha(opacity=70);
  opacity: 0.7;
}
.scroll-wrapper > .scroll-element:hover .scroll-element_outer .scroll-element_size,
.scroll-wrapper > .scroll-element.scroll-draggable .scroll-element_outer .scroll-element_size {
  opacity: 1;
}
.scroll-wrapper > .scroll-element:hover .scroll-element_outer .scroll-bar,
.scroll-wrapper > .scroll-element.scroll-draggable .scroll-element_outer .scroll-bar {
  height: 100%;
  width: 100%;
}
.scroll-wrapper > .scroll-element.scroll-x:hover .scroll-element_outer,
.scroll-wrapper > .scroll-element.scroll-x.scroll-draggable .scroll-element_outer {
  height: 10px;
  min-height: 7px;
}
.scroll-wrapper > .scroll-element.scroll-y:hover .scroll-element_outer,
.scroll-wrapper > .scroll-element.scroll-y.scroll-draggable .scroll-element_outer {
  min-width: 7px;
  width: 7px;
}
/* Other overides */
.container-fluid {
  padding-left: 30px;
  padding-right: 30px;
}
.copyright {
  padding: 25px 0;
  border-top: 1px solid rgba(98, 98, 98, 0.07);
}
.navbar-center,
.navbar-center > li {
  float: none;
  display: inline-block;
  *display: inline;
  /* ie7 fix */
  *zoom: 1;
  /* hasLayout ie7 trigger */
  vertical-align: top;
}
.navbar-nav li a {
  min-width: 50px;
}
.pager {
  margin: 0;
}
/* Horizontal Menu */
@media (min-width: 992px) {
  .horizontal-menu [data-pages="sidebar"] + .page-container {
    padding-right: 70px;
  }
}
.horizontal-menu [data-pages="sidebar"] + .page-container .header .brand {
  padding-left: inherit;
  text-align: center;
}
.horizontal-menu #horizontal-menu-toggle {
  display: block !important;
}
.horizontal-menu .page-container {
  padding-right: 0;
}
.horizontal-menu .header .brand {
  padding-right: 35px;
  text-align: right;
}
.horizontal-menu .header-seperation {
  display: none;
}
.horizontal-menu .bar {
  width: 100%;
  background-color: #fff;
  position: fixed;
  display: table;
  z-index: 50;
}
.horizontal-menu .bar-inner {
  display: table-cell;
  width: 100%;
}
.horizontal-menu .bar-inner > ul {
  margin: 0;
  padding: 0;
  padding-right: 16px;
}
.horizontal-menu .bar-inner > ul > li {
  font-family: "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
  display: inline-block;
  padding: 10px 15px;
  vertical-align: middle;
}
.horizontal-menu .bar-inner > ul > li.classic {
  position: relative;
}
.horizontal-menu .bar-inner > ul > li.open > a {
  opacity: 1;
}
.horizontal-menu .bar-inner > ul > li.mega.open,
.horizontal-menu .bar-inner > ul > li.horizontal.open {
  position: inherit;
}
.horizontal-menu .bar-inner > ul > li > a {
  color: #000;
}
.horizontal-menu .bar-inner > ul > li > a > .arrow {
  display: inline-block;
}
.horizontal-menu .bar-inner > ul > li > a > .arrow:before {
  display: inline;
  font-size: 16px;
  font-family: FontAwesome;
  height: auto;
  content: "\f107";
  font-weight: 300;
  text-shadow: none;
  margin-right: 8px;
  opacity: 0.5;
  position: relative;
  vertical-align: middle;
}
.horizontal-menu .bar-inner > ul > li a {
  opacity: 0.7;
}
.horizontal-menu .bar-inner > ul > li a .description {
  opacity: 0.7;
  transition: opacity 0.1s linear 0s;
}
.horizontal-menu .bar-inner > ul > li a:hover {
  opacity: 1;
}
.horizontal-menu .bar-inner > ul > li a:hover .description {
  opacity: 1;
}
.horizontal-menu .bar-inner > ul > li.open {
  background: #fff;
}
.horizontal-menu .bar-inner > ul > li.open > .classic {
  max-height: 999px;
}
.horizontal-menu .bar-inner > ul > li.open > .classic > li {
  opacity: 1;
}
.horizontal-menu .bar-inner > ul > li.open > .mega,
.horizontal-menu .bar-inner > ul > li.open > .horizontal {
  display: block;
}
.horizontal-menu .bar-inner > ul > li > .classic {
  margin: 0;
  padding: 0;
  position: absolute;
  background-color: #fff;
  list-style: none;
  left: 0;
  right: 0;
  top: 40px;
  min-width: 220px;
  max-height: 0;
  overflow: hidden;
  -webkit-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
}
.horizontal-menu .bar-inner > ul > li > .classic > li {
  margin: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e5e9ec;
  color: #22262e;
  opacity: 0;
  -webkit-transition: all 0.1s linear 0.1s;
  transition: all 0.1s linear 0.1s;
}
.horizontal-menu .bar-inner > ul > li > .classic > li a {
  color: #000;
}
.horizontal-menu .bar-inner > ul > li > .classic > li a .description {
  display: block;
  font-size: 12px;
  color: #2c2c2c;
}
.horizontal-menu .bar-inner > ul > li > .classic > li:last-child {
  border-bottom: 0;
}
.horizontal-menu .bar-inner > ul > li > .horizontal {
  margin: 0;
  top: 40px;
  padding: 0;
  position: absolute;
  background-color: #fff;
  list-style: none;
  display: none;
  left: 0;
  right: 0;
  width: 100%;
  border-bottom: 1px solid #e5e9ec;
}
.horizontal-menu .bar-inner > ul > li > .horizontal li {
  margin: 15px;
  color: #000;
  display: inline-block;
}
.horizontal-menu .bar-inner > ul > li > .horizontal li a {
  color: #000;
}
.horizontal-menu .bar-inner > ul > li > .horizontal li a .description {
  display: block;
  font-size: 12px;
  color: #2c2c2c;
}
.horizontal-menu .bar-inner > ul > li > .mega {
  margin: 0;
  top: 40px;
  padding: 0;
  position: absolute;
  background-color: #fff;
  list-style: none;
  display: none;
  left: 0;
  right: 0;
  padding-bottom: 20px;
  border-bottom: 1px solid #e5e9ec;
}
.horizontal-menu .bar-inner > ul > li > .mega > li {
  margin: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e5e9ec;
  color: #000;
}
.horizontal-menu .bar-inner > ul > li > .mega > li a {
  color: #000;
}
.horizontal-menu .bar-inner > ul > li > .mega .sub-menu-heading {
  font-size: 14px;
  color: #000;
  margin-bottom: 10px;
  margin-top: 20px;
}
.horizontal-menu .bar-inner > ul > li > .mega .sub-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
}
.horizontal-menu .bar-inner > ul > li > .mega .sub-menu > li {
  padding-right: 0;
  padding-bottom: 5px;
}
.horizontal-menu .bar-inner > ul > li > .mega .sub-menu > li > a {
  color: #000;
}
@media (min-width: 980px) {
  .horizontal-menu .page-content {
    margin-right: 0;
  }
  .horizontal-menu .page-content .content {
    padding-top: 123px;
  }
}
/*------------------------------------------------------------------
[2. View Ports]
*/
.view-port {
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
  white-space: nowrap;
  word-spacing: 0;
  font-size: 0;
  overflow: hidden;
}
.view-port > * {
  font-size: initial;
}
.view-port .navbar {
  border-radius: 0;
  padding-left: 0;
  margin-bottom: 0;
  border-left: 0;
  display: table;
  width: 100%;
  top: 0;
  border-top: 0;
}
.view-port .navbar .navbar-inner {
  display: table-cell;
  height: 50px;
  vertical-align: middle;
}
.view-port .navbar .action {
  position: absolute;
  top: 0;
  line-height: 50px;
  z-index: 1;
}
.view-port .navbar .action.pull-right {
  left: 0;
}
.view-port .navbar .view-heading {
  font-size: 15px;
  text-align: center;
}
.view-port .navbar > p {
  line-height: 12px;
  font-size: 12px;
  margin: 0;
}
.view-port .navbar.navbar-sm {
  min-height: 35px;
}
.view-port .navbar.navbar-sm .navbar-inner {
  height: 35px;
}
.view-port .navbar.navbar-sm .action {
  line-height: 35px;
}
.view-port .view {
  display: inline-block;
  width: 100%;
  height: 100%;
  vertical-align: top;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.view-port .view:first-child:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0;
  -webkit-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
  z-index: -1;
}
.view-port .view:only-child {
  margin: 0;
}
.view-port.from-top > .view:last-child {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  -ms-transform: translate(100%, 0);
}
.view-port.push > .view:first-child {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  -ms-transform: translate(100%, 0);
}
.view-port.push > .view:last-child {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  -ms-transform: translate(100%, 0);
}
.view-port.push-parrallax > .view:first-child {
  -webkit-transition: all 400ms cubic-bezier(0.1, 0.7, 0.1, 1);
  transition: all 400ms cubic-bezier(0.1, 0.7, 0.1, 1);
  -webkit-transform: translate3d(25%, 0, 0);
  transform: translate3d(25%, 0, 0);
  -ms-transform: translate(25%, 0);
}
.view-port.push-parrallax > .view:first-child:before {
  opacity: 0;
  z-index: 100;
}
.view-port.push-parrallax > .view:last-child {
  -webkit-transition: all 400ms cubic-bezier(0.1, 0.7, 0.1, 1);
  transition: all 400ms cubic-bezier(0.1, 0.7, 0.1, 1);
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  -ms-transform: translate(100%, 0);
  box-shadow: 0 0 9px rgba(191, 191, 191, 0.36);
}
/*------------------------------------------------------------------
[5. Typography]
*/
/* Standard elements
--------------------------------------------------
*/
html {
  font-size: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
body {
  color: #626262;
  font-family: "Segoe UI", Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.01em;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
  -moz-font-feature-settings: "kern" 1;
}
/* Headings 
------------------------------------
*/
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 10px 0;
  font-family: "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-weight: 300;
  color: #2c2c2c;
}
h1 {
  font-size: 44px;
  line-height: 55px;
  letter-spacing: -0.08px;
}
h2 {
  font-size: 31px;
  line-height: 40px;
}
h3 {
  font-size: 27px;
  line-height: 35px;
}
h4 {
  font-size: 22px;
  line-height: 31.88px;
}
h5 {
  font-size: 18px;
  line-height: 25.88px;
}
h3 small,
h4 small,
h5 small {
  font-weight: 300;
}
h1.block,
h2.block,
h3.block,
h4.block,
h5.block,
h6.block {
  padding-bottom: 10px;
}
/* Lins and Others
------------------------------------
*/
a {
  text-shadow: none !important;
  color: #3a8fc8;
  transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}
a:focus,
a:hover,
a:active {
  color: #48b0f7;
}
a,
a:focus,
a:hover,
a:active {
  outline: 0 !important;
  text-decoration: none;
}
a[ng-click] {
  cursor: pointer;
}
br {
  line-height: normal;
  clear: both;
}
code {
  color: #c64643;
  background-color: #f0f0f0;
}
code:hover {
  background-color: #fddddd;
}
p {
  display: block;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.01em;
  line-height: 22px;
  margin: 0px 0px 10px 0px;
  font-style: normal;
  white-space: normal;
}
small,
.small {
  line-height: 18px;
}
label.inline {
  display: inline-block;
  position: relative;
  top: 0px;
  font-size: 13px;
}
ul > li,
ol > li {
  padding-right: 3px;
  line-height: 24px;
}
ul.lg-icon > li,
ol.lg-icon > li {
  font-size: 21px;
}
ul.lg-icon > li span,
ol.lg-icon > li span {
  font-size: 14px;
}
ul.no-style,
ol.no-style {
  list-style: none;
  padding-right: 5px;
}
address {
  margin-bottom: 0px;
}
address a {
  color: #626262;
}
blockquote {
  padding: 0 0 0 18px;
  border-left: 0;
}
blockquote:before {
  content: '';
  font-family: FontAwesome;
  content: "\f10d";
  margin-left: 13px;
  float: right;
}
blockquote p {
  font-size: 16px;
}
blockquote small {
  line-height: 29px;
  color: #8b91a0;
  padding-right: 30px;
}
blockquote small:before {
  content: "";
}
blockquote.pull-right {
  border-right: 0;
}
blockquote.pull-right:before {
  float: right;
  content: '';
  font-family: FontAwesome;
  content: "\f10d";
  margin-left: 13px;
  margin-right: 0;
}
blockquote.pull-right small {
  padding-right: 30px;
}
blockquote.pull-right small:after {
  content: "";
}
hr {
  border-color: #e6e6e6;
}
hr.double {
  border-width: 2px;
}
hr.dotted {
  border-style: dotted none none;
}
/* Font Sizes 
------------------------------------
*/
.small-text {
  font-size: 12px !important;
}
.normal-text {
  font-size: 13px !important;
}
.large-text {
  font-size: 15px !important;
}
/* Font Weights
------------------------------------
 */
.normal {
  font-weight: normal;
}
.semi-bold {
  font-weight: 400 !important;
}
.bold {
  font-weight: bold !important;
}
.light {
  font-weight: 300 !important;
}
/* Misc 
------------------------------------
*/
.logo {
  margin: 18px 14px;
}
.all-caps {
  text-transform: uppercase;
}
.muted {
  color: #e2e2e2;
}
.hint-text {
  opacity: 0.7;
}
.no-decoration {
  text-decoration: none !important;
}
/* Monochrome Colors
------------------------------------
 */
.bg-master {
  background-color: #626262;
}
.bg-master-light {
  background-color: #e6e6e6;
}
.bg-master-lighter {
  background-color: #f0f0f0;
}
.bg-master-lightest {
  background-color: #fafafa;
}
.bg-master-dark {
  background-color: #2c2c2c;
}
.bg-master-darker {
  background-color: #1a1a1a;
}
.bg-master-darkest {
  background-color: #121212;
}
/* Contextual Colors
------------------------------------
*/
/* Primary
------------------------------------
*/
.bg-primary {
  background-color: #e67e22;
}
.bg-primary-dark {
  background-color: #ba661c;
}
.bg-primary-darker {
  background-color: #8a4c14;
}
.bg-primary-light {
  background-color: #eb984e;
}
.bg-primary-lighter {
  background-color: #fae5d3;
}
/* Complete 
------------------------------------
*/
.bg-complete {
  background-color: #48b0f7;
}
.bg-complete-dark {
  background-color: #3a8fc8;
}
.bg-complete-darker {
  background-color: #2b6a94;
}
.bg-complete-light {
  background-color: #6dc0f9;
}
.bg-complete-lighter {
  background-color: #daeffd;
}
/* Success 
------------------------------------
*/
.bg-success {
  background-color: #10cfbd;
}
.bg-success-dark {
  background-color: #0da899;
}
.bg-success-darker {
  background-color: #0a7c71;
}
.bg-success-light {
  background-color: #40d9ca;
}
.bg-success-lighter {
  background-color: #cff5f2;
}
/* Info
------------------------------------
*/
.bg-info {
  background-color: #3b4752;
}
.bg-info-dark {
  background-color: #303a42;
}
.bg-info-darker {
  background-color: #232b31;
}
.bg-info-light {
  background-color: #626c75;
}
.bg-info-lighter {
  background-color: #d8dadc;
}
/* Danger 
------------------------------------
*/
.bg-danger {
  background-color: #f55753;
}
.bg-danger-dark {
  background-color: #c64643;
}
.bg-danger-darker {
  background-color: #933432;
}
.bg-danger-light {
  background-color: #f77975;
}
.bg-danger-lighter {
  background-color: #fddddd;
}
/* Warning
------------------------------------
 */
.bg-warning {
  background-color: #f8d053;
}
.bg-warning-dark {
  background-color: #c9a843;
}
.bg-warning-darker {
  background-color: #957d32;
}
.bg-warning-light {
  background-color: #f9d975;
}
.bg-warning-lighter {
  background-color: #fef6dd;
}
/* More Color Options
------------------------------------
*/
/* Menu 
------------------------------------
*/
.bg-menu-dark {
  background-color: #21252d;
}
.bg-menu {
  background-color: #2b303b;
}
.bg-menu-light {
  background-color: #788195;
}
/* Gradients
------------------------------------
*/
.gradient-grey {
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 75%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 75%);
}
.gradient-black {
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 75%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 75%);
}
/* Other Colors
------------------------------------
*/
.bg-white {
  background-color: #fff;
}
.bg-transparent {
  background-color: transparent !important;
}
/* Text Colors */
.link {
  opacity: 0.7;
}
.link:hover {
  opacity: 1;
}
.text-master {
  color: #626262 !important;
}
.text-master-light {
  color: #e6e6e6 !important;
}
.text-black {
  color: #2c2c2c !important;
}
.text-white {
  color: #fff !important;
}
.text-complete {
  color: #48b0f7 !important;
}
.text-success {
  color: #10cfbd !important;
}
.text-info {
  color: #3b4752 !important;
}
.text-warning {
  color: #f8d053 !important;
}
.text-warning-dark {
  color: #c9a843 !important;
}
.text-danger {
  color: #f55753 !important;
}
.text-primary {
  color: #e67e22 !important;
}
/* Text Aligngments
------------------------------------
*/
.text-right {
  text-align: left !important;
}
.text-left {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
/* Labels
------------------------------------
*/
.label {
  padding: 3px 9px;
  font-size: 11px;
  text-shadow: none;
  background-color: #e6e6e6;
  font-weight: 600;
  color: #626262;
}
.label-success {
  background-color: #10cfbd;
  color: #fff;
}
.label-warning {
  background-color: #f8d053;
  color: #fff;
}
.label-important,
.label-danger {
  background-color: #f55753;
  color: #fff;
}
.label-info {
  background-color: #48b0f7;
  color: #fff;
}
.label-inverse {
  background-color: #3a8fc8;
  color: #fff;
}
.label-white {
  background-color: #fff;
  color: #626262;
}
/* Font Sizes
------------------------------------
*/
.fs-10 {
  font-size: 10px !important;
}
.fs-11 {
  font-size: 11px !important;
}
.fs-12 {
  font-size: 12px !important;
}
.fs-13 {
  font-size: 13px !important;
}
.fs-14 {
  font-size: 14px !important;
}
.fs-15 {
  font-size: 15px !important;
}
.fs-16 {
  font-size: 16px !important;
}
/* Line-heights
------------------------------------
*/
.lh-normal {
  line-height: normal;
}
.lh-10 {
  line-height: 10px;
}
.lh-11 {
  line-height: 11px;
}
.lh-12 {
  line-height: 12px;
}
.lh-13 {
  line-height: 13px;
}
.lh-14 {
  line-height: 14px;
}
.lh-15 {
  line-height: 15px;
}
.lh-16 {
  line-height: 16px;
}
/* Font Faces
------------------------------------
*/
.font-arial {
  font-family: Arial, sans-serif !important;
}
.font-montserrat {
  font-family: 'Montserrat' !important;
}
.font-georgia {
  font-family: Georgia !important;
}
.font-heading {
  font-family: "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
/* Wells
------------------------------------
*/
.well {
  background-color: #e6e6e6;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  border: none;
  background-image: none;
}
.well.well-large {
  padding: 24px;
  width: auto;
}
.well.well-small {
  padding: 13px;
  width: auto;
}
.well.green {
  background-color: #48b0f7;
  color: #fff;
  border: none;
}
.overflow-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.jumbotron p {
  font-size: 14px;
  font-weight: normal;
  margin-bottom: inherit;
}
.jumbotron p.small {
  font-size: 85%;
}
/* Responsive Handlers : Typo
------------------------------------
*/
@media (max-width: 1400px) {
  body,
  p {
    font-size: 13px;
    line-height: 20px;
  }
  h1 {
    font-size: 33px;
    line-height: 44px;
    letter-spacing: -0.08px;
  }
  h2 {
    font-size: 28px;
    line-height: 40px;
  }
  h3 {
    font-size: 24px;
    line-height: 35.88px;
  }
  h4 {
    font-size: 18px;
    line-height: 33.88px;
  }
  h5 {
    font-size: 16px;
    line-height: 25.88px;
  }
  small,
  .small {
    font-size: 89%;
    line-height: 17px;
  }
}
/* For Windows : Fixes 
------------------------------------
*/
.windows body,
.windows p {
  font-size: 13px;
  letter-spacing: normal;
}
.windows h1 {
  font-size: 33px;
  line-height: 49px;
}
.windows h2 {
  font-size: 29px;
  line-height: 40px;
}
.windows h3 {
  font-size: 29px;
  line-height: 33px;
}
.windows h4 {
  font-size: 23px;
  line-height: 32px;
}
.windows h5 {
  font-size: 19px;
  line-height: 28px;
  font-weight: normal;
}
.windows h1,
.windows h2,
.windows h3,
.windows h4,
.windows h5 {
  font-weight: 300;
  letter-spacing: normal;
}
.windows .jumbotron p {
  font-size: 13px;
}
.windows .jumbotron p.small {
  font-size: 88%;
}
.windows small,
.windows .small {
  font-size: 89%;
}
/*------------------------------------------------------------------
[6. Buttons]
*/
/*
[Buttons Base Styles]
*/
.btn {
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.01em;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
  -moz-font-feature-settings: "kern" 1;
  margin-bottom: 0;
  border: 1px solid #f0f0f0;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  background-image: none !important;
  color: #626262;
  background-color: #fff;
  text-shadow: none;
  box-shadow: none;
  line-height: 21px;
  padding-right: 17px;
  padding-left: 17px;
  position: relative;
  transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}
.btn:hover {
  background-color: #fafafa;
  border: 1px solid rgba(98, 98, 98, 0.27);
}
.btn.active {
  border-color: #e6e6e6;
  background: #fff;
}
.btn:focus,
.btn:active:focus,
.btn.active:focus {
  outline: none !important;
  outline-style: none;
}
.btn .caret {
  margin-right: 3px;
}
.btn .caret.single {
  margin-right: 0px;
}
.btn:hover,
.btn:focus,
.btn:active,
.btn.active,
.btn.disabled,
.btn[disabled] {
  box-shadow: none;
}
button:focus {
  outline: none !important;
}
/*
Alternate buttons
--------------------------------------------------
*/
.btn-primary,
.btn-primary:focus {
  color: #fff;
  background-color: #e67e22;
  border-color: #e67e22;
}
.btn-primary.active,
.btn-primary:active,
.btn-primary.active:focus,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open .dropdown-toggle.btn-primary {
  background-color: #ba661c;
  border-color: #ba661c;
  color: #fff;
}
.btn-primary.hover,
.btn-primary:hover,
.open .dropdown-toggle.btn-primary {
  background-color: #eb984e;
  border-color: #eb984e;
  color: #fff;
}
.btn-primary.active:hover {
  background: #c66c1d;
  border-color: #c66c1d;
}
.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
  background-color: #e67e22;
  border-color: #e67e22;
}
.btn-primary .badge {
  color: #e67e22;
  background-color: #fff;
}
.btn-success,
.btn-success:focus {
  color: #fff;
  background-color: #10cfbd;
  border-color: #10cfbd;
}
.btn-success.active,
.btn-success:active,
.btn-success.active:focus,
.btn-success:active:focus,
.btn-success:active:hover,
.open .dropdown-toggle.btn-success {
  background-color: #0da899;
  border-color: #0da899;
  color: #fff;
}
.btn-success.hover,
.btn-success:hover,
.open .dropdown-toggle.btn-success {
  background-color: #40d9ca;
  border-color: #40d9ca;
  color: #fff;
}
.btn-success.active:hover {
  background: #0eb2a3;
  border-color: #0eb2a3;
}
.btn-success.disabled,
.btn-success[disabled],
fieldset[disabled] .btn-success,
.btn-success.disabled:hover,
.btn-success[disabled]:hover,
fieldset[disabled] .btn-success:hover,
.btn-success.disabled:focus,
.btn-success[disabled]:focus,
fieldset[disabled] .btn-success:focus,
.btn-success.disabled:active,
.btn-success[disabled]:active,
fieldset[disabled] .btn-success:active,
.btn-success.disabled.active,
.btn-success[disabled].active,
fieldset[disabled] .btn-success.active {
  background-color: #10cfbd;
  border-color: #10cfbd;
}
.btn-success .badge {
  color: #10cfbd;
  background-color: #fff;
}
.btn-complete,
.btn-complete:focus {
  color: #fff;
  background-color: #48b0f7;
  border-color: #48b0f7;
}
.btn-complete.active,
.btn-complete:active,
.btn-complete.active:focus,
.btn-complete:active:focus,
.btn-complete:active:hover,
.open .dropdown-toggle.btn-complete {
  background-color: #3a8fc8;
  border-color: #3a8fc8;
  color: #fff;
}
.btn-complete.hover,
.btn-complete:hover,
.open .dropdown-toggle.btn-complete {
  background-color: #6dc0f9;
  border-color: #6dc0f9;
  color: #fff;
}
.btn-complete.active:hover {
  background: #3e97d4;
  border-color: #3e97d4;
}
.btn-complete.disabled,
.btn-complete[disabled],
fieldset[disabled] .btn-complete,
.btn-complete.disabled:hover,
.btn-complete[disabled]:hover,
fieldset[disabled] .btn-complete:hover,
.btn-complete.disabled:focus,
.btn-complete[disabled]:focus,
fieldset[disabled] .btn-complete:focus,
.btn-complete.disabled:active,
.btn-complete[disabled]:active,
fieldset[disabled] .btn-complete:active,
.btn-complete.disabled.active,
.btn-complete[disabled].active,
fieldset[disabled] .btn-complete.active {
  background-color: #48b0f7;
  border-color: #48b0f7;
}
.btn-complete .badge {
  color: #48b0f7;
  background-color: #fff;
}
.btn-info,
.btn-info:focus {
  color: #fff;
  background-color: #3b4752;
  border-color: #3b4752;
}
.btn-info.active,
.btn-info:active,
.btn-info.active:focus,
.btn-info:active:focus,
.btn-info:active:hover,
.open .dropdown-toggle.btn-info {
  background-color: #303a42;
  border-color: #303a42;
  color: #fff;
}
.btn-info.hover,
.btn-info:hover,
.open .dropdown-toggle.btn-info {
  background-color: #626c75;
  border-color: #626c75;
  color: #fff;
}
.btn-info.active:hover {
  background: #333d47;
  border-color: #333d47;
}
.btn-info.disabled,
.btn-info[disabled],
fieldset[disabled] .btn-info,
.btn-info.disabled:hover,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info:hover,
.btn-info.disabled:focus,
.btn-info[disabled]:focus,
fieldset[disabled] .btn-info:focus,
.btn-info.disabled:active,
.btn-info[disabled]:active,
fieldset[disabled] .btn-info:active,
.btn-info.disabled.active,
.btn-info[disabled].active,
fieldset[disabled] .btn-info.active {
  background-color: #3b4752;
  border-color: #3b4752;
}
.btn-info .badge {
  color: #3b4752;
  background-color: #fff;
}
.btn-warning,
.btn-warning:focus {
  color: #fff;
  background-color: #f8d053;
  border-color: #f8d053;
}
.btn-warning.active,
.btn-warning:active,
.btn-warning.active:focus,
.btn-warning:active:focus,
.btn-warning:active:hover,
.open .dropdown-toggle.btn-warning {
  background-color: #c9a843;
  border-color: #c9a843;
  color: #fff;
}
.btn-warning.hover,
.btn-warning:hover,
.open .dropdown-toggle.btn-warning {
  background-color: #f9d975;
  border-color: #f9d975;
  color: #fff;
}
.btn-warning.active:hover {
  background: #d5b347;
  border-color: #d5b347;
}
.btn-warning.disabled,
.btn-warning[disabled],
fieldset[disabled] .btn-warning,
.btn-warning.disabled:hover,
.btn-warning[disabled]:hover,
fieldset[disabled] .btn-warning:hover,
.btn-warning.disabled:focus,
.btn-warning[disabled]:focus,
fieldset[disabled] .btn-warning:focus,
.btn-warning.disabled:active,
.btn-warning[disabled]:active,
fieldset[disabled] .btn-warning:active,
.btn-warning.disabled.active,
.btn-warning[disabled].active,
fieldset[disabled] .btn-warning.active {
  background-color: #f8d053;
  border-color: #f8d053;
}
.btn-warning .badge {
  color: #f8d053;
  background-color: #fff;
}
.btn-danger,
.btn-danger:focus {
  color: #fff;
  background-color: #f55753;
  border-color: #f55753;
}
.btn-danger.active,
.btn-danger:active,
.btn-danger.active:focus,
.btn-danger:active:focus,
.btn-danger:active:hover,
.open .dropdown-toggle.btn-danger {
  background-color: #c64643;
  border-color: #c64643;
  color: #fff;
}
.btn-danger.hover,
.btn-danger:hover,
.open .dropdown-toggle.btn-danger {
  background-color: #f77975;
  border-color: #f77975;
  color: #fff;
}
.btn-danger.active:hover {
  background: #d34b47;
  border-color: #d34b47;
}
.btn-danger.disabled,
.btn-danger[disabled],
fieldset[disabled] .btn-danger,
.btn-danger.disabled:hover,
.btn-danger[disabled]:hover,
fieldset[disabled] .btn-danger:hover,
.btn-danger.disabled:focus,
.btn-danger[disabled]:focus,
fieldset[disabled] .btn-danger:focus,
.btn-danger.disabled:active,
.btn-danger[disabled]:active,
fieldset[disabled] .btn-danger:active,
.btn-danger.disabled.active,
.btn-danger[disabled].active,
fieldset[disabled] .btn-danger.active {
  background-color: #f55753;
  border-color: #f55753;
}
.btn-danger .badge {
  color: #f55753;
  background-color: #fff;
}
.btn-default,
.btn-default:focus {
  color: #5e5e5e;
  background-color: #fff;
  border-color: #f0f0f0;
}
.btn-default.active,
.btn-default:active,
.btn-default.active:focus,
.btn-default:active:focus,
.btn-default:active:hover {
  background-color: #f0f0f0;
  border-color: #e6e6e6;
  color: #2c2c2c;
}
.btn-default.hover,
.btn-default:hover {
  background-color: #fafafa;
  border-color: rgba(98, 98, 98, 0.27);
  color: #1a1a1a;
}
.btn-default.active:hover {
  background: #f0f0f0;
}
.btn-link {
  color: #5e5e5e;
  background-color: transparent;
  border: none;
}
.btn-link:hover,
.btn-link:focus,
.btn-link:active,
.btn-link.active,
.btn-link.disabled,
.btn-link[disabled] {
  background-color: transparent;
  border: none;
  text-decoration: none;
  outline: none;
}
.btn-file {
  position: relative;
  overflow: hidden;
}
.btn-file input[type=file] {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  background: red;
  cursor: inherit;
  display: block;
}
/*
Button Sizes
--------------------------------------------------
*/
.btn-lg,
.btn-group-lg > .btn {
  padding-right: 28px;
  padding-left: 28px;
  line-height: 23px;
}
.btn-sm,
.btn-group-sm > .btn {
  padding-right: 16px;
  padding-left: 16px;
  font-size: 11.9px;
  line-height: 20px;
}
.btn-xs,
.btn-group-xs > .btn {
  padding: 2px 9px;
  font-size: 10.5px;
}
.btn-cons {
  margin-left: 5px;
  min-width: 120px;
}
/*
Rounded buttons
--------------------------------------------------
*/
.btn-rounded {
  border-radius: 100px;
}
/*
 Dropdown menus
--------------------------------------------------
*/
.btn-group.open .dropdown-toggle,
.open .dropdown-toggle,
.open.dropdown-default .dropdown-toggle {
  box-shadow: none;
}
/* Pages default dropdown */
.dropdown-default {
  display: inline-block;
}
.dropdown-default.open > .btn.dropdown-toggle {
  border-color: transparent !important;
  background: transparent !important;
  z-index: 791 !important;
}
.dropdown-default.open .dropdown-menu {
  opacity: 1;
  transform: scale(1, 1);
  z-index: 700;
}
.dropdown-default.open .dropdown-menu li {
  visibility: visible;
}
.dropdown-default.open.dropup > .btn-primary + .dropdown-menu:after,
.dropdown-default.open.dropup > .btn-success + .dropdown-menu:after,
.dropdown-default.open.dropup > .btn-complete + .dropdown-menu:after,
.dropdown-default.open.dropup > .btn-warning + .dropdown-menu:after,
.dropdown-default.open.dropup > .btn-danger + .dropdown-menu:after,
.dropdown-default.open.dropup > .btn-info + .dropdown-menu:after {
  top: auto;
  bottom: 0;
}
.dropdown-default.open > .btn-primary + .dropdown-menu:after,
.dropdown-default.open > .btn-success + .dropdown-menu:after,
.dropdown-default.open > .btn-complete + .dropdown-menu:after,
.dropdown-default.open > .btn-warning + .dropdown-menu:after,
.dropdown-default.open > .btn-danger + .dropdown-menu:after,
.dropdown-default.open > .btn-info + .dropdown-menu:after {
  top: 0;
  height: 42px;
}
.dropdown-default.open > .btn-primary + .dropdown-menu:after {
  background-color: #e67e22;
}
.dropdown-default.open > .btn-success + .dropdown-menu:after {
  background-color: #10cfbd;
}
.dropdown-default.open > .btn-complete + .dropdown-menu:after {
  background-color: #48b0f7;
}
.dropdown-default.open > .btn-warning + .dropdown-menu:after {
  background-color: #f8d053;
}
.dropdown-default.open > .btn-danger + .dropdown-menu:after {
  background-color: #f55753;
}
.dropdown-default.open > .btn-info + .dropdown-menu:after {
  background-color: #3b4752;
}
.dropdown-default.dropup .btn.dropdown-toggle.btn-lg + .dropdown-menu {
  margin-bottom: -47px;
  padding-bottom: 49px;
}
.dropdown-default.dropup .btn.dropdown-toggle.btn-lg + .dropdown-menu:after {
  bottom: 49px;
}
.dropdown-default.dropup .btn.dropdown-toggle.btn-sm + .dropdown-menu {
  margin-bottom: -34px;
  padding-bottom: 36px;
}
.dropdown-default.dropup .btn.dropdown-toggle.btn-sm + .dropdown-menu:after {
  bottom: 36px;
}
.dropdown-default.dropup .btn.dropdown-toggle.btn-xs + .dropdown-menu {
  margin-bottom: -29px;
  padding-bottom: 31px;
}
.dropdown-default.dropup .btn.dropdown-toggle.btn-xs + .dropdown-menu:after {
  bottom: 31px;
}
.dropdown-default.dropup .dropdown-menu {
  margin-bottom: -40px;
  padding: 0 0 44px 3px !important;
  transform-origin: center bottom 0;
}
.dropdown-default.dropup .dropdown-menu:after {
  bottom: 43px;
  top: auto !important;
}
.dropdown-default .btn.dropdown-toggle {
  text-align: left;
  padding-left: 27px;
}
.dropdown-default .btn.dropdown-toggle.btn-lg {
  padding-left: 42px;
}
.dropdown-default .btn.dropdown-toggle.btn-lg .caret {
  left: 26px;
}
.dropdown-default .btn.dropdown-toggle.btn-lg + .dropdown-menu {
  margin-top: -47px;
  padding-top: 49px;
}
.dropdown-default .btn.dropdown-toggle.btn-lg + .dropdown-menu:after {
  top: 49px;
}
.dropdown-default .btn.dropdown-toggle.btn-sm {
  padding-left: 26px;
}
.dropdown-default .btn.dropdown-toggle.btn-sm .caret {
  left: 16px;
}
.dropdown-default .btn.dropdown-toggle.btn-sm + .dropdown-menu {
  margin-top: -34px;
  padding-top: 36px;
}
.dropdown-default .btn.dropdown-toggle.btn-sm + .dropdown-menu:after {
  top: 36px;
}
.dropdown-default .btn.dropdown-toggle.btn-xs {
  padding-left: 21px;
}
.dropdown-default .btn.dropdown-toggle.btn-xs .caret {
  left: 8px;
}
.dropdown-default .btn.dropdown-toggle.btn-xs + .dropdown-menu {
  margin-top: -29px;
  padding-top: 31px;
}
.dropdown-default .btn.dropdown-toggle.btn-xs + .dropdown-menu:after {
  top: 31px;
}
.dropdown-default .btn.dropdown-toggle .caret {
  position: absolute;
  left: 15px;
  top: 50%;
  margin-top: -2px;
}
.dropdown-default .btn-rounded {
  padding-right: 17px;
  padding-left: 17px;
}
.dropdown-default .btn-rounded + .dropdown-menu {
  border-radius: 17px;
}
.dropdown-default .dropdown-menu {
  margin-top: -40px;
  padding-top: 42px;
  overflow: hidden;
  backface-visibility: hidden;
  display: block;
  opacity: 0;
  transform: scale(1, 0);
  transform-origin: center top 0;
  -webkit-transition: all 170ms cubic-bezier(0.05, 0.74, 0.27, 0.99) 0s;
  transition: all 170ms cubic-bezier(0.05, 0.74, 0.27, 0.99) 0s;
  z-index: -1;
}
.dropdown-default .dropdown-menu:after {
  content: "";
  position: absolute;
  height: 1px;
  right: 0;
  top: 42px;
  background: #e6e6e6;
  width: 100%;
}
.dropdown-default .dropdown-menu li {
  visibility: hidden;
}
.dropdown-menu {
  position: absolute;
  display: none;
  float: left;
  list-style: none;
  text-shadow: none;
  -webkit-box-shadow: 0px 0px 5px rgba(98, 98, 98, 0.2);
  box-shadow: 0px 0px 5px rgba(98, 98, 98, 0.2);
  border: none;
  border-radius: 3px;
  font-size: 13px;
  margin: 0;
  background: #fafafa;
  min-width: 50px;
  z-index: 700 !important;
}
.dropdown-menu .divider {
  background-color: #e6e6e6;
  height: 1px;
  margin: 3px 0;
  border-bottom: 0px;
}
.dropdown-menu > li {
  padding-left: 0px;
}
.dropdown-menu > li:first-child {
  padding-top: 9px;
}
.dropdown-menu > li:last-child {
  padding-bottom: 9px;
}
.dropdown-menu > li.dropdown-header {
  padding: 3px 20px;
}
.dropdown-menu > li.active > a,
.dropdown-menu > li.active > a:hover,
.dropdown-menu > li.active > a:focus {
  color: #1a1a1a;
  text-decoration: none;
  background-color: #f0f0f0;
  background-image: none;
}
.dropdown-menu > li.disabled > a,
.dropdown-menu > li.disabled > a:hover,
.dropdown-menu > li.disabled > a:focus {
  color: #959595;
}
.dropdown-menu > li.disabled > a:hover,
.dropdown-menu > li.disabled > a:focus {
  text-decoration: none;
  cursor: default;
  background-color: transparent;
  background-image: none;
}
.dropdown-menu > li > a {
  line-height: 35px;
  color: #626262;
  padding: 0 20px;
  border-radius: 3px;
  text-align: right;
}
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  color: #1a1a1a;
  text-decoration: none;
  background-color: transparent;
  background-image: none;
}
.dropdown-backdrop {
  z-index: 600;
}
/*
Animated buttons
--------------------------------------------------
*/
.btn-animated {
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.btn-animated > span {
  display: inline-block;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.btn-animated:before {
  position: absolute;
  height: 100%;
  width: 100%;
  font-size: 100%;
  line-height: 2.5;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.btn-animated:after {
  content: '';
  position: absolute;
  z-index: -1;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.btn-animated.from-top:before {
  right: 0;
  top: -100%;
}
.btn-animated.from-top:hover:before,
.btn-animated.from-top.show-icon:before {
  top: 0;
}
.btn-animated.from-top:hover > span,
.btn-animated.from-top.show-icon > span {
  -webkit-transform: translateY(300%);
  -ms-transform: translateY(300%);
  transform: translateY(300%);
}
.btn-animated.from-left:before {
  right: -100%;
  top: 0;
}
.btn-animated.from-left:hover:before,
.btn-animated.from-left.show-icon:before {
  right: 0;
}
.btn-animated.from-left:hover > span,
.btn-animated.from-left.show-icon > span {
  -webkit-transform: translateX(-200%);
  -ms-transform: translateX(-200%);
  transform: translateX(-200%);
}
.btn-animated.fa:before {
  font-family: FontAwesome;
}
.btn-animated.pg:before {
  font-family: "pages-icon";
}
/*
Tag buttons
--------------------------------------------------
*/
.btn-tag {
  line-height: 17px;
  border-radius: 17px 3px 3px 17px;
  padding: 5px 19px;
}
.btn-tag:hover,
.btn-tag.hover {
  border-color: transparent;
}
.btn-tag.btn-tag-light {
  background: #fafdff;
  color: #5b8ca5;
  border: 1px solid #cbe4f0;
}
.btn-tag.btn-tag-light:hover {
  background: #fff;
}
.btn-tag.btn-tag-dark {
  background: #e6e6e6;
  color: #626262;
}
.btn-tag.btn-tag-dark:hover {
  background: #ebebeb;
}
.btn-tag.btn-tag-rounded {
  border-radius: 17px;
}
/*
Misc buttons
--------------------------------------------------
*/
.btn-toolbar .btn {
  padding-left: 14px;
  padding-right: 14px;
}
.pager .disabled > button,
.pager .disabled > button:hover,
.pager .disabled > button:focus,
.pager .disabled > span {
  cursor: not-allowed;
  opacity: 0.5;
}
/*------------------------------------------------------------------
[8. Notifications]
*/
/* Badges
--------------------------------------------------
*/
.badge {
  text-shadow: none;
  font-family: wf_segoe-ui_light, wf_segoe-ui_normal, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  background-color: #e6e6e6;
  font-size: 11px;
  padding-left: 6px;
  padding-right: 6px;
  padding-bottom: 4px;
  color: #626262;
}
.badge-success {
  background-color: #10cfbd;
  color: #fff;
}
.badge-warning {
  background-color: #f8d053;
  color: #fff;
}
.badge-important {
  background-color: #f55753;
  color: #fff;
}
.badge-danger {
  background-color: #f55753;
  color: #fff;
}
.badge-info {
  background-color: #3b4752;
  color: #fff;
}
.badge-inverse {
  background-color: #2b6a94;
  color: #fff;
}
.badge-white {
  background-color: #fff;
  color: #626262;
}
.badge-disable {
  background-color: #2c2c2c;
  color: #626262;
}
/* Notification popup
--------------------------------------------------
*/
.popover {
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 9px rgba(191, 191, 191, 0.36);
  z-index: 790;
}
.notification-toggle {
  top: 35px;
  right: -26px;
  padding: 0;
}
.notification-toggle:before {
  border-bottom: 0px !important;
}
.notification-toggle:after {
  border-bottom: 0px !important;
}
.notification-panel {
  background-color: #fff;
  border: 1px solid #e6e6e6;
}
.notification-panel .notification-body {
  height: auto;
  max-height: 350px;
  position: relative;
  overflow: hidden;
}
.notification-panel .notification-body .notification-item {
  position: relative;
  margin-right: 25px;
  background-color: #fff;
  padding-left: 26px;
}
.notification-panel .notification-body .notification-item.unread .heading {
  opacity: 1;
}
.notification-panel .notification-body .notification-item.unread .option {
  background-color: #daeffd;
}
.notification-panel .notification-body .notification-item.unread .option .mark {
  color: #6dc0f9;
}
.notification-panel .notification-body .notification-item .heading {
  line-height: 43px;
  margin-left: 5px;
  background: #fff;
  border-bottom: 1px solid #e6e6e6;
  position: relative;
  float: right;
  width: 100%;
  font-size: 14px;
  opacity: 0.7;
}
.notification-panel .notification-body .notification-item .heading:after {
  content: '';
  position: absolute;
  width: 5px;
  height: 100%;
  background-color: #fff;
  left: 0;
  top: 1px;
}
.notification-panel .notification-body .notification-item .heading .thumbnail-wrapper {
  cursor: pointer;
}
.notification-panel .notification-body .notification-item .heading .thumbnail-wrapper i {
  -webkit-transition: all 0.12s linear;
  transition: all 0.12s linear;
}
.notification-panel .notification-body .notification-item .heading .time {
  font-size: 11px;
  margin-left: 8px;
}
.notification-panel .notification-body .notification-item .heading.open {
  display: block;
}
.notification-panel .notification-body .notification-item .heading.open .more-details {
  display: block;
}
.notification-panel .notification-body .notification-item .heading.open .thumbnail-wrapper i {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.notification-panel .notification-body .notification-item .heading .thumbnail-wrapper.d24 {
  line-height: 20px;
}
.notification-panel .notification-body .notification-item .more-details {
  display: none;
  background-color: #fff;
  width: 100%;
  height: 100%;
  clear: both;
  position: relative;
}
.notification-panel .notification-body .notification-item .more-details .more-details-inner {
  margin-right: 10px;
  padding-right: 28px;
  padding-top: 15px;
  margin-bottom: 20px;
  position: relative;
  border-right: 1px solid rgba(230, 230, 230, 0.7);
  text-align: right;
}
.notification-panel .notification-body .notification-item .more-details .more-details-inner:after {
  width: 14px;
  height: 14px;
  position: absolute;
  content: '';
  background-color: #fff;
  right: -7px;
  top: 25px;
  border: 1px solid rgba(230, 230, 230, 0.9);
  border-radius: 99px;
  -webkit-border-radius: 99px;
  -moz-border-radius: 99px;
}
.notification-panel .notification-body .notification-item .more-details .more-details-inner:before {
  color: #c0c0c0;
  position: absolute;
  bottom: 0;
  right: -5px;
  content: ' \25CF';
  font-size: 13px;
  line-height: 5px;
  background-color: #fff;
  height: 9px;
}
.notification-panel .notification-body .notification-item .more-details .more-details-inner .hint-text {
  opacity: 0.44;
}
.notification-panel .notification-body .notification-item .option {
  font-size: 10px;
  position: absolute;
  height: 44px;
  width: 26px;
  left: 0;
  text-align: center;
  vertical-align: middle;
  line-height: 44px;
  background-color: #fafafa;
  height: 100%;
}
.notification-panel .notification-body .notification-item .option .mark {
  background-color: transparent;
  color: #c0c0c0;
}
.notification-panel .notification-body .notification-item .option .mark:before {
  content: ' \25CF';
  font-size: 12px;
}
.notification-panel .notification-body .notification-item:last-child .heading {
  border-bottom: 0px;
}
.notification-panel .notification-footer {
  padding: 10px;
  display: block;
  border-top: 1px solid #e6e6e6;
}
.notification-panel .notification-footer a {
  color: #626262;
  opacity: 0.54;
}
.notification-panel .notification-footer a:hover {
  opacity: 0.74;
}
/* Simple alerts
--------------------------------------------------
*/
.alerts-container {
  position: fixed;
  width: 350px;
  right: 20px;
  z-index: 999;
  top: 80px;
}
.alerts-container[data-placement$='-left'] {
  left: 100px;
  right: auto;
}
.alerts-container[data-placement$='-right'] {
  right: 20px;
  left: auto;
}
.alerts-container[data-placement^='top-'] {
  top: 80px;
  bottom: auto;
}
.alerts-container[data-placement^='bottom-'] {
  top: auto;
  bottom: 20px;
}
.alerts-container[data-placement='bottom-right'] .alert:last-child,
.alerts-container[data-placement='bottom-left'] .alert:last-child {
  margin-bottom: 0;
}
.alerts-container .alert {
  position: relative;
}
.alerts-container .alert .close {
  position: absolute;
  left: 9px;
  top: 15px;
}
/* Pages Notifications plugin
--------------------------------------------------
*/
body.menu-pin > .pgn-wrapper[data-position$='-left'],
body.menu-pin > .pgn-wrapper[data-position="top"],
body.menu-pin > .pgn-wrapper[data-position="bottom"] {
  right: 270px;
}
.pgn-wrapper {
  position: fixed;
  z-index: 1000;
}
.pgn-wrapper[data-position$='-left'] {
  right: 30px;
}
.pgn-wrapper[data-position$='-right'] {
  left: 20px;
}
.pgn-wrapper[data-position^='top-'] {
  top: 20px;
}
.pgn-wrapper[data-position^='bottom-'] {
  bottom: 20px;
}
.pgn-wrapper[data-position='top'] {
  top: 0;
  left: 0;
  right: 0;
}
.pgn-wrapper[data-position='bottom'] {
  bottom: 0;
  left: 0;
  right: 0;
}
.pgn {
  position: relative;
  margin: 10px;
}
.pgn .alert {
  margin: 0;
}
/* Simple 
------------------------------------
*/
.pgn-simple .alert {
  padding-top: 13px;
  padding-bottom: 13px;
  max-width: 500px;
  animation: fadeIn 0.3s cubic-bezier(0.05, 0.74, 0.27, 0.99) forwards;
  -webkit-animation: fadeIn 0.3s cubic-bezier(0.05, 0.74, 0.27, 0.99) forwards;
  max-height: 250px;
  overflow: hidden;
}
/* Bar 
------------------------------------
*/
.pgn-bar {
  overflow: hidden;
  margin: 0;
}
.pgn-bar .alert {
  border-radius: 0;
  padding-top: 13px;
  padding-bottom: 13px;
  max-height: 91px;
}
.pgn-wrapper[data-position='top'] .pgn-bar .alert {
  animation: slideInFromTop 0.5s cubic-bezier(0.05, 0.74, 0.27, 0.99) forwards;
  -webkit-animation: slideInFromTop 0.5s cubic-bezier(0.05, 0.74, 0.27, 0.99) forwards;
  transform-origin: top left;
  -webkit-transform-origin: top left;
}
.pgn-wrapper[data-position='bottom'] .pgn-bar .alert {
  animation: slideInFromBottom 0.5s cubic-bezier(0.05, 0.74, 0.27, 0.99) forwards;
  -webkit-animation: slideInFromBottom 0.5s cubic-bezier(0.05, 0.74, 0.27, 0.99) forwards;
  transform-origin: bottom left;
  -webkit-transform-origin: bottom left;
}
.pgn-bar .alert span {
  opacity: 0;
  animation: fadeIn 0.3s cubic-bezier(0.05, 0.74, 0.27, 0.99) forwards;
  -webkit-animation: fadeIn 0.3s cubic-bezier(0.05, 0.74, 0.27, 0.99) forwards;
}
@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
@-webkit-keyframes slideInFromTop {
  0% {
    -webkit-transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(0);
  }
}
@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
@-webkit-keyframes slideInFromBottom {
  0% {
    -webkit-transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(0);
  }
}
/* Circle 
------------------------------------
*/
.pgn-circle .alert {
  border-radius: 300px;
  animation: fadeInCircle 0.3s ease forwards, resizeCircle 0.3s 0.4s cubic-bezier(0.25, 0.25, 0.4, 1.6) forwards;
  -webkit-animation: fadeInCircle 0.3s ease forwards, resizeCircle 0.3s 0.4s cubic-bezier(0.25, 0.25, 0.4, 1.6) forwards;
  height: 60px;
  overflow: hidden;
  padding: 6px 6px 6px 55px;
  -webkit-transform: translateZ(0);
  position: relative;
}
.pgn-wrapper[data-position$='-right'] .pgn-circle .alert {
  float: right;
}
.pgn-wrapper[data-position$='-left'] .pgn-circle .alert {
  float: left;
}
.pgn-circle .alert > div > div.pgn-thumbnail > div {
  border-radius: 50%;
  overflow: hidden;
  width: 48px;
  height: 48px;
}
.pgn-circle .alert > div > div.pgn-thumbnail > div > img {
  width: 100%;
  height: 100%;
}
.pgn-circle .alert > div > div.pgn-message > div {
  opacity: 0;
  height: 47px;
  padding-right: 9px;
  animation: fadeIn 0.3s 0.5s ease forwards;
  -webkit-animation: fadeIn 0.3s 0.5s ease forwards;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  word-wrap: break-word;
}
.pgn-circle .alert > div > div.pgn-message > div p:only-child {
  padding: 12px 0;
}
.pgn-circle .alert .close {
  margin-top: -12px;
  position: absolute;
  left: 18px;
  top: 50%;
  opacity: 0;
  animation: fadeIn 0.3s 0.5s ease forwards;
  -webkit-animation: fadeIn 0.3s 0.5s ease forwards;
}
.pgn-circle .alert p {
  margin-bottom: 0;
}
.pgn-circle .alert > div {
  display: table;
  height: 100%;
}
.pgn-circle .alert > div > div {
  display: table-cell;
  vertical-align: middle;
}
@keyframes fadeInCircle {
  0% {
    opacity: 0;
    width: 60px;
  }
  100% {
    opacity: 1;
    width: 60px;
  }
}
@-webkit-keyframes fadeInCircle {
  0% {
    opacity: 0;
    width: 60px;
  }
  100% {
    opacity: 1;
    width: 60px;
  }
}
@keyframes resizeCircle {
  0% {
    width: 60px;
  }
  100% {
    width: 300px;
  }
}
@-webkit-keyframes resizeCircle {
  0% {
    width: 60px;
  }
  100% {
    width: 300px;
  }
}
/* Flip 
------------------------------------
*/
.pgn-wrapper[data-position^='top-'] .pgn-flip {
  top: -30px;
}
.pgn-wrapper[data-position^='bottom-'] .pgn-flip {
  bottom: -30px;
}
.pgn-wrapper[data-position^='bottom-'] .pgn-flip .alert {
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}
.pgn-flip .alert {
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
  box-shadow: 0 6px 4px -3px rgba(0, 0, 0, 0.2);
  -webkit-animation-name: flipInX;
  animation-name: flipInX;
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
  border-radius: 0;
  padding: 25px 35px;
  max-width: 500px;
  max-height: 250px;
  overflow: hidden;
}
@-webkit-keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -90deg);
    -webkit-transition-timing-function: ease-in;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 20deg);
    -webkit-transition-timing-function: ease-out;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -10deg);
    -webkit-transition-timing-function: ease-in;
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 5deg);
    -webkit-transition-timing-function: ease-out;
  }
  100% {
    -webkit-transform: perspective(400px);
  }
}
@keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -90deg);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 20deg);
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -10deg);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 5deg);
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  100% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* Pages Notification customizations */
body > .pgn-wrapper[data-position="top"] {
  top: 60px;
  right: 70px;
}
body > .pgn-wrapper[data-position="bottom"] {
  right: 70px;
}
body > .pgn-wrapper[data-position$='-left'] {
  right: 90px;
  left: auto;
}
body > .pgn-wrapper[data-position^='top-'] {
  top: 80px;
  bottom: auto;
}
.pgn-circle .alert .close {
  margin-top: -4px;
}
body > .pgn-wrapper {
  z-index: 900;
}
@media (max-width: 979px) {
  body > .pgn-wrapper[data-position="top"] {
    right: 0;
  }
  body > .pgn-wrapper[data-position="bottom"] {
    right: 0;
  }
}
@media (max-width: 767px) {
  body > .pgn-wrapper[data-position$='-left'],
  body > .pgn-wrapper[data-position$='-right'] {
    left: 10px !important;
    right: 10px !important;
  }
  body > .pgn-wrapper[data-position$='-right'] .alert,
  body > .pgn-wrapper[data-position$='-left'] .alert {
    max-width: 100%;
    width: 100%;
  }
}
/* Notifications demo
--------------------------------------------------
*/
.notification-positions {
  border-radius: 4px;
  border: 3px dashed #e6e6e6;
  height: 370px;
  position: relative;
}
.notification-positions > div > .position:not(:only-child) {
  max-width: 50%;
}
.notification-positions .position {
  padding: 15px;
}
.notification-positions .position img {
  border: 3px solid transparent;
}
.notification-positions .position:hover {
  cursor: pointer;
}
.notification-positions .position.active img {
  border-color: #0090d9;
}
.notification-positions .position.pull-top,
.notification-positions .position.pull-bottom {
  left: 0;
  right: 0;
}
.notification-positions .position img {
  width: 100%;
}
/*------------------------------------------------------------------
[10. Modals]
*/
.modal .close:focus {
  outline: 0;
}
.modal .modal-dialog {
  transition: all 0.2s !important;
}
.modal .modal-content {
  border: 1px solid #f2f6f7;
  border-radius: 3px;
  box-shadow: none;
}
.modal .modal-header {
  text-align: center;
  border-bottom: 0;
  padding: 25px 25px 0 25px;
}
.modal .modal-header p {
  color: #8b91a0;
}
.modal .modal-body {
  box-shadow: none;
  padding: 25px;
  padding-top: 0;
  white-space: normal;
}
.modal .modal-footer {
  border-top: none;
  box-shadow: none;
  margin-top: 0;
  padding: 25px;
  padding-top: 0;
}
.modal .drop-shadow {
  box-shadow: 0 0 9px rgba(191, 191, 191, 0.36) !important;
}
.modal.fade {
  opacity: 1 !important;
}
.modal.fade.stick-up .modal-dialog {
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
  -ms-transform: translate(0, -100%);
  margin-top: -5px;
}
.modal.fade.stick-up.in .modal-dialog {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -ms-transform: translate(0, 0);
}
.modal.fade.slide-up {
  height: 100%;
  top: auto;
}
.modal.fade.slide-up.in .modal-dialog {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -ms-transform: translate(0, 0);
}
.modal.fade.slide-up.disable-scroll {
  overflow: hidden;
}
.modal.fade.slide-up .modal-dialog {
  display: table;
  height: 100%;
  -webkit-transform: translate3d(0, 75%, 0);
  transform: translate3d(0, 75%, 0);
  -ms-transform: translate(0, 75%);
}
.modal.fade.slide-up .modal-content-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal.fade.center-scale .modal-dialog {
  display: table;
  height: 100%;
}
.modal.fade.center-scale .modal-content {
  display: table-cell;
  vertical-align: middle;
  border: 0;
  background: transparent;
}
.modal.fade.center-scale .modal-content .modal-header {
  border-bottom: 0px;
}
.modal.fade.center-scale.in .modal-dialog {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.modal.fade.center-scale .modal-dialog {
  opacity: 0;
  -webkit-transform: scale(0.6);
  -ms-transform: scale(0.6);
  transform: scale(0.6);
}
.modal.fade.fill-in {
  background-color: rgba(255, 255, 255, 0);
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.modal.fade.fill-in > .close {
  margin-top: 20px;
  margin-left: 20px;
  opacity: 0.6;
}
.modal.fade.fill-in > .close > i {
  font-size: 16px;
}
.modal.fade.fill-in .modal-dialog {
  display: table;
  height: 100%;
}
.modal.fade.fill-in .modal-content {
  display: table-cell;
  vertical-align: middle;
  border: 0;
  background: transparent;
}
.modal.fade.fill-in .modal-content .modal-header {
  border-bottom: 0px;
}
.modal.fade.fill-in.in {
  background-color: rgba(255, 255, 255, 0.85);
}
.modal.fade.fill-in.in .modal-dialog {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.modal.fade.fill-in .modal-dialog {
  opacity: 0;
  -webkit-transform: scale(0.6);
  -ms-transform: scale(0.6);
  transform: scale(0.6);
}
.modal.fade.slide-right .close {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 10px;
  z-index: 10;
}
.modal.fade.slide-right.in .modal-dialog {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -ms-transform: translate(0, 0);
}
.modal.fade.slide-right .modal-dialog {
  position: absolute;
  left: 0;
  margin: 0;
  height: 100%;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  -ms-transform: translate(-100%, 0);
}
.modal.fade.slide-right .modal-dialog .modal-content-wrapper {
  height: 100%;
  max-width: 100vw;
}
.modal.fade.slide-right .modal-dialog .modal-content {
  height: 100%;
}
.modal.fade.slide-right .modal-dialog .modal-body {
  background-color: #fff;
}
.modal.fade.slide-right .modal-content {
  border-radius: 0px;
}
.fill-in-modal .modal-backdrop {
  background-color: transparent;
}
.modal-backdrop {
  opacity: 0;
  -webkit-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}
.modal-backdrop.in {
  opacity: 0.3;
}
/* Responsive Handlers: Modals
------------------------------------
*/
@media (max-width: 768px) {
  .modal.fill-in .modal-dialog {
    width: calc(100% - 20px);
  }
  .modal.slide-up .modal-dialog {
    width: calc(100% - 20px);
  }
}
@media (min-width: 768px) {
  .modal.stick-up .modal-dialog {
    margin: -5px auto;
  }
  .modal.slide-up .modal-dialog {
    margin: 0 auto;
  }
  .modal.fill-in .modal-dialog {
    margin: 0 auto;
  }
  .modal .modal-content {
    box-shadow: none;
  }
}
/*------------------------------------------------------------------
[15. Form Elements]
*/
label,
input,
button,
select,
textarea {
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
}
input[type="radio"],
input[type="checkbox"] {
  margin-top: 1px 0 0;
  line-height: normal;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
select[multiple],
select[size] {
  height: auto !important;
}
input:focus,
select:focus,
textarea:focus,
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
form legend {
  margin: 15px 0px 10px 0px;
}
.form-control {
  background-color: #fff;
  background-image: none;
  border: 1px solid rgba(0, 0, 0, 0.07);
  font-family: Arial, sans-serif;
  -webkit-appearance: none;
  color: #2c2c2c;
  outline: 0;
  height: 35px;
  padding: 9px 12px;
  line-height: normal;
  font-size: 14px;
  font-weight: normal;
  vertical-align: middle;
  min-height: 35px;
  -webkit-transition: all 0.12s ease;
  transition: all 0.12s ease;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-transition: background 0.2s linear 0s;
  transition: background 0.2s linear 0s;
}
.form-control:focus {
  border-color: rgba(0, 0, 0, 0.1);
  background-color: #f0f0f0;
  outline: 0 !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.form-control:focus::-moz-placeholder {
  color: inherit;
  opacity: 0.7;
}
.form-control:focus:-ms-input-placeholder {
  color: inherit;
  opacity: 0.7;
}
.form-control:focus::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.7;
}
.form-control::-moz-placeholder {
  color: inherit;
  opacity: 0.33;
}
.form-control:-ms-input-placeholder {
  color: inherit;
  opacity: 0.33;
}
.form-control::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.33;
}
.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background: #f8f8f8;
  color: rgba(98, 98, 98, 0.23);
}
/* Input Sizes
--------------------------------------------------
*/
.input-sm,
.form-horizontal .form-group-sm .form-control {
  font-size: 13px;
  min-height: 32px;
  height: 32px;
  padding: 8px 9px;
}
.input-lg,
.form-horizontal .form-group-lg .form-control {
  border-radius: 3px;
  font-size: 18px;
  height: 45px;
  padding: 11px 13px;
}
.input-xlg {
  height: 51px;
  font-size: 18px;
  line-height: 22px;
}
/* Checkboxes and Radio buttons
--------------------------------------------------
*/
.radio,
.checkbox {
  margin-bottom: 10px;
  margin-top: 10px;
  padding-left: 0px;
}
.radio label,
.checkbox label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 25px !important;
  margin-right: 15px;
  font-size: 13px;
}
.radio label:before,
.checkbox label:before {
  content: "";
  display: inline-block;
  width: 17px;
  height: 17px;
  margin-right: 10px;
  position: absolute;
  left: 0px;
  background-color: #fff;
  border: 1px solid #d0d0d0;
}
.radio label {
  margin-bottom: 6px;
}
.radio label:before {
  bottom: 2.5px;
  border-radius: 99px;
  -webkit-transition: border 0.3s 0s cubic-bezier(0.455, 0.03, 0.215, 1.33);
  transition: border 0.3s 0s cubic-bezier(0.455, 0.03, 0.215, 1.33);
}
.radio input[type=radio]:checked + label:before {
  border-width: 5px;
}
.radio input[type="radio"]:focus + label {
  color: #2c2c2c;
}
.radio input[type="radio"]:focus + label:before {
  background-color: #e6e6e6;
}
.radio input[type=radio] {
  opacity: 0;
  width: 0;
  height: 0;
}
.radio input[type=radio][disabled] + label {
  opacity: 0.65;
}
.radio.radio-success input[type=radio]:checked + label:before {
  border-color: #10cfbd;
}
.radio.radio-primary input[type=radio]:checked + label:before {
  border-color: #e67e22;
}
.radio.radio-info input[type=radio]:checked + label:before {
  border-color: #3b4752;
}
.radio.radio-warning input[type=radio]:checked + label:before {
  border-color: #f8d053;
}
.radio.radio-danger input[type=radio]:checked + label:before {
  border-color: #f55753;
}
.radio.radio-complete input[type=radio]:checked + label:before {
  border-color: #48b0f7;
}
.checkbox input[type=radio][disabled] + label:after {
  background-color: #e6e6e6;
}
.checkbox label {
  transition: border 0.2s linear 0s, color 0.2s linear 0s;
  white-space: nowrap;
}
.checkbox label:before {
  top: 1.4px;
  border-radius: 3px;
  transition: border 0.2s linear 0s, color 0.2s linear 0s;
}
.checkbox label::after {
  display: inline-block;
  width: 16px;
  height: 16px;
  position: absolute;
  left: 3.2px;
  top: 0px;
  font-size: 11px;
  transition: border 0.2s linear 0s, color 0.2s linear 0s;
}
.checkbox label:after {
  border-radius: 3px;
}
.checkbox input[type=checkbox] {
  opacity: 0;
  width: 0;
  height: 0;
}
.checkbox.checkbox-circle label:after {
  border-radius: 99px;
}
.checkbox.checkbox-circle label:before {
  border-radius: 99px;
}
.checkbox input[type=checkbox]:checked + label:before {
  border-width: 8.5px;
}
.checkbox input[type=checkbox]:checked + label::after {
  font-family: 'FontAwesome';
  content: "\F00C";
  color: #fff;
}
.checkbox input[type="checkbox"]:focus + label {
  color: #2c2c2c;
}
.checkbox input[type="checkbox"]:focus + label:before {
  background-color: #e6e6e6;
}
.checkbox input[type=checkbox][disabled] + label {
  opacity: 0.65;
}
.checkbox input[type=checkbox][disabled] + label:before {
  background-color: #eceff3;
}
.checkbox.right label {
  margin-right: 35px;
  padding-left: 0 !important;
}
.checkbox.right label:before {
  right: -35px;
  left: auto;
}
.checkbox.right input[type=checkbox]:checked + label {
  position: relative;
}
.checkbox.right input[type=checkbox]:checked + label::after {
  font-family: 'FontAwesome';
  content: "\F00C";
  position: absolute;
  right: -27px;
  left: auto;
}
body.rtl .checkbox label::after {
  left: -1.2px;
}
.checkbox.check-success input[type=checkbox]:checked + label:before {
  border-color: #10cfbd;
}
.checkbox.check-primary input[type=checkbox]:checked + label:before {
  border-color: #e67e22;
}
.checkbox.check-complete input[type=checkbox]:checked + label:before {
  border-color: #48b0f7;
}
.checkbox.check-warning input[type=checkbox]:checked + label:before {
  border-color: #f8d053;
}
.checkbox.check-danger input[type=checkbox]:checked + label:before {
  border-color: #f55753;
}
.checkbox.check-info input[type=checkbox]:checked + label:before {
  border-color: #3b4752;
}
.checkbox.check-success input[type=checkbox]:checked + label::after,
.checkbox.check-primary input[type=checkbox]:checked + label::after,
.checkbox.check-complete input[type=checkbox]:checked + label::after,
.checkbox.check-warning input[type=checkbox]:checked + label::after,
.checkbox.check-danger input[type=checkbox]:checked + label::after,
.checkbox.check-info input[type=checkbox]:checked + label::after {
  color: #fff;
}
.input-group-addon:first-child {
  border-left: 0;
  border-right: 1px solid rgba(0, 0, 0, 0.07);
  -webkit-border-top-right-radius: 2px;
  -moz-border-radius-topright: 2px;
  border-top-right-radius: 2px;
  -webkit-border-bottom-right-radius: 2px;
  -moz-border-radius-bottomright: 2px;
  border-bottom-right-radius: 2px;
  -webkit-border-top-left-radius: 0;
  -moz-border-radius-topleft: 0;
  border-top-left-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-bottomleft: 0;
  border-bottom-left-radius: 0;
}
.input-group-addon:last-child {
  border-left: 1px solid rgba(0, 0, 0, 0.07);
  border-right: 0;
  -webkit-border-top-left-radius: 2px;
  -moz-border-radius-topleft: 2px;
  border-top-left-radius: 2px;
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius-bottomleft: 2px;
  border-bottom-left-radius: 2px;
}
.input-group .form-control:first-child {
  -webkit-border-top-left-radius: 0;
  -moz-border-radius-topleft: 0;
  border-top-left-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-bottomleft: 0;
  border-bottom-left-radius: 0;
}
.input-group.transparent .input-group-addon {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.07);
}
/* Form layouts
--------------------------------------------------
*/
@media only screen and (min-width: 768px) {
  form .row {
    margin-left: 0;
    margin-right: 0;
  }
  form .row [class*='col-']:not(:first-child),
  form .row [class*='col-']:not(:last-child) {
    padding-left: 7px;
    padding-right: 7px;
  }
  form .row [class*='col-']:first-child {
    padding-right: 0;
  }
  form .row [class*='col-']:last-child {
    padding-left: 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  form .row [class*="col-md-"]:not(:first-child),
  form .row [class*="col-md-"]:not(:last-child) {
    padding-right: 0;
    padding-left: 0;
  }
}
/* Form layouts  : Horizontal
--------------------------------------------------
*/
.form-horizontal .form-group {
  border-bottom: 1px solid #e6e6e6;
  padding-top: 19px;
  padding-bottom: 19px;
  margin-bottom: 0;
}
.form-horizontal .form-group:last-child {
  border-bottom: none;
}
.form-horizontal .form-group:hover .control-label {
  opacity: 0.6;
}
.form-horizontal .form-group.focused .control-label {
  opacity: 1;
}
.form-horizontal .form-group .control-label {
  text-align: right;
  opacity: 0.42;
  -webkit-transition: opacity ease 0.3s;
  transition: opacity ease 0.3s;
}
/* Form layouts  : Attached
--------------------------------------------------
*/
.form-group-attached .form-group.form-group-default {
  border-radius: 0;
  margin-bottom: 0;
}
.form-group-attached > div {
  margin: 0;
}
.form-group-attached > div:first-child.row > [class*='col-']:first-child .form-group-default {
  -webkit-border-top-right-radius: 2px;
  -moz-border-radius-topright: 2px;
  border-top-right-radius: 2px;
}
.form-group-attached > div:first-child.row > [class*='col-']:last-child .form-group-default {
  -webkit-border-top-left-radius: 2px;
  -moz-border-radius-topleft: 2px;
  border-top-left-radius: 2px;
}
.form-group-attached > div:first-child.form-group-default {
  -webkit-border-top-right-radius: 2px;
  -moz-border-radius-topright: 2px;
  border-top-right-radius: 2px;
  -webkit-border-top-left-radius: 2px;
  -moz-border-radius-topleft: 2px;
  border-top-left-radius: 2px;
}
.form-group-attached > div:last-child.row > [class*='col-']:first-child .form-group-default {
  -webkit-border-bottom-right-radius: 2px;
  -moz-border-radius-bottomright: 2px;
  border-bottom-right-radius: 2px;
}
.form-group-attached > div:last-child.row > [class*='col-']:last-child .form-group-default {
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius-bottomleft: 2px;
  border-bottom-left-radius: 2px;
}
.form-group-attached > div:last-child.form-group-default {
  -webkit-border-bottom-right-radius: 2px;
  -moz-border-radius-bottomright: 2px;
  border-bottom-right-radius: 2px;
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius-bottomleft: 2px;
  border-bottom-left-radius: 2px;
}
.form-group-attached > div.row > [class*='col-'] {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.form-group-attached > div.row > [class*='col-']:not(:only-child):not(:last-child) > .form-group-default {
  border-left-color: transparent;
}
.form-group-attached > div:not(:last-child) .form-group-default,
.form-group-attached > div:not(:last-child).form-group-default {
  border-bottom-color: transparent;
}
/* Form layouts  : Responsive Handlers
--------------------------------------------------
*/
@media (max-width: 767px) {
  .form-group-attached .form-group-default {
    border-right-color: rgba(0, 0, 0, 0.07) !important;
  }
}
@media only screen and (min-width: 768px) {
  .form-group-attached > div.row > [class*='col-'] .form-group {
    height: 100%;
    width: 100%;
  }
}
/* Form Groups
--------------------------------------------------
*/
.form-group {
  margin-bottom: 10px;
}
.form-group label {
  letter-spacing: 0.13px;
}
.form-group label:not(.error) {
  font-family: 'Montserrat';
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 600;
}
.form-group label .help {
  margin-right: 8px;
}
.form-group .help {
  font-size: 12px;
  color: rgba(98, 98, 98, 0.55);
}
.form-group-default {
  background-color: #fff;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.07);
  border-radius: 2px;
  padding-top: 7px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 4px;
  overflow: hidden;
  -webkit-transition: background-color 0.2s ease;
  transition: background-color 0.2s ease;
}
.form-group-default.required:after {
  color: #f55753;
  content: "*";
  font-family: arial;
  font-size: 20px;
  position: absolute;
  left: 12px;
  top: 6px;
}
.form-group-default.disabled {
  background: #f8f8f8;
  color: rgba(98, 98, 98, 0.23);
}
.form-group-default.disabled input {
  opacity: 0.6;
}
.form-group-default.disabled.focused {
  background: #f8f8f8;
}
.form-group-default.disabled.focused label {
  opacity: 1;
}
.form-group-default.focused {
  border-color: rgba(0, 0, 0, 0.1) !important;
  background-color: #f0f0f0;
}
.form-group-default.focused label {
  opacity: 0.4;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
.form-group-default.has-error {
  background-color: rgba(245, 87, 83, 0.1);
  margin-bottom: 0.1rem;
}
.form-group-default.has-success .form-control-feedback,
.form-group-default.has-error .form-control-feedback {
  display: none !important;
}
.form-group-default.has-success .form-control,
.form-group-default.has-success .form-control:focus,
.form-group-default.has-error .form-control,
.form-group-default.has-error .form-control:focus {
  border: none;
  box-shadow: none;
}
.form-group-default.input-group {
  padding: 0;
}
.form-group-default.input-group > label {
  margin-top: 6px;
  padding-right: 12px;
}
.form-group-default.input-group > label.inline {
  margin-top: 16px;
  float: left;
}
.form-group-default.input-group > .form-control {
  margin-top: -2px;
  margin-bottom: 3px;
  padding-right: 12px;
}
.form-group-default.input-group .input-group-addon {
  height: calc(54px - 4px);
  min-width: calc(54px - 4px);
  border-radius: 0;
  border: none;
}
.form-group-default.input-group.focused .input-group-addon {
  border-color: rgba(0, 0, 0, 0.1);
}
.form-group-default .form-control {
  border: none;
  height: 25px;
  min-height: 25px;
  padding: 0;
  margin-top: -4px;
  background: none;
}
.form-group-default .form-control.error {
  color: #2c2c2c;
}
.form-group-default .form-control:focus {
  background: none;
}
.form-group-default textarea.form-control {
  padding-top: 5px;
}
.form-group-default label {
  margin: 0;
  display: block;
  opacity: 1;
  -webkit-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
}
.form-group-default label.label-lg {
  font-size: 13px;
  left: 13px;
  top: 9px;
}
.form-group-default label.label-sm {
  font-size: 11px;
  left: 11px;
  top: 6px;
}
.form-group-default label.highlight {
  opacity: 1;
}
.form-group-default label.fade {
  opacity: 0.5;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
.form-group-default > .input-lg {
  height: 29px;
  min-height: 29px;
  padding-right: 1px;
}
.form-group-default > .input-sm {
  min-height: 18px;
  height: 18px;
}
.form-group-default.form-group-default-select {
  overflow: visible;
}
.form-group-default.form-group-default-select .ui-select-container.ui-select-bootstrap.dropdown .ui-select-match {
  padding-top: 6px;
  height: 23px;
}
.form-group-default.form-group-default-select .ui-select-container .select2-choices {
  border: 0px;
}
.form-group-default.form-group-default-select2 {
  padding: 0;
}
.form-group-default.form-group-default-select2 > label {
  position: absolute;
  z-index: 10;
  padding: 7px 12px 0 12px;
}
.form-group-default.form-group-default-select2 > label.label-lg {
  right: 0;
  top: 0;
}
.form-group-default.form-group-default-select2 > label.label-sm {
  right: 0;
  top: 0;
}
.form-group-default.form-group-default-select2 .select2-container .select2-choice {
  padding-top: 20px;
  height: 52px;
}
.form-group-default.form-group-default-select2 .select2-container .select2-choice .select2-arrow b:before {
  top: 20px;
}
.form-group-default.form-group-default-select2 .select2-container .select2-choice .select2-chosen {
  padding-right: 3px;
  padding-top: 1px;
}
.form-group-default.form-group-default-select2 .select2-container .select2-choices {
  padding-top: 20px;
  height: 52px;
  border: 0px;
}
.form-group-default.form-group-default-select2 > .input-lg {
  height: auto;
  padding: 0;
}
.form-group-default.form-group-default-select2 > .input-lg .select2-choice {
  padding-top: 20px;
  height: 56px;
}
.form-group-default.form-group-default-select2 > .input-sm {
  height: auto;
  padding: 0;
}
.form-group-default.form-group-default-select2 > .input-sm .select2-choice {
  padding-top: 20px;
  height: 46px;
}
.form-group-default.form-group-default-selectFx {
  padding: 0;
}
.form-group-default.form-group-default-selectFx > label {
  position: absolute;
  z-index: 10;
  padding: 7px 12px 0 12px;
}
.form-group-default.form-group-default-selectFx > label.label-lg {
  left: 0;
  top: 0;
}
.form-group-default.form-group-default-selectFx > label.label-sm {
  left: 0;
  top: 0;
}
.form-group-default.form-group-default-selectFx .cs-wrapper .cs-placeholder {
  padding-top: 28px;
  height: 52px;
  padding-right: 12px;
}
.form-group-default.form-group-default-selectFx .cs-wrapper .cs-select {
  height: auto;
}
.form-group-default.form-group-default-selectFx .cs-wrapper .cs-select > span:after,
.form-group-default.form-group-default-selectFx .cs-wrapper .cs-select .cs-selected span:after {
  top: 39px;
}
.form-group-default.form-group-default-selectFx .cs-wrapper .cs-select.input-lg .cs-placeholder {
  height: 60px;
}
.form-group-default.form-group-default-selectFx .cs-wrapper .cs-select.input-sm .cs-placeholder {
  height: 50px;
}
.form-group-default.form-group-default-selectFx .cs-wrapper .dropdown-placeholder {
  vertical-align: top;
}
/* Form validation
--------------------------------------------------
*/
.has-success .help-block,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline {
  color: #0da899;
}
.has-success .form-control {
  border-color: #0da899;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.has-success .form-control:focus {
  border-color: #09786e;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.has-success .input-group-addon {
  background: #f0f0f0;
  border: 1px solid rgba(0, 0, 0, 0.07);
  color: rgba(98, 98, 98, 0.47);
}
.has-success .form-control-feedback {
  color: #0da899;
}
.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline {
  color: #c9a843;
}
.has-warning .form-control {
  border-color: #c9a843;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.has-warning .form-control:focus {
  border-color: #a98b31;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.has-warning .input-group-addon {
  background: #f0f0f0;
  border: 1px solid rgba(0, 0, 0, 0.07);
  color: rgba(98, 98, 98, 0.47);
}
.has-warning .form-control-feedback {
  color: #c9a843;
}
.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline {
  color: #f55753;
}
.has-error .form-control {
  border-color: #f55753;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.has-error .form-control:focus {
  border-color: #f22823;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.has-error .input-group-addon {
  background: #f0f0f0;
  border: 1px solid rgba(0, 0, 0, 0.07);
  color: rgba(98, 98, 98, 0.47);
}
.has-error .form-control-feedback {
  color: #f55753;
}
.error {
  font-size: 12px;
  color: #f55753;
  display: block;
}
/* Addon inputs
--------------------------------------------------
*/
.input-group-addon {
  background: #f0f0f0;
  border: 1px solid rgba(0, 0, 0, 0.07);
  color: rgba(98, 98, 98, 0.47);
  font-size: 14px;
  padding: 6px 9px;
  display: table-cell;
  border-radius: 3px;
  transition: border 0.2s linear 0s, box-shadow 0.2s linear 0s, color 0.2s linear 0s, box-shadow 0.2s linear 0s, background 0.2s linear 0s;
}
.input-group-addon i {
  position: relative;
  top: 1px;
}
.input-group-addon.primary {
  background-color: #e67e22;
  border: 1px solid #e67e22;
  color: #fff;
}
.input-group-addon.primary .arrow {
  color: #e67e22;
}
.input-group-addon.success {
  background-color: #0090d9;
  color: #fff;
}
.input-group-addon.success .arrow {
  color: #0090d9;
}
.input-group-addon.info {
  background-color: #1f3853;
  color: #fff;
}
.input-group-addon.info .arrow {
  color: #1f3853;
}
.input-group-addon.warning {
  background-color: #fbb05e;
  color: #fff;
}
.input-group-addon.warning .arrow {
  color: #fbb05e;
}
.input-group-addon.danger {
  background-color: #f35958;
  color: #fff;
}
.input-group-addon.danger .arrow {
  color: #f35958;
}
.input-group-addon .arrow {
  position: relative;
  left: -6px;
  color: #D1DADE;
  z-index: 100;
}
.input-group-addon .arrow:before {
  font-family: 'FontAwesome';
  content: "\f0da";
  font-size: 23px;
  position: absolute;
  right: 17px;
  top: -2px;
}
.input-group-addon:last-child .arrow:before {
  font-family: 'FontAwesome';
  content: "\f0d9";
  font-size: 23px;
  position: absolute;
  right: -23px;
  top: -2px;
}
.input-group-addon:last-child input {
  border-left: 0px;
}
/* Plugins
--------------------------------------------------
Datepicker
https://github.com/eternicode/bootstrap-datepicker
*/
.datepicker {
  padding: 16px 25px;
  border-radius: 2px;
  font-size: 12px;
}
.datepicker:after {
  border-bottom-color: #fafafa;
}
.datepicker thead tr .datepicker-switch {
  color: #6f7b8a;
  font-size: 13px;
}
.datepicker thead tr .next,
.datepicker thead tr .prev {
  color: #e67e22;
  content: '';
  font-size: 0px;
}
.datepicker thead tr .next:before,
.datepicker thead tr .prev:before {
  color: #e67e22;
  font-family: 'FontAwesome';
  font-size: 10px;
}
.datepicker thead tr .prev:before {
  content: "\f053";
}
.datepicker thead tr .next:before {
  content: "\f054";
}
.datepicker thead tr .dow {
  font-family: 'Montserrat';
  color: #e67e22;
  text-transform: uppercase;
  font-size: 11px;
}
.datepicker thead tr th {
  width: 31px;
  height: 29px;
}
.datepicker tbody tr .odd {
  color: #d0d3d8;
}
.datepicker table tr td {
  width: 31px;
  height: 29px;
}
.datepicker table tr td.old,
.datepicker table tr td.new {
  color: #e6e6e6;
}
.datepicker table tr td.day:hover {
  background: #f0f0f0;
}
.datepicker table tr td.active {
  background-color: #e67e22 !important;
}
.datepicker table tr td.active,
.datepicker table tr td.active:hover,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover {
  background-image: none;
  text-shadow: none;
  font-weight: 600;
}
.datepicker table tr td.today,
.datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover {
  background-color: #f0f0f0;
  background-image: none;
  color: #fff;
}
.datepicker table tr td span {
  border-radius: 4px;
  width: 42px;
  height: 42px;
  line-height: 42px;
}
.datepicker table tr td span.active {
  background-color: #e67e22 !important;
}
.datepicker table tr td span.active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover {
  background-image: none;
  border: none;
  text-shadow: none;
}
.datepicker.dropdown-menu {
  border-color: #e6e6e6;
  color: #626262;
}
.datepicker.datepicker-dropdown.datepicker-orient-bottom:before {
  border-color: #e6e6e6;
}
.datepicker-inline {
  width: auto;
}
.input-daterange .input-group-addon {
  text-shadow: none;
  border: 0;
}
/* Timepicker
https://github.com/m3wolf/bootstrap3-timepicker
*/
.bootstrap-timepicker-widget table td a i {
  font-size: 12px;
}
.bootstrap-timepicker-widget a.btn,
.bootstrap-timepicker-widget .bootstrap-timepicker-widget input {
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
}
.bootstrap-timepicker-widget.dropdown-menu {
  background: #fff;
}
/* Daterangepicker
http://www.dangrossman.info/
*/
.daterangepicker {
  background: #fff;
}
.daterangepicker .calendar .calendar-date {
  border: 1px solid rgba(0, 0, 0, 0.07);
}
.daterangepicker .calendar .prev,
.daterangepicker .calendar .next,
.daterangepicker .calendar th {
  color: #e67e22;
  text-transform: uppercase;
  font-size: 11px;
}
.daterangepicker .calendar .month {
  color: #6f7b8a;
  font-size: 13px;
}
.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #e67e22;
  border-color: #e67e22;
}
/* Select2
http://ivaynberg.github.io/select2/
*/
.form-group-default .select2-container .select2-choice,
.select2-container-multi .select2-choices {
  border-color: transparent;
}
.select2-container .select2-choice {
  background-image: none;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.07);
  padding: 3px 9px;
  transition: border 0.2s linear 0s;
  height: 35px;
}
.select2-container .select2-choice .select2-arrow {
  left: 0;
  right: auto;
  background: transparent;
  border-left: 0px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.select2-container .select2-choice .select2-arrow b:before {
  font-family: 'FontAwesome';
  content: "\f0d7";
  position: relative;
  top: 2px;
  left: 5px;
  font-size: 12px;
}
.select2-container .select2-choice > .select2-chosen {
  margin-left: 26px;
  margin-right: 0;
}
.select2-container.select2-drop-above .select2-choice {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}
.select2-search input,
.select2-search-choice-close,
.select2-container .select2-choice abbr,
.select2-container .select2-choice .select2-arrow b {
  background-image: none !important;
}
.select2-dropdown-open.select2-drop-above .select2-choice,
.select2-dropdown-open.select2-drop-above .select2-choices {
  background-image: none !important;
  border: 1px solid rgba(0, 0, 0, 0.07);
  background: #f0f0f0;
}
.select2-dropdown-open .select2-choice .select2-arrow {
  -webkit-transform: scale(scale(1, -1));
  -ms-transform: scale(scale(1, -1));
  transform: scale(scale(1, -1));
}
.select2-drop.select2-drop-above {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  box-shadow: none;
}
.select2-drop.select2-drop-above.select2-drop-active {
  border: 1px solid rgba(0, 0, 0, 0.07);
  border-bottom: none;
  border-radius: 2px;
  padding-top: 0px;
}
.select2-container-active .select2-choice,
.select2-container-active .select2-choices {
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.07);
  background: #f0f0f0;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.select2-search {
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
}
.select2-search input {
  background: #fff !important;
  vertical-align: baseline;
  line-height: 28px;
  border-radius: 2px;
  border: none;
  font-size: 12px;
  border: 1px solid rgba(0, 0, 0, 0.07);
}
.select2-results {
  margin: 3px 3px 10px 10px;
}
.select2-results li {
  color: #626262;
}
.select2-results li.select2-result-with-children > .select2-result-label {
  color: #2c2c2c;
}
.select2-results li.select2-result-with-children > .select2-result-label:first-child {
  padding-top: 8px;
}
.select2-results .select2-highlighted {
  background: #f0f0f0;
  border-radius: 3px;
}
.select2-results .select2-highlighted .select2-result-label {
  color: #626262;
}
.select2-results .select2-no-results,
.select2-results .select2-searching,
.select2-results .select2-selection-limit {
  background: none;
  color: #626262;
  font-size: 12px;
  padding-right: 8px;
  padding-top: 0px;
  position: relative;
  top: -5px;
}
.select2-drop-active {
  border: 1px solid rgba(0, 0, 0, 0.07);
  border-bottom: none;
  border-top: none;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  padding-top: 5px;
  z-index: 790;
}
.select2-container-multi .select2-choices {
  background-image: none;
  border: 1px solid rgba(0, 0, 0, 0.07);
  border-radius: 2px;
}
.select2-container-multi .select2-choices .select2-search-choice {
  background-color: #e6e6e6;
  background-image: none;
  border: none;
  box-shadow: none;
  color: inherit;
  border-radius: 8px;
  margin: 8px -5px 7px 10px;
  padding: 4px 21px 4px 8px;
}
.select2-container-multi.select2-container-active .select2-choices {
  border: 1px solid rgba(0, 0, 0, 0.07);
  box-shadow: none;
}
.select2-container-multi.select2-container-active .select2-search-choice {
  background-color: #fff !important;
}
.select2-container-multi .select2-search-choice-close {
  right: 6px;
}
.select2-search-choice-close {
  background: none;
  top: 4px;
  left: 0;
}
.select2-search-choice-close:hover {
  text-decoration: none;
}
.select2-search-choice-close:before {
  font-family: 'FontAwesome';
  content: "\f00d";
  font-size: 12px;
  color: #626262;
}
.select2-drop-multi .select2-results .select2-no-results,
.select2-drop-multi .select2-results .select2-searching,
.select2-drop-multi .select2-results .select2-selection-limit {
  top: 0px;
}
.select2.form-control {
  padding: 0;
  box-shadow: none;
  border: 0;
}
.select2-drop-mask {
  z-index: 700;
}
.ui-select-bootstrap .ui-select-choices-row.active > a {
  background: #f0f0f0;
  border-radius: 3px;
  color: #626262;
}
.ui-select-bootstrap > .ui-select-choices {
  background-color: #fff;
}
.ui-select-choices-group-label {
  color: #2c2c2c;
  font-weight: bold;
}
.modal-open .select2-drop-active {
  z-index: 1051;
}
.modal-open .datepicker.dropdown-menu {
  z-index: 1051 !important;
}
.modal-open .select2-drop-mask {
  z-index: 1050;
}
.modal-open .cs-skin-slide.cs-active {
  z-index: 1050;
}
.dropdown-placeholder {
  display: inline-block;
  vertical-align: middle;
}
.dropdown-mask {
  bottom: 0;
  display: none;
  left: 0;
  outline: 0 none;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 600;
}
/* Bootstrap Tags input
https://github.com/timschlechter/bootstrap-tagsinput
*/
.form-group-default .bootstrap-tagsinput {
  border: 0px;
  padding-right: 0;
}
.bootstrap-tagsinput {
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  padding-bottom: 5px;
  -webkit-box-shadow: none;
  box-shadow: none;
  width: 100%;
  -webkit-transition: background 0.2s linear 0s;
  transition: background 0.2s linear 0s;
}
.bootstrap-tagsinput.active-element {
  background-color: #e6e6e6;
}
.bootstrap-tagsinput input {
  border: none;
  margin-bottom: 0px;
  min-height: 25px;
  min-width: 10em !important;
}
.bootstrap-tagsinput .tag {
  vertical-align: middle;
  padding: 6px 9px;
  padding-left: 6px;
  border-radius: 3px;
  line-height: 30px;
}
.bootstrap-tagsinput .tag[data-role="remove"] {
  margin-left: 4px;
}
.bootstrap-tagsinput .tag[data-role="remove"]:hover:active,
.bootstrap-tagsinput .tag [data-role="remove"]:hover {
  box-shadow: none;
}
.bootstrap-tagsinput .tag [data-role="remove"]:after {
  font-family: 'pages-icon';
  content: "\e60a";
  padding: 0;
}
/* Bootstrap3 wysihtml5
https://github.com/Waxolunist/bootstrap3-wysihtml5-bower
*/
.wysiwyg5-wrapper {
  position: relative;
}
.wysiwyg5-wrapper .wysihtml5-toolbar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  /*height: 50px;*/
  background: #f0f0f0;
  border-top: 1px solid #e6e6e6;
}
.wysiwyg5-wrapper .wysihtml5-toolbar .btn {
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
  border-color: transparent;
  border-right-color: #e6e6e6;
  color: #A5A5A5;
  font-size: 16px;
  font-weight: 600;
  height: 50px;
  line-height: 50px;
  padding: 0 5px;
  border-width: 1px;
  border-radius: 0 !important;
  box-shadow: none !important;
}
.wysiwyg5-wrapper .wysihtml5-toolbar .btn:hover .editor-icon {
  opacity: 0.8;
}
.wysiwyg5-wrapper .wysihtml5-toolbar .btn.active .editor-icon {
  opacity: 1;
}
.wysiwyg5-wrapper .wysihtml5-toolbar .btn.dropdown-toggle {
  padding-right: 10px;
  padding-left: 20px;
}
.wysiwyg5-wrapper .wysihtml5-toolbar .btn.dropdown-toggle .current-font {
  opacity: 0.5;
  font-size: 14px;
}
.wysiwyg5-wrapper .wysihtml5-toolbar > li {
  margin: 0;
  padding: 0;
}
.wysiwyg5-wrapper .wysiwyg {
  width: 100%;
  min-height: 200px;
  font-size: 14px;
  line-height: 18px;
  padding-bottom: 50px !important;
  border: 0;
}
.wysiwyg5-wrapper .wysiwyg:focus {
  background-color: #fafafa;
  outline: 0 !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.wysiwyg5-wrapper .expand-wysiwyg {
  bottom: 0;
  color: #A5A5A5;
  font-size: 20px;
  font-weight: 600;
  height: 50px;
  line-height: 50px;
  padding: 0 15px;
  position: absolute;
  left: 0;
}
.wysiwyg5-wrapper .wysihtml5-sandbox {
  border: none !important;
  padding: 16px 16px 50px !important;
  width: 100% !important;
}
.wysiwyg5-wrapper .wysihtml5-sandbox.expanded {
  height: 100% !important;
}
.toggle-wysiwyg {
  position: absolute;
  left: 15px;
  top: 0;
}
.toggle-wysiwyg li {
  display: inline-block;
  font-weight: 600;
}
.editor-icon {
  display: inline-block;
  height: 40px;
  margin-top: 5px;
  opacity: 0.4;
  vertical-align: top;
  width: 40px;
}
.editor-icon-headline {
  background-position: 0 0;
  width: 31px;
}
.editor-icon-bold {
  background-position: -40px 0;
}
.editor-icon-italic {
  background-position: -80px 0;
}
.editor-icon-underline {
  background-position: -120px 0;
}
.editor-icon-link {
  background-position: -160px 0;
}
.editor-icon-quote {
  background-position: -200px 0;
}
.editor-icon-ul {
  background-position: -240px 0;
}
.editor-icon-ol {
  background-position: -280px 0;
}
.editor-icon-outdent {
  background-position: -320px 0;
}
.editor-icon-indent {
  background-position: -360px 0;
}
.editor-icon-image {
  background-position: -400px 0;
}
.editor-icon-html {
  background-position: -440px 0;
}
/* Summernote
https://github.com/HackerWins/summernote
*/
.summernote-wrapper .note-editor {
  border-color: #e6e6e6;
}
.summernote-wrapper .note-editor .note-toolbar {
  padding: 0;
  background-color: #f0f0f0;
  border-bottom: none;
}
.summernote-wrapper .note-editor .note-toolbar .btn-group {
  margin: 0 -1px 0 0;
}
.summernote-wrapper .note-editor .note-toolbar .btn-group .btn {
  font-size: 12px;
  font-weight: 600;
  height: 50px;
  min-width: 47px;
  line-height: 50px;
  padding: 0 5px;
  border-radius: 0;
  background-color: #f0f0f0;
  border-color: transparent;
  border-right-color: #e6e6e6;
  border-bottom-color: #e6e6e6;
  color: #626262;
}
.summernote-wrapper .note-editor .note-toolbar .btn-group .btn.active,
.summernote-wrapper .note-editor .note-toolbar .btn-group .btn:active {
  background-color: #e6e6e6;
}
.summernote-wrapper .note-editor .note-toolbar .btn-group .btn.dropdown-toggle {
  min-width: 61px;
}
.summernote-wrapper .note-editor .note-toolbar .btn-group .btn:not(:last-child),
.summernote-wrapper .note-editor .note-toolbar .btn-group .btn:not(:only-child) {
  margin-right: 1px;
}
.summernote-wrapper .note-editor .note-statusbar {
  background-color: transparent;
}
.summernote-wrapper .note-editor .note-statusbar .note-resizebar {
  border-top-color: transparent;
}
.summernote-wrapper .note-editor .note-statusbar .note-resizebar .note-icon-bar {
  border-top: 1px solid #e6e6e6;
}
.summernote-wrapper .note-popover .popover .popover-content .dropdown-menu li a i,
.summernote-wrapper .note-toolbar .dropdown-menu li a i {
  color: #e67e22;
}
input,
input:focus {
  -webkit-transition: none !important;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
}
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px #f0f0f0 inset !important;
}
input.error:-webkit-autofill,
input.error:-webkit-autofill:focus,
.has-error input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #F9E9E9 inset !important;
}
/* Pages SelectFx */
/* Default custom select styles */
div.cs-select {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  text-align: right;
  background: #fff;
  width: 100%;
  max-width: 500px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
div.cs-select:focus {
  outline: none;
  /* For better accessibility add a style for this in your skin */
}
.cs-select select {
  display: none;
}
.cs-select span {
  display: block;
  position: relative;
  cursor: pointer;
  padding: 1em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* Placeholder and selected option */
.cs-select > span {
  padding-left: 3em;
}
.cs-select > span::after,
.cs-select .cs-selected span::after {
  speak: none;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.cs-select > span::after {
  content: '\25BE';
  left: 1em;
}
.cs-select .cs-selected span::after {
  content: '\2713';
  margin-right: 1em;
}
.cs-select.cs-active > span::after {
  -webkit-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}
/* Options */
.cs-select .cs-options {
  position: absolute;
  overflow: hidden;
  width: 100%;
  background: #fff;
  visibility: hidden;
}
.cs-select.cs-active .cs-options {
  visibility: visible;
}
.cs-select ul {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}
.cs-select ul span {
  padding: 1em;
}
.cs-select ul li.cs-focus span {
  background-color: #ddd;
}
/* Optgroup and optgroup label */
.cs-select li.cs-optgroup ul {
  padding-right: 1em;
}
.cs-select li.cs-optgroup > span {
  cursor: default;
}
div.cs-skin-slide {
  color: #fff;
  /*font-size: 1.5em;*/
  width: 300px;
}
@media screen and (max-width: 30em) {
  div.cs-skin-slide {
    font-size: 1em;
    width: 250px;
  }
}
div.cs-skin-slide::before {
  content: '';
  background: #282b30;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
}
.cs-skin-slide.cs-active::before {
  -webkit-transform: scale3d(1.1, 3.5, 1);
  transform: scale3d(1.1, 3.5, 1);
}
.cs-skin-slide > span {
  height: 80px;
  line-height: 32px;
  -webkit-transition: text-indent 0.3s, opacity 0.3s;
  transition: text-indent 0.3s, opacity 0.3s;
}
@media screen and (max-width: 30em) {
  .cs-skin-slide > span {
    height: 60px;
    line-height: 28px;
  }
}
.cs-skin-slide.cs-active > span {
  text-indent: -290px;
  opacity: 0;
}
.cs-skin-slide.cs-active > span::after {
  -webkit-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
}
.cs-skin-slide .cs-options {
  background: transparent;
  width: 70%;
  height: 400%;
  padding: 1.9em 0;
  top: 50%;
  right: 50%;
  -webkit-transform: translate3d(50%, -50%, 0);
  transform: translate3d(50%, -50%, 0);
  -ms-transform: translate(50%, -50%);
}
@media screen and (max-width: 30em) {
  .cs-skin-slide .cs-options {
    padding-top: 3em;
  }
}
.cs-skin-slide .cs-options li {
  opacity: 0;
  -webkit-transform: translate3d(30%, 0, 0);
  transform: translate3d(30%, 0, 0);
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
}
.cs-skin-slide.cs-active .cs-options li {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
.cs-skin-slide.cs-active .cs-options li:first-child {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
}
.cs-skin-slide.cs-active .cs-options li:nth-child(2) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}
.cs-skin-slide.cs-active .cs-options li:nth-child(3) {
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.15s;
}
.cs-skin-slide.cs-active .cs-options li:nth-child(4) {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.cs-skin-slide.cs-active .cs-options li:nth-child(5) {
  -webkit-transition-delay: 0.25s;
  transition-delay: 0.25s;
}
/* more options need more delay declaration */
.cs-skin-slide .cs-options li span {
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 2px;
  font-size: 65%;
  padding: 0.8em 2.5em 0.8em 1em;
}
.cs-skin-slide .cs-options li span:hover,
.cs-skin-slide .cs-options li.cs-focus span,
.cs-skin-slide .cs-options li.cs-selected span {
  color: #eb7e7f;
  background: transparent;
}
.cs-skin-slide .cs-selected span::after {
  content: '';
}
/* Pages Select  overriding */
.form-group-default .cs-skin-slide > span {
  padding: 0 0 0 30px;
  height: 22px;
  line-height: 21px;
}
.form-group-default .cs-wrapper {
  width: 100%;
}
.cs-wrapper {
  display: inline-block;
}
.form-control.cs-select:not(.cs-active) {
  width: 100% !important;
}
.cs-select {
  background-color: transparent;
}
.cs-select span {
  text-overflow: initial;
}
.cs-select .cs-placeholder {
  width: 100%;
}
div.cs-skin-slide {
  width: auto;
  font-family: Arial, sans-serif;
  color: #5e5e5e;
}
div.cs-skin-slide:before {
  background-color: transparent;
}
div.cs-skin-slide.cs-transparent {
  background: none;
}
div.cs-skin-slide.cs-transparent .cs-backdrop {
  border-color: transparent;
  background: none;
}
div.cs-skin-slide.cs-transparent.cs-active .cs-backdrop {
  background: #fafafa;
}
div.cs-skin-slide > span {
  height: 35px;
  padding: 6px 17px 6px 33px;
  line-height: 23px;
  z-index: 1;
}
div.cs-skin-slide.cs-active {
  z-index: 790;
}
div.cs-skin-slide.cs-active:before {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}
div.cs-skin-slide.cs-active .cs-backdrop {
  border: transparent;
  background: #fafafa;
  box-shadow: -1px 0 1px #ccc, 1px 0 1px #ccc;
}
div.cs-skin-slide > span:after,
div.cs-skin-slide.cs-active > span:after {
  content: "\f0d7";
  font-family: FontAwesome;
  color: #5e5e5e;
}
div.cs-skin-slide .cs-options {
  height: auto;
  padding: 9px 0;
  width: auto;
  padding: 10px;
  max-height: 350px;
  transform: translate3d(1, 1, 1);
  overflow: hidden;
  z-index: 1;
}
div.cs-skin-slide .cs-options ul {
  width: 100%;
  display: table;
}
div.cs-skin-slide .cs-options ul li {
  display: table-row;
}
div.cs-skin-slide .cs-options ul li span {
  display: table-cell;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: normal;
  padding: 5px 0;
  text-transform: none;
  max-height: 350px;
  overflow-y: auto;
}
div.cs-skin-slide .cs-options ul li span:hover,
div.cs-skin-slide .cs-options ul li.cs-focus span,
div.cs-skin-slide .cs-options ul li.cs-selected span {
  color: #2c2c2c;
}
.cs-backdrop {
  background: none repeat scroll 0 0 #fff;
  border: 1px solid rgba(0, 0, 0, 0.07);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.cs-skin-slide.cs-active .cs-options li:nth-child(6) {
  transition-delay: 0.3s;
}
.cs-skin-slide.cs-active .cs-options li:nth-child(7) {
  transition-delay: 0.35s;
}
.cs-skin-slide.cs-active .cs-options li:nth-child(8) {
  transition-delay: 0.4s;
}
.cs-skin-slide.cs-active .cs-options li:nth-child(9) {
  transition-delay: 0.45s;
}
.cs-skin-slide.cs-active .cs-options li:nth-child(10) {
  transition-delay: 0.5s;
}
.cs-skin-slide.cs-active .cs-options li:nth-child(11) {
  transition-delay: 0.55s;
}
/* Dropzone for Angular */
.dropzone[dropzone="dropzone"] input[type="file"] {
  visibility: hidden;
}
/* end overrides */
/* Demo specifc classes */
.demo-form-wysiwyg {
  height: 250px;
}
/*------------------------------------------------------------------
[16. Tables and Datatables]
*/
/* Generic Tables
------------------------------------
*/
.table {
  margin-top: 5px;
}
.table thead tr th {
  text-transform: uppercase;
  font-weight: 600;
  font-family: 'Montserrat';
  font-size: 13px;
  padding-top: 14px;
  padding-bottom: 14px;
  vertical-align: middle;
  border-bottom: 1px solid rgba(230, 230, 230, 0.7);
  color: rgba(44, 44, 44, 0.35);
}
.table thead tr th[class*='sorting_']:not([class='sorting_disabled']) {
  color: #2c2c2c;
}
.table thead tr th:first-child {
  padding-right: 18px !important;
}
.table thead tr th .btn {
  margin-top: -20px;
  margin-bottom: -20px;
}
.table tbody tr td {
  background: #fff;
  border-bottom: 1px solid rgba(230, 230, 230, 0.7);
  border-top: 0px;
  padding: 20px;
  font-size: 13.5px;
}
.table tbody tr td .btn-tag {
  background: rgba(44, 44, 44, 0.07);
  display: inline-block;
  margin: 5px;
  border-radius: 4px;
  padding: 5px;
  color: #62605a !important;
}
.table tbody tr td .btn-tag:hover {
  background: rgba(44, 44, 44, 0.15);
}
.table tbody tr td[class*='sorting_'] {
  color: #000;
}
.table tbody tr.selected td {
  background: #fef6dd;
}
.table.table-hover tbody tr:hover td {
  background: #daeffd !important;
}
.table.table-hover tbody tr.selected:hover td {
  background: #fef6dd !important;
}
.table.table-striped tbody tr td {
  background: #fafafa !important;
}
.table.table-striped tbody tr:nth-child(2n+1) td {
  background: #fff !important;
}
.table.table-borderless tbody tr td {
  border-top: 0;
}
.table.table-condensed {
  table-layout: fixed;
}
.table.table-condensed thead tr th {
  padding-left: 20px;
  padding-right: 20px;
}
.table.table-condensed tbody tr td {
  padding-top: 12px;
  padding-bottom: 12px;
}
.table.table-condensed thead tr th,
.table.table-condensed tbody tr td,
.table.table-condensed tbody tr td *:not(.dropdown-default) {
  white-space: nowrap;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table.table-condensed.table-detailed > tbody > tr.shown > td {
  background: #fef6dd;
}
.table.table-condensed.table-detailed > tbody > tr.shown > td:first-child:before {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.table.table-condensed.table-detailed > tbody > tr.shown + tr > td {
  background: rgba(250, 250, 250, 0.4);
  padding: 0px 40px;
}
.table.table-condensed.table-detailed > tbody > tr.shown + tr > td .table-inline {
  background: transparent;
}
.table.table-condensed.table-detailed > tbody > tr.shown + tr > td .table-inline tr,
.table.table-condensed.table-detailed > tbody > tr.shown + tr > td .table-inline td {
  background: transparent;
  font-weight: 600;
}
.table.table-condensed.table-detailed > tbody > tr.row-details > td:first-child:before {
  content: '';
}
.table.table-condensed.table-detailed > tbody > tr > td:hover {
  cursor: pointer;
}
.table.table-condensed.table-detailed > tbody > tr > td:first-child:before {
  content: "\f054";
  display: inline-block;
  margin-left: 8px;
  font-family: 'FontAwesome';
  -webkit-transition: all 0.12s linear;
  transition: all 0.12s linear;
}
.table.table-condensed.table-detailed .table-inline td {
  border: none;
  text-align: left;
}
.table.table-borderless > tbody > tr > td {
  border-bottom: 0px;
}
.fht-table {
  margin-bottom: 0 !important;
}
/* Data-tables
------------------------------------
*/
.table.dataTable.no-footer {
  border: none;
}
.dataTables_scroll:hover .dataTables_scrollBody:before {
  content: "";
  top: 0;
  height: 0;
}
.dataTables_scrollBody {
  overflow-y: auto;
  border: none !important;
}
.dataTables_scrollBody:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 60px;
  bottom: 0;
  background: transparent;
}
.dataTables_wrapper .dataTables_paginate {
  float: left;
}
.dataTables_wrapper .dataTables_paginate ul > li.disabled a {
  opacity: 0.5;
}
.dataTables_wrapper .dataTables_paginate ul > li > a {
  padding: 5px 10px;
  color: #626262;
  opacity: 0.35;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}
.dataTables_wrapper .dataTables_paginate ul > li > a:hover {
  opacity: 0.65;
}
.dataTables_wrapper .dataTables_paginate ul > li.next > a,
.dataTables_wrapper .dataTables_paginate ul > li.prev > a {
  opacity: 1;
}
.dataTables_wrapper .dataTables_paginate ul > li.disabled a {
  opacity: 0.35;
}
.dataTables_wrapper .dataTables_paginate ul > li.disabled a:hover {
  opacity: 0.35;
}
.dataTables_wrapper .dataTables_info,
.dataTables_wrapper .dataTables_paginate {
  margin-top: 25px !important;
}
.dataTables_paginate.paging_bootstrap.pagination {
  padding-top: 0;
  padding-left: 20px;
}
.dataTables_wrapper .dataTables_info {
  clear: none;
  font-size: 12px;
  padding: 0 33px;
  color: #626262;
}
.dataTables_wrapper .dataTables_paginate ul > li {
  display: inline-block;
  padding-right: 0;
  font-size: 11px;
}
.dataTables_scrollHeadInner {
  padding-left: 0 !important;
}
.export-options-container {
  position: relative;
}
.dataTables_wrapper .dataTables_paginate ul > li.active > a {
  font-weight: bold;
  color: #626262;
  opacity: 1;
}
.export-options-container a {
  color: inherit;
  opacity: 1;
}
.exportOptions .DTTT.btn-group a {
  display: block !important;
}
/* Responsive Handlers : Tables */
@media (max-width: 991px) {
  .dataTables_wrapper .dataTables_info {
    float: right;
  }
  .dataTables_paginate.paging_bootstrap.pagination {
    float: left;
  }
}
@media (max-width: 480px) {
  .dataTables_wrapper .dataTables_info,
  .dataTables_wrapper .dataTables_paginate {
    float: none;
    text-align: right;
    clear: both;
    display: block;
  }
}
/* Demo classes */
.demo-table-search thead th:nth-child(1) {
  width: 20%;
}
.demo-table-search thead th:nth-child(2) {
  width: 22%;
}
.demo-table-search thead th:nth-child(3) {
  width: 24%;
}
.demo-table-search thead th:nth-child(4) {
  width: 15%;
}
.demo-table-search thead th:nth-child(5) {
  width: 19%;
}
.demo-table-dynamic thead th:nth-child(1) {
  width: 25%;
}
.demo-table-dynamic thead th:nth-child(2) {
  width: 30%;
}
.demo-table-dynamic thead th:nth-child(3) {
  width: 20%;
}
.demo-table-dynamic thead th:nth-child(4) {
  width: 25%;
}
/*------------------------------------------------------------------
[18. List]
*/
.list-view-wrapper {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  width: 100%;
  -webkit-overflow-scrolling: touch;
}
.list-view-container {
  position: relative;
}
.list-view-container .list-quickscroll ul {
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 10px;
  z-index: 120;
  padding-top: 10px;
  padding-bottom: 10px;
}
.list-view-container .list-quickscroll ul li a {
  font-size: 0.7em;
  vertical-align: baseline;
}
.list-view-group-container {
  margin: 0;
  min-height: 1px;
  overflow: hidden;
  padding: 26px 0 0 0;
  position: relative;
}
.list-view-group-container:last-child ul {
  border: 0;
}
.list-view-group-header,
.list-view-fake-header {
  background: #fafafa;
  color: #232830;
  font: normal 11px/14px 'Montserrat', Arial;
  text-transform: uppercase;
  margin: 0;
  padding: 6px 15px 5px 0;
  position: absolute;
  border-top: 1px solid rgba(0, 0, 0, 0.07);
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  z-index: 10;
}
.list-view-group-header {
  bottom: auto;
  min-height: 1px;
  top: 0;
  width: 100%;
  border-top: 0;
}
.list-view-fake-header {
  width: 100%;
  z-index: 100;
  font-size: 11px !important;
  line-height: 14px !important;
}
.list-view-fake-header.list-view-hidden {
  visibility: hidden;
}
.list-view-group-container.list-view-animated .list-view-group-header {
  bottom: 0;
  top: auto;
}
input.list-view-search {
  font-size: 15px;
  color: #232830;
}
.no-top-border .list-view-fake-header {
  border-top: 0;
}
.list-view ul {
  list-style: none;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
}
.list-view li {
  padding-right: 15px;
  white-space: nowrap;
  cursor: pointer;
  width: 100%;
  display: table;
}
.list-view li:hover {
  background-color: #daeffd;
}
.list-view li.active {
  background-color: #fef6dd;
}
.list-view li p {
  margin: 0;
  line-height: 19px;
}
.list-view li > a {
  margin-left: 15px;
  display: table;
  width: 100%;
}
.list-view.boreded li > a {
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
}
.list-view.boreded li:last-child > a {
  border-bottom: 0;
}
.list-view.boreded li + li {
  border-top: 0;
}
[data-ios="true"] .list-view-group-header,
[data-ios="true"] .list-view-fake-header {
  width: 288px;
  /*scrollbars aren't visible in iOS devices, so make the headers wider */
}
.list-group-item {
  border: 1px solid #f0f0f0;
}
.list-group-item.active,
.list-group-item.active:hover,
.list-group-item.active:focus {
  background-color: #40d9ca;
  border-color: #40d9ca;
}
.list-group-item:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.list-group-item:last-child {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}
/*------------------------------------------------------------------
[28. Misc : Helper Classes]
*/
.custom {
  height: 150px;
}
.icon-list .fa-item {
  display: block;
  color: #121212;
  line-height: 32px;
  height: 32px;
  padding-right: 10px;
}
.icon-list .fa-item > i {
  width: 32px;
  font-size: 14px;
  display: inline-block;
  text-align: left;
  margin-left: 10px;
}
/* Thumbnail for icons and profile pics 
------------------------------------
*/
.thumbnail-wrapper {
  display: inline-block;
  overflow: hidden;
  float: right;
}
.thumbnail-wrapper.circular {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
}
.thumbnail-wrapper.bordered {
  border-width: 4px;
  border-style: solid;
}
.thumbnail-wrapper.bordered.d16 {
  width: 20px;
  height: 20px;
}
.thumbnail-wrapper.bordered.d16 > * {
  line-height: 12px;
}
.thumbnail-wrapper.bordered.d24 {
  width: 28px;
  height: 28px;
}
.thumbnail-wrapper.bordered.d24 > * {
  line-height: 24px;
}
.thumbnail-wrapper.bordered.d32 {
  width: 36px;
  height: 36px;
}
.thumbnail-wrapper.bordered.d32 > * {
  line-height: 28px;
}
.thumbnail-wrapper.bordered.d48 {
  width: 52px;
  height: 52px;
}
.thumbnail-wrapper.bordered.d48 > * {
  line-height: 44px;
}
.thumbnail-wrapper.d16 {
  width: 16px;
  height: 16px;
}
.thumbnail-wrapper.d16 > * {
  line-height: 16px;
}
.thumbnail-wrapper.d24 {
  width: 24px;
  height: 24px;
}
.thumbnail-wrapper.d24 > * {
  line-height: 24px;
}
.thumbnail-wrapper.d32 {
  width: 32px;
  height: 32px;
}
.thumbnail-wrapper.d32 > * {
  line-height: 32px;
}
.thumbnail-wrapper.d39 {
  width: 39px;
  height: 39px;
}
.thumbnail-wrapper.d39 > * {
  line-height: 39px;
}
.thumbnail-wrapper.d48 {
  width: 48px;
  height: 48px;
}
.thumbnail-wrapper.d48 > * {
  line-height: 50px;
}
.thumbnail-wrapper > * {
  vertical-align: middle;
  width: 100%;
  height: 100%;
  text-align: center;
}
/* Profile dropdown
------------------------------------
*/
.profile-dropdown {
  background: #fff;
  padding: 0;
}
.profile-dropdown:before {
  position: absolute;
  top: -7px;
  right: 15px;
  display: inline-block;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-left: 7px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: '';
}
.profile-dropdown:after {
  position: absolute;
  top: -6px;
  right: 16px;
  display: inline-block;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #ffffff;
  border-left: 6px solid transparent;
  content: '';
}
.profile-dropdown li:last-child {
  margin-top: 11px;
  padding: 0;
}
.profile-dropdown li:last-child > a {
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 19px;
}
.profile-dropdown li > a {
  opacity: 0.5;
  -webkit-transition: opacity ease 0.3s;
  transition: opacity ease 0.3s;
  padding-right: 17px;
  padding-left: 37px;
  min-width: 138px;
}
.profile-dropdown li > a > i {
  margin-left: 5px;
}
.profile-dropdown li > a:hover {
  opacity: 1;
}
.profile-dropdown-toggle {
  background: transparent;
  border: none;
}
/* Scroll 
------------------------------------
*/
.scrollable {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
/* Overides 
------------------------------------
*/
.no-padding {
  padding: 0px !important;
}
.no-margin {
  margin: 0px !important;
}
.no-overflow {
  overflow: hidden !important;
}
.auto-overflow {
  overflow: auto !important;
}
.reset-overflow {
  overflow: initial !important;
}
.center-margin {
  margin-left: auto;
  margin-right: auto;
}
.inherit-size {
  width: inherit;
  height: inherit;
}
.inherit-height {
  height: inherit;
}
.image-responsive-height {
  width: 100%;
}
.image-responsive-width {
  height: 100%;
}
.overlayer {
  position: absolute;
  display: block;
  z-index: 21;
}
.overlayer.fullwidth {
  width: 100%;
}
.overlayer-wrapper {
  position: relative;
  display: block;
  z-index: 10;
}
.overlay-fixed {
  position: fixed !important;
  top: auto !important;
}
.top-left {
  position: absolute !important;
  top: 0;
  left: 0;
}
.top-right {
  position: absolute !important;
  top: 1px;
  right: 0;
}
.bottom-left {
  position: absolute !important;
  bottom: 1px;
  left: 0;
}
.bottom-right {
  position: absolute !important;
  bottom: 0;
  right: 0;
}
.pull-bottom {
  position: absolute !important;
  bottom: 0;
}
.pull-up {
  position: absolute !important;
  top: 0;
}
.pull-left {
  float: right !important;
}
.pull-right {
  float: left !important;
}
.pull-center {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  display: table;
  z-index: 1;
}
.cursor {
  cursor: pointer;
}
.scroll-x-hidden {
  overflow-x: hidden !important;
}
/* Generic Padding Helpers 
------------------------------------
*/
.p-t-0 {
  padding-top: 0px !important;
}
.p-r-0 {
  padding-left: 0px !important;
}
.p-l-0 {
  padding-right: 0px !important;
}
.p-b-0 {
  padding-bottom: 0px !important;
}
.padding-0 {
  padding: 0px !important;
}
.p-t-5 {
  padding-top: 5px !important;
}
.p-r-5 {
  padding-left: 5px !important;
}
.p-l-5 {
  padding-right: 5px !important;
}
.p-b-5 {
  padding-bottom: 5px !important;
}
.padding-5 {
  padding: 5px !important;
}
.p-t-10 {
  padding-top: 10px !important;
}
.p-r-10 {
  padding-left: 10px !important;
}
.p-l-10 {
  padding-right: 10px !important;
}
.p-b-10 {
  padding-bottom: 10px !important;
}
.padding-10 {
  padding: 10px !important;
}
.p-t-15 {
  padding-top: 15px !important;
}
.p-r-15 {
  padding-left: 15px !important;
}
.p-l-15 {
  padding-right: 15px !important;
}
.p-b-15 {
  padding-bottom: 15px !important;
}
.padding-15 {
  padding: 15px !important;
}
.p-t-20 {
  padding-top: 20px !important;
}
.p-r-20 {
  padding-left: 20px !important;
}
.p-l-20 {
  padding-right: 20px !important;
}
.p-b-20 {
  padding-bottom: 20px !important;
}
.padding-20 {
  padding: 20px !important;
}
.p-t-25 {
  padding-top: 25px !important;
}
.p-r-25 {
  padding-left: 25px !important;
}
.p-l-25 {
  padding-right: 25px !important;
}
.p-b-25 {
  padding-bottom: 25px !important;
}
.padding-25 {
  padding: 25px !important;
}
.p-t-30 {
  padding-top: 30px !important;
}
.p-r-30 {
  padding-left: 30px !important;
}
.p-l-30 {
  padding-right: 30px !important;
}
.p-b-30 {
  padding-bottom: 30px !important;
}
.padding-30 {
  padding: 30px !important;
}
.p-t-35 {
  padding-top: 35px !important;
}
.p-r-35 {
  padding-left: 35px !important;
}
.p-l-35 {
  padding-right: 35px !important;
}
.p-b-35 {
  padding-bottom: 35px !important;
}
.padding-35 {
  padding: 35px !important;
}
.p-t-40 {
  padding-top: 40px !important;
}
.p-r-40 {
  padding-left: 40px !important;
}
.p-l-40 {
  padding-right: 40px !important;
}
.p-b-40 {
  padding-bottom: 40px !important;
}
.padding-40 {
  padding: 40px !important;
}
.p-t-45 {
  padding-top: 45px !important;
}
.p-r-45 {
  padding-left: 45px !important;
}
.p-l-45 {
  padding-right: 45px !important;
}
.p-b-45 {
  padding-bottom: 45px !important;
}
.padding-45 {
  padding: 45px !important;
}
.p-t-50 {
  padding-top: 50px !important;
}
.p-r-50 {
  padding-left: 50px !important;
}
.p-l-50 {
  padding-right: 50px !important;
}
.p-b-50 {
  padding-bottom: 50px !important;
}
.padding-50 {
  padding: 50px !important;
}
.p-t-55 {
  padding-top: 55px !important;
}
.p-r-55 {
  padding-left: 55px !important;
}
.p-l-55 {
  padding-right: 55px !important;
}
.p-b-55 {
  padding-bottom: 55px !important;
}
.padding-55 {
  padding: 55px !important;
}
.p-t-60 {
  padding-top: 60px !important;
}
.p-r-60 {
  padding-left: 60px !important;
}
.p-l-60 {
  padding-right: 60px !important;
}
.p-b-60 {
  padding-bottom: 60px !important;
}
.padding-60 {
  padding: 60px !important;
}
.p-t-65 {
  padding-top: 65px !important;
}
.p-r-65 {
  padding-left: 65px !important;
}
.p-l-65 {
  padding-right: 65px !important;
}
.p-b-65 {
  padding-bottom: 65px !important;
}
.padding-65 {
  padding: 65px !important;
}
.p-t-70 {
  padding-top: 70px !important;
}
.p-r-70 {
  padding-left: 70px !important;
}
.p-l-70 {
  padding-right: 70px !important;
}
.p-b-70 {
  padding-bottom: 70px !important;
}
.padding-70 {
  padding: 70px !important;
}
.p-t-75 {
  padding-top: 75px !important;
}
.p-r-75 {
  padding-left: 75px !important;
}
.p-l-75 {
  padding-right: 75px !important;
}
.p-b-75 {
  padding-bottom: 75px !important;
}
.padding-75 {
  padding: 75px !important;
}
.p-t-80 {
  padding-top: 80px !important;
}
.p-r-80 {
  padding-left: 80px !important;
}
.p-l-80 {
  padding-right: 80px !important;
}
.p-b-80 {
  padding-bottom: 80px !important;
}
.padding-80 {
  padding: 80px !important;
}
.p-t-85 {
  padding-top: 85px !important;
}
.p-r-85 {
  padding-left: 85px !important;
}
.p-l-85 {
  padding-right: 85px !important;
}
.p-b-85 {
  padding-bottom: 85px !important;
}
.padding-85 {
  padding: 85px !important;
}
.p-t-90 {
  padding-top: 90px !important;
}
.p-r-90 {
  padding-left: 90px !important;
}
.p-l-90 {
  padding-right: 90px !important;
}
.p-b-90 {
  padding-bottom: 90px !important;
}
.padding-90 {
  padding: 90px !important;
}
.p-t-95 {
  padding-top: 95px !important;
}
.p-r-95 {
  padding-left: 95px !important;
}
.p-l-95 {
  padding-right: 95px !important;
}
.p-b-95 {
  padding-bottom: 95px !important;
}
.padding-95 {
  padding: 95px !important;
}
.p-t-100 {
  padding-top: 100px !important;
}
.p-r-100 {
  padding-left: 100px !important;
}
.p-l-100 {
  padding-right: 100px !important;
}
.p-b-100 {
  padding-bottom: 100px !important;
}
.padding-100 {
  padding: 100px !important;
}
/* Generic Margin Helpers
------------------------------------
 */
.m-t-0 {
  margin-top: 0px;
}
.m-r-0 {
  margin-left: 0px;
}
.m-l-0 {
  margin-right: 0px;
}
.m-b-0 {
  margin-bottom: 0px;
}
.m-t-5 {
  margin-top: 5px;
}
.m-r-5 {
  margin-left: 5px;
}
.m-l-5 {
  margin-right: 5px;
}
.m-b-5 {
  margin-bottom: 5px;
}
.m-t-10 {
  margin-top: 10px;
}
.m-r-10 {
  margin-left: 10px;
}
.m-l-10 {
  margin-right: 10px;
}
.m-b-10 {
  margin-bottom: 10px;
}
.m-t-15 {
  margin-top: 15px;
}
.m-r-15 {
  margin-left: 15px;
}
.m-l-15 {
  margin-right: 15px;
}
.m-b-15 {
  margin-bottom: 15px;
}
.m-t-20 {
  margin-top: 20px;
}
.m-r-20 {
  margin-left: 20px;
}
.m-l-20 {
  margin-right: 20px;
}
.m-b-20 {
  margin-bottom: 20px;
}
.m-t-25 {
  margin-top: 25px;
}
.m-r-25 {
  margin-left: 25px;
}
.m-l-25 {
  margin-right: 25px;
}
.m-b-25 {
  margin-bottom: 25px;
}
.m-t-30 {
  margin-top: 30px;
}
.m-r-30 {
  margin-left: 30px;
}
.m-l-30 {
  margin-right: 30px;
}
.m-b-30 {
  margin-bottom: 30px;
}
.m-t-35 {
  margin-top: 35px;
}
.m-r-35 {
  margin-left: 35px;
}
.m-l-35 {
  margin-right: 35px;
}
.m-b-35 {
  margin-bottom: 35px;
}
.m-t-40 {
  margin-top: 40px;
}
.m-r-40 {
  margin-left: 40px;
}
.m-l-40 {
  margin-right: 40px;
}
.m-b-40 {
  margin-bottom: 40px;
}
.m-t-45 {
  margin-top: 45px;
}
.m-r-45 {
  margin-left: 45px;
}
.m-l-45 {
  margin-right: 45px;
}
.m-b-45 {
  margin-bottom: 45px;
}
.m-t-50 {
  margin-top: 50px;
}
.m-r-50 {
  margin-left: 50px;
}
.m-l-50 {
  margin-right: 50px;
}
.m-b-50 {
  margin-bottom: 50px;
}
.m-t-55 {
  margin-top: 55px;
}
.m-r-55 {
  margin-left: 55px;
}
.m-l-55 {
  margin-right: 55px;
}
.m-b-55 {
  margin-bottom: 55px;
}
.m-t-60 {
  margin-top: 60px;
}
.m-r-60 {
  margin-left: 60px;
}
.m-l-60 {
  margin-right: 60px;
}
.m-b-60 {
  margin-bottom: 60px;
}
.m-t-65 {
  margin-top: 65px;
}
.m-r-65 {
  margin-left: 65px;
}
.m-l-65 {
  margin-right: 65px;
}
.m-b-65 {
  margin-bottom: 65px;
}
.m-t-70 {
  margin-top: 70px;
}
.m-r-70 {
  margin-left: 70px;
}
.m-l-70 {
  margin-right: 70px;
}
.m-b-70 {
  margin-bottom: 70px;
}
.m-t-75 {
  margin-top: 75px;
}
.m-r-75 {
  margin-left: 75px;
}
.m-l-75 {
  margin-right: 75px;
}
.m-b-75 {
  margin-bottom: 75px;
}
.m-t-80 {
  margin-top: 80px;
}
.m-r-80 {
  margin-left: 80px;
}
.m-l-80 {
  margin-right: 80px;
}
.m-b-80 {
  margin-bottom: 80px;
}
.m-t-85 {
  margin-top: 85px;
}
.m-r-85 {
  margin-left: 85px;
}
.m-l-85 {
  margin-right: 85px;
}
.m-b-85 {
  margin-bottom: 85px;
}
.m-t-90 {
  margin-top: 90px;
}
.m-r-90 {
  margin-left: 90px;
}
.m-l-90 {
  margin-right: 90px;
}
.m-b-90 {
  margin-bottom: 90px;
}
.m-t-95 {
  margin-top: 95px;
}
.m-r-95 {
  margin-left: 95px;
}
.m-l-95 {
  margin-right: 95px;
}
.m-b-95 {
  margin-bottom: 95px;
}
.m-t-100 {
  margin-top: 100px;
}
.m-r-100 {
  margin-left: 100px;
}
.m-l-100 {
  margin-right: 100px;
}
.m-b-100 {
  margin-bottom: 100px;
}
.full-height {
  height: 100% !important;
}
.full-width {
  width: 100%;
}
.hide {
  display: none;
}
.inline {
  display: inline-block !important;
}
.block {
  display: block;
}
.b-blank {
  border-color: #000;
}
/* Border Helpers 
------------------------------------
*/
.b-a,
.b-r,
.b-l,
.b-t,
.b-b {
  border-style: solid;
  border-width: 0;
}
.b-r {
  border-left-width: 1px;
}
.b-l {
  border-right-width: 1px;
}
.b-t {
  border-top-width: 1px;
}
.b-b {
  border-bottom-width: 1px;
}
.b-a {
  border-width: 1px;
}
.b-dashed {
  border-style: dashed;
}
.b-thick {
  border-width: 2px;
}
.b-transparent {
  border-color: rgba(0, 0, 0, 0.4);
}
.b-transparent-white {
  border-color: rgba(255, 255, 255, 0.3);
}
.b-grey {
  border-color: #e6e6e6;
}
.b-white {
  border-color: #fff;
}
.b-primary {
  border-color: #e67e22;
}
.b-complete {
  border-color: #e67e22;
}
.b-success {
  border-color: #10cfbd;
}
.b-info {
  border-color: #3b4752;
}
.b-danger {
  border-color: #f55753;
}
.b-warning {
  border-color: #f8d053;
}
/* Border Radius
------------------------------------
*/
.b-rad-sm {
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
}
.b-rad-md {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
}
.b-rad-lg {
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
}
.no-border {
  border: none !important;
}
/** Profile Wrappers **/
.profile-img-wrapper {
  float: right;
  border-radius: 100px 100px 100px 100px;
  display: inline-block;
  height: 35px;
  overflow: hidden;
  width: 35px;
  -webkit-flex: 1;
  -moz-flex: 1;
  flex: 1;
}
.profile-img-wrapper.big {
  height: 68px;
  width: 68px;
}
.profile-img-wrapper.with-left-space-custom {
  margin-right: 7px;
}
.relative {
  position: relative;
}
/* Demo Purposes */
.icon-set-preview {
  transition: opacity 0.1s linear;
}
#icon-list {
  transition: all 0.1s ease-in-out;
}
.error-number {
  font-family: 'Montserrat';
  font-size: 90px;
  line-height: 90px;
}
.error-container-innner {
  margin-left: auto;
  margin-right: auto;
  width: 360px;
}
.error-container {
  margin-top: -100px;
  margin-left: auto;
  margin-right: auto;
  width: 38%;
}
.visible-xlg {
  display: none;
}
.hidden-xlg {
  display: block;
}
.sm-gutter .row > [class^="col-"],
.sm-gutter .row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}
.sm-gutter .row {
  margin-right: -5px;
  margin-left: -5px;
}
/* Aspect ratio */
.ar-1-1 .panel,
.ar-2-1 .panel,
.ar-1-2 .panel,
.ar-3-2 .panel,
.ar-2-3 .panel {
  margin: 0;
}
.ar-1-1 {
  position: relative;
  width: 100%;
  /* desired width */
  overflow: hidden;
}
.ar-1-1:before {
  content: "";
  display: block;
  padding-top: 100%;
  /* initial ratio of 1:1*/
}
.ar-1-1 > div {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.ar-2-1 {
  position: relative;
  width: 100%;
  /* desired width */
  overflow: hidden;
}
.ar-2-1:before {
  content: "";
  display: block;
  padding-top: calc(50% -  5px);
  /* initial ratio of 1:1*/
}
.ar-2-1 > div {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.ar-1-2 {
  position: relative;
  width: 100%;
  /* desired width */
  overflow: hidden;
}
.ar-1-2:before {
  content: "";
  display: block;
  padding-top: calc(150% -  5px);
  /* initial ratio of 1:1*/
}
.ar-1-2 > div {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.ar-3-2 {
  position: relative;
  width: 100%;
  /* desired width */
  overflow: hidden;
}
.ar-3-2:before {
  content: "";
  display: block;
  padding-top: calc(75% -  5px);
  /* initial ratio of 1:1*/
}
.ar-3-2 > div {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.ar-2-3 {
  position: relative;
  width: 100%;
  /* desired width */
  overflow: hidden;
}
.ar-2-3:before {
  content: "";
  display: block;
  padding-top: calc(125% -  5px);
  /* initial ratio of 1:1*/
}
.ar-2-3 > div {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.v-align-bottom {
  vertical-align: bottom !important;
}
.v-align-top {
  vertical-align: top !important;
}
.v-align-middle {
  vertical-align: middle !important;
}
/* vertical alignment styles */
.col-top {
  vertical-align: top !important;
}
.col-middle {
  vertical-align: middle !important;
}
.col-bottom {
  vertical-align: bottom !important;
}
/* columns of same height styles 
------------------------------------
*/
.container-xs-height {
  display: table;
  padding-left: 0px;
  padding-right: 0px;
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.row-xs-height {
  display: table-row;
}
.col-xs-height {
  display: table-cell;
  float: none;
}
@media (min-width: 768px) {
  .container-sm-height {
    display: table;
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
  }
  .row-sm-height {
    display: table-row;
  }
  .col-sm-height {
    display: table-cell !important;
    float: none !important;
  }
}
@media (min-width: 992px) {
  .container-md-height {
    display: table;
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
  }
  .row-md-height {
    display: table-row;
  }
  .col-md-height {
    display: table-cell !important;
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .container-lg-height {
    display: table;
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
  }
  .row-lg-height {
    display: table-row;
  }
  .col-lg-height {
    display: table-cell !important;
    float: none !important;
  }
}
.visible-xlg {
  display: none;
}
.hidden-xlg {
  display: block;
}
/*** Large screens ***/
@media (min-width: 1824px) {
  .visible-xlg {
    display: block !important;
  }
  .hidden-xlg {
    display: none !important;
  }
  .quickview-wrapper .tab-content #quickview-notes > .inner {
    width: 570px;
  }
  .container-fluid.container-fixed-lg {
    width: 1700px;
    margin-right: auto;
    margin-left: auto;
  }
  .menu-pin .container-fluid.container-fixed-lg {
    width: 1430px;
  }
  .visible-xlg {
    display: block !important;
  }
  .hidden-xlg {
    display: none !important;
  }
  .col-xlg-1,
  .col-xlg-2,
  .col-xlg-3,
  .col-xlg-4,
  .col-xlg-5,
  .col-xlg-6,
  .col-xlg-7,
  .col-xlg-8,
  .col-xlg-9,
  .col-xlg-10,
  .col-xlg-11 {
    float: left;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
  }
  .col-xlg-12 {
    float: left;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    width: 100%;
  }
  .col-xlg-11 {
    width: 91.6667%;
  }
  .col-xlg-10 {
    width: 83.3333%;
  }
  .col-xlg-9 {
    width: 75%;
  }
  .col-xlg-8 {
    width: 66.6667%;
  }
  .col-xlg-7 {
    width: 58.3333%;
  }
  .col-xlg-6 {
    width: 50%;
  }
  .col-xlg-5 {
    width: 41.6667%;
  }
  .col-xlg-4 {
    width: 33.3333%;
  }
  .col-xlg-3 {
    width: 25%;
  }
  .col-xlg-2 {
    width: 16.6667%;
  }
  .col-xlg-1 {
    width: 8.33333%;
  }
  .col-xlg-pull-12 {
    right: 100%;
  }
  .col-xlg-pull-11 {
    right: 91.6667%;
  }
  .col-xlg-pull-10 {
    right: 83.3333%;
  }
  .col-xlg-pull-9 {
    right: 75%;
  }
  .col-xlg-pull-8 {
    right: 66.6667%;
  }
  .col-xlg-pull-7 {
    right: 58.3333%;
  }
  .col-xlg-pull-6 {
    right: 50%;
  }
  .col-xlg-pull-5 {
    right: 41.6667%;
  }
  .col-xlg-pull-4 {
    right: 33.3333%;
  }
  .col-xlg-pull-3 {
    right: 25%;
  }
  .col-xlg-pull-2 {
    right: 16.6667%;
  }
  .col-xlg-pull-1 {
    right: 8.33333%;
  }
  .col-xlg-pull-0 {
    right: 0;
  }
  .col-xlg-push-12 {
    left: 100%;
  }
  .col-xlg-push-11 {
    left: 91.6667%;
  }
  .col-xlg-push-10 {
    left: 83.3333%;
  }
  .col-xlg-push-9 {
    left: 75%;
  }
  .col-xlg-push-8 {
    left: 66.6667%;
  }
  .col-xlg-push-7 {
    left: 58.3333%;
  }
  .col-xlg-push-6 {
    left: 50%;
  }
  .col-xlg-push-5 {
    left: 41.6667%;
  }
  .col-xlg-push-4 {
    left: 33.3333%;
  }
  .col-xlg-push-3 {
    left: 25%;
  }
  .col-xlg-push-2 {
    left: 16.6667%;
  }
  .col-xlg-push-1 {
    left: 8.33333%;
  }
  .col-xlg-push-0 {
    left: 0;
  }
  .col-xlg-offset-12 {
    margin-left: 100%;
  }
  .col-xlg-offset-11 {
    margin-left: 91.6667%;
  }
  .col-xlg-offset-10 {
    margin-left: 83.3333%;
  }
  .col-xlg-offset-9 {
    margin-left: 75%;
  }
  .col-xlg-offset-8 {
    margin-left: 66.6667%;
  }
  .col-xlg-offset-7 {
    margin-left: 58.3333%;
  }
  .col-xlg-offset-6 {
    margin-left: 50%;
  }
  .col-xlg-offset-5 {
    margin-left: 41.6667%;
  }
  .col-xlg-offset-4 {
    margin-left: 33.3333%;
  }
  .col-xlg-offset-3 {
    margin-left: 25%;
  }
  .col-xlg-offset-2 {
    margin-left: 16.6667%;
  }
  .col-xlg-offset-1 {
    margin-left: 8.33333%;
  }
  .col-xlg-offset-0 {
    margin-left: 0;
  }
}
/*** Desktops ***/
/*** Medium Size Screen ***/
@media only screen and (max-width: 1400px) {
  .page-sidebar .page-sidebar-inner .sidebar-slide .sidebar-menu {
    bottom: 50px;
  }
  .page-sidebar .page-sidebar-inner .sidebar-slide .sidebar-widgets {
    display: none;
  }
  .footer-widget {
    padding: 11px 21px !important;
  }
}
/*** Desktops & Laptops ***/
@media only screen and (min-width: 980px) {
  body.ie9.menu-pin .page-sidebar {
    transform: none !important;
    -webkit-transform: none !important;
    -ms-transform: none !important;
  }
  body.menu-pin {
    overflow-x: hidden;
  }
  body.menu-pin .header .brand {
    width: 245px;
  }
  body.menu-pin .page-container {
    padding-right: 0;
  }
  body.menu-pin .page-container .page-content-wrapper .content {
    padding-right: 270px;
  }
  body.menu-pin .page-container .page-content-wrapper .footer {
    right: 270px;
  }
  body.menu-pin [data-toggle-pin="sidebar"] > i:before {
    content: "\f192";
  }
  body.menu-pin .page-sidebar {
    transform: translate(-200px, 0) !important;
    -webkit-transform: translate(-200px, 0) !important;
    -ms-transform: translate(-200px, 0) !important;
  }
  body.menu-pin .page-sidebar {
    width: 270px;
  }
  body.menu-pin .page-sidebar .sidebar-header .sidebar-header-controls {
    -webkit-transform: translateX(-18px);
    -ms-transform: translateX(-18px);
    transform: translateX(-18px);
  }
  body.menu-pin .page-sidebar .menu-items .icon-thumbnail {
    -webkit-transform: translate3d(14px, 0, 0);
    transform: translate3d(14px, 0, 0);
    -ms-transform: translate(14px, 0);
  }
  body.menu-behind .page-sidebar {
    z-index: 799;
  }
  body.menu-behind .header .brand {
    width: 200px;
    text-align: right;
    padding-right: 20px;
  }
  body.box-layout {
    background-color: #fff;
  }
  body.box-layout > .container,
  body.box-layout > .full-height > .container {
    height: 100%;
    padding: 0;
    background-color: #fafafa;
  }
  body.box-layout .header {
    background-color: transparent;
    border: 0;
    padding: 0;
  }
  body.box-layout .header > .container {
    background-color: #fff;
    border-bottom: 1px solid rgba(230, 230, 230, 0.7);
    padding: 0 20px 0 0;
  }
  body.box-layout .page-sidebar {
    left: auto;
    transform: none !important;
    -webkit-transform: none !important;
  }
  body.box-layout .page-container .page-content-wrapper .footer {
    width: auto;
  }
  .header .brand {
    position: relative;
  }
  .header .user-info-wrapper .user-details .user-name {
    font-size: 16px;
  }
  .header .user-info-wrapper .user-details .user-other {
    font-size: 10px;
  }
  .header .user-info-wrapper .user-pic {
    position: relative;
    top: -6px;
  }
  .notification-panel {
    width: 400px;
  }
}
/*** General Small Screen Desktops ***/
/*** General tablets and phones ***/
@media (max-width: 991px) {
  .page-container {
    padding-right: 0;
  }
  body.sidebar-open .page-container {
    -webkit-transform: translate3d(-250px, 0, 0);
    transform: translate3d(-250px, 0, 0);
    -ms-transform: translate(-250px, 0);
    overflow: hidden;
    position: fixed;
  }
  body.box-layout > .container {
    padding: 0;
    height: 100%;
  }
  body.box-layout .header > .container {
    padding: 0;
  }
  body.box-layout .header > .container .pull-right .sm-action-bar {
    left: 0;
  }
  body.menu-opened.horizontal-menu {
    overflow-y: hidden;
  }
  body.menu-opened.horizontal-menu .bar {
    -webkit-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
    -ms-transform: translate(0%, 0);
  }
  .header {
    padding: 0 10px;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
    background: #fff !important;
  }
  .header .header-inner {
    text-align: center;
  }
  .header .header-inner .mark-email {
    left: 45px;
    position: absolute;
    top: 23px;
  }
  .header .header-inner .quickview-link {
    position: absolute;
    right: 0;
    top: 12px;
  }
  .header .brand {
    width: auto;
  }
  .header .notification-list,
  .header .search-link {
    display: none;
  }
  .header > .pull-left,
  .header > .pull-right {
    position: relative;
  }
  .header > .pull-right .sm-action-bar {
    left: 0;
  }
  .sm-action-bar {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 10;
  }
  .sm-action-bar > a {
    padding: 10px;
    display: inline-block;
  }
  .pace .pace-activity {
    top: 60px;
  }
  .page-sidebar {
    width: 250px;
    z-index: auto;
    right: 0 !important;
    -webkit-transform: translate3d(0, 0px, 0px) !important;
    transform: translate3d(0, 0px, 0px) !important;
  }
  .page-sidebar .sidebar-menu .menu-items > li ul.sub-menu > li {
    padding: 0px 30px 0 36px;
  }
  .page-container {
    padding-left: 0;
    position: relative;
    transition: transform 0.25s ease;
    -webkit-transition: -webkit-transform 0.25s ease;
  }
  .page-container .page-content-wrapper .content {
    overflow-x: hidden;
    height: auto;
  }
  .icon-thumbnail {
    margin-right: 24px;
  }
  .page-sidebar {
    display: none;
    z-index: auto;
  }
  .page-sidebar.visible {
    display: block;
  }
  .page-sidebar .page-sidebar-inner {
    z-index: 1;
    left: 0 !important;
    width: 260px;
  }
  .page-sidebar .page-sidebar-inner .sidebar-slide .sidebar-menu {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    top: 40px;
    bottom: 0;
  }
  .page-sidebar .page-sidebar-inner .sidebar-slide .sidebar-menu ul > li > a > .badge {
    display: inline-block;
  }
  .secondary-sidebar {
    float: none;
    height: auto;
    left: 50%;
    margin-left: -125px;
    padding: 20px;
    position: absolute;
    right: 0;
    top: 60px;
    z-index: 9999;
    display: none;
    border-radius: 10px;
  }
  .secondary-sidebar .btn-compose {
    display: none;
  }
  .inner-content {
    margin: 0;
  }
  .breadcrumb {
    padding-left: 15px;
    padding-right: 15px;
  }
  .copyright {
    padding-left: 15px;
    padding-right: 15px;
  }
  body > .pgn-wrapper[data-position="top"] {
    top: 48px;
    left: 0;
  }
  body > .pgn-wrapper[data-position="bottom"] {
    left: 0;
  }
  body > .pgn-wrapper[data-position$='-left'] {
    left: 20px;
    right: auto;
  }
  .sm-table {
    display: table;
    width: 100%;
  }
  .user-profile-wrapper {
    position: absolute;
    right: 50px;
    top: -9px;
  }
  /*** Horizontal Menu **/
  .horizontal-menu .bar {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 250px;
    background-color: #fff;
    z-index: 10;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    right: 0;
    z-index: 1000;
    box-shadow: 0 0 9px rgba(191, 191, 191, 0.36);
    border-left: 1px solid rgba(222, 227, 231, 0.56);
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    -ms-transform: translate(-100%, 0);
    -webkit-transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  }
  .horizontal-menu .bar .bar-inner > ul > li {
    display: block;
  }
  .horizontal-menu .bar .bar-inner > ul > li > a .arrow {
    float: right;
    padding-right: 25px;
  }
  .horizontal-menu .bar .bar-inner > ul > li .classic {
    position: relative;
    background-color: transparent;
    top: 0;
  }
  .horizontal-menu .bar .bar-inner > ul > li .mega,
  .horizontal-menu .bar .bar-inner > ul > li.horizontal {
    position: relative;
  }
  .horizontal-menu .bar .bar-inner > ul > li > .horizontal {
    position: relative;
    top: 0;
    border-bottom: 0;
  }
  .horizontal-menu .bar .bar-inner > ul > li > .horizontal li {
    display: block;
  }
}
/* Landscape view of all tablet devices */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .page-sidebar .sidebar-menu .menu-items ul {
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    -webkit-perspective: 1000;
  }
  .page-container .page-content-wrapper .content {
    padding-top: 60px;
  }
  body.box-layout > .container {
    width: 100%;
  }
  .error-page .error-container {
    width: auto;
  }
  .secondary-sidebar {
    float: none;
    height: auto;
    left: 50%;
    margin-right: -155px;
    padding: 20px;
    position: absolute;
    right: 0;
    top: 60px;
    z-index: 9999;
    display: none;
    border-radius: 10px;
  }
  .secondary-sidebar .btn-compose {
    display: none;
  }
  .split-details {
    margin-right: 0;
  }
  .toggle-secondary-sidebar {
    display: block;
    font-size: 18px;
    left: 50%;
    margin-right: -36px;
    position: absolute;
  }
  .toggle-secondary-sidebar ~ .brand {
    display: none !important;
  }
}
@media (max-width: 991px) {
  .sm-b-r,
  .sm-b-l,
  .sm-b-t,
  .sm-b-b {
    border-width: 0;
  }
  .sm-b-r {
    border-right-width: 1px;
  }
  .sm-b-l {
    border-left-width: 1px;
  }
  .sm-b-t {
    border-top-width: 1px;
  }
  .sm-b-b {
    border-bottom-width: 1px;
  }
}
@media (min-width: 1200px) {
  .row-same-height {
    overflow: hidden;
  }
  .row-same-height > [class*="col-lg"] {
    margin-bottom: -99999px;
    padding-bottom: 99999px;
  }
}
@media (min-width: 992px) {
  .row-same-height {
    overflow: hidden;
  }
  .row-same-height > [class*="col-md"] {
    margin-bottom: -99999px;
    padding-bottom: 99999px;
  }
  .horizontal-menu .bar {
    display: table !important;
  }
  .horizontal-menu .bar + div {
    padding-top: 50px;
  }
}
@media (min-width: 768px) {
  .row-same-height {
    overflow: hidden;
  }
  .row-same-height > [class*="col-sm"] {
    margin-bottom: -99999px;
    padding-bottom: 99999px;
  }
  .box-layout .container .jumbotron,
  .container-fluid .jumbotron {
    padding: 0;
  }
  .dataTables_wrapper.form-inline .checkbox input[type=checkbox],
  .form-inline .radio input[type=radio] {
    position: absolute;
  }
}
/* Portrait view of all tablet devices */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .page-sidebar .sidebar-menu .menu-items > li > a {
    padding-left: 27px;
  }
  .page-sidebar .sidebar-menu .menu-items > li ul.sub-menu > li {
    padding: 0px 27px 0 31px;
  }
  .icon-thumbnail {
    margin-right: 21px;
  }
  .error-page .error-container {
    width: auto;
  }
}
@media (max-width: 979px) {
  .toggle-secondary-sidebar {
    font-size: 18px;
    position: static;
    display: block;
  }
  .toggle-secondary-sidebar ~ .brand {
    display: none !important;
  }
  .secondary-sidebar {
    float: none;
    height: auto;
    right: 50%;
    margin-right: -125px;
    padding: 20px;
    position: absolute;
    right: 0;
    top: 60px;
    z-index: 9999;
    display: none;
    border-radius: 10px;
  }
  .secondary-sidebar .btn-compose {
    display: none;
  }
  .split-view .split-list {
    width: 100%;
    height: auto;
    bottom: 50px;
    left: 0;
    top: 0;
    position: absolute;
    z-index: 2000;
    float: none;
  }
  .split-view .split-list .item {
    padding: 8px;
  }
  .split-view .split-list .item .inline {
    margin-right: 5px;
    width: 188px;
  }
  .split-view .split-details {
    margin-left: 0;
    width: 100%;
  }
  .split-view .split-details .email-content-wrapper {
    overflow: auto;
    padding: 0;
    height: calc(100% - 50px);
  }
  .split-view .split-details .email-content-wrapper .email-content {
    width: 90%;
  }
  .compose-wrapper {
    display: block !important;
  }
  .compose-wrapper .btn-compose {
    display: block !important;
  }
}
@media (max-width: 767px) {
  .header {
    height: 48px;
  }
  .header .notification-list,
  .header .search-link {
    display: none;
  }
  .header .header-inner {
    height: 48px;
  }
  .header .user-info-wrapper {
    display: none;
  }
  .header .search-link {
    height: 19px;
    width: 16px;
    overflow: hidden;
  }
  .jumbotron,
  .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
  .page-container .page-content-wrapper .content {
    padding-top: 48px;
    padding-bottom: 100px;
  }
  .page-sidebar .sidebar-header {
    padding: 0 12px;
  }
  .page-sidebar .sidebar-menu .menu-items > li > a {
    padding-left: 20px;
  }
  .page-sidebar .sidebar-menu .menu-items > li ul.sub-menu > li {
    padding: 0px 25px 0 28px;
  }
  .icon-thumbnail {
    margin-right: 20px;
  }
  .secondary-sidebar {
    top: 48px;
  }
  .split-details {
    margin-left: 0;
  }
  .email-composer {
    padding-left: 30px;
    padding-right: 30px;
  }
  .sm-pull-bottom,
  .sm-pull-up {
    position: relative !important;
  }
  .sm-p-t-0 {
    padding-top: 0px !important;
  }
  .sm-p-r-0 {
    padding-right: 0px !important;
  }
  .sm-p-l-0 {
    padding-left: 0px !important;
  }
  .sm-p-b-0 {
    padding-bottom: 0px !important;
  }
  .sm-padding-0 {
    padding: 0px !important;
  }
  .sm-p-t-5 {
    padding-top: 5px !important;
  }
  .sm-p-r-5 {
    padding-right: 5px !important;
  }
  .sm-p-l-5 {
    padding-left: 5px !important;
  }
  .sm-p-b-5 {
    padding-bottom: 5px !important;
  }
  .sm-padding-5 {
    padding: 5px !important;
  }
  .sm-p-t-10 {
    padding-top: 10px !important;
  }
  .sm-p-r-10 {
    padding-right: 10px !important;
  }
  .sm-p-l-10 {
    padding-left: 10px !important;
  }
  .sm-p-b-10 {
    padding-bottom: 10px !important;
  }
  .sm-padding-10 {
    padding: 10px !important;
  }
  .sm-p-t-15 {
    padding-top: 15px !important;
  }
  .sm-p-r-15 {
    padding-right: 15px !important;
  }
  .sm-p-l-15 {
    padding-left: 15px !important;
  }
  .sm-p-b-15 {
    padding-bottom: 15px !important;
  }
  .sm-padding-15 {
    padding: 15px !important;
  }
  .sm-p-t-20 {
    padding-top: 20px !important;
  }
  .sm-p-r-20 {
    padding-right: 20px !important;
  }
  .sm-p-l-20 {
    padding-left: 20px !important;
  }
  .sm-p-b-20 {
    padding-bottom: 20px !important;
  }
  .sm-padding-20 {
    padding: 20px !important;
  }
  .sm-p-t-25 {
    padding-top: 25px !important;
  }
  .sm-p-r-25 {
    padding-right: 25px !important;
  }
  .sm-p-l-25 {
    padding-left: 25px !important;
  }
  .sm-p-b-25 {
    padding-bottom: 25px !important;
  }
  .sm-padding-25 {
    padding: 25px !important;
  }
  .sm-p-t-30 {
    padding-top: 30px !important;
  }
  .sm-p-r-30 {
    padding-right: 30px !important;
  }
  .sm-p-l-30 {
    padding-left: 30px !important;
  }
  .sm-p-b-30 {
    padding-bottom: 30px !important;
  }
  .sm-padding-30 {
    padding: 30px !important;
  }
  .sm-p-t-35 {
    padding-top: 35px !important;
  }
  .sm-p-r-35 {
    padding-right: 35px !important;
  }
  .sm-p-l-35 {
    padding-left: 35px !important;
  }
  .sm-p-b-35 {
    padding-bottom: 35px !important;
  }
  .sm-padding-35 {
    padding: 35px !important;
  }
  .sm-p-t-40 {
    padding-top: 40px !important;
  }
  .sm-p-r-40 {
    padding-right: 40px !important;
  }
  .sm-p-l-40 {
    padding-left: 40px !important;
  }
  .sm-p-b-40 {
    padding-bottom: 40px !important;
  }
  .sm-padding-40 {
    padding: 40px !important;
  }
  .sm-p-t-45 {
    padding-top: 45px !important;
  }
  .sm-p-r-45 {
    padding-right: 45px !important;
  }
  .sm-p-l-45 {
    padding-left: 45px !important;
  }
  .sm-p-b-45 {
    padding-bottom: 45px !important;
  }
  .sm-padding-45 {
    padding: 45px !important;
  }
  .sm-p-t-50 {
    padding-top: 50px !important;
  }
  .sm-p-r-50 {
    padding-right: 50px !important;
  }
  .sm-p-l-50 {
    padding-left: 50px !important;
  }
  .sm-p-b-50 {
    padding-bottom: 50px !important;
  }
  .sm-padding-50 {
    padding: 50px !important;
  }
  .sm-m-t-5 {
    margin-top: 5px !important;
  }
  .sm-m-r-5 {
    margin-right: 5px !important;
  }
  .sm-m-l-5 {
    margin-left: 5px !important;
  }
  .sm-m-b-5 {
    margin-bottom: 5px !important;
  }
  .sm-m-t-10 {
    margin-top: 10px !important;
  }
  .sm-m-r-10 {
    margin-right: 10px !important;
  }
  .sm-m-l-10 {
    margin-left: 10px !important;
  }
  .sm-m-b-10 {
    margin-bottom: 10px !important;
  }
  .sm-m-t-15 {
    margin-top: 15px !important;
  }
  .sm-m-r-15 {
    margin-right: 15px !important;
  }
  .sm-m-l-15 {
    margin-left: 15px !important;
  }
  .sm-m-b-15 {
    margin-bottom: 15px !important;
  }
  .sm-m-t-20 {
    margin-top: 20px !important;
  }
  .sm-m-r-20 {
    margin-right: 20px !important;
  }
  .sm-m-l-20 {
    margin-left: 20px !important;
  }
  .sm-m-b-20 {
    margin-bottom: 20px !important;
  }
  .sm-m-t-25 {
    margin-top: 25px !important;
  }
  .sm-m-r-25 {
    margin-right: 25px !important;
  }
  .sm-m-l-25 {
    margin-left: 25px !important;
  }
  .sm-m-b-25 {
    margin-bottom: 25px !important;
  }
  .sm-m-t-30 {
    margin-top: 30px !important;
  }
  .sm-m-r-30 {
    margin-right: 30px !important;
  }
  .sm-m-l-30 {
    margin-left: 30px !important;
  }
  .sm-m-b-30 {
    margin-bottom: 30px !important;
  }
  .sm-m-t-35 {
    margin-top: 35px !important;
  }
  .sm-m-r-35 {
    margin-right: 35px !important;
  }
  .sm-m-l-35 {
    margin-left: 35px !important;
  }
  .sm-m-b-35 {
    margin-bottom: 35px !important;
  }
  .sm-m-t-40 {
    margin-top: 40px !important;
  }
  .sm-m-r-40 {
    margin-right: 40px !important;
  }
  .sm-m-l-40 {
    margin-left: 40px !important;
  }
  .sm-m-b-40 {
    margin-bottom: 40px !important;
  }
  .sm-m-t-45 {
    margin-top: 45px !important;
  }
  .sm-m-r-45 {
    margin-right: 45px !important;
  }
  .sm-m-l-45 {
    margin-left: 45px !important;
  }
  .sm-m-b-45 {
    margin-bottom: 45px !important;
  }
  .sm-m-t-50 {
    margin-top: 50px !important;
  }
  .sm-m-r-50 {
    margin-right: 50px !important;
  }
  .sm-m-l-50 {
    margin-left: 50px !important;
  }
  .sm-m-b-50 {
    margin-bottom: 50px !important;
  }
  .sm-no-margin {
    margin: 0px;
  }
  .sm-no-padding {
    padding: 0px;
  }
  .sm-text-right {
    text-align: right !important;
  }
  .sm-text-left {
    text-align: left !important;
  }
  .sm-text-center {
    text-align: center !important;
  }
  .sm-pull-right {
    float: right !important;
  }
  .sm-pull-left {
    float: left !important;
  }
  .sm-pull-reset {
    float: none !important;
  }
  .sm-block {
    display: block;
  }
  .error-container {
    width: auto;
  }
  .sm-image-responsive-height {
    width: 100%;
    height: auto;
  }
}
/*** Phones ***/
@media (max-width: 480px) {
  body {
    width: 100%;
  }
  body .header {
    width: 100%;
    height: 48px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  }
  body .header .header-inner {
    height: 48px;
    text-align: center;
  }
  body .header .header-inner .toggle-secondary-sidebar {
    font-size: 16px;
    top: 12px;
  }
  body .header .header-inner .mark-email {
    left: 35px;
    top: 14px;
  }
  body .header .header-inner .quickview-link {
    top: 14px;
  }
  body .header .notification-list,
  body .header .search-link {
    display: none;
  }
  body .header .dropdown-submenu {
    top: 12px;
  }
  body .header .notification-list,
  body .header .search-link {
    display: none;
  }
  body #overlay-search {
    font-size: 48px;
    height: 118px;
    line-height: 46px;
  }
  .page-sidebar .sidebar-header {
    height: 48px;
    line-height: 48px;
  }
  .panel .panel-heading {
    padding-left: 15px;
  }
  .panel .panel-body {
    padding: 15px;
    padding-top: 0;
  }
  .error-page {
    padding: 15px;
  }
  .error-page .error-container {
    margin-top: 30px;
    width: auto;
  }
  .error-page .pull-bottom {
    position: relative;
  }
  .map-controls {
    left: 10px;
  }
  .register-container {
    height: auto !important;
  }
  .error-container-innner {
    width: auto;
  }
}
.header .brand {
  text-align: center;
}
.sidebar-menu .menu-items > li.always-open > ul.sub-menu {
  display: block !important;
}
.b-dark-grey {
  border-color: #999;
}
/*!
 * Font Awesome Pro 6.0.0-alpha1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
:root {
  --fa-style-family: 'Font Awesome 6 Pro';
  --fa-style: 900;
}
.fa {
  font-family: 'Font Awesome 6 Pro';
  font-family: var(--fa-style-family);
  font-weight: 900;
  font-weight: var(--fa-style);
}
.fa,
.fas,
.fa-solid,
.far,
.fa-regular,
.fal,
.fa-light,
.fat,
.fa-thin,
.fad,
.fa-duotone,
.fab,
.fa-brands {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}
/* makes the font 33% larger relative to the icon container */
.fa-lg {
  font-size: 1.33333333em;
  line-height: 0.75em;
  vertical-align: -0.0667em;
}
.fa-xs {
  font-size: 0.75em;
}
.fa-sm {
  font-size: 0.875em;
}
.fa-1x {
  font-size: 1em;
}
.fa-2x {
  font-size: 2em;
}
.fa-3x {
  font-size: 3em;
}
.fa-4x {
  font-size: 4em;
}
.fa-5x {
  font-size: 5em;
}
.fa-6x {
  font-size: 6em;
}
.fa-7x {
  font-size: 7em;
}
.fa-8x {
  font-size: 8em;
}
.fa-9x {
  font-size: 9em;
}
.fa-10x {
  font-size: 10em;
}
.fa-fw {
  text-align: center;
  width: 1.25em;
}
.fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0;
}
.fa-ul > li {
  position: relative;
}
.fa-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit;
}
.fa-border {
  border-radius: 0.1em;
  border: solid 0.08em #eee;
  padding: 0.2em 0.25em 0.15em;
}
.fa-pull-left {
  float: left;
}
.fa-pull-right {
  float: right;
}
.fa.fa-pull-left,
.fas.fa-pull-left,
.far.fa-pull-left,
.fal.fa-pull-left,
.fab.fa-pull-left {
  margin-right: 0.3em;
}
.fa.fa-pull-right,
.fas.fa-pull-right,
.far.fa-pull-right,
.fal.fa-pull-right,
.fab.fa-pull-right {
  margin-left: 0.3em;
}
.fa-spin {
  animation: fa-spin 2s infinite linear;
}
.fa-pulse {
  animation: fa-spin 1s infinite steps(8);
}
@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  transform: rotate(90deg);
}
.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  transform: rotate(180deg);
}
.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  transform: rotate(270deg);
}
.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  transform: scale(-1, 1);
}
.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(1, -1);
}
.fa-flip-both,
.fa-flip-horizontal.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(-1, -1);
}
:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical,
:root .fa-flip-both {
  filter: none;
}
.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2em;
}
.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}
.fa-stack-1x {
  line-height: inherit;
}
.fa-stack-2x {
  font-size: 2em;
}
.fa-inverse {
  color: #fff;
}
/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
   readers do not read off random characters that represent icons */
.fa-0:before {
  content: "\e089";
}
.fa-1:before {
  content: "\e08a";
}
.fa-2:before {
  content: "\e08b";
}
.fa-3:before {
  content: "\e08c";
}
.fa-4:before {
  content: "\e08d";
}
.fa-5:before {
  content: "\e08e";
}
.fa-6:before {
  content: "\e08f";
}
.fa-7:before {
  content: "\e090";
}
.fa-8:before {
  content: "\e091";
}
.fa-9:before {
  content: "\e092";
}
.fa-500px:before {
  content: "\f26e";
}
.fa-a:before {
  content: "\e093";
}
.fa-abacus:before {
  content: "\f640";
}
.fa-accessible-icon:before {
  content: "\f368";
}
.fa-accusoft:before {
  content: "\f369";
}
.fa-acorn:before {
  content: "\f6ae";
}
.fa-acquisitions-incorporated:before {
  content: "\f6af";
}
.fa-address-book:before {
  content: "\f2b9";
}
.fa-contact-book:before {
  content: "\f2b9";
}
.fa-address-card:before {
  content: "\f2bb";
}
.fa-contact-card:before {
  content: "\f2bb";
}
.fa-vcard:before {
  content: "\f2bb";
}
.fa-adn:before {
  content: "\f170";
}
.fa-adversal:before {
  content: "\f36a";
}
.fa-affiliatetheme:before {
  content: "\f36b";
}
.fa-air-conditioner:before {
  content: "\f8f4";
}
.fa-air-freshener:before {
  content: "\f5d0";
}
.fa-airbnb:before {
  content: "\f834";
}
.fa-alarm-clock:before {
  content: "\f34e";
}
.fa-alarm-exclamation:before {
  content: "\f843";
}
.fa-alarm-plus:before {
  content: "\f844";
}
.fa-alarm-snooze:before {
  content: "\f845";
}
.fa-album:before {
  content: "\f89f";
}
.fa-album-collection:before {
  content: "\f8a0";
}
.fa-algolia:before {
  content: "\f36c";
}
.fa-alicorn:before {
  content: "\f6b0";
}
.fa-alien:before {
  content: "\f8f5";
}
.fa-alien-8bit:before {
  content: "\f8f6";
}
.fa-alien-monster:before {
  content: "\f8f6";
}
.fa-align-center:before {
  content: "\f037";
}
.fa-align-justify:before {
  content: "\f039";
}
.fa-align-left:before {
  content: "\f036";
}
.fa-align-right:before {
  content: "\f038";
}
.fa-align-slash:before {
  content: "\f846";
}
.fa-alipay:before {
  content: "\f642";
}
.fa-alt:before {
  content: "\e094";
}
.fa-amazon:before {
  content: "\f270";
}
.fa-amazon-pay:before {
  content: "\f42c";
}
.fa-amilia:before {
  content: "\f36d";
}
.fa-amp-guitar:before {
  content: "\f8a1";
}
.fa-ampersand:before {
  content: "\e095";
}
.fa-anchor:before {
  content: "\f13d";
}
.fa-android:before {
  content: "\f17b";
}
.fa-angel:before {
  content: "\f779";
}
.fa-angellist:before {
  content: "\f209";
}
.fa-angle-down:before {
  content: "\f107";
}
.fa-angle-left:before {
  content: "\f104";
}
.fa-angle-right:before {
  content: "\f105";
}
.fa-angle-up:before {
  content: "\f106";
}
.fa-angles-down:before {
  content: "\f103";
}
.fa-angles-down:before {
  content: "\f103";
}
.fa-angles-left:before {
  content: "\f100";
}
.fa-angles-left:before {
  content: "\f100";
}
.fa-angles-right:before {
  content: "\f101";
}
.fa-angles-right:before {
  content: "\f101";
}
.fa-angles-up:before {
  content: "\f102";
}
.fa-angles-up:before {
  content: "\f102";
}
.fa-angrycreative:before {
  content: "\f36e";
}
.fa-angular:before {
  content: "\f420";
}
.fa-ankh:before {
  content: "\f644";
}
.fa-app-store:before {
  content: "\f36f";
}
.fa-app-store-ios:before {
  content: "\f370";
}
.fa-apper:before {
  content: "\f371";
}
.fa-apple:before {
  content: "\f179";
}
.fa-apple-crate:before {
  content: "\f6b1";
}
.fa-apple-pay:before {
  content: "\f415";
}
.fa-apple-whole:before {
  content: "\f5d1";
}
.fa-apple-alt:before {
  content: "\f5d1";
}
.fa-archway:before {
  content: "\f557";
}
.fa-arrow-down:before {
  content: "\f063";
}
.fa-arrow-down-1-9:before {
  content: "\f162";
}
.fa-sort-numeric-down:before {
  content: "\f162";
}
.fa-sort-numeric-asc:before {
  content: "\f162";
}
.fa-arrow-down-9-1:before {
  content: "\f886";
}
.fa-sort-numeric-down-alt:before {
  content: "\f886";
}
.fa-sort-numeric-desc:before {
  content: "\f886";
}
.fa-arrow-down-a-z:before {
  content: "\f15d";
}
.fa-sort-alpha-down:before {
  content: "\f15d";
}
.fa-sort-alpha-asc:before {
  content: "\f15d";
}
.fa-arrow-down-arrow-up:before {
  content: "\f883";
}
.fa-sort-alt:before {
  content: "\f883";
}
.fa-arrow-down-big-small:before {
  content: "\f88c";
}
.fa-sort-size-down:before {
  content: "\f88c";
}
.fa-arrow-down-from-line:before {
  content: "\f345";
}
.fa-arrow-from-top:before {
  content: "\f345";
}
.fa-arrow-down-left-and-arrow-up-right-to-center:before {
  content: "\e096";
}
.fa-arrow-down-long:before {
  content: "\f175";
}
.fa-long-arrow-down:before {
  content: "\f175";
}
.fa-arrow-down-short-wide:before {
  content: "\f884";
}
.fa-sort-amount-down-alt:before {
  content: "\f884";
}
.fa-sort-amount-desc:before {
  content: "\f884";
}
.fa-arrow-down-small-big:before {
  content: "\f88d";
}
.fa-sort-size-down-alt:before {
  content: "\f88d";
}
.fa-arrow-down-square-triangle:before {
  content: "\f889";
}
.fa-sort-shapes-down-alt:before {
  content: "\f889";
}
.fa-arrow-down-to-line:before {
  content: "\f33d";
}
.fa-arrow-to-bottom:before {
  content: "\f33d";
}
.fa-arrow-down-to-square:before {
  content: "\e097";
}
.fa-arrow-down-to-square:before {
  content: "\e097";
}
.fa-arrow-down-triangle-square:before {
  content: "\f888";
}
.fa-sort-shapes-down:before {
  content: "\f888";
}
.fa-arrow-down-wide-short:before {
  content: "\f160";
}
.fa-sort-amount-down:before {
  content: "\f160";
}
.fa-sort-amount-asc:before {
  content: "\f160";
}
.fa-arrow-down-z-a:before {
  content: "\f881";
}
.fa-sort-alpha-down-alt:before {
  content: "\f881";
}
.fa-sort-alpha-desc:before {
  content: "\f881";
}
.fa-arrow-left:before {
  content: "\f060";
}
.fa-arrow-left-from-line:before {
  content: "\f344";
}
.fa-arrow-from-right:before {
  content: "\f344";
}
.fa-arrow-left-long:before {
  content: "\f177";
}
.fa-long-arrow-left:before {
  content: "\f177";
}
.fa-arrow-left-rotate:before {
  content: "\f0e2";
}
.fa-undo:before {
  content: "\f0e2";
}
.fa-arrow-rotate-left:before {
  content: "\f0e2";
}
.fa-arrow-left-to-line:before {
  content: "\f33e";
}
.fa-arrow-to-left:before {
  content: "\f33e";
}
.fa-arrow-pointer:before {
  content: "\f245";
}
.fa-mouse-pointer:before {
  content: "\f245";
}
.fa-arrow-right:before {
  content: "\f061";
}
.fa-arrow-right-arrow-left:before {
  content: "\f0ec";
}
.fa-exchange:before {
  content: "\f0ec";
}
.fa-arrow-right-from-bracket:before {
  content: "\f08b";
}
.fa-sign-out:before {
  content: "\f08b";
}
.fa-arrow-right-from-line:before {
  content: "\f343";
}
.fa-arrow-from-left:before {
  content: "\f343";
}
.fa-arrow-right-long:before {
  content: "\f178";
}
.fa-long-arrow-right:before {
  content: "\f178";
}
.fa-arrow-right-to-bracket:before {
  content: "\f090";
}
.fa-sign-in:before {
  content: "\f090";
}
.fa-arrow-right-to-line:before {
  content: "\f340";
}
.fa-arrow-to-right:before {
  content: "\f340";
}
.fa-arrow-rotate-forward:before {
  content: "\f01e";
}
.fa-redo:before {
  content: "\f01e";
}
.fa-repeat:before {
  content: "\f363";
}
.fa-rotate-right:before {
  content: "\f01e";
}
.fa-arrow-turn-down:before {
  content: "\f149";
}
.fa-level-down:before {
  content: "\f149";
}
.fa-arrow-turn-up:before {
  content: "\f148";
}
.fa-level-up:before {
  content: "\f148";
}
.fa-arrow-up:before {
  content: "\f062";
}
.fa-arrow-up-1-9:before {
  content: "\f163";
}
.fa-sort-numeric-up:before {
  content: "\f163";
}
.fa-arrow-up-9-1:before {
  content: "\f887";
}
.fa-sort-numeric-up-alt:before {
  content: "\f887";
}
.fa-arrow-up-a-z:before {
  content: "\f15e";
}
.fa-sort-alpha-up:before {
  content: "\f15e";
}
.fa-arrow-up-arrow-down:before {
  content: "\e098";
}
.fa-sort-up-down:before {
  content: "\e098";
}
.fa-arrow-up-big-small:before {
  content: "\f88e";
}
.fa-sort-size-up:before {
  content: "\f88e";
}
.fa-arrow-up-from-line:before {
  content: "\f342";
}
.fa-arrow-from-bottom:before {
  content: "\f342";
}
.fa-arrow-up-from-square:before {
  content: "\e099";
}
.fa-arrow-up-long:before {
  content: "\f176";
}
.fa-long-arrow-up:before {
  content: "\f176";
}
.fa-arrow-up-right-and-arrow-down-left-from-center:before {
  content: "\e09a";
}
.fa-arrow-up-right-from-square:before {
  content: "\f08e";
}
.fa-external-link:before {
  content: "\f08e";
}
.fa-arrow-up-short-wide:before {
  content: "\f885";
}
.fa-sort-amount-up-alt:before {
  content: "\f885";
}
.fa-arrow-up-small-big:before {
  content: "\f88f";
}
.fa-sort-size-up-alt:before {
  content: "\f88f";
}
.fa-arrow-up-square-triangle:before {
  content: "\f88b";
}
.fa-sort-shapes-up-alt:before {
  content: "\f88b";
}
.fa-arrow-up-to-line:before {
  content: "\f341";
}
.fa-arrow-to-top:before {
  content: "\f341";
}
.fa-arrow-up-triangle-square:before {
  content: "\f88a";
}
.fa-sort-shapes-up:before {
  content: "\f88a";
}
.fa-arrow-up-wide-short:before {
  content: "\f161";
}
.fa-sort-amount-up:before {
  content: "\f161";
}
.fa-arrow-up-z-a:before {
  content: "\f882";
}
.fa-sort-alpha-up-alt:before {
  content: "\f882";
}
.fa-arrows-left-right:before {
  content: "\f07e";
}
.fa-arrows-h:before {
  content: "\f07e";
}
.fa-arrows-maximize:before {
  content: "\f31d";
}
.fa-expand-arrows:before {
  content: "\f31d";
}
.fa-arrows-minimize:before {
  content: "\e09b";
}
.fa-compress-arrows:before {
  content: "\e09b";
}
.fa-arrows-repeat:before {
  content: "\f364";
}
.fa-repeat-alt:before {
  content: "\f364";
}
.fa-arrows-repeat-1:before {
  content: "\f366";
}
.fa-repeat-1-alt:before {
  content: "\f366";
}
.fa-arrows-retweet:before {
  content: "\f361";
}
.fa-retweet-alt:before {
  content: "\f361";
}
.fa-arrows-rotate:before {
  content: "\f021";
}
.fa-sync:before {
  content: "\f021";
}
.fa-refresh:before {
  content: "\f021";
}
.fa-arrows-up-down:before {
  content: "\f07d";
}
.fa-arrows-v:before {
  content: "\f07d";
}
.fa-arrows-up-down-left-right:before {
  content: "\f047";
}
.fa-arrows:before {
  content: "\f047";
}
.fa-artstation:before {
  content: "\f77a";
}
.fa-asterisk:before {
  content: "\f069";
}
.fa-asymmetrik:before {
  content: "\f372";
}
.fa-at:before {
  content: "\f1fa";
}
.fa-atlassian:before {
  content: "\f77b";
}
.fa-atom:before {
  content: "\f5d2";
}
.fa-atom-simple:before {
  content: "\f5d3";
}
.fa-atom-alt:before {
  content: "\f5d3";
}
.fa-audible:before {
  content: "\f373";
}
.fa-austral-sign:before {
  content: "\e09c";
}
.fa-autoprefixer:before {
  content: "\f41c";
}
.fa-avianex:before {
  content: "\f374";
}
.fa-aviato:before {
  content: "\f421";
}
.fa-award:before {
  content: "\f559";
}
.fa-aws:before {
  content: "\f375";
}
.fa-axe:before {
  content: "\f6b2";
}
.fa-axe-battle:before {
  content: "\f6b3";
}
.fa-b:before {
  content: "\e09d";
}
.fa-baby:before {
  content: "\f77c";
}
.fa-baby-carriage:before {
  content: "\f77d";
}
.fa-carriage-baby:before {
  content: "\f77d";
}
.fa-backpack:before {
  content: "\f5d4";
}
.fa-backward:before {
  content: "\f04a";
}
.fa-backward-fast:before {
  content: "\f049";
}
.fa-fast-backward:before {
  content: "\f049";
}
.fa-backward-step:before {
  content: "\f048";
}
.fa-step-backward:before {
  content: "\f048";
}
.fa-bacon:before {
  content: "\f7e5";
}
.fa-bacteria:before {
  content: "\e059";
}
.fa-bacterium:before {
  content: "\e05a";
}
.fa-badge:before {
  content: "\f335";
}
.fa-badge-check:before {
  content: "\f336";
}
.fa-badge-dollar:before {
  content: "\f645";
}
.fa-badge-percent:before {
  content: "\f646";
}
.fa-badge-sheriff:before {
  content: "\f8a2";
}
.fa-badger-honey:before {
  content: "\f6b4";
}
.fa-bag-shopping:before {
  content: "\f290";
}
.fa-shopping-bag:before {
  content: "\f290";
}
.fa-bags-shopping:before {
  content: "\f847";
}
.fa-bahai:before {
  content: "\f666";
}
.fa-baht-sign:before {
  content: "\e09e";
}
.fa-ball-pile:before {
  content: "\f77e";
}
.fa-ballot:before {
  content: "\f732";
}
.fa-ballot-check:before {
  content: "\f733";
}
.fa-ban:before {
  content: "\f05e";
}
.fa-cancel:before {
  content: "\f05e";
}
.fa-ban-bug:before {
  content: "\f7f9";
}
.fa-debug:before {
  content: "\f7f9";
}
.fa-ban-parking:before {
  content: "\f616";
}
.fa-parking-circle-slash:before {
  content: "\f616";
}
.fa-ban-smoking:before {
  content: "\f54d";
}
.fa-smoking-ban:before {
  content: "\f54d";
}
.fa-bandage:before {
  content: "\f462";
}
.fa-band-aid:before {
  content: "\f462";
}
.fa-bandcamp:before {
  content: "\f2d5";
}
.fa-banjo:before {
  content: "\f8a3";
}
.fa-bank:before {
  content: "\f19c";
}
.fa-university:before {
  content: "\f19c";
}
.fa-institution:before {
  content: "\f19c";
}
.fa-barcode:before {
  content: "\f02a";
}
.fa-barcode-read:before {
  content: "\f464";
}
.fa-barcode-scan:before {
  content: "\f465";
}
.fa-bars:before {
  content: "\f0c9";
}
.fa-navicon:before {
  content: "\f0c9";
}
.fa-bars-progress:before {
  content: "\f828";
}
.fa-tasks-alt:before {
  content: "\f828";
}
.fa-bars-staggered:before {
  content: "\f550";
}
.fa-stream:before {
  content: "\f550";
}
.fa-reorder:before {
  content: "\f550";
}
.fa-baseball-ball:before {
  content: "\f433";
}
.fa-baseball-bat-ball:before {
  content: "\f432";
}
.fa-baseball:before {
  content: "\f432";
}
.fa-basket-shopping:before {
  content: "\f291";
}
.fa-shopping-basket:before {
  content: "\f291";
}
.fa-basket-shopping-simple:before {
  content: "\e09f";
}
.fa-shopping-basket-alt:before {
  content: "\e09f";
}
.fa-basketball-ball:before {
  content: "\f434";
}
.fa-basketball-hoop:before {
  content: "\f435";
}
.fa-bat:before {
  content: "\f6b5";
}
.fa-bath:before {
  content: "\f2cd";
}
.fa-bathtub:before {
  content: "\f2cd";
}
.fa-battery-bolt:before {
  content: "\f376";
}
.fa-battery-empty:before {
  content: "\f244";
}
.fa-battery-0:before {
  content: "\f244";
}
.fa-battery-exclamation:before {
  content: "\e0a0";
}
.fa-battery-full:before {
  content: "\f240";
}
.fa-battery:before {
  content: "\f240";
}
.fa-battery-5:before {
  content: "\f240";
}
.fa-battery-half:before {
  content: "\f242";
}
.fa-battery-3:before {
  content: "\f242";
}
.fa-battery-low:before {
  content: "\e0a1";
}
.fa-battery-1:before {
  content: "\e0a1";
}
.fa-battery-quarter:before {
  content: "\f243";
}
.fa-battery-2:before {
  content: "\f243";
}
.fa-battery-slash:before {
  content: "\f377";
}
.fa-battery-three-quarters:before {
  content: "\f241";
}
.fa-battery-4:before {
  content: "\f241";
}
.fa-battle-net:before {
  content: "\f835";
}
.fa-bed:before {
  content: "\f236";
}
.fa-bed-bunk:before {
  content: "\f8f8";
}
.fa-bed-empty:before {
  content: "\f8f9";
}
.fa-bed-front:before {
  content: "\f8f7";
}
.fa-bed-alt:before {
  content: "\f8f7";
}
.fa-bed-pulse:before {
  content: "\f487";
}
.fa-procedures:before {
  content: "\f487";
}
.fa-bee:before {
  content: "\e0a2";
}
.fa-beer-mug:before {
  content: "\e0a3";
}
.fa-beer-foam:before {
  content: "\e0a3";
}
.fa-beer-mug-empty:before {
  content: "\f0fc";
}
.fa-beer:before {
  content: "\f0fc";
}
.fa-behance:before {
  content: "\f1b4";
}
.fa-behance-square:before {
  content: "\f1b5";
}
.fa-bell:before {
  content: "\f0f3";
}
.fa-bell-concierge:before {
  content: "\f562";
}
.fa-concierge-bell:before {
  content: "\f562";
}
.fa-bell-exclamation:before {
  content: "\f848";
}
.fa-bell-on:before {
  content: "\f8fa";
}
.fa-bell-plus:before {
  content: "\f849";
}
.fa-bell-school:before {
  content: "\f5d5";
}
.fa-bell-school-slash:before {
  content: "\f5d6";
}
.fa-bell-slash:before {
  content: "\f1f6";
}
.fa-bells:before {
  content: "\f77f";
}
.fa-bezier-curve:before {
  content: "\f55b";
}
.fa-bicycle:before {
  content: "\f206";
}
.fa-bimobject:before {
  content: "\f378";
}
.fa-binoculars:before {
  content: "\f1e5";
}
.fa-biohazard:before {
  content: "\f780";
}
.fa-bitbucket:before {
  content: "\f171";
}
.fa-bitcoin:before {
  content: "\f379";
}
.fa-bitcoin-sign:before {
  content: "\e0a4";
}
.fa-bity:before {
  content: "\f37a";
}
.fa-black-tie:before {
  content: "\f27e";
}
.fa-blackberry:before {
  content: "\f37b";
}
.fa-blanket:before {
  content: "\f498";
}
.fa-blender:before {
  content: "\f517";
}
.fa-blender-phone:before {
  content: "\f6b6";
}
.fa-blinds:before {
  content: "\f8fb";
}
.fa-blinds-open:before {
  content: "\f8fc";
}
.fa-blinds-raised:before {
  content: "\f8fd";
}
.fa-blog:before {
  content: "\f781";
}
.fa-blogger:before {
  content: "\f37c";
}
.fa-blogger-b:before {
  content: "\f37d";
}
.fa-bluetooth:before {
  content: "\f293";
}
.fa-bluetooth-b:before {
  content: "\f294";
}
.fa-bold:before {
  content: "\f032";
}
.fa-bolt:before {
  content: "\f0e7";
}
.fa-flash:before {
  content: "\f0e7";
}
.fa-bomb:before {
  content: "\f1e2";
}
.fa-bone:before {
  content: "\f5d7";
}
.fa-bone-break:before {
  content: "\f5d8";
}
.fa-bong:before {
  content: "\f55c";
}
.fa-book:before {
  content: "\f02d";
}
.fa-book-atlas:before {
  content: "\f558";
}
.fa-atlas:before {
  content: "\f558";
}
.fa-book-bible:before {
  content: "\f647";
}
.fa-bible:before {
  content: "\f647";
}
.fa-book-blank:before {
  content: "\f5d9";
}
.fa-book-alt:before {
  content: "\f5d9";
}
.fa-book-font:before {
  content: "\e0a5";
}
.fa-book-heart:before {
  content: "\f499";
}
.fa-book-journal-whills:before {
  content: "\f66a";
}
.fa-journal-whills:before {
  content: "\f66a";
}
.fa-book-medical:before {
  content: "\f7e6";
}
.fa-book-open:before {
  content: "\f518";
}
.fa-book-open-cover:before {
  content: "\e0a6";
}
.fa-book-open-alt:before {
  content: "\e0a6";
}
.fa-book-open-reader:before {
  content: "\f5da";
}
.fa-book-reader:before {
  content: "\f5da";
}
.fa-book-quran:before {
  content: "\f687";
}
.fa-quran:before {
  content: "\f687";
}
.fa-book-skull:before {
  content: "\f6b7";
}
.fa-book-dead:before {
  content: "\f6b7";
}
.fa-book-sparkles:before {
  content: "\f6b8";
}
.fa-book-spells:before {
  content: "\f6b8";
}
.fa-book-tanakh:before {
  content: "\f827";
}
.fa-tanakh:before {
  content: "\f827";
}
.fa-book-user:before {
  content: "\f7e7";
}
.fa-bookmark:before {
  content: "\f02e";
}
.fa-bookmark-slash:before {
  content: "\e0a7";
}
.fa-books:before {
  content: "\f5db";
}
.fa-books-medical:before {
  content: "\f7e8";
}
.fa-boombox:before {
  content: "\f8a5";
}
.fa-boot:before {
  content: "\f782";
}
.fa-booth-curtain:before {
  content: "\f734";
}
.fa-bootstrap:before {
  content: "\f836";
}
.fa-border-all:before {
  content: "\f84c";
}
.fa-border-bottom:before {
  content: "\f84d";
}
.fa-border-bottom-right:before {
  content: "\f854";
}
.fa-border-style-alt:before {
  content: "\f854";
}
.fa-border-center-h:before {
  content: "\f89c";
}
.fa-border-center-v:before {
  content: "\f89d";
}
.fa-border-inner:before {
  content: "\f84e";
}
.fa-border-left:before {
  content: "\f84f";
}
.fa-border-none:before {
  content: "\f850";
}
.fa-border-outer:before {
  content: "\f851";
}
.fa-border-right:before {
  content: "\f852";
}
.fa-border-top:before {
  content: "\f855";
}
.fa-border-top-left:before {
  content: "\f853";
}
.fa-border-style:before {
  content: "\f853";
}
.fa-bow-arrow:before {
  content: "\f6b9";
}
.fa-bowl-hot:before {
  content: "\f823";
}
.fa-soup:before {
  content: "\f823";
}
.fa-bowling-ball:before {
  content: "\f436";
}
.fa-bowling-ball-pin:before {
  content: "\e0a8";
}
.fa-bowling-pins:before {
  content: "\f437";
}
.fa-box:before {
  content: "\f466";
}
.fa-box-archive:before {
  content: "\f187";
}
.fa-archive:before {
  content: "\f187";
}
.fa-box-ballot:before {
  content: "\f735";
}
.fa-box-check:before {
  content: "\f467";
}
.fa-box-circle-check:before {
  content: "\e0a9";
}
.fa-box-dollar:before {
  content: "\f4a0";
}
.fa-box-usd:before {
  content: "\f4a0";
}
.fa-box-heart:before {
  content: "\f49d";
}
.fa-box-open:before {
  content: "\f49e";
}
.fa-box-open-full:before {
  content: "\f49c";
}
.fa-box-full:before {
  content: "\f49c";
}
.fa-box-taped:before {
  content: "\f49a";
}
.fa-box-alt:before {
  content: "\f49a";
}
.fa-box-tissue:before {
  content: "\e05b";
}
.fa-boxes-stacked:before {
  content: "\f468";
}
.fa-boxes:before {
  content: "\f468";
}
.fa-boxes-alt:before {
  content: "\f468";
}
.fa-boxing-glove:before {
  content: "\f438";
}
.fa-glove-boxing:before {
  content: "\f438";
}
.fa-bracket-curly:before {
  content: "\e0aa";
}
.fa-bracket-curly-left:before {
  content: "\e0aa";
}
.fa-bracket-round:before {
  content: "\e0ab";
}
.fa-parenthesis:before {
  content: "\e0ab";
}
.fa-bracket-square:before {
  content: "\e0ac";
}
.fa-bracket:before {
  content: "\e0ac";
}
.fa-bracket-left:before {
  content: "\e0ac";
}
.fa-brackets-curly:before {
  content: "\f7ea";
}
.fa-brackets-round:before {
  content: "\e0ad";
}
.fa-parentheses:before {
  content: "\e0ad";
}
.fa-brackets-square:before {
  content: "\f7e9";
}
.fa-brackets:before {
  content: "\f7e9";
}
.fa-braille:before {
  content: "\f2a1";
}
.fa-brain:before {
  content: "\f5dc";
}
.fa-brain-arrow-curved-right:before {
  content: "\f677";
}
.fa-mind-share:before {
  content: "\f677";
}
.fa-brake-warning:before {
  content: "\e0ae";
}
.fa-bread-loaf:before {
  content: "\f7eb";
}
.fa-bread-slice:before {
  content: "\f7ec";
}
.fa-briefcase:before {
  content: "\f0b1";
}
.fa-briefcase-clock:before {
  content: "\f64a";
}
.fa-business-time:before {
  content: "\f64a";
}
.fa-briefcase-medical:before {
  content: "\f469";
}
.fa-bring-forward:before {
  content: "\f856";
}
.fa-bring-front:before {
  content: "\f857";
}
.fa-broom:before {
  content: "\f51a";
}
.fa-browser:before {
  content: "\f37e";
}
.fa-browsers:before {
  content: "\e0af";
}
.fa-brush:before {
  content: "\f55d";
}
.fa-btc:before {
  content: "\f15a";
}
.fa-buffer:before {
  content: "\f837";
}
.fa-bug:before {
  content: "\f188";
}
.fa-building:before {
  content: "\f1ad";
}
.fa-bullhorn:before {
  content: "\f0a1";
}
.fa-bullseye:before {
  content: "\f140";
}
.fa-bullseye-arrow:before {
  content: "\f648";
}
.fa-bullseye-pointer:before {
  content: "\f649";
}
.fa-burger:before {
  content: "\f805";
}
.fa-hamburger:before {
  content: "\f805";
}
.fa-burger-cheese:before {
  content: "\f7f1";
}
.fa-cheeseburger:before {
  content: "\f7f1";
}
.fa-burger-fries:before {
  content: "\e0b0";
}
.fa-burger-glass:before {
  content: "\e0b1";
}
.fa-burger-soda:before {
  content: "\f858";
}
.fa-buromobelexperte:before {
  content: "\f37f";
}
.fa-burrito:before {
  content: "\f7ed";
}
.fa-bus:before {
  content: "\f207";
}
.fa-bus-school:before {
  content: "\f5dd";
}
.fa-bus-simple:before {
  content: "\f55e";
}
.fa-bus-alt:before {
  content: "\f55e";
}
.fa-buy-n-large:before {
  content: "\f8a6";
}
.fa-buysellads:before {
  content: "\f20d";
}
.fa-c:before {
  content: "\e0b2";
}
.fa-cabinet-filing:before {
  content: "\f64b";
}
.fa-cable-car:before {
  content: "\e0b3";
}
.fa-cactus:before {
  content: "\f8a7";
}
.fa-cake-candles:before {
  content: "\f1fd";
}
.fa-birthday-cake:before {
  content: "\f1fd";
}
.fa-calculator:before {
  content: "\f1ec";
}
.fa-calculator-simple:before {
  content: "\f64c";
}
.fa-calculator-alt:before {
  content: "\f64c";
}
.fa-calendar:before {
  content: "\f133";
}
.fa-calendar-arrow-down:before {
  content: "\e0b4";
}
.fa-calendar-download:before {
  content: "\e0b4";
}
.fa-calendar-arrow-up:before {
  content: "\e0b5";
}
.fa-calendar-upload:before {
  content: "\e0b5";
}
.fa-calendar-check:before {
  content: "\f274";
}
.fa-calendar-clock:before {
  content: "\e0b6";
}
.fa-calendar-time:before {
  content: "\e0b6";
}
.fa-calendar-day:before {
  content: "\f783";
}
.fa-calendar-days:before {
  content: "\f073";
}
.fa-calendar-alt:before {
  content: "\f073";
}
.fa-calendar-exclamation:before {
  content: "\f334";
}
.fa-calendar-heart:before {
  content: "\e0b7";
}
.fa-calendar-image:before {
  content: "\e0b8";
}
.fa-calendar-lines:before {
  content: "\e0b9";
}
.fa-calendar-note:before {
  content: "\e0b9";
}
.fa-calendar-minus:before {
  content: "\f272";
}
.fa-calendar-pen:before {
  content: "\f333";
}
.fa-calendar-edit:before {
  content: "\f333";
}
.fa-calendar-plus:before {
  content: "\f271";
}
.fa-calendar-range:before {
  content: "\e0ba";
}
.fa-calendar-star:before {
  content: "\f736";
}
.fa-calendar-week:before {
  content: "\f784";
}
.fa-calendar-xmark:before {
  content: "\f273";
}
.fa-calendar-times:before {
  content: "\f273";
}
.fa-calendars:before {
  content: "\e0bb";
}
.fa-camcorder:before {
  content: "\f8a8";
}
.fa-video-handheld:before {
  content: "\f8a8";
}
.fa-camera:before {
  content: "\f030";
}
.fa-camera-alt:before {
  content: "\f030";
}
.fa-camera-cctv:before {
  content: "\f8ac";
}
.fa-cctv:before {
  content: "\f8ac";
}
.fa-camera-movie:before {
  content: "\f8a9";
}
.fa-camera-polaroid:before {
  content: "\f8aa";
}
.fa-camera-retro:before {
  content: "\f083";
}
.fa-camera-security:before {
  content: "\f8fe";
}
.fa-camera-home:before {
  content: "\f8fe";
}
.fa-camera-viewfinder:before {
  content: "\e0bc";
}
.fa-camera-web:before {
  content: "\f832";
}
.fa-webcam:before {
  content: "\f832";
}
.fa-camera-web-slash:before {
  content: "\f833";
}
.fa-webcam-slash:before {
  content: "\f833";
}
.fa-campfire:before {
  content: "\f6ba";
}
.fa-campground:before {
  content: "\f6bb";
}
.fa-canadian-maple-leaf:before {
  content: "\f785";
}
.fa-candle-holder:before {
  content: "\f6bc";
}
.fa-candy-cane:before {
  content: "\f786";
}
.fa-candy-corn:before {
  content: "\f6bd";
}
.fa-cannabis:before {
  content: "\f55f";
}
.fa-capsules:before {
  content: "\f46b";
}
.fa-car:before {
  content: "\f1b9";
}
.fa-automobile:before {
  content: "\f1b9";
}
.fa-car-battery:before {
  content: "\f5df";
}
.fa-battery-car:before {
  content: "\f5df";
}
.fa-car-building:before {
  content: "\f859";
}
.fa-car-bump:before {
  content: "\f5e0";
}
.fa-car-bus:before {
  content: "\f85a";
}
.fa-car-crash:before {
  content: "\f5e1";
}
.fa-car-garage:before {
  content: "\f5e2";
}
.fa-car-rear:before {
  content: "\f5de";
}
.fa-car-alt:before {
  content: "\f5de";
}
.fa-car-side:before {
  content: "\f5e4";
}
.fa-car-tilt:before {
  content: "\f5e5";
}
.fa-car-wash:before {
  content: "\f5e6";
}
.fa-car-wrench:before {
  content: "\f5e3";
}
.fa-car-mechanic:before {
  content: "\f5e3";
}
.fa-caravan:before {
  content: "\f8ff";
}
.fa-caravan-simple:before {
  content: "\e000";
}
.fa-caravan-alt:before {
  content: "\e000";
}
.fa-caret-down:before {
  content: "\f0d7";
}
.fa-caret-left:before {
  content: "\f0d9";
}
.fa-caret-right:before {
  content: "\f0da";
}
.fa-caret-up:before {
  content: "\f0d8";
}
.fa-carrot:before {
  content: "\f787";
}
.fa-cars:before {
  content: "\f85b";
}
.fa-cart-arrow-down:before {
  content: "\f218";
}
.fa-cart-flatbed:before {
  content: "\f474";
}
.fa-dolly-flatbed:before {
  content: "\f474";
}
.fa-cart-flatbed-boxes:before {
  content: "\f475";
}
.fa-dolly-flatbed-alt:before {
  content: "\f475";
}
.fa-cart-flatbed-empty:before {
  content: "\f476";
}
.fa-dolly-flatbed-empty:before {
  content: "\f476";
}
.fa-cart-flatbed-suitcase:before {
  content: "\f59d";
}
.fa-luggage-cart:before {
  content: "\f59d";
}
.fa-cart-plus:before {
  content: "\f217";
}
.fa-cart-shopping:before {
  content: "\f07a";
}
.fa-shopping-cart:before {
  content: "\f07a";
}
.fa-casette-betamax:before {
  content: "\f8a4";
}
.fa-betamax:before {
  content: "\f8a4";
}
.fa-casette-vhs:before {
  content: "\f8ec";
}
.fa-vhs:before {
  content: "\f8ec";
}
.fa-cash-register:before {
  content: "\f788";
}
.fa-cassette-tape:before {
  content: "\f8ab";
}
.fa-cat:before {
  content: "\f6be";
}
.fa-cat-space:before {
  content: "\e001";
}
.fa-cauldron:before {
  content: "\f6bf";
}
.fa-cc-amazon-pay:before {
  content: "\f42d";
}
.fa-cc-amex:before {
  content: "\f1f3";
}
.fa-cc-apple-pay:before {
  content: "\f416";
}
.fa-cc-diners-club:before {
  content: "\f24c";
}
.fa-cc-discover:before {
  content: "\f1f2";
}
.fa-cc-jcb:before {
  content: "\f24b";
}
.fa-cc-mastercard:before {
  content: "\f1f1";
}
.fa-cc-paypal:before {
  content: "\f1f4";
}
.fa-cc-stripe:before {
  content: "\f1f5";
}
.fa-cc-visa:before {
  content: "\f1f0";
}
.fa-cedi-sign:before {
  content: "\e0bd";
}
.fa-cent-sign:before {
  content: "\e0be";
}
.fa-centercode:before {
  content: "\f380";
}
.fa-centos:before {
  content: "\f789";
}
.fa-certificate:before {
  content: "\f0a3";
}
.fa-chair:before {
  content: "\f6c0";
}
.fa-chair-office:before {
  content: "\f6c1";
}
.fa-chalkboard:before {
  content: "\f51b";
}
.fa-blackboard:before {
  content: "\f51b";
}
.fa-chalkboard-user:before {
  content: "\f51c";
}
.fa-chalkboard-teacher:before {
  content: "\f51c";
}
.fa-champagne-glass:before {
  content: "\f79e";
}
.fa-glass-champagne:before {
  content: "\f79e";
}
.fa-champagne-glasses:before {
  content: "\f79f";
}
.fa-glass-cheers:before {
  content: "\f79f";
}
.fa-charging-station:before {
  content: "\f5e7";
}
.fa-chart-area:before {
  content: "\f1fe";
}
.fa-area-chart:before {
  content: "\f1fe";
}
.fa-chart-bar:before {
  content: "\f080";
}
.fa-bar-chart:before {
  content: "\f080";
}
.fa-chart-bullet:before {
  content: "\e0bf";
}
.fa-chart-candlestick:before {
  content: "\e0c0";
}
.fa-chart-column:before {
  content: "\e0c1";
}
.fa-chart-gantt:before {
  content: "\e0c2";
}
.fa-chart-line:before {
  content: "\f201";
}
.fa-line-chart:before {
  content: "\f201";
}
.fa-chart-line-down:before {
  content: "\f64d";
}
.fa-chart-line-up:before {
  content: "\e0c3";
}
.fa-chart-mixed:before {
  content: "\f643";
}
.fa-analytics:before {
  content: "\f643";
}
.fa-chart-network:before {
  content: "\f78a";
}
.fa-chart-pie:before {
  content: "\f200";
}
.fa-pie-chart:before {
  content: "\f200";
}
.fa-chart-pie-simple:before {
  content: "\f64e";
}
.fa-chart-pie-alt:before {
  content: "\f64e";
}
.fa-chart-pyramid:before {
  content: "\e0c4";
}
.fa-chart-radar:before {
  content: "\e0c5";
}
.fa-chart-scatter:before {
  content: "\f7ee";
}
.fa-chart-scatter-3d:before {
  content: "\e0c6";
}
.fa-chart-scatter-bubble:before {
  content: "\e0c7";
}
.fa-chart-tree-map:before {
  content: "\e0c8";
}
.fa-chart-user:before {
  content: "\f6a3";
}
.fa-user-chart:before {
  content: "\f6a3";
}
.fa-chart-waterfall:before {
  content: "\e0c9";
}
.fa-check:before {
  content: "\f00c";
}
.fa-check-double:before {
  content: "\f560";
}
.fa-cheese:before {
  content: "\f7ef";
}
.fa-cheese-swiss:before {
  content: "\f7f0";
}
.fa-chess:before {
  content: "\f439";
}
.fa-chess-bishop:before {
  content: "\f43a";
}
.fa-chess-bishop-piece:before {
  content: "\f43b";
}
.fa-chess-bishop-alt:before {
  content: "\f43b";
}
.fa-chess-board:before {
  content: "\f43c";
}
.fa-chess-clock:before {
  content: "\f43d";
}
.fa-chess-clock-flip:before {
  content: "\f43e";
}
.fa-chess-clock-alt:before {
  content: "\f43e";
}
.fa-chess-king:before {
  content: "\f43f";
}
.fa-chess-king-piece:before {
  content: "\f440";
}
.fa-chess-king-alt:before {
  content: "\f440";
}
.fa-chess-knight:before {
  content: "\f441";
}
.fa-chess-knight-piece:before {
  content: "\f442";
}
.fa-chess-knight-alt:before {
  content: "\f442";
}
.fa-chess-pawn:before {
  content: "\f443";
}
.fa-chess-pawn-piece:before {
  content: "\f444";
}
.fa-chess-pawn-alt:before {
  content: "\f444";
}
.fa-chess-queen:before {
  content: "\f445";
}
.fa-chess-queen-piece:before {
  content: "\f446";
}
.fa-chess-queen-alt:before {
  content: "\f446";
}
.fa-chess-rook:before {
  content: "\f447";
}
.fa-chess-rook-piece:before {
  content: "\f448";
}
.fa-chess-rook-alt:before {
  content: "\f448";
}
.fa-chevron-down:before {
  content: "\f078";
}
.fa-chevron-left:before {
  content: "\f053";
}
.fa-chevron-right:before {
  content: "\f054";
}
.fa-chevron-up:before {
  content: "\f077";
}
.fa-chevrons-down:before {
  content: "\f322";
}
.fa-chevron-double-down:before {
  content: "\f322";
}
.fa-chevrons-left:before {
  content: "\f323";
}
.fa-chevron-double-left:before {
  content: "\f323";
}
.fa-chevrons-right:before {
  content: "\f324";
}
.fa-chevron-double-right:before {
  content: "\f324";
}
.fa-chevrons-up:before {
  content: "\f325";
}
.fa-chevron-double-up:before {
  content: "\f325";
}
.fa-child:before {
  content: "\f1ae";
}
.fa-chimney:before {
  content: "\f78b";
}
.fa-chrome:before {
  content: "\f268";
}
.fa-chromecast:before {
  content: "\f838";
}
.fa-church:before {
  content: "\f51d";
}
.fa-circle:before {
  content: "\f111";
}
.fa-circle-0:before {
  content: "\e0ca";
}
.fa-circle-1:before {
  content: "\e0cb";
}
.fa-circle-2:before {
  content: "\e0cc";
}
.fa-circle-3:before {
  content: "\e0cd";
}
.fa-circle-4:before {
  content: "\e0ce";
}
.fa-circle-5:before {
  content: "\e0cf";
}
.fa-circle-6:before {
  content: "\e0d0";
}
.fa-circle-7:before {
  content: "\e0d1";
}
.fa-circle-8:before {
  content: "\e0d2";
}
.fa-circle-9:before {
  content: "\e0d3";
}
.fa-circle-a:before {
  content: "\e0d4";
}
.fa-circle-ampersand:before {
  content: "\e0d5";
}
.fa-circle-arrow-down:before {
  content: "\f0ab";
}
.fa-arrow-circle-down:before {
  content: "\f0ab";
}
.fa-circle-arrow-left:before {
  content: "\f0a8";
}
.fa-arrow-circle-left:before {
  content: "\f0a8";
}
.fa-circle-arrow-right:before {
  content: "\f0a9";
}
.fa-arrow-circle-right:before {
  content: "\f0a9";
}
.fa-circle-arrow-up:before {
  content: "\f0aa";
}
.fa-arrow-circle-up:before {
  content: "\f0aa";
}
.fa-circle-b:before {
  content: "\e0d6";
}
.fa-circle-book-open:before {
  content: "\e0d7";
}
.fa-book-circle:before {
  content: "\e0d7";
}
.fa-circle-bookmark:before {
  content: "\e0d8";
}
.fa-bookmark-circle:before {
  content: "\e0d8";
}
.fa-circle-c:before {
  content: "\e0d9";
}
.fa-circle-calendar:before {
  content: "\e0da";
}
.fa-calendar-circle:before {
  content: "\e0da";
}
.fa-circle-camera:before {
  content: "\e0db";
}
.fa-camera-circle:before {
  content: "\e0db";
}
.fa-circle-caret-down:before {
  content: "\f32d";
}
.fa-caret-circle-down:before {
  content: "\f32d";
}
.fa-circle-caret-left:before {
  content: "\f32e";
}
.fa-caret-circle-left:before {
  content: "\f32e";
}
.fa-circle-caret-right:before {
  content: "\f330";
}
.fa-caret-circle-right:before {
  content: "\f330";
}
.fa-circle-caret-up:before {
  content: "\f331";
}
.fa-caret-circle-up:before {
  content: "\f331";
}
.fa-circle-check:before {
  content: "\f058";
}
.fa-check-circle:before {
  content: "\f058";
}
.fa-circle-chevron-down:before {
  content: "\f13a";
}
.fa-chevron-circle-down:before {
  content: "\f13a";
}
.fa-circle-chevron-left:before {
  content: "\f137";
}
.fa-chevron-circle-left:before {
  content: "\f137";
}
.fa-circle-chevron-right:before {
  content: "\f138";
}
.fa-chevron-circle-right:before {
  content: "\f138";
}
.fa-circle-chevron-up:before {
  content: "\f139";
}
.fa-chevron-circle-up:before {
  content: "\f139";
}
.fa-circle-d:before {
  content: "\e0dc";
}
.fa-circle-dashed:before {
  content: "\e0dd";
}
.fa-circle-dollar:before {
  content: "\f2e8";
}
.fa-usd-circle:before {
  content: "\f2e8";
}
.fa-dollar-circle:before {
  content: "\f2e8";
}
.fa-circle-dollar-to-slot:before {
  content: "\f4b9";
}
.fa-donate:before {
  content: "\f4b9";
}
.fa-circle-dot:before {
  content: "\f192";
}
.fa-dot-circle:before {
  content: "\f192";
}
.fa-circle-down:before {
  content: "\f358";
}
.fa-arrow-alt-circle-down:before {
  content: "\f358";
}
.fa-circle-e:before {
  content: "\e0de";
}
.fa-circle-envelope:before {
  content: "\e0df";
}
.fa-envelope-circle:before {
  content: "\e0df";
}
.fa-circle-exclamation:before {
  content: "\f06a";
}
.fa-exclamation-circle:before {
  content: "\f06a";
}
.fa-circle-f:before {
  content: "\e0e0";
}
.fa-circle-g:before {
  content: "\e0e1";
}
.fa-circle-h:before {
  content: "\f47e";
}
.fa-hospital-symbol:before {
  content: "\f47e";
}
.fa-circle-half:before {
  content: "\e0e2";
}
.fa-circle-half-stroke:before {
  content: "\f042";
}
.fa-adjust:before {
  content: "\f042";
}
.fa-circle-heart:before {
  content: "\f4c7";
}
.fa-heart-circle:before {
  content: "\f4c7";
}
.fa-circle-i:before {
  content: "\e0e3";
}
.fa-circle-info:before {
  content: "\f05a";
}
.fa-info-circle:before {
  content: "\f05a";
}
.fa-circle-j:before {
  content: "\e0e4";
}
.fa-circle-k:before {
  content: "\e0e5";
}
.fa-circle-l:before {
  content: "\e0e6";
}
.fa-circle-left:before {
  content: "\f359";
}
.fa-arrow-alt-circle-left:before {
  content: "\f359";
}
.fa-circle-location-arrow:before {
  content: "\f602";
}
.fa-location-circle:before {
  content: "\f602";
}
.fa-circle-m:before {
  content: "\e0e7";
}
.fa-circle-microphone:before {
  content: "\e0e8";
}
.fa-microphone-circle:before {
  content: "\e0e8";
}
.fa-circle-microphone-lines:before {
  content: "\e0e9";
}
.fa-microphone-circle-alt:before {
  content: "\e0e9";
}
.fa-circle-minus:before {
  content: "\f056";
}
.fa-minus-circle:before {
  content: "\f056";
}
.fa-circle-n:before {
  content: "\e0ea";
}
.fa-circle-notch:before {
  content: "\f1ce";
}
.fa-circle-o:before {
  content: "\e0eb";
}
.fa-circle-p:before {
  content: "\e0ec";
}
.fa-circle-parking:before {
  content: "\f615";
}
.fa-parking-circle:before {
  content: "\f615";
}
.fa-circle-pause:before {
  content: "\f28b";
}
.fa-pause-circle:before {
  content: "\f28b";
}
.fa-circle-phone:before {
  content: "\e0ed";
}
.fa-phone-circle:before {
  content: "\e0ed";
}
.fa-circle-phone-flip:before {
  content: "\e0ee";
}
.fa-phone-circle-alt:before {
  content: "\e0ee";
}
.fa-circle-phone-hangup:before {
  content: "\e0ef";
}
.fa-phone-circle-down:before {
  content: "\e0ef";
}
.fa-circle-play:before {
  content: "\f144";
}
.fa-play-circle:before {
  content: "\f144";
}
.fa-circle-plus:before {
  content: "\f055";
}
.fa-plus-circle:before {
  content: "\f055";
}
.fa-circle-q:before {
  content: "\e0f0";
}
.fa-circle-quarter:before {
  content: "\e0f1";
}
.fa-circle-question:before {
  content: "\f059";
}
.fa-question-circle:before {
  content: "\f059";
}
.fa-circle-r:before {
  content: "\e0f2";
}
.fa-circle-radiation:before {
  content: "\f7ba";
}
.fa-radiation-alt:before {
  content: "\f7ba";
}
.fa-circle-right:before {
  content: "\f35a";
}
.fa-arrow-alt-circle-right:before {
  content: "\f35a";
}
.fa-circle-s:before {
  content: "\e0f3";
}
.fa-circle-sort:before {
  content: "\e030";
}
.fa-sort-circle:before {
  content: "\e030";
}
.fa-circle-sort-down:before {
  content: "\e031";
}
.fa-sort-circle-down:before {
  content: "\e031";
}
.fa-circle-sort-up:before {
  content: "\e032";
}
.fa-sort-circle-up:before {
  content: "\e032";
}
.fa-circle-star:before {
  content: "\e0f4";
}
.fa-star-circle:before {
  content: "\e0f4";
}
.fa-circle-stop:before {
  content: "\f28d";
}
.fa-stop-circle:before {
  content: "\f28d";
}
.fa-circle-t:before {
  content: "\e0f5";
}
.fa-circle-three-quarters:before {
  content: "\e0f6";
}
.fa-circle-trash:before {
  content: "\e0f7";
}
.fa-trash-circle:before {
  content: "\e0f7";
}
.fa-circle-u:before {
  content: "\e0f8";
}
.fa-circle-up:before {
  content: "\f35b";
}
.fa-arrow-alt-circle-up:before {
  content: "\f35b";
}
.fa-circle-user:before {
  content: "\f2bd";
}
.fa-user-circle:before {
  content: "\f2bd";
}
.fa-circle-v:before {
  content: "\e0f9";
}
.fa-circle-video:before {
  content: "\e0fa";
}
.fa-video-circle:before {
  content: "\e0fa";
}
.fa-circle-w:before {
  content: "\e0fb";
}
.fa-circle-waveform-lines:before {
  content: "\e0fc";
}
.fa-waveform-circle:before {
  content: "\e0fc";
}
.fa-circle-x:before {
  content: "\e0fd";
}
.fa-circle-xmark:before {
  content: "\f057";
}
.fa-times-circle:before {
  content: "\f057";
}
.fa-xmark-circle:before {
  content: "\f057";
}
.fa-circle-y:before {
  content: "\e0fe";
}
.fa-circle-z:before {
  content: "\e0ff";
}
.fa-city:before {
  content: "\f64f";
}
.fa-clarinet:before {
  content: "\f8ad";
}
.fa-claw-marks:before {
  content: "\f6c2";
}
.fa-clipboard:before {
  content: "\f328";
}
.fa-clipboard-check:before {
  content: "\f46c";
}
.fa-clipboard-list:before {
  content: "\f46d";
}
.fa-clipboard-list-check:before {
  content: "\f737";
}
.fa-clipboard-medical:before {
  content: "\e100";
}
.fa-clipboard-prescription:before {
  content: "\f5e8";
}
.fa-clipboard-user:before {
  content: "\f7f3";
}
.fa-clock:before {
  content: "\f017";
}
.fa-clock-desk:before {
  content: "\e101";
}
.fa-clock-rotate-left:before {
  content: "\f1da";
}
.fa-history:before {
  content: "\f1da";
}
.fa-clone:before {
  content: "\f24d";
}
.fa-closed-captioning:before {
  content: "\f20a";
}
.fa-cloud:before {
  content: "\f0c2";
}
.fa-cloud-arrow-down:before {
  content: "\f0ed";
}
.fa-cloud-download:before {
  content: "\f0ed";
}
.fa-cloud-download-alt:before {
  content: "\f0ed";
}
.fa-cloud-arrow-up:before {
  content: "\f0ee";
}
.fa-cloud-upload:before {
  content: "\f0ee";
}
.fa-cloud-upload-alt:before {
  content: "\f0ee";
}
.fa-cloud-bolt:before {
  content: "\f76c";
}
.fa-thunderstorm:before {
  content: "\f76c";
}
.fa-cloud-bolt-moon:before {
  content: "\f76d";
}
.fa-thunderstorm-moon:before {
  content: "\f76d";
}
.fa-cloud-bolt-sun:before {
  content: "\f76e";
}
.fa-thunderstorm-sun:before {
  content: "\f76e";
}
.fa-cloud-drizzle:before {
  content: "\f738";
}
.fa-cloud-fog:before {
  content: "\f74e";
}
.fa-fog:before {
  content: "\f74e";
}
.fa-cloud-hail:before {
  content: "\f739";
}
.fa-cloud-hail-mixed:before {
  content: "\f73a";
}
.fa-cloud-meatball:before {
  content: "\f73b";
}
.fa-cloud-moon:before {
  content: "\f6c3";
}
.fa-cloud-moon-rain:before {
  content: "\f73c";
}
.fa-cloud-music:before {
  content: "\f8ae";
}
.fa-cloud-rain:before {
  content: "\f73d";
}
.fa-cloud-rainbow:before {
  content: "\f73e";
}
.fa-cloud-showers:before {
  content: "\f73f";
}
.fa-cloud-showers-heavy:before {
  content: "\f740";
}
.fa-cloud-sleet:before {
  content: "\f741";
}
.fa-cloud-snow:before {
  content: "\f742";
}
.fa-cloud-sun:before {
  content: "\f6c4";
}
.fa-cloud-sun-rain:before {
  content: "\f743";
}
.fa-cloud-word:before {
  content: "\e102";
}
.fa-cloudflare:before {
  content: "\e07d";
}
.fa-clouds:before {
  content: "\f744";
}
.fa-clouds-moon:before {
  content: "\f745";
}
.fa-clouds-sun:before {
  content: "\f746";
}
.fa-cloudscale:before {
  content: "\f383";
}
.fa-cloudsmith:before {
  content: "\f384";
}
.fa-cloudversify:before {
  content: "\f385";
}
.fa-club:before {
  content: "\f327";
}
.fa-code:before {
  content: "\f121";
}
.fa-code-branch:before {
  content: "\f126";
}
.fa-code-commit:before {
  content: "\f386";
}
.fa-code-merge:before {
  content: "\f387";
}
.fa-codepen:before {
  content: "\f1cb";
}
.fa-codiepie:before {
  content: "\f284";
}
.fa-coffee-bean:before {
  content: "\e103";
}
.fa-coffee-beans:before {
  content: "\e104";
}
.fa-coffee-pot:before {
  content: "\e002";
}
.fa-coffin:before {
  content: "\f6c6";
}
.fa-coffin-cross:before {
  content: "\e051";
}
.fa-coin:before {
  content: "\f85c";
}
.fa-coins:before {
  content: "\f51e";
}
.fa-colon-sign:before {
  content: "\e105";
}
.fa-comet:before {
  content: "\e003";
}
.fa-command:before {
  content: "\e106";
}
.fa-comment:before {
  content: "\f075";
}
.fa-comment-arrow-down:before {
  content: "\e107";
}
.fa-comment-arrow-up:before {
  content: "\e108";
}
.fa-comment-captions:before {
  content: "\e109";
}
.fa-comment-check:before {
  content: "\f4ac";
}
.fa-comment-dollar:before {
  content: "\f651";
}
.fa-comment-dots:before {
  content: "\f4ad";
}
.fa-commenting:before {
  content: "\f4ad";
}
.fa-comment-exclamation:before {
  content: "\f4af";
}
.fa-comment-image:before {
  content: "\e10a";
}
.fa-comment-lines:before {
  content: "\f4b0";
}
.fa-comment-medical:before {
  content: "\f7f5";
}
.fa-comment-middle:before {
  content: "\e10b";
}
.fa-comment-middle-top:before {
  content: "\e10c";
}
.fa-comment-minus:before {
  content: "\f4b1";
}
.fa-comment-music:before {
  content: "\f8b0";
}
.fa-comment-pen:before {
  content: "\f4ae";
}
.fa-comment-edit:before {
  content: "\f4ae";
}
.fa-comment-plus:before {
  content: "\f4b2";
}
.fa-comment-quote:before {
  content: "\e10d";
}
.fa-comment-slash:before {
  content: "\f4b3";
}
.fa-comment-smile:before {
  content: "\f4b4";
}
.fa-comment-sms:before {
  content: "\f7cd";
}
.fa-sms:before {
  content: "\f7cd";
}
.fa-comment-text:before {
  content: "\e10e";
}
.fa-comment-xmark:before {
  content: "\f4b5";
}
.fa-comment-times:before {
  content: "\f4b5";
}
.fa-comments:before {
  content: "\f086";
}
.fa-comments-dollar:before {
  content: "\f653";
}
.fa-compact-disc:before {
  content: "\f51f";
}
.fa-compass:before {
  content: "\f14e";
}
.fa-compass-drafting:before {
  content: "\f568";
}
.fa-drafting-compass:before {
  content: "\f568";
}
.fa-compass-slash:before {
  content: "\f5e9";
}
.fa-compress:before {
  content: "\f066";
}
.fa-compress-wide:before {
  content: "\f326";
}
.fa-computer-classic:before {
  content: "\f8b1";
}
.fa-computer-mouse:before {
  content: "\f8cc";
}
.fa-mouse:before {
  content: "\f8cc";
}
.fa-computer-mouse-scrollwheel:before {
  content: "\f8cd";
}
.fa-mouse-alt:before {
  content: "\f8cd";
}
.fa-computer-speaker:before {
  content: "\f8b2";
}
.fa-confluence:before {
  content: "\f78d";
}
.fa-connectdevelop:before {
  content: "\f20e";
}
.fa-container-storage:before {
  content: "\f4b7";
}
.fa-contao:before {
  content: "\f26d";
}
.fa-conveyor-belt:before {
  content: "\f46e";
}
.fa-conveyor-belt-boxes:before {
  content: "\f46f";
}
.fa-conveyor-belt-alt:before {
  content: "\f46f";
}
.fa-conveyor-belt-empty:before {
  content: "\e10f";
}
.fa-cookie:before {
  content: "\f563";
}
.fa-cookie-bite:before {
  content: "\f564";
}
.fa-copy:before {
  content: "\f0c5";
}
.fa-copyright:before {
  content: "\f1f9";
}
.fa-corn:before {
  content: "\f6c7";
}
.fa-cotton-bureau:before {
  content: "\f89e";
}
.fa-couch:before {
  content: "\f4b8";
}
.fa-cow:before {
  content: "\f6c8";
}
.fa-cowbell:before {
  content: "\f8b3";
}
.fa-cowbell-circle-plus:before {
  content: "\f8b4";
}
.fa-cowbell-more:before {
  content: "\f8b4";
}
.fa-cpanel:before {
  content: "\f388";
}
.fa-creative-commons:before {
  content: "\f25e";
}
.fa-creative-commons-by:before {
  content: "\f4e7";
}
.fa-creative-commons-nc:before {
  content: "\f4e8";
}
.fa-creative-commons-nc-eu:before {
  content: "\f4e9";
}
.fa-creative-commons-nc-jp:before {
  content: "\f4ea";
}
.fa-creative-commons-nd:before {
  content: "\f4eb";
}
.fa-creative-commons-pd:before {
  content: "\f4ec";
}
.fa-creative-commons-pd-alt:before {
  content: "\f4ed";
}
.fa-creative-commons-remix:before {
  content: "\f4ee";
}
.fa-creative-commons-sa:before {
  content: "\f4ef";
}
.fa-creative-commons-sampling:before {
  content: "\f4f0";
}
.fa-creative-commons-sampling-plus:before {
  content: "\f4f1";
}
.fa-creative-commons-share:before {
  content: "\f4f2";
}
.fa-creative-commons-zero:before {
  content: "\f4f3";
}
.fa-credit-card:before {
  content: "\f09d";
}
.fa-credit-card-alt:before {
  content: "\f09d";
}
.fa-credit-card-blank:before {
  content: "\f389";
}
.fa-credit-card-front:before {
  content: "\f38a";
}
.fa-cricket-bat-ball:before {
  content: "\f449";
}
.fa-cricket:before {
  content: "\f449";
}
.fa-critical-role:before {
  content: "\f6c9";
}
.fa-croissant:before {
  content: "\f7f6";
}
.fa-crop:before {
  content: "\f125";
}
.fa-crop-simple:before {
  content: "\f565";
}
.fa-crop-alt:before {
  content: "\f565";
}
.fa-cross:before {
  content: "\f654";
}
.fa-crosshairs:before {
  content: "\f05b";
}
.fa-crow:before {
  content: "\f520";
}
.fa-crown:before {
  content: "\f521";
}
.fa-crutch:before {
  content: "\f7f7";
}
.fa-crutches:before {
  content: "\f7f8";
}
.fa-cruzeiro-sign:before {
  content: "\e110";
}
.fa-css3:before {
  content: "\f13c";
}
.fa-css3-alt:before {
  content: "\f38b";
}
.fa-cube:before {
  content: "\f1b2";
}
.fa-cubes:before {
  content: "\f1b3";
}
.fa-cup-togo:before {
  content: "\f6c5";
}
.fa-coffee-togo:before {
  content: "\f6c5";
}
.fa-curling-stone:before {
  content: "\f44a";
}
.fa-curling:before {
  content: "\f44a";
}
.fa-cuttlefish:before {
  content: "\f38c";
}
.fa-d:before {
  content: "\e111";
}
.fa-d-and-d:before {
  content: "\f38d";
}
.fa-d-and-d-beyond:before {
  content: "\f6ca";
}
.fa-dagger:before {
  content: "\f6cb";
}
.fa-dailymotion:before {
  content: "\e052";
}
.fa-dashcube:before {
  content: "\f210";
}
.fa-database:before {
  content: "\f1c0";
}
.fa-deer:before {
  content: "\f78e";
}
.fa-deer-rudolph:before {
  content: "\f78f";
}
.fa-deezer:before {
  content: "\e077";
}
.fa-delete-left:before {
  content: "\f55a";
}
.fa-backspace:before {
  content: "\f55a";
}
.fa-delete-right:before {
  content: "\e112";
}
.fa-delicious:before {
  content: "\f1a5";
}
.fa-democrat:before {
  content: "\f747";
}
.fa-deploydog:before {
  content: "\f38e";
}
.fa-deskpro:before {
  content: "\f38f";
}
.fa-desktop:before {
  content: "\f108";
}
.fa-desktop-alt:before {
  content: "\f108";
}
.fa-dev:before {
  content: "\f6cc";
}
.fa-deviantart:before {
  content: "\f1bd";
}
.fa-dharmachakra:before {
  content: "\f655";
}
.fa-dhl:before {
  content: "\f790";
}
.fa-diagram-stankey:before {
  content: "\e113";
}
.fa-diagram-venn:before {
  content: "\e114";
}
.fa-dial:before {
  content: "\e115";
}
.fa-dial-med-high:before {
  content: "\e115";
}
.fa-dial-high:before {
  content: "\e116";
}
.fa-dial-low:before {
  content: "\e117";
}
.fa-dial-max:before {
  content: "\e118";
}
.fa-dial-med:before {
  content: "\e119";
}
.fa-dial-med-low:before {
  content: "\e11a";
}
.fa-dial-min:before {
  content: "\e11b";
}
.fa-diamond:before {
  content: "\f219";
}
.fa-diamond-turn-right:before {
  content: "\f5eb";
}
.fa-directions:before {
  content: "\f5eb";
}
.fa-diaspora:before {
  content: "\f791";
}
.fa-dice:before {
  content: "\f522";
}
.fa-dice-d10:before {
  content: "\f6cd";
}
.fa-dice-d12:before {
  content: "\f6ce";
}
.fa-dice-d20:before {
  content: "\f6cf";
}
.fa-dice-d4:before {
  content: "\f6d0";
}
.fa-dice-d6:before {
  content: "\f6d1";
}
.fa-dice-d8:before {
  content: "\f6d2";
}
.fa-dice-five:before {
  content: "\f523";
}
.fa-dice-four:before {
  content: "\f524";
}
.fa-dice-one:before {
  content: "\f525";
}
.fa-dice-six:before {
  content: "\f526";
}
.fa-dice-three:before {
  content: "\f527";
}
.fa-dice-two:before {
  content: "\f528";
}
.fa-digg:before {
  content: "\f1a6";
}
.fa-digital-ocean:before {
  content: "\f391";
}
.fa-diploma:before {
  content: "\f5ea";
}
.fa-scroll-ribbon:before {
  content: "\f5ea";
}
.fa-disc-drive:before {
  content: "\f8b5";
}
.fa-discord:before {
  content: "\f392";
}
.fa-discourse:before {
  content: "\f393";
}
.fa-disease:before {
  content: "\f7fa";
}
.fa-display:before {
  content: "\e11c";
}
.fa-display-code:before {
  content: "\e11d";
}
.fa-desktop-code:before {
  content: "\e11d";
}
.fa-display-medical:before {
  content: "\e11e";
}
.fa-desktop-medical:before {
  content: "\e11e";
}
.fa-display-slash:before {
  content: "\e11f";
}
.fa-desktop-slash:before {
  content: "\e11f";
}
.fa-divide:before {
  content: "\f529";
}
.fa-dna:before {
  content: "\f471";
}
.fa-do-not-enter:before {
  content: "\f5ec";
}
.fa-dochub:before {
  content: "\f394";
}
.fa-docker:before {
  content: "\f395";
}
.fa-dog:before {
  content: "\f6d3";
}
.fa-dog-leashed:before {
  content: "\f6d4";
}
.fa-dollar-sign:before {
  content: "\f155";
}
.fa-dollar:before {
  content: "\f155";
}
.fa-usd:before {
  content: "\f155";
}
.fa-dolly:before {
  content: "\f472";
}
.fa-dolly-box:before {
  content: "\f472";
}
.fa-dolly-empty:before {
  content: "\f473";
}
.fa-dolphin:before {
  content: "\e120";
}
.fa-dong-sign:before {
  content: "\e121";
}
.fa-door-closed:before {
  content: "\f52a";
}
.fa-door-open:before {
  content: "\f52b";
}
.fa-dove:before {
  content: "\f4ba";
}
.fa-down:before {
  content: "\f354";
}
.fa-arrow-alt-down:before {
  content: "\f354";
}
.fa-down-from-line:before {
  content: "\f349";
}
.fa-arrow-alt-from-top:before {
  content: "\f349";
}
.fa-down-left-and-up-right-to-center:before {
  content: "\f422";
}
.fa-compress-alt:before {
  content: "\f422";
}
.fa-down-long:before {
  content: "\f309";
}
.fa-long-arrow-alt-down:before {
  content: "\f309";
}
.fa-down-to-line:before {
  content: "\f34a";
}
.fa-arrow-alt-to-bottom:before {
  content: "\f34a";
}
.fa-download:before {
  content: "\f019";
}
.fa-draft2digital:before {
  content: "\f396";
}
.fa-dragon:before {
  content: "\f6d5";
}
.fa-draw-circle:before {
  content: "\f5ed";
}
.fa-draw-polygon:before {
  content: "\f5ee";
}
.fa-draw-square:before {
  content: "\f5ef";
}
.fa-dreidel:before {
  content: "\f792";
}
.fa-dribbble:before {
  content: "\f17d";
}
.fa-dribbble-square:before {
  content: "\f397";
}
.fa-drone:before {
  content: "\f85f";
}
.fa-drone-front:before {
  content: "\f860";
}
.fa-drone-alt:before {
  content: "\f860";
}
.fa-dropbox:before {
  content: "\f16b";
}
.fa-droplet:before {
  content: "\f043";
}
.fa-tint:before {
  content: "\f043";
}
.fa-droplet-degree:before {
  content: "\f748";
}
.fa-dewpoint:before {
  content: "\f748";
}
.fa-droplet-percent:before {
  content: "\f750";
}
.fa-humidity:before {
  content: "\f750";
}
.fa-droplet-slash:before {
  content: "\f5c7";
}
.fa-tint-slash:before {
  content: "\f5c7";
}
.fa-drum:before {
  content: "\f569";
}
.fa-drum-steelpan:before {
  content: "\f56a";
}
.fa-drumstick:before {
  content: "\f6d6";
}
.fa-drumstick-bite:before {
  content: "\f6d7";
}
.fa-drupal:before {
  content: "\f1a9";
}
.fa-dryer:before {
  content: "\f861";
}
.fa-dryer-heat:before {
  content: "\f862";
}
.fa-dryer-alt:before {
  content: "\f862";
}
.fa-duck:before {
  content: "\f6d8";
}
.fa-dumbbell:before {
  content: "\f44b";
}
.fa-dumpster:before {
  content: "\f793";
}
.fa-dumpster-fire:before {
  content: "\f794";
}
.fa-dungeon:before {
  content: "\f6d9";
}
.fa-dyalog:before {
  content: "\f399";
}
.fa-e:before {
  content: "\e122";
}
.fa-ear:before {
  content: "\f5f0";
}
.fa-ear-deaf:before {
  content: "\f2a4";
}
.fa-deaf:before {
  content: "\f2a4";
}
.fa-deafness:before {
  content: "\f2a4";
}
.fa-hard-of-hearing:before {
  content: "\f2a4";
}
.fa-ear-listen:before {
  content: "\f2a2";
}
.fa-assistive-listening-systems:before {
  content: "\f2a2";
}
.fa-ear-muffs:before {
  content: "\f795";
}
.fa-earlybirds:before {
  content: "\f39a";
}
.fa-earth-africa:before {
  content: "\f57c";
}
.fa-globe-africa:before {
  content: "\f57c";
}
.fa-earth-americas:before {
  content: "\f57d";
}
.fa-earth:before {
  content: "\f57d";
}
.fa-globe-americas:before {
  content: "\f57d";
}
.fa-earth-asia:before {
  content: "\f57e";
}
.fa-globe-asia:before {
  content: "\f57e";
}
.fa-earth-europa:before {
  content: "\f7a2";
}
.fa-globe-europe:before {
  content: "\f7a2";
}
.fa-ebay:before {
  content: "\f4f4";
}
.fa-eclipse:before {
  content: "\f749";
}
.fa-edge:before {
  content: "\f282";
}
.fa-edge-legacy:before {
  content: "\e078";
}
.fa-egg:before {
  content: "\f7fb";
}
.fa-egg-fried:before {
  content: "\f7fc";
}
.fa-eject:before {
  content: "\f052";
}
.fa-elementor:before {
  content: "\f430";
}
.fa-elephant:before {
  content: "\f6da";
}
.fa-ellipsis:before {
  content: "\f141";
}
.fa-ellipsis-h:before {
  content: "\f141";
}
.fa-ellipsis-stroke:before {
  content: "\f39b";
}
.fa-ellipsis-h-alt:before {
  content: "\f39b";
}
.fa-ellipsis-stroke-vertical:before {
  content: "\f39c";
}
.fa-ellipsis-v-alt:before {
  content: "\f39c";
}
.fa-ellipsis-vertical:before {
  content: "\f142";
}
.fa-ellipsis-v:before {
  content: "\f142";
}
.fa-ello:before {
  content: "\f5f1";
}
.fa-ember:before {
  content: "\f423";
}
.fa-empire:before {
  content: "\f1d1";
}
.fa-empty-set:before {
  content: "\f656";
}
.fa-engine:before {
  content: "\e123";
}
.fa-engine-warning:before {
  content: "\f5f2";
}
.fa-engine-exclamation:before {
  content: "\f5f2";
}
.fa-envelope:before {
  content: "\f0e0";
}
.fa-envelope-dot:before {
  content: "\e124";
}
.fa-envelope-badge:before {
  content: "\e124";
}
.fa-envelope-open:before {
  content: "\f2b6";
}
.fa-envelope-open-dollar:before {
  content: "\f657";
}
.fa-envelope-open-text:before {
  content: "\f658";
}
.fa-envelopes:before {
  content: "\e125";
}
.fa-envelopes-bulk:before {
  content: "\f674";
}
.fa-mail-bulk:before {
  content: "\f674";
}
.fa-envira:before {
  content: "\f299";
}
.fa-equals:before {
  content: "\f52c";
}
.fa-eraser:before {
  content: "\f12d";
}
.fa-erlang:before {
  content: "\f39d";
}
.fa-ethereum:before {
  content: "\f42e";
}
.fa-ethernet:before {
  content: "\f796";
}
.fa-etsy:before {
  content: "\f2d7";
}
.fa-euro-sign:before {
  content: "\f153";
}
.fa-euro:before {
  content: "\f153";
}
.fa-eur:before {
  content: "\f153";
}
.fa-evernote:before {
  content: "\f839";
}
.fa-exclamation:before {
  content: "\f12a";
}
.fa-expand:before {
  content: "\f065";
}
.fa-expand-wide:before {
  content: "\f320";
}
.fa-expeditedssl:before {
  content: "\f23e";
}
.fa-eye:before {
  content: "\f06e";
}
.fa-eye-dropper:before {
  content: "\f1fb";
}
.fa-eye-dropper-empty:before {
  content: "\f1fb";
}
.fa-eyedropper:before {
  content: "\f1fb";
}
.fa-eye-dropper-full:before {
  content: "\e126";
}
.fa-eye-dropper-half:before {
  content: "\e127";
}
.fa-eye-evil:before {
  content: "\f6db";
}
.fa-eye-low-vision:before {
  content: "\f2a8";
}
.fa-low-vision:before {
  content: "\f2a8";
}
.fa-eye-slash:before {
  content: "\f070";
}
.fa-f:before {
  content: "\e128";
}
.fa-face-angry:before {
  content: "\f556";
}
.fa-angry:before {
  content: "\f556";
}
.fa-face-dizzy:before {
  content: "\f567";
}
.fa-dizzy:before {
  content: "\f567";
}
.fa-face-flushed:before {
  content: "\f579";
}
.fa-flushed:before {
  content: "\f579";
}
.fa-face-frown:before {
  content: "\f119";
}
.fa-frown:before {
  content: "\f119";
}
.fa-face-frown-open:before {
  content: "\f57a";
}
.fa-frown-open:before {
  content: "\f57a";
}
.fa-face-grimace:before {
  content: "\f57f";
}
.fa-grimace:before {
  content: "\f57f";
}
.fa-face-grin:before {
  content: "\f580";
}
.fa-grin:before {
  content: "\f580";
}
.fa-face-grin-beam:before {
  content: "\f582";
}
.fa-grin-beam:before {
  content: "\f582";
}
.fa-face-grin-beam-sweat:before {
  content: "\f583";
}
.fa-grin-beam-sweat:before {
  content: "\f583";
}
.fa-face-grin-hearts:before {
  content: "\f584";
}
.fa-grin-hearts:before {
  content: "\f584";
}
.fa-face-grin-squint:before {
  content: "\f585";
}
.fa-grin-squint:before {
  content: "\f585";
}
.fa-face-grin-squint-tears:before {
  content: "\f586";
}
.fa-grin-squint-tears:before {
  content: "\f586";
}
.fa-face-grin-stars:before {
  content: "\f587";
}
.fa-grin-stars:before {
  content: "\f587";
}
.fa-face-grin-tears:before {
  content: "\f588";
}
.fa-grin-tears:before {
  content: "\f588";
}
.fa-face-grin-tongue:before {
  content: "\f589";
}
.fa-grin-tongue:before {
  content: "\f589";
}
.fa-face-grin-tongue-squint:before {
  content: "\f58a";
}
.fa-grin-tongue-squint:before {
  content: "\f58a";
}
.fa-face-grin-tongue-wink:before {
  content: "\f58b";
}
.fa-grin-tongue-wink:before {
  content: "\f58b";
}
.fa-face-grin-wide:before {
  content: "\f581";
}
.fa-grin-alt:before {
  content: "\f581";
}
.fa-face-grin-wink:before {
  content: "\f58c";
}
.fa-grin-wink:before {
  content: "\f58c";
}
.fa-face-kiss:before {
  content: "\f596";
}
.fa-kiss:before {
  content: "\f596";
}
.fa-face-kiss-beam:before {
  content: "\f597";
}
.fa-kiss-beam:before {
  content: "\f597";
}
.fa-face-kiss-wink-heart:before {
  content: "\f598";
}
.fa-kiss-wink-heart:before {
  content: "\f598";
}
.fa-face-laugh:before {
  content: "\f599";
}
.fa-laugh:before {
  content: "\f599";
}
.fa-face-laugh-beam:before {
  content: "\f59a";
}
.fa-laugh-beam:before {
  content: "\f59a";
}
.fa-face-laugh-squint:before {
  content: "\f59b";
}
.fa-laugh-squint:before {
  content: "\f59b";
}
.fa-face-laugh-wink:before {
  content: "\f59c";
}
.fa-laugh-wink:before {
  content: "\f59c";
}
.fa-face-meh:before {
  content: "\f11a";
}
.fa-meh:before {
  content: "\f11a";
}
.fa-face-meh-blank:before {
  content: "\f5a4";
}
.fa-meh-blank:before {
  content: "\f5a4";
}
.fa-face-rolling-eyes:before {
  content: "\f5a5";
}
.fa-meh-rolling-eyes:before {
  content: "\f5a5";
}
.fa-face-sad-cry:before {
  content: "\f5b3";
}
.fa-sad-cry:before {
  content: "\f5b3";
}
.fa-face-sad-tear:before {
  content: "\f5b4";
}
.fa-sad-tear:before {
  content: "\f5b4";
}
.fa-face-smile:before {
  content: "\f118";
}
.fa-smile:before {
  content: "\f118";
}
.fa-face-smile-beam:before {
  content: "\f5b8";
}
.fa-smile-beam:before {
  content: "\f5b8";
}
.fa-face-smile-plus:before {
  content: "\f5b9";
}
.fa-smile-plus:before {
  content: "\f5b9";
}
.fa-face-smile-wink:before {
  content: "\f4da";
}
.fa-smile-wink:before {
  content: "\f4da";
}
.fa-face-surprise:before {
  content: "\f5c2";
}
.fa-surprise:before {
  content: "\f5c2";
}
.fa-face-tired:before {
  content: "\f5c8";
}
.fa-tired:before {
  content: "\f5c8";
}
.fa-facebook:before {
  content: "\f09a";
}
.fa-facebook-f:before {
  content: "\f39e";
}
.fa-facebook-messenger:before {
  content: "\f39f";
}
.fa-facebook-square:before {
  content: "\f082";
}
.fa-fan:before {
  content: "\f863";
}
.fa-fan-table:before {
  content: "\e004";
}
.fa-fantasy-flight-games:before {
  content: "\f6dc";
}
.fa-farm:before {
  content: "\f864";
}
.fa-barn-silo:before {
  content: "\f864";
}
.fa-faucet:before {
  content: "\e005";
}
.fa-faucet-drip:before {
  content: "\e006";
}
.fa-fax:before {
  content: "\f1ac";
}
.fa-feather:before {
  content: "\f52d";
}
.fa-feather-pointed:before {
  content: "\f56b";
}
.fa-feather-alt:before {
  content: "\f56b";
}
.fa-fedex:before {
  content: "\f797";
}
.fa-fedora:before {
  content: "\f798";
}
.fa-field-hockey-stick-ball:before {
  content: "\f44c";
}
.fa-field-hockey:before {
  content: "\f44c";
}
.fa-figma:before {
  content: "\f799";
}
.fa-file:before {
  content: "\f15b";
}
.fa-file-arrow-down:before {
  content: "\f56d";
}
.fa-file-download:before {
  content: "\f56d";
}
.fa-file-arrow-up:before {
  content: "\f574";
}
.fa-file-upload:before {
  content: "\f574";
}
.fa-file-audio:before {
  content: "\f1c7";
}
.fa-file-certificate:before {
  content: "\f5f3";
}
.fa-file-award:before {
  content: "\f5f3";
}
.fa-file-chart-column:before {
  content: "\f659";
}
.fa-file-chart-line:before {
  content: "\f659";
}
.fa-file-chart-pie:before {
  content: "\f65a";
}
.fa-file-check:before {
  content: "\f316";
}
.fa-file-code:before {
  content: "\f1c9";
}
.fa-file-contract:before {
  content: "\f56c";
}
.fa-file-csv:before {
  content: "\f6dd";
}
.fa-file-dashed-line:before {
  content: "\f877";
}
.fa-page-break:before {
  content: "\f877";
}
.fa-file-excel:before {
  content: "\f1c3";
}
.fa-file-exclamation:before {
  content: "\f31a";
}
.fa-file-export:before {
  content: "\f56e";
}
.fa-arrow-right-from-file:before {
  content: "\f56e";
}
.fa-file-heart:before {
  content: "\e129";
}
.fa-file-image:before {
  content: "\f1c5";
}
.fa-file-import:before {
  content: "\f56f";
}
.fa-arrow-right-to-file:before {
  content: "\f56f";
}
.fa-file-invoice:before {
  content: "\f570";
}
.fa-file-invoice-dollar:before {
  content: "\f571";
}
.fa-file-lines:before {
  content: "\f15c";
}
.fa-file-alt:before {
  content: "\f15c";
}
.fa-file-text:before {
  content: "\f15c";
}
.fa-file-magnifying-glass:before {
  content: "\f865";
}
.fa-file-search:before {
  content: "\f865";
}
.fa-file-medical:before {
  content: "\f477";
}
.fa-file-minus:before {
  content: "\f318";
}
.fa-file-music:before {
  content: "\f8b6";
}
.fa-file-pdf:before {
  content: "\f1c1";
}
.fa-file-pen:before {
  content: "\f31c";
}
.fa-file-edit:before {
  content: "\f31c";
}
.fa-file-plus:before {
  content: "\f319";
}
.fa-file-powerpoint:before {
  content: "\f1c4";
}
.fa-file-prescription:before {
  content: "\f572";
}
.fa-file-signature:before {
  content: "\f573";
}
.fa-file-spreadsheet:before {
  content: "\f65b";
}
.fa-file-user:before {
  content: "\f65c";
}
.fa-file-video:before {
  content: "\f1c8";
}
.fa-file-waveform:before {
  content: "\f478";
}
.fa-file-medical-alt:before {
  content: "\f478";
}
.fa-file-word:before {
  content: "\f1c2";
}
.fa-file-xmark:before {
  content: "\f317";
}
.fa-file-times:before {
  content: "\f317";
}
.fa-file-zipper:before {
  content: "\f1c6";
}
.fa-file-archive:before {
  content: "\f1c6";
}
.fa-files:before {
  content: "\e12a";
}
.fa-files-medical:before {
  content: "\f7fd";
}
.fa-fill:before {
  content: "\f575";
}
.fa-fill-drip:before {
  content: "\f576";
}
.fa-film:before {
  content: "\f008";
}
.fa-film-canister:before {
  content: "\f8b7";
}
.fa-film-simple:before {
  content: "\f3a0";
}
.fa-film-alt:before {
  content: "\f3a0";
}
.fa-films:before {
  content: "\e12b";
}
.fa-filter:before {
  content: "\f0b0";
}
.fa-filter-list:before {
  content: "\e12c";
}
.fa-filters:before {
  content: "\e12d";
}
.fa-fingerprint:before {
  content: "\f577";
}
.fa-fire:before {
  content: "\f06d";
}
.fa-fire-extinguisher:before {
  content: "\f134";
}
.fa-fire-flame:before {
  content: "\f6df";
}
.fa-flame:before {
  content: "\f6df";
}
.fa-fire-flame-curved:before {
  content: "\f7e4";
}
.fa-fire-alt:before {
  content: "\f7e4";
}
.fa-fire-flame-simple:before {
  content: "\f46a";
}
.fa-burn:before {
  content: "\f46a";
}
.fa-fire-smoke:before {
  content: "\f74b";
}
.fa-firefox:before {
  content: "\f269";
}
.fa-firefox-browser:before {
  content: "\e007";
}
.fa-fireplace:before {
  content: "\f79a";
}
.fa-first-order:before {
  content: "\f2b0";
}
.fa-first-order-alt:before {
  content: "\f50a";
}
.fa-firstdraft:before {
  content: "\f3a1";
}
.fa-fish:before {
  content: "\f578";
}
.fa-fish-cooked:before {
  content: "\f7fe";
}
.fa-fist-raised:before {
  content: "\f6de";
}
.fa-flag:before {
  content: "\f024";
}
.fa-flag-checkered:before {
  content: "\f11e";
}
.fa-flag-pennant:before {
  content: "\f456";
}
.fa-pennant:before {
  content: "\f456";
}
.fa-flag-swallowtail:before {
  content: "\f74c";
}
.fa-flag-alt:before {
  content: "\f74c";
}
.fa-flag-usa:before {
  content: "\f74d";
}
.fa-flashlight:before {
  content: "\f8b8";
}
.fa-flask:before {
  content: "\f0c3";
}
.fa-flask-round-poison:before {
  content: "\f6e0";
}
.fa-flask-poison:before {
  content: "\f6e0";
}
.fa-flask-round-potion:before {
  content: "\f6e1";
}
.fa-flask-potion:before {
  content: "\f6e1";
}
.fa-flickr:before {
  content: "\f16e";
}
.fa-flipboard:before {
  content: "\f44d";
}
.fa-florin-sign:before {
  content: "\e12e";
}
.fa-flower:before {
  content: "\f7ff";
}
.fa-flower-daffodil:before {
  content: "\f800";
}
.fa-flower-tulip:before {
  content: "\f801";
}
.fa-flute:before {
  content: "\f8b9";
}
.fa-flux-capacitor:before {
  content: "\f8ba";
}
.fa-fly:before {
  content: "\f417";
}
.fa-folder:before {
  content: "\f07b";
}
.fa-folder-arrow-down:before {
  content: "\e053";
}
.fa-folder-download:before {
  content: "\e053";
}
.fa-folder-arrow-up:before {
  content: "\e054";
}
.fa-folder-upload:before {
  content: "\e054";
}
.fa-folder-blank:before {
  content: "\e12f";
}
.fa-folder-bookmark:before {
  content: "\e130";
}
.fa-folder-gear:before {
  content: "\e131";
}
.fa-folder-cog:before {
  content: "\e131";
}
.fa-folder-grid:before {
  content: "\e132";
}
.fa-folder-heart:before {
  content: "\e133";
}
.fa-folder-image:before {
  content: "\e134";
}
.fa-folder-magnifying-glass:before {
  content: "\e135";
}
.fa-folder-search:before {
  content: "\e135";
}
.fa-folder-medical:before {
  content: "\e136";
}
.fa-folder-minus:before {
  content: "\f65d";
}
.fa-folder-music:before {
  content: "\e137";
}
.fa-folder-open:before {
  content: "\f07c";
}
.fa-folder-plus:before {
  content: "\f65e";
}
.fa-folder-tree:before {
  content: "\f802";
}
.fa-folder-user:before {
  content: "\e138";
}
.fa-folder-xmark:before {
  content: "\f65f";
}
.fa-folder-times:before {
  content: "\f65f";
}
.fa-folders:before {
  content: "\f660";
}
.fa-font:before {
  content: "\f031";
}
.fa-font-awesome:before {
  content: "\f2b4";
}
.fa-font-awesome-alt:before {
  content: "\f35c";
}
.fa-font-awesome-flag:before {
  content: "\f425";
}
.fa-font-awesome-logo-full:before {
  content: "\f4e6";
}
.fa-font-case:before {
  content: "\f866";
}
.fa-fonticons:before {
  content: "\f280";
}
.fa-fonticons-fi:before {
  content: "\f3a2";
}
.fa-football-ball:before {
  content: "\f44e";
}
.fa-football-helmet:before {
  content: "\f44f";
}
.fa-fork:before {
  content: "\f2e3";
}
.fa-utensil-fork:before {
  content: "\f2e3";
}
.fa-fork-knife:before {
  content: "\f2e6";
}
.fa-utensils-alt:before {
  content: "\f2e6";
}
.fa-forklift:before {
  content: "\f47a";
}
.fa-fort-awesome:before {
  content: "\f286";
}
.fa-fort-awesome-alt:before {
  content: "\f3a3";
}
.fa-forumbee:before {
  content: "\f211";
}
.fa-forward:before {
  content: "\f04e";
}
.fa-forward-fast:before {
  content: "\f050";
}
.fa-fast-forward:before {
  content: "\f050";
}
.fa-forward-step:before {
  content: "\f051";
}
.fa-step-forward:before {
  content: "\f051";
}
.fa-foursquare:before {
  content: "\f180";
}
.fa-franc-sign:before {
  content: "\e139";
}
.fa-free-code-camp:before {
  content: "\f2c5";
}
.fa-freebsd:before {
  content: "\f3a4";
}
.fa-french-fries:before {
  content: "\f803";
}
.fa-frog:before {
  content: "\f52e";
}
.fa-fulcrum:before {
  content: "\f50b";
}
.fa-function:before {
  content: "\f661";
}
.fa-funnel-circle-dollar:before {
  content: "\f662";
}
.fa-funnel-dollar:before {
  content: "\f662";
}
.fa-futbol-ball:before {
  content: "\f1e3";
}
.fa-soccer-ball:before {
  content: "\f1e3";
}
.fa-futbol:before {
  content: "\f1e3";
}
.fa-g:before {
  content: "\e13a";
}
.fa-galactic-republic:before {
  content: "\f50c";
}
.fa-galactic-senate:before {
  content: "\f50d";
}
.fa-galaxy:before {
  content: "\e008";
}
.fa-game-board:before {
  content: "\f867";
}
.fa-game-board-simple:before {
  content: "\f868";
}
.fa-game-board-alt:before {
  content: "\f868";
}
.fa-game-console-handheld:before {
  content: "\f8bb";
}
.fa-gamepad:before {
  content: "\f11b";
}
.fa-gamepad-modern:before {
  content: "\f8bc";
}
.fa-gamepad-alt:before {
  content: "\f8bc";
}
.fa-garage:before {
  content: "\e009";
}
.fa-garage-car:before {
  content: "\e00a";
}
.fa-garage-open:before {
  content: "\e00b";
}
.fa-gas-pump:before {
  content: "\f52f";
}
.fa-gas-pump-slash:before {
  content: "\f5f4";
}
.fa-gauge:before {
  content: "\f625";
}
.fa-gauge-high:before {
  content: "\f625";
}
.fa-tachometer-alt:before {
  content: "\f625";
}
.fa-tachometer-alt-fast:before {
  content: "\f625";
}
.fa-dashboard:before {
  content: "\f625";
}
.fa-gauge-low:before {
  content: "\f627";
}
.fa-tachometer-alt-slow:before {
  content: "\f627";
}
.fa-gauge-max:before {
  content: "\f626";
}
.fa-tachometer-alt-fastest:before {
  content: "\f626";
}
.fa-gauge-med:before {
  content: "\f624";
}
.fa-tachometer-alt-average:before {
  content: "\f624";
}
.fa-gauge-min:before {
  content: "\f628";
}
.fa-tachometer-alt-slowest:before {
  content: "\f628";
}
.fa-gauge-simple:before {
  content: "\f62a";
}
.fa-gauge-simple-high:before {
  content: "\f62a";
}
.fa-tachometer:before {
  content: "\f62a";
}
.fa-gauge-simple-low:before {
  content: "\f62c";
}
.fa-tachometer-slow:before {
  content: "\f62c";
}
.fa-gauge-simple-max:before {
  content: "\f62b";
}
.fa-tachometer-fastest:before {
  content: "\f62b";
}
.fa-gauge-simple-med:before {
  content: "\f629";
}
.fa-tachometer-average:before {
  content: "\f629";
}
.fa-gauge-simple-min:before {
  content: "\f62d";
}
.fa-tachometer-slowest:before {
  content: "\f62d";
}
.fa-gavel:before {
  content: "\f0e3";
}
.fa-legal:before {
  content: "\f0e3";
}
.fa-gear:before {
  content: "\f013";
}
.fa-cog:before {
  content: "\f013";
}
.fa-gears:before {
  content: "\f085";
}
.fa-cogs:before {
  content: "\f085";
}
.fa-gem:before {
  content: "\f3a5";
}
.fa-genderless:before {
  content: "\f22d";
}
.fa-get-pocket:before {
  content: "\f265";
}
.fa-gg:before {
  content: "\f260";
}
.fa-gg-circle:before {
  content: "\f261";
}
.fa-ghost:before {
  content: "\f6e2";
}
.fa-gift:before {
  content: "\f06b";
}
.fa-gift-card:before {
  content: "\f663";
}
.fa-gifts:before {
  content: "\f79c";
}
.fa-gingerbread-man:before {
  content: "\f79d";
}
.fa-git:before {
  content: "\f1d3";
}
.fa-git-alt:before {
  content: "\f841";
}
.fa-git-square:before {
  content: "\f1d2";
}
.fa-github:before {
  content: "\f09b";
}
.fa-github-alt:before {
  content: "\f113";
}
.fa-github-square:before {
  content: "\f092";
}
.fa-gitkraken:before {
  content: "\f3a6";
}
.fa-gitlab:before {
  content: "\f296";
}
.fa-gitter:before {
  content: "\f426";
}
.fa-glass:before {
  content: "\f804";
}
.fa-glass-citrus:before {
  content: "\f869";
}
.fa-glass-empty:before {
  content: "\e13b";
}
.fa-glass-half:before {
  content: "\e13c";
}
.fa-glass-half-full:before {
  content: "\e13c";
}
.fa-glass-half-empty:before {
  content: "\e13c";
}
.fa-glasses:before {
  content: "\f530";
}
.fa-glasses-round:before {
  content: "\f5f5";
}
.fa-glasses-alt:before {
  content: "\f5f5";
}
.fa-glide:before {
  content: "\f2a5";
}
.fa-glide-g:before {
  content: "\f2a6";
}
.fa-globe:before {
  content: "\f0ac";
}
.fa-globe-snow:before {
  content: "\f7a3";
}
.fa-globe-stand:before {
  content: "\f5f6";
}
.fa-gofore:before {
  content: "\f3a7";
}
.fa-golf-ball-tee:before {
  content: "\f450";
}
.fa-golf-ball:before {
  content: "\f450";
}
.fa-golf-club:before {
  content: "\f451";
}
.fa-goodreads:before {
  content: "\f3a8";
}
.fa-goodreads-g:before {
  content: "\f3a9";
}
.fa-google:before {
  content: "\f1a0";
}
.fa-google-drive:before {
  content: "\f3aa";
}
.fa-google-pay:before {
  content: "\e079";
}
.fa-google-play:before {
  content: "\f3ab";
}
.fa-google-plus:before {
  content: "\f2b3";
}
.fa-google-plus-g:before {
  content: "\f0d5";
}
.fa-google-plus-square:before {
  content: "\f0d4";
}
.fa-google-wallet:before {
  content: "\f1ee";
}
.fa-gopuram:before {
  content: "\f664";
}
.fa-graduation-cap:before {
  content: "\f19d";
}
.fa-mortar-board:before {
  content: "\f19d";
}
.fa-gramophone:before {
  content: "\f8bd";
}
.fa-gratipay:before {
  content: "\f184";
}
.fa-grav:before {
  content: "\f2d6";
}
.fa-greater-than:before {
  content: "\f531";
}
.fa-greater-than-equal:before {
  content: "\f532";
}
.fa-grid:before {
  content: "\e13d";
}
.fa-grid-3:before {
  content: "\e13d";
}
.fa-grid-2:before {
  content: "\e13e";
}
.fa-grid-4:before {
  content: "\e13f";
}
.fa-grid-5:before {
  content: "\e140";
}
.fa-grip:before {
  content: "\f58d";
}
.fa-grip-horizontal:before {
  content: "\f58d";
}
.fa-grip-lines:before {
  content: "\f7a4";
}
.fa-grip-lines-vertical:before {
  content: "\f7a5";
}
.fa-grip-vertical:before {
  content: "\f58e";
}
.fa-gripfire:before {
  content: "\f3ac";
}
.fa-grunt:before {
  content: "\f3ad";
}
.fa-guarani-sign:before {
  content: "\e141";
}
.fa-guilded:before {
  content: "\e07e";
}
.fa-guitar:before {
  content: "\f7a6";
}
.fa-guitar-electric:before {
  content: "\f8be";
}
.fa-guitars:before {
  content: "\f8bf";
}
.fa-gulp:before {
  content: "\f3ae";
}
.fa-h:before {
  content: "\e142";
}
.fa-h1:before {
  content: "\f313";
}
.fa-h2:before {
  content: "\f314";
}
.fa-h3:before {
  content: "\f315";
}
.fa-h4:before {
  content: "\f86a";
}
.fa-hacker-news:before {
  content: "\f1d4";
}
.fa-hacker-news-square:before {
  content: "\f3af";
}
.fa-hackerrank:before {
  content: "\f5f7";
}
.fa-hammer:before {
  content: "\f6e3";
}
.fa-hammer-war:before {
  content: "\f6e4";
}
.fa-hamsa:before {
  content: "\f665";
}
.fa-hand-dots:before {
  content: "\f461";
}
.fa-allergies:before {
  content: "\f461";
}
.fa-hand-heart:before {
  content: "\f4bc";
}
.fa-hand-holding:before {
  content: "\f4bd";
}
.fa-hand-holding-box:before {
  content: "\f47b";
}
.fa-hand-holding-dollar:before {
  content: "\f4c0";
}
.fa-hand-holding-usd:before {
  content: "\f4c0";
}
.fa-hand-holding-droplet:before {
  content: "\f4c1";
}
.fa-hand-holding-water:before {
  content: "\f4c1";
}
.fa-hand-holding-heart:before {
  content: "\f4be";
}
.fa-hand-holding-magic:before {
  content: "\f6e5";
}
.fa-hand-holding-medical:before {
  content: "\e05c";
}
.fa-hand-holding-seedling:before {
  content: "\f4bf";
}
.fa-hand-lizard:before {
  content: "\f258";
}
.fa-hand-middle-finger:before {
  content: "\f806";
}
.fa-hand-paper:before {
  content: "\f256";
}
.fa-hand-peace:before {
  content: "\f25b";
}
.fa-hand-point-down:before {
  content: "\f0a7";
}
.fa-hand-point-left:before {
  content: "\f0a5";
}
.fa-hand-point-right:before {
  content: "\f0a4";
}
.fa-hand-point-up:before {
  content: "\f0a6";
}
.fa-hand-pointer:before {
  content: "\f25a";
}
.fa-hand-rock:before {
  content: "\f255";
}
.fa-hand-scissors:before {
  content: "\f257";
}
.fa-hand-sparkles:before {
  content: "\e05d";
}
.fa-hand-spock:before {
  content: "\f259";
}
.fa-hands:before {
  content: "\f2a7";
}
.fa-sign-language:before {
  content: "\f2a7";
}
.fa-signing:before {
  content: "\f2a7";
}
.fa-hands-asl-interpreting:before {
  content: "\f2a3";
}
.fa-american-sign-language-interpreting:before {
  content: "\f2a3";
}
.fa-hands-american-sign-language-interpreting:before {
  content: "\f2a3";
}
.fa-asl-interpreting:before {
  content: "\f2a3";
}
.fa-hands-bubbles:before {
  content: "\e05e";
}
.fa-hands-wash:before {
  content: "\e05e";
}
.fa-hands-holding:before {
  content: "\f4c2";
}
.fa-hands-holding-diamond:before {
  content: "\f47c";
}
.fa-hand-receiving:before {
  content: "\f47c";
}
.fa-hands-holding-dollar:before {
  content: "\f4c5";
}
.fa-hands-usd:before {
  content: "\f4c5";
}
.fa-hands-holding-heart:before {
  content: "\f4c3";
}
.fa-hands-heart:before {
  content: "\f4c3";
}
.fa-hands-praying:before {
  content: "\f684";
}
.fa-praying-hands:before {
  content: "\f684";
}
.fa-handshake:before {
  content: "\f2b5";
}
.fa-handshake-angle:before {
  content: "\f4c4";
}
.fa-hands-helping:before {
  content: "\f4c4";
}
.fa-handshake-simple:before {
  content: "\f4c6";
}
.fa-handshake-alt:before {
  content: "\f4c6";
}
.fa-handshake-simple-slash:before {
  content: "\e05f";
}
.fa-handshake-alt-slash:before {
  content: "\e05f";
}
.fa-handshake-slash:before {
  content: "\e060";
}
.fa-hanukiah:before {
  content: "\f6e6";
}
.fa-hard-drive:before {
  content: "\f0a0";
}
.fa-hdd:before {
  content: "\f0a0";
}
.fa-hashtag:before {
  content: "\f292";
}
.fa-hat-chef:before {
  content: "\f86b";
}
.fa-hat-cowboy:before {
  content: "\f8c0";
}
.fa-hat-cowboy-side:before {
  content: "\f8c1";
}
.fa-hat-santa:before {
  content: "\f7a7";
}
.fa-hat-winter:before {
  content: "\f7a8";
}
.fa-hat-witch:before {
  content: "\f6e7";
}
.fa-hat-wizard:before {
  content: "\f6e8";
}
.fa-head-side:before {
  content: "\f6e9";
}
.fa-head-side-brain:before {
  content: "\f808";
}
.fa-head-side-cough:before {
  content: "\e061";
}
.fa-head-side-cough-slash:before {
  content: "\e062";
}
.fa-head-side-goggles:before {
  content: "\f6ea";
}
.fa-head-vr:before {
  content: "\f6ea";
}
.fa-head-side-headphones:before {
  content: "\f8c2";
}
.fa-head-side-heart:before {
  content: "\e143";
}
.fa-head-side-mask:before {
  content: "\e063";
}
.fa-head-side-medical:before {
  content: "\f809";
}
.fa-head-side-virus:before {
  content: "\e064";
}
.fa-heading:before {
  content: "\f1dc";
}
.fa-header:before {
  content: "\f1dc";
}
.fa-headphones:before {
  content: "\f025";
}
.fa-headphones-simple:before {
  content: "\f58f";
}
.fa-headphones-alt:before {
  content: "\f58f";
}
.fa-headset:before {
  content: "\f590";
}
.fa-heart:before {
  content: "\f004";
}
.fa-heart-crack:before {
  content: "\f7a9";
}
.fa-heart-broken:before {
  content: "\f7a9";
}
.fa-heart-half:before {
  content: "\e144";
}
.fa-heart-half-stroke:before {
  content: "\e145";
}
.fa-heart-half-alt:before {
  content: "\e145";
}
.fa-heart-pulse:before {
  content: "\f21e";
}
.fa-heartbeat:before {
  content: "\f21e";
}
.fa-heat:before {
  content: "\e00c";
}
.fa-helicopter:before {
  content: "\f533";
}
.fa-helmet-battle:before {
  content: "\f6eb";
}
.fa-helmet-safety:before {
  content: "\f807";
}
.fa-hat-hard:before {
  content: "\f807";
}
.fa-hard-hat:before {
  content: "\f807";
}
.fa-hexagon:before {
  content: "\f312";
}
.fa-hexagon-minus:before {
  content: "\f307";
}
.fa-minus-hexagon:before {
  content: "\f307";
}
.fa-hexagon-plus:before {
  content: "\f300";
}
.fa-plus-hexagon:before {
  content: "\f300";
}
.fa-hexagon-xmark:before {
  content: "\f2ee";
}
.fa-times-hexagon:before {
  content: "\f2ee";
}
.fa-xmark-hexagon:before {
  content: "\f2ee";
}
.fa-highlighter:before {
  content: "\f591";
}
.fa-highlighter-line:before {
  content: "\e146";
}
.fa-hippo:before {
  content: "\f6ed";
}
.fa-hips:before {
  content: "\f452";
}
.fa-hire-a-helper:before {
  content: "\f3b0";
}
.fa-hive:before {
  content: "\e07f";
}
.fa-hockey-mask:before {
  content: "\f6ee";
}
.fa-hockey-puck:before {
  content: "\f453";
}
.fa-hockey-sticks:before {
  content: "\f454";
}
.fa-holly-berry:before {
  content: "\f7aa";
}
.fa-home:before {
  content: "\f015";
}
.fa-home-lg:before {
  content: "\f015";
}
.fa-home-heart:before {
  content: "\f4c9";
}
.fa-home-simple:before {
  content: "\f80a";
}
.fa-home-lg-alt:before {
  content: "\f80a";
}
.fa-home-user:before {
  content: "\e147";
}
.fa-hood-cloak:before {
  content: "\f6ef";
}
.fa-hooli:before {
  content: "\f427";
}
.fa-horizontal-rule:before {
  content: "\f86c";
}
.fa-hornbill:before {
  content: "\f592";
}
.fa-horse:before {
  content: "\f6f0";
}
.fa-horse-head:before {
  content: "\f7ab";
}
.fa-horse-saddle:before {
  content: "\f8c3";
}
.fa-hospital:before {
  content: "\f0f8";
}
.fa-hospital-user:before {
  content: "\f80d";
}
.fa-hospital-wide:before {
  content: "\f47d";
}
.fa-hospital-alt:before {
  content: "\f47d";
}
.fa-hospitals:before {
  content: "\f80e";
}
.fa-hot-tub-person:before {
  content: "\f593";
}
.fa-hot-tub:before {
  content: "\f593";
}
.fa-hotdog:before {
  content: "\f80f";
}
.fa-hotel:before {
  content: "\f594";
}
.fa-hotjar:before {
  content: "\f3b1";
}
.fa-hourglass:before {
  content: "\f254";
}
.fa-hourglass-2:before {
  content: "\f254";
}
.fa-hourglass-half:before {
  content: "\f254";
}
.fa-hourglass-empty:before {
  content: "\f252";
}
.fa-hourglass-end:before {
  content: "\f253";
}
.fa-hourglass-3:before {
  content: "\f253";
}
.fa-hourglass-start:before {
  content: "\f251";
}
.fa-hourglass-1:before {
  content: "\f251";
}
.fa-house:before {
  content: "\e00d";
}
.fa-house-crack:before {
  content: "\f6f1";
}
.fa-house-damage:before {
  content: "\f6f1";
}
.fa-house-day:before {
  content: "\e00e";
}
.fa-house-flood:before {
  content: "\f74f";
}
.fa-house-heart:before {
  content: "\e148";
}
.fa-house-laptop:before {
  content: "\e066";
}
.fa-laptop-house:before {
  content: "\e066";
}
.fa-house-medical:before {
  content: "\f7f2";
}
.fa-clinic-medical:before {
  content: "\f7f2";
}
.fa-house-night:before {
  content: "\e010";
}
.fa-house-person-leave:before {
  content: "\e00f";
}
.fa-house-person-depart:before {
  content: "\e00f";
}
.fa-house-person-return:before {
  content: "\e011";
}
.fa-house-person-arrive:before {
  content: "\e011";
}
.fa-house-signal:before {
  content: "\e012";
}
.fa-house-user:before {
  content: "\e065";
}
.fa-houzz:before {
  content: "\f27c";
}
.fa-hryvnia-sign:before {
  content: "\f6f2";
}
.fa-hryvnia:before {
  content: "\f6f2";
}
.fa-html5:before {
  content: "\f13b";
}
.fa-hubspot:before {
  content: "\f3b2";
}
.fa-hurricane:before {
  content: "\f751";
}
.fa-i:before {
  content: "\e149";
}
.fa-i-cursor:before {
  content: "\f246";
}
.fa-ice-cream:before {
  content: "\f810";
}
.fa-ice-skate:before {
  content: "\f7ac";
}
.fa-icicles:before {
  content: "\f7ad";
}
.fa-icons:before {
  content: "\f86d";
}
.fa-heart-music-camera-bolt:before {
  content: "\f86d";
}
.fa-id-badge:before {
  content: "\f2c1";
}
.fa-id-card:before {
  content: "\f2c2";
}
.fa-drivers-license:before {
  content: "\f2c2";
}
.fa-id-card-clip:before {
  content: "\f47f";
}
.fa-id-card-alt:before {
  content: "\f47f";
}
.fa-ideal:before {
  content: "\e013";
}
.fa-igloo:before {
  content: "\f7ae";
}
.fa-image:before {
  content: "\f03e";
}
.fa-image-landscape:before {
  content: "\e14a";
}
.fa-landscape:before {
  content: "\e14a";
}
.fa-image-polaroid:before {
  content: "\f8c4";
}
.fa-image-polaroid-user:before {
  content: "\e14b";
}
.fa-image-portrait:before {
  content: "\f3e0";
}
.fa-portrait:before {
  content: "\f3e0";
}
.fa-image-user:before {
  content: "\e14c";
}
.fa-images:before {
  content: "\f302";
}
.fa-images-user:before {
  content: "\e14d";
}
.fa-imdb:before {
  content: "\f2d8";
}
.fa-inbox:before {
  content: "\f01c";
}
.fa-inbox-full:before {
  content: "\e14e";
}
.fa-inbox-in:before {
  content: "\f310";
}
.fa-inbox-arrow-down:before {
  content: "\f310";
}
.fa-inbox-out:before {
  content: "\f311";
}
.fa-inbox-arrow-up:before {
  content: "\f311";
}
.fa-inboxes:before {
  content: "\e14f";
}
.fa-indent:before {
  content: "\f03c";
}
.fa-indian-rupee-sign:before {
  content: "\e150";
}
.fa-indian-rupee:before {
  content: "\e150";
}
.fa-inr:before {
  content: "\e150";
}
.fa-industry:before {
  content: "\f275";
}
.fa-industry-windows:before {
  content: "\f3b3";
}
.fa-industry-alt:before {
  content: "\f3b3";
}
.fa-infinity:before {
  content: "\f534";
}
.fa-info:before {
  content: "\f129";
}
.fa-inhaler:before {
  content: "\f5f9";
}
.fa-innosoft:before {
  content: "\e080";
}
.fa-input-numeric:before {
  content: "\e151";
}
.fa-input-pipe:before {
  content: "\e152";
}
.fa-input-text:before {
  content: "\e153";
}
.fa-instagram:before {
  content: "\f16d";
}
.fa-instagram-square:before {
  content: "\e055";
}
.fa-instalod:before {
  content: "\e081";
}
.fa-integral:before {
  content: "\f667";
}
.fa-intercom:before {
  content: "\f7af";
}
.fa-internet-explorer:before {
  content: "\f26b";
}
.fa-intersection:before {
  content: "\f668";
}
.fa-invision:before {
  content: "\f7b0";
}
.fa-ioxhost:before {
  content: "\f208";
}
.fa-island-tropical:before {
  content: "\f811";
}
.fa-island-tree-palm:before {
  content: "\f811";
}
.fa-italic:before {
  content: "\f033";
}
.fa-itch-io:before {
  content: "\f83a";
}
.fa-itunes:before {
  content: "\f3b4";
}
.fa-itunes-note:before {
  content: "\f3b5";
}
.fa-j:before {
  content: "\e154";
}
.fa-jack-o-lantern:before {
  content: "\f30e";
}
.fa-java:before {
  content: "\f4e4";
}
.fa-jedi:before {
  content: "\f669";
}
.fa-jedi-order:before {
  content: "\f50e";
}
.fa-jenkins:before {
  content: "\f3b6";
}
.fa-jet-fighter:before {
  content: "\f0fb";
}
.fa-fighter-jet:before {
  content: "\f0fb";
}
.fa-jira:before {
  content: "\f7b1";
}
.fa-joget:before {
  content: "\f3b7";
}
.fa-joint:before {
  content: "\f595";
}
.fa-joomla:before {
  content: "\f1aa";
}
.fa-joystick:before {
  content: "\f8c5";
}
.fa-js:before {
  content: "\f3b8";
}
.fa-js-square:before {
  content: "\f3b9";
}
.fa-jsfiddle:before {
  content: "\f1cc";
}
.fa-jug:before {
  content: "\f8c6";
}
.fa-k:before {
  content: "\e155";
}
.fa-kaaba:before {
  content: "\f66b";
}
.fa-kaggle:before {
  content: "\f5fa";
}
.fa-kazoo:before {
  content: "\f8c7";
}
.fa-kerning:before {
  content: "\f86f";
}
.fa-key:before {
  content: "\f084";
}
.fa-key-skeleton:before {
  content: "\f6f3";
}
.fa-keybase:before {
  content: "\f4f5";
}
.fa-keyboard:before {
  content: "\f11c";
}
.fa-keyboard-down:before {
  content: "\e156";
}
.fa-keyboard-left:before {
  content: "\e157";
}
.fa-keycdn:before {
  content: "\f3ba";
}
.fa-keynote:before {
  content: "\f66c";
}
.fa-khanda:before {
  content: "\f66d";
}
.fa-kickstarter:before {
  content: "\f3bb";
}
.fa-kickstarter-k:before {
  content: "\f3bc";
}
.fa-kidneys:before {
  content: "\f5fb";
}
.fa-kip-sign:before {
  content: "\e158";
}
.fa-kit-medical:before {
  content: "\f479";
}
.fa-first-aid:before {
  content: "\f479";
}
.fa-kite:before {
  content: "\f6f4";
}
.fa-kiwi-bird:before {
  content: "\f535";
}
.fa-knife:before {
  content: "\f2e4";
}
.fa-utensil-knife:before {
  content: "\f2e4";
}
.fa-knife-kitchen:before {
  content: "\f6f5";
}
.fa-korvue:before {
  content: "\f42f";
}
.fa-l:before {
  content: "\e159";
}
.fa-lambda:before {
  content: "\f66e";
}
.fa-lamp:before {
  content: "\f4ca";
}
.fa-lamp-desk:before {
  content: "\e014";
}
.fa-lamp-floor:before {
  content: "\e015";
}
.fa-landmark:before {
  content: "\f66f";
}
.fa-landmark-dome:before {
  content: "\f752";
}
.fa-landmark-alt:before {
  content: "\f752";
}
.fa-language:before {
  content: "\f1ab";
}
.fa-laptop:before {
  content: "\f109";
}
.fa-laptop-code:before {
  content: "\f5fc";
}
.fa-laptop-medical:before {
  content: "\f812";
}
.fa-laptop-mobile:before {
  content: "\f87a";
}
.fa-phone-laptop:before {
  content: "\f87a";
}
.fa-laptop-slash:before {
  content: "\e15a";
}
.fa-laravel:before {
  content: "\f3bd";
}
.fa-lari-sign:before {
  content: "\e15b";
}
.fa-lasso:before {
  content: "\f8c8";
}
.fa-lasso-sparkles:before {
  content: "\e15c";
}
.fa-lastfm:before {
  content: "\f202";
}
.fa-lastfm-square:before {
  content: "\f203";
}
.fa-layers:before {
  content: "\f5fd";
}
.fa-layer-group:before {
  content: "\f5fd";
}
.fa-layers-minus:before {
  content: "\f5fe";
}
.fa-layer-minus:before {
  content: "\f5fe";
}
.fa-layers-plus:before {
  content: "\f5ff";
}
.fa-layer-plus:before {
  content: "\f5ff";
}
.fa-leaf:before {
  content: "\f06c";
}
.fa-leaf-heart:before {
  content: "\f4cb";
}
.fa-leaf-maple:before {
  content: "\f6f6";
}
.fa-leaf-oak:before {
  content: "\f6f7";
}
.fa-leanpub:before {
  content: "\f212";
}
.fa-left:before {
  content: "\f355";
}
.fa-arrow-alt-left:before {
  content: "\f355";
}
.fa-left-from-line:before {
  content: "\f348";
}
.fa-arrow-alt-from-right:before {
  content: "\f348";
}
.fa-left-long:before {
  content: "\f30a";
}
.fa-long-arrow-alt-left:before {
  content: "\f30a";
}
.fa-left-right:before {
  content: "\f337";
}
.fa-arrows-alt-h:before {
  content: "\f337";
}
.fa-left-to-line:before {
  content: "\f34b";
}
.fa-arrow-alt-to-left:before {
  content: "\f34b";
}
.fa-lemon:before {
  content: "\f094";
}
.fa-less:before {
  content: "\f41d";
}
.fa-less-than:before {
  content: "\f536";
}
.fa-less-than-equal:before {
  content: "\f537";
}
.fa-life-ring:before {
  content: "\f1cd";
}
.fa-light-ceiling:before {
  content: "\e016";
}
.fa-light-switch:before {
  content: "\e017";
}
.fa-light-switch-off:before {
  content: "\e018";
}
.fa-light-switch-on:before {
  content: "\e019";
}
.fa-lightbulb:before {
  content: "\f0eb";
}
.fa-lightbulb-dollar:before {
  content: "\f670";
}
.fa-lightbulb-exclamation:before {
  content: "\f671";
}
.fa-lightbulb-on:before {
  content: "\f672";
}
.fa-lightbulb-slash:before {
  content: "\f673";
}
.fa-lights-holiday:before {
  content: "\f7b2";
}
.fa-line:before {
  content: "\f3c0";
}
.fa-line-columns:before {
  content: "\f870";
}
.fa-line-height:before {
  content: "\f871";
}
.fa-link:before {
  content: "\f0c1";
}
.fa-chain:before {
  content: "\f0c1";
}
.fa-link-horizontal:before {
  content: "\e15d";
}
.fa-chain-horizontal:before {
  content: "\e15d";
}
.fa-link-horizontal-slash:before {
  content: "\e15e";
}
.fa-chain-horizontal-slash:before {
  content: "\e15e";
}
.fa-link-slash:before {
  content: "\f127";
}
.fa-chain-slash:before {
  content: "\f127";
}
.fa-chain-broken:before {
  content: "\f127";
}
.fa-unlink:before {
  content: "\f127";
}
.fa-linkedin:before {
  content: "\f08c";
}
.fa-linkedin-in:before {
  content: "\f0e1";
}
.fa-linode:before {
  content: "\f2b8";
}
.fa-linux:before {
  content: "\f17c";
}
.fa-lips:before {
  content: "\f600";
}
.fa-lira-sign:before {
  content: "\f195";
}
.fa-list:before {
  content: "\f03a";
}
.fa-list-squares:before {
  content: "\f03a";
}
.fa-list-check:before {
  content: "\f0ae";
}
.fa-tasks:before {
  content: "\f0ae";
}
.fa-list-dropdown:before {
  content: "\e15f";
}
.fa-list-music:before {
  content: "\f8c9";
}
.fa-list-ol:before {
  content: "\f0cb";
}
.fa-list-numeric:before {
  content: "\f0cb";
}
.fa-list-1-2:before {
  content: "\f0cb";
}
.fa-list-radio:before {
  content: "\e160";
}
.fa-list-tree:before {
  content: "\e161";
}
.fa-list-ul:before {
  content: "\f0ca";
}
.fa-list-dots:before {
  content: "\f0ca";
}
.fa-location:before {
  content: "\f041";
}
.fa-map-marker:before {
  content: "\f041";
}
.fa-location-arrow:before {
  content: "\f124";
}
.fa-location-check:before {
  content: "\f606";
}
.fa-map-marker-check:before {
  content: "\f606";
}
.fa-location-crosshairs:before {
  content: "\f601";
}
.fa-location-crosshairs-slash:before {
  content: "\f603";
}
.fa-location-dot:before {
  content: "\f3c5";
}
.fa-map-marker-alt:before {
  content: "\f3c5";
}
.fa-location-dot-slash:before {
  content: "\f605";
}
.fa-map-marker-alt-slash:before {
  content: "\f605";
}
.fa-location-exclamation:before {
  content: "\f608";
}
.fa-map-marker-exclamation:before {
  content: "\f608";
}
.fa-location-minus:before {
  content: "\f609";
}
.fa-map-marker-minus:before {
  content: "\f609";
}
.fa-location-pen:before {
  content: "\f607";
}
.fa-map-marker-edit:before {
  content: "\f607";
}
.fa-location-plus:before {
  content: "\f60a";
}
.fa-map-marker-plus:before {
  content: "\f60a";
}
.fa-location-question:before {
  content: "\f60b";
}
.fa-map-marker-question:before {
  content: "\f60b";
}
.fa-location-slash:before {
  content: "\f60c";
}
.fa-map-marker-slash:before {
  content: "\f60c";
}
.fa-location-smile:before {
  content: "\f60d";
}
.fa-map-marker-smile:before {
  content: "\f60d";
}
.fa-location-times:before {
  content: "\f60e";
}
.fa-map-marker-times:before {
  content: "\f60e";
}
.fa-lock:before {
  content: "\f023";
}
.fa-lock-keyhole:before {
  content: "\f30d";
}
.fa-lock-alt:before {
  content: "\f30d";
}
.fa-lock-keyhole-open:before {
  content: "\f3c2";
}
.fa-lock-open-alt:before {
  content: "\f3c2";
}
.fa-lock-open:before {
  content: "\f3c1";
}
.fa-loveseat:before {
  content: "\f4cc";
}
.fa-couch-small:before {
  content: "\f4cc";
}
.fa-luchador-mask:before {
  content: "\f455";
}
.fa-luchador:before {
  content: "\f455";
}
.fa-mask-luchador:before {
  content: "\f455";
}
.fa-lungs:before {
  content: "\f604";
}
.fa-lungs-virus:before {
  content: "\e067";
}
.fa-lyft:before {
  content: "\f3c3";
}
.fa-m:before {
  content: "\e162";
}
.fa-mace:before {
  content: "\f6f8";
}
.fa-magento:before {
  content: "\f3c4";
}
.fa-magnet:before {
  content: "\f076";
}
.fa-magnifying-glass:before {
  content: "\f002";
}
.fa-search:before {
  content: "\f002";
}
.fa-magnifying-glass-dollar:before {
  content: "\f688";
}
.fa-search-dollar:before {
  content: "\f688";
}
.fa-magnifying-glass-location:before {
  content: "\f689";
}
.fa-search-location:before {
  content: "\f689";
}
.fa-magnifying-glass-minus:before {
  content: "\f010";
}
.fa-search-minus:before {
  content: "\f010";
}
.fa-magnifying-glass-plus:before {
  content: "\f00e";
}
.fa-search-plus:before {
  content: "\f00e";
}
.fa-mailbox:before {
  content: "\f813";
}
.fa-mailchimp:before {
  content: "\f59e";
}
.fa-manat-sign:before {
  content: "\e163";
}
.fa-mandalorian:before {
  content: "\f50f";
}
.fa-mandolin:before {
  content: "\f6f9";
}
.fa-map:before {
  content: "\f279";
}
.fa-map-location:before {
  content: "\f59f";
}
.fa-map-marked:before {
  content: "\f59f";
}
.fa-map-location-dot:before {
  content: "\f5a0";
}
.fa-map-marked-alt:before {
  content: "\f5a0";
}
.fa-map-pin:before {
  content: "\f276";
}
.fa-markdown:before {
  content: "\f60f";
}
.fa-marker:before {
  content: "\f5a1";
}
.fa-mars:before {
  content: "\f222";
}
.fa-mars-double:before {
  content: "\f227";
}
.fa-mars-stroke:before {
  content: "\f229";
}
.fa-mars-stroke-right:before {
  content: "\f22b";
}
.fa-mars-stroke-h:before {
  content: "\f22b";
}
.fa-mars-stroke-up:before {
  content: "\f22a";
}
.fa-mars-stroke-v:before {
  content: "\f22a";
}
.fa-martini-glass:before {
  content: "\f57b";
}
.fa-glass-martini-alt:before {
  content: "\f57b";
}
.fa-martini-glass-citrus:before {
  content: "\f561";
}
.fa-cocktail:before {
  content: "\f561";
}
.fa-martini-glass-empty:before {
  content: "\f000";
}
.fa-glass-martini:before {
  content: "\f000";
}
.fa-mask:before {
  content: "\f6fa";
}
.fa-masks-theater:before {
  content: "\f630";
}
.fa-theater-masks:before {
  content: "\f630";
}
.fa-mastodon:before {
  content: "\f4f6";
}
.fa-maxcdn:before {
  content: "\f136";
}
.fa-maximize:before {
  content: "\f31e";
}
.fa-expand-arrows-alt:before {
  content: "\f31e";
}
.fa-mdb:before {
  content: "\f8ca";
}
.fa-meat:before {
  content: "\f814";
}
.fa-medal:before {
  content: "\f5a2";
}
.fa-medapps:before {
  content: "\f3c6";
}
.fa-medium:before {
  content: "\f23a";
}
.fa-medium-m:before {
  content: "\f3c7";
}
.fa-medrt:before {
  content: "\f3c8";
}
.fa-meetup:before {
  content: "\f2e0";
}
.fa-megaphone:before {
  content: "\f675";
}
.fa-megaport:before {
  content: "\f5a3";
}
.fa-memory:before {
  content: "\f538";
}
.fa-mendeley:before {
  content: "\f7b3";
}
.fa-menorah:before {
  content: "\f676";
}
.fa-mercury:before {
  content: "\f223";
}
.fa-message:before {
  content: "\f27a";
}
.fa-comment-alt:before {
  content: "\f27a";
}
.fa-message-arrow-down:before {
  content: "\e164";
}
.fa-comment-alt-arrow-down:before {
  content: "\e164";
}
.fa-message-arrow-up:before {
  content: "\e165";
}
.fa-comment-alt-arrow-up:before {
  content: "\e165";
}
.fa-message-captions:before {
  content: "\e166";
}
.fa-comment-alt-captions:before {
  content: "\e166";
}
.fa-message-check:before {
  content: "\f4a2";
}
.fa-comment-alt-check:before {
  content: "\f4a2";
}
.fa-message-dollar:before {
  content: "\f650";
}
.fa-comment-alt-dollar:before {
  content: "\f650";
}
.fa-message-dots:before {
  content: "\f4a3";
}
.fa-comment-alt-dots:before {
  content: "\f4a3";
}
.fa-messaging:before {
  content: "\f4a3";
}
.fa-message-exclamation:before {
  content: "\f4a5";
}
.fa-comment-alt-exclamation:before {
  content: "\f4a5";
}
.fa-message-image:before {
  content: "\e167";
}
.fa-comment-alt-image:before {
  content: "\e167";
}
.fa-message-lines:before {
  content: "\f4a6";
}
.fa-comment-alt-lines:before {
  content: "\f4a6";
}
.fa-message-medical:before {
  content: "\f7f4";
}
.fa-comment-alt-medical:before {
  content: "\f7f4";
}
.fa-message-middle:before {
  content: "\e168";
}
.fa-comment-middle-alt:before {
  content: "\e168";
}
.fa-message-middle-top:before {
  content: "\e169";
}
.fa-comment-middle-top-alt:before {
  content: "\e169";
}
.fa-message-minus:before {
  content: "\f4a7";
}
.fa-comment-alt-minus:before {
  content: "\f4a7";
}
.fa-message-music:before {
  content: "\f8af";
}
.fa-comment-alt-music:before {
  content: "\f8af";
}
.fa-message-pen:before {
  content: "\f4a4";
}
.fa-message-edit:before {
  content: "\f4a4";
}
.fa-comment-alt-edit:before {
  content: "\f4a4";
}
.fa-message-plus:before {
  content: "\f4a8";
}
.fa-comment-alt-plus:before {
  content: "\f4a8";
}
.fa-message-qoute:before {
  content: "\e16a";
}
.fa-comment-alt-quote:before {
  content: "\e16a";
}
.fa-message-slash:before {
  content: "\f4a9";
}
.fa-comment-alt-slash:before {
  content: "\f4a9";
}
.fa-message-smile:before {
  content: "\f4aa";
}
.fa-comment-alt-smile:before {
  content: "\f4aa";
}
.fa-message-text:before {
  content: "\e16b";
}
.fa-comment-alt-text:before {
  content: "\e16b";
}
.fa-message-xmark:before {
  content: "\f4ab";
}
.fa-comment-alt-times:before {
  content: "\f4ab";
}
.fa-message-times:before {
  content: "\f4ab";
}
.fa-messages:before {
  content: "\f4b6";
}
.fa-comments-alt:before {
  content: "\f4b6";
}
.fa-messages-dollar:before {
  content: "\f652";
}
.fa-comments-alt-dollar:before {
  content: "\f652";
}
.fa-meteor:before {
  content: "\f753";
}
.fa-microblog:before {
  content: "\e01a";
}
.fa-microchip:before {
  content: "\f2db";
}
.fa-microphone:before {
  content: "\f130";
}
.fa-microphone-lines:before {
  content: "\f3c9";
}
.fa-microphone-alt:before {
  content: "\f3c9";
}
.fa-microphone-lines-slash:before {
  content: "\f539";
}
.fa-microphone-alt-slash:before {
  content: "\f539";
}
.fa-microphone-slash:before {
  content: "\f131";
}
.fa-microphone-stand:before {
  content: "\f8cb";
}
.fa-microscope:before {
  content: "\f610";
}
.fa-microsoft:before {
  content: "\f3ca";
}
.fa-microwave:before {
  content: "\e01b";
}
.fa-mill-sign:before {
  content: "\e16c";
}
.fa-minimize:before {
  content: "\f78c";
}
.fa-compress-arrows-alt:before {
  content: "\f78c";
}
.fa-minus:before {
  content: "\f068";
}
.fa-subtract:before {
  content: "\f068";
}
.fa-mistletoe:before {
  content: "\f7b4";
}
.fa-mitten:before {
  content: "\f7b5";
}
.fa-mix:before {
  content: "\f3cb";
}
.fa-mixcloud:before {
  content: "\f289";
}
.fa-mixer:before {
  content: "\e056";
}
.fa-mizuni:before {
  content: "\f3cc";
}
.fa-mobile:before {
  content: "\f3ce";
}
.fa-mobile-android:before {
  content: "\f3ce";
}
.fa-mobile-phone:before {
  content: "\f3ce";
}
.fa-mobile-button:before {
  content: "\f10b";
}
.fa-mobile-notch:before {
  content: "\e16d";
}
.fa-mobile-iphone:before {
  content: "\e16d";
}
.fa-mobile-screen:before {
  content: "\f3cf";
}
.fa-mobile-android-alt:before {
  content: "\f3cf";
}
.fa-mobile-screen-button:before {
  content: "\f3cd";
}
.fa-mobile-alt:before {
  content: "\f3cd";
}
.fa-modx:before {
  content: "\f285";
}
.fa-monero:before {
  content: "\f3d0";
}
.fa-money-bill:before {
  content: "\f0d6";
}
.fa-money-bill-1:before {
  content: "\f3d1";
}
.fa-money-bill-alt:before {
  content: "\f3d1";
}
.fa-money-bill-1-wave:before {
  content: "\f53b";
}
.fa-money-bill-wave-alt:before {
  content: "\f53b";
}
.fa-money-bill-simple:before {
  content: "\e16e";
}
.fa-money-bill-simple-wave:before {
  content: "\e16f";
}
.fa-money-bill-wave:before {
  content: "\f53a";
}
.fa-money-bills:before {
  content: "\e170";
}
.fa-money-bills-simple:before {
  content: "\e171";
}
.fa-money-bills-alt:before {
  content: "\e171";
}
.fa-money-check:before {
  content: "\f53c";
}
.fa-money-check-dollar:before {
  content: "\f53d";
}
.fa-money-check-alt:before {
  content: "\f53d";
}
.fa-money-check-dollar-pen:before {
  content: "\f873";
}
.fa-money-check-edit-alt:before {
  content: "\f873";
}
.fa-money-check-pen:before {
  content: "\f872";
}
.fa-money-check-edit:before {
  content: "\f872";
}
.fa-monitor-waveform:before {
  content: "\f611";
}
.fa-monitor-heart-rate:before {
  content: "\f611";
}
.fa-monkey:before {
  content: "\f6fb";
}
.fa-monument:before {
  content: "\f5a6";
}
.fa-moon:before {
  content: "\f186";
}
.fa-moon-cloud:before {
  content: "\f754";
}
.fa-moon-over-sun:before {
  content: "\f74a";
}
.fa-eclipse-alt:before {
  content: "\f74a";
}
.fa-moon-stars:before {
  content: "\f755";
}
.fa-mortar-pestle:before {
  content: "\f5a7";
}
.fa-mosque:before {
  content: "\f678";
}
.fa-motorcycle:before {
  content: "\f21c";
}
.fa-mountain:before {
  content: "\f6fc";
}
.fa-mountains:before {
  content: "\f6fd";
}
.fa-mp3-player:before {
  content: "\f8ce";
}
.fa-mug:before {
  content: "\f874";
}
.fa-mug-hot:before {
  content: "\f7b6";
}
.fa-mug-marshmallows:before {
  content: "\f7b7";
}
.fa-mug-saucer:before {
  content: "\f0f4";
}
.fa-coffee:before {
  content: "\f0f4";
}
.fa-mug-tea:before {
  content: "\f875";
}
.fa-mug-tea-saucer:before {
  content: "\e172";
}
.fa-music:before {
  content: "\f001";
}
.fa-music-note:before {
  content: "\f8cf";
}
.fa-music-alt:before {
  content: "\f8cf";
}
.fa-music-note-slash:before {
  content: "\f8d0";
}
.fa-music-alt-slash:before {
  content: "\f8d0";
}
.fa-music-slash:before {
  content: "\f8d1";
}
.fa-n:before {
  content: "\e173";
}
.fa-naira-sign:before {
  content: "\e174";
}
.fa-napster:before {
  content: "\f3d2";
}
.fa-narwhal:before {
  content: "\f6fe";
}
.fa-neos:before {
  content: "\f612";
}
.fa-network-wired:before {
  content: "\f6ff";
}
.fa-neuter:before {
  content: "\f22c";
}
.fa-newspaper:before {
  content: "\f1ea";
}
.fa-nimblr:before {
  content: "\f5a8";
}
.fa-node:before {
  content: "\f419";
}
.fa-node-js:before {
  content: "\f3d3";
}
.fa-not-equal:before {
  content: "\f53e";
}
.fa-notdef:before {
  content: "\e175";
}
.fa-note:before {
  content: "\e176";
}
.fa-note-medical:before {
  content: "\e177";
}
.fa-note-sticky:before {
  content: "\f249";
}
.fa-sticky-note:before {
  content: "\f249";
}
.fa-notes:before {
  content: "\e178";
}
.fa-notes-medical:before {
  content: "\f481";
}
.fa-npm:before {
  content: "\f3d4";
}
.fa-ns8:before {
  content: "\f3d5";
}
.fa-nutritionix:before {
  content: "\f3d6";
}
.fa-o:before {
  content: "\e179";
}
.fa-object-group:before {
  content: "\f247";
}
.fa-object-ungroup:before {
  content: "\f248";
}
.fa-octagon:before {
  content: "\f306";
}
.fa-octagon-minus:before {
  content: "\f308";
}
.fa-minus-octagon:before {
  content: "\f308";
}
.fa-octagon-plus:before {
  content: "\f301";
}
.fa-plus-octagon:before {
  content: "\f301";
}
.fa-octagon-xmark:before {
  content: "\f2f0";
}
.fa-times-octagon:before {
  content: "\f2f0";
}
.fa-xmark-octagon:before {
  content: "\f2f0";
}
.fa-octopus-deploy:before {
  content: "\e082";
}
.fa-odnoklassniki:before {
  content: "\f263";
}
.fa-odnoklassniki-square:before {
  content: "\f264";
}
.fa-oil-can:before {
  content: "\f613";
}
.fa-oil-can-drip:before {
  content: "\e17a";
}
.fa-oil-temperature:before {
  content: "\f614";
}
.fa-oil-temp:before {
  content: "\f614";
}
.fa-old-republic:before {
  content: "\f510";
}
.fa-om:before {
  content: "\f679";
}
.fa-omega:before {
  content: "\f67a";
}
.fa-opencart:before {
  content: "\f23d";
}
.fa-openid:before {
  content: "\f19b";
}
.fa-opera:before {
  content: "\f26a";
}
.fa-optin-monster:before {
  content: "\f23c";
}
.fa-option:before {
  content: "\e17b";
}
.fa-orcid:before {
  content: "\f8d2";
}
.fa-ornament:before {
  content: "\f7b8";
}
.fa-osi:before {
  content: "\f41a";
}
.fa-otter:before {
  content: "\f700";
}
.fa-outdent:before {
  content: "\f03b";
}
.fa-dedent:before {
  content: "\f03b";
}
.fa-outlet:before {
  content: "\e01c";
}
.fa-oven:before {
  content: "\e01d";
}
.fa-overline:before {
  content: "\f876";
}
.fa-p:before {
  content: "\e17c";
}
.fa-page4:before {
  content: "\f3d7";
}
.fa-pagelines:before {
  content: "\f18c";
}
.fa-pager:before {
  content: "\f815";
}
.fa-paint-brush:before {
  content: "\f1fc";
}
.fa-paint-brush-fine:before {
  content: "\f5a9";
}
.fa-paint-brush-alt:before {
  content: "\f5a9";
}
.fa-paint-roller:before {
  content: "\f5aa";
}
.fa-paintbrush-pencil:before {
  content: "\e17d";
}
.fa-palette:before {
  content: "\f53f";
}
.fa-palette-boxes:before {
  content: "\f483";
}
.fa-pallet-alt:before {
  content: "\f483";
}
.fa-palfed:before {
  content: "\f3d8";
}
.fa-pallet:before {
  content: "\f482";
}
.fa-pallet-box:before {
  content: "\e17e";
}
.fa-panorama:before {
  content: "\e17f";
}
.fa-paper-plane:before {
  content: "\f1d8";
}
.fa-paper-plane-top:before {
  content: "\e180";
}
.fa-paper-plane-alt:before {
  content: "\e180";
}
.fa-send:before {
  content: "\e180";
}
.fa-paperclip:before {
  content: "\f0c6";
}
.fa-parachute-box:before {
  content: "\f4cd";
}
.fa-paragraph:before {
  content: "\f1dd";
}
.fa-paragraph-left:before {
  content: "\f878";
}
.fa-paragraph-rtl:before {
  content: "\f878";
}
.fa-passport:before {
  content: "\f5ab";
}
.fa-paste:before {
  content: "\f0ea";
}
.fa-file-clipboard:before {
  content: "\f0ea";
}
.fa-patreon:before {
  content: "\f3d9";
}
.fa-pause:before {
  content: "\f04c";
}
.fa-paw:before {
  content: "\f1b0";
}
.fa-paw-claws:before {
  content: "\f702";
}
.fa-paw-simple:before {
  content: "\f701";
}
.fa-paw-alt:before {
  content: "\f701";
}
.fa-paypal:before {
  content: "\f1ed";
}
.fa-peace:before {
  content: "\f67c";
}
.fa-pegasus:before {
  content: "\f703";
}
.fa-pen:before {
  content: "\f304";
}
.fa-pen-circle:before {
  content: "\e181";
}
.fa-pen-clip:before {
  content: "\f305";
}
.fa-pen-alt:before {
  content: "\f305";
}
.fa-pen-clip-slash:before {
  content: "\e182";
}
.fa-pen-alt-slash:before {
  content: "\e182";
}
.fa-pen-fancy:before {
  content: "\f5ac";
}
.fa-pen-fancy-slash:before {
  content: "\e183";
}
.fa-pen-field:before {
  content: "\e184";
}
.fa-pen-nib:before {
  content: "\f5ad";
}
.fa-pen-paintbrush:before {
  content: "\f618";
}
.fa-pencil-paintbrush:before {
  content: "\f618";
}
.fa-pen-ruler:before {
  content: "\f5ae";
}
.fa-pencil-ruler:before {
  content: "\f5ae";
}
.fa-pen-slash:before {
  content: "\e185";
}
.fa-pen-swirl:before {
  content: "\e186";
}
.fa-pen-to-square:before {
  content: "\f044";
}
.fa-edit:before {
  content: "\f044";
}
.fa-pencil:before {
  content: "\f040";
}
.fa-pencil-alt:before {
  content: "\f040";
}
.fa-pencil-slash:before {
  content: "\e187";
}
.fa-penny-arcade:before {
  content: "\f704";
}
.fa-people-arrows-left-right:before {
  content: "\e068";
}
.fa-people-arrows:before {
  content: "\e068";
}
.fa-people-carry-box:before {
  content: "\f4ce";
}
.fa-people-carry:before {
  content: "\f4ce";
}
.fa-pepper-hot:before {
  content: "\f816";
}
.fa-perbyte:before {
  content: "\e083";
}
.fa-percent:before {
  content: "\f541";
}
.fa-percentage:before {
  content: "\f541";
}
.fa-percent:before {
  content: "\f541";
}
.fa-periscope:before {
  content: "\f3da";
}
.fa-person:before {
  content: "\f183";
}
.fa-male:before {
  content: "\f183";
}
.fa-person-biking:before {
  content: "\f84a";
}
.fa-biking:before {
  content: "\f84a";
}
.fa-person-biking-mountain:before {
  content: "\f84b";
}
.fa-biking-mountain:before {
  content: "\f84b";
}
.fa-person-booth:before {
  content: "\f756";
}
.fa-person-carry-box:before {
  content: "\f4cf";
}
.fa-person-carry:before {
  content: "\f4cf";
}
.fa-person-digging:before {
  content: "\f85e";
}
.fa-digging:before {
  content: "\f85e";
}
.fa-person-dolly:before {
  content: "\f4d0";
}
.fa-person-dolly-empty:before {
  content: "\f4d1";
}
.fa-person-dots-from-line:before {
  content: "\f470";
}
.fa-diagnoses:before {
  content: "\f470";
}
.fa-person-dress:before {
  content: "\f182";
}
.fa-female:before {
  content: "\f182";
}
.fa-person-from-portal:before {
  content: "\e023";
}
.fa-portal-exit:before {
  content: "\e023";
}
.fa-person-hiking:before {
  content: "\f6ec";
}
.fa-hiking:before {
  content: "\f6ec";
}
.fa-person-praying:before {
  content: "\f683";
}
.fa-pray:before {
  content: "\f683";
}
.fa-person-running:before {
  content: "\f70c";
}
.fa-running:before {
  content: "\f70c";
}
.fa-person-seat:before {
  content: "\e188";
}
.fa-person-seat-reclined:before {
  content: "\e189";
}
.fa-person-sign:before {
  content: "\f757";
}
.fa-person-skating:before {
  content: "\f7c5";
}
.fa-skating:before {
  content: "\f7c5";
}
.fa-person-ski-jumping:before {
  content: "\f7c7";
}
.fa-ski-jump:before {
  content: "\f7c7";
}
.fa-person-ski-lift:before {
  content: "\f7c8";
}
.fa-ski-lift:before {
  content: "\f7c8";
}
.fa-person-skiing:before {
  content: "\f7c9";
}
.fa-skiing:before {
  content: "\f7c9";
}
.fa-person-skiing-nordic:before {
  content: "\f7ca";
}
.fa-skiing-nordic:before {
  content: "\f7ca";
}
.fa-person-sledding:before {
  content: "\f7cb";
}
.fa-sledding:before {
  content: "\f7cb";
}
.fa-person-snowboarding:before {
  content: "\f7ce";
}
.fa-snowboarding:before {
  content: "\f7ce";
}
.fa-person-snowmobiling:before {
  content: "\f7d1";
}
.fa-snowmobile:before {
  content: "\f7d1";
}
.fa-person-swimming:before {
  content: "\f5c4";
}
.fa-swimmer:before {
  content: "\f5c4";
}
.fa-person-to-portal:before {
  content: "\e022";
}
.fa-portal-enter:before {
  content: "\e022";
}
.fa-person-walking:before {
  content: "\f554";
}
.fa-walking:before {
  content: "\f554";
}
.fa-person-walking-with-cane:before {
  content: "\f29d";
}
.fa-blind:before {
  content: "\f29d";
}
.fa-peseta-sign:before {
  content: "\e18a";
}
.fa-peso-sign:before {
  content: "\e18b";
}
.fa-phabricator:before {
  content: "\f3db";
}
.fa-phoenix-framework:before {
  content: "\f3dc";
}
.fa-phoenix-squadron:before {
  content: "\f511";
}
.fa-phone:before {
  content: "\f095";
}
.fa-phone-arrow-down-left:before {
  content: "\e18c";
}
.fa-phone-incoming:before {
  content: "\e18c";
}
.fa-phone-arrow-down:before {
  content: "\e18c";
}
.fa-phone-arrow-up-right:before {
  content: "\e18d";
}
.fa-phone-outgoing:before {
  content: "\e18d";
}
.fa-phone-arrow-up:before {
  content: "\e18d";
}
.fa-phone-flip:before {
  content: "\f879";
}
.fa-phone-alt:before {
  content: "\f879";
}
.fa-phone-hangup:before {
  content: "\e18e";
}
.fa-phone-hangup:before {
  content: "\e18e";
}
.fa-phone-missed:before {
  content: "\e18f";
}
.fa-phone-office:before {
  content: "\f67d";
}
.fa-phone-plus:before {
  content: "\f4d2";
}
.fa-phone-rotary:before {
  content: "\f8d3";
}
.fa-phone-slash:before {
  content: "\f3dd";
}
.fa-phone-volume:before {
  content: "\f2a0";
}
.fa-volume-control-phone:before {
  content: "\f2a0";
}
.fa-photo-film:before {
  content: "\f87c";
}
.fa-photo-film:before {
  content: "\f87c";
}
.fa-photo-film-music:before {
  content: "\e190";
}
.fa-photo-film-music:before {
  content: "\e190";
}
.fa-php:before {
  content: "\f457";
}
.fa-pi:before {
  content: "\f67e";
}
.fa-piano:before {
  content: "\f8d4";
}
.fa-piano-keyboard:before {
  content: "\f8d5";
}
.fa-pie:before {
  content: "\f705";
}
.fa-pied-piper:before {
  content: "\f2ae";
}
.fa-pied-piper-alt:before {
  content: "\f1a8";
}
.fa-pied-piper-hat:before {
  content: "\f4e5";
}
.fa-pied-piper-pp:before {
  content: "\f1a7";
}
.fa-pied-piper-square:before {
  content: "\e01e";
}
.fa-pig:before {
  content: "\f706";
}
.fa-piggy-bank:before {
  content: "\f4d3";
}
.fa-pills:before {
  content: "\f484";
}
.fa-pinball:before {
  content: "\e191";
}
.fa-pinterest:before {
  content: "\f0d2";
}
.fa-pinterest-p:before {
  content: "\f231";
}
.fa-pinterest-square:before {
  content: "\f0d3";
}
.fa-pizza:before {
  content: "\f817";
}
.fa-pizza-slice:before {
  content: "\f818";
}
.fa-place-of-worship:before {
  content: "\f67f";
}
.fa-plane:before {
  content: "\f072";
}
.fa-plane-arrival:before {
  content: "\f5af";
}
.fa-plane-departure:before {
  content: "\f5b0";
}
.fa-plane-engines:before {
  content: "\f3de";
}
.fa-plane-engines:before {
  content: "\f3de";
}
.fa-plane-prop:before {
  content: "\e192";
}
.fa-plane-slash:before {
  content: "\e069";
}
.fa-plane-tail:before {
  content: "\e193";
}
.fa-plane-up:before {
  content: "\e194";
}
.fa-plane-up-slash:before {
  content: "\e195";
}
.fa-planet-moon:before {
  content: "\e01f";
}
.fa-planet-ringed:before {
  content: "\e020";
}
.fa-play:before {
  content: "\f04b";
}
.fa-playstation:before {
  content: "\f3df";
}
.fa-plug:before {
  content: "\f1e6";
}
.fa-plus:before {
  content: "\f067";
}
.fa-add:before {
  content: "\f067";
}
.fa-podcast:before {
  content: "\f2ce";
}
.fa-podium:before {
  content: "\f680";
}
.fa-podium-star:before {
  content: "\f758";
}
.fa-police-box:before {
  content: "\e021";
}
.fa-poll-people:before {
  content: "\f759";
}
.fa-poo:before {
  content: "\f2fe";
}
.fa-poo-bolt:before {
  content: "\f75a";
}
.fa-poo-storm:before {
  content: "\f75a";
}
.fa-poop:before {
  content: "\f619";
}
.fa-popcorn:before {
  content: "\f819";
}
.fa-power-off:before {
  content: "\f011";
}
.fa-prescription:before {
  content: "\f5b1";
}
.fa-prescription-bottle:before {
  content: "\f485";
}
.fa-prescription-bottle-medical:before {
  content: "\f486";
}
.fa-prescription-bottle-medical:before {
  content: "\f486";
}
.fa-presentation-screen:before {
  content: "\f685";
}
.fa-presentation-screen:before {
  content: "\f685";
}
.fa-print:before {
  content: "\f02f";
}
.fa-print-magnifying-glass:before {
  content: "\f81a";
}
.fa-print-search:before {
  content: "\f81a";
}
.fa-print-slash:before {
  content: "\f686";
}
.fa-product-hunt:before {
  content: "\f288";
}
.fa-project-diagram:before {
  content: "\f542";
}
.fa-projector:before {
  content: "\f8d6";
}
.fa-pump-medical:before {
  content: "\e06a";
}
.fa-pump-soap:before {
  content: "\e06b";
}
.fa-pumpkin:before {
  content: "\f707";
}
.fa-pushed:before {
  content: "\f3e1";
}
.fa-puzzle-piece:before {
  content: "\f12e";
}
.fa-puzzle-piece-simple:before {
  content: "\e196";
}
.fa-puzzle-piece-alt:before {
  content: "\e196";
}
.fa-python:before {
  content: "\f3e2";
}
.fa-q:before {
  content: "\e197";
}
.fa-qq:before {
  content: "\f1d6";
}
.fa-qrcode:before {
  content: "\f029";
}
.fa-question:before {
  content: "\f128";
}
.fa-quidditch-broom-ball:before {
  content: "\f458";
}
.fa-broom-ball:before {
  content: "\f458";
}
.fa-quidditch:before {
  content: "\f458";
}
.fa-quinscape:before {
  content: "\f459";
}
.fa-quora:before {
  content: "\f2c4";
}
.fa-quote-left:before {
  content: "\f10d";
}
.fa-quote-left-round:before {
  content: "\e198";
}
.fa-quote-left-alt:before {
  content: "\e198";
}
.fa-quote-right:before {
  content: "\f10e";
}
.fa-quote-right-round:before {
  content: "\e199";
}
.fa-quote-right-alt:before {
  content: "\e199";
}
.fa-r:before {
  content: "\e19a";
}
.fa-r-project:before {
  content: "\f4f7";
}
.fa-rabbit:before {
  content: "\f708";
}
.fa-rabbit-running:before {
  content: "\f709";
}
.fa-rabbit-fast:before {
  content: "\f709";
}
.fa-racquet:before {
  content: "\f45a";
}
.fa-radar:before {
  content: "\e024";
}
.fa-radiation:before {
  content: "\f7b9";
}
.fa-radio:before {
  content: "\f8d7";
}
.fa-radio-tuner:before {
  content: "\f8d8";
}
.fa-radio-alt:before {
  content: "\f8d8";
}
.fa-rainbow:before {
  content: "\f75b";
}
.fa-raindrops:before {
  content: "\f75c";
}
.fa-ram:before {
  content: "\f70a";
}
.fa-ramp-loading:before {
  content: "\f4d4";
}
.fa-raspberry-pi:before {
  content: "\f7bb";
}
.fa-ravelry:before {
  content: "\f2d9";
}
.fa-raygun:before {
  content: "\e025";
}
.fa-react:before {
  content: "\f41b";
}
.fa-reacteurope:before {
  content: "\f75d";
}
.fa-readme:before {
  content: "\f4d5";
}
.fa-rebel:before {
  content: "\f1d0";
}
.fa-receipt:before {
  content: "\f543";
}
.fa-record-vinyl:before {
  content: "\f8d9";
}
.fa-rectangle:before {
  content: "\f2fa";
}
.fa-rectangle-landscape:before {
  content: "\f2fa";
}
.fa-rectangle-ad:before {
  content: "\f641";
}
.fa-ad:before {
  content: "\f641";
}
.fa-rectangle-audio-description:before {
  content: "\f29e";
}
.fa-audio-description:before {
  content: "\f29e";
}
.fa-rectangle-barcode:before {
  content: "\f463";
}
.fa-barcode-alt:before {
  content: "\f463";
}
.fa-rectangle-list:before {
  content: "\f022";
}
.fa-list-alt:before {
  content: "\f022";
}
.fa-rectangle-vertical:before {
  content: "\f2fb";
}
.fa-rectangle-portrait:before {
  content: "\f2fb";
}
.fa-rectangle-wide:before {
  content: "\f2fc";
}
.fa-rectangle-xmark:before {
  content: "\f410";
}
.fa-times-rectangle:before {
  content: "\f410";
}
.fa-rectangle-times:before {
  content: "\f410";
}
.fa-window-close:before {
  content: "\f410";
}
.fa-recycle:before {
  content: "\f1b8";
}
.fa-red-river:before {
  content: "\f3e3";
}
.fa-reddit:before {
  content: "\f1a1";
}
.fa-reddit-alien:before {
  content: "\f281";
}
.fa-reddit-square:before {
  content: "\f1a2";
}
.fa-redhat:before {
  content: "\f7bc";
}
.fa-refrigerator:before {
  content: "\e026";
}
.fa-registered:before {
  content: "\f25d";
}
.fa-renren:before {
  content: "\f18b";
}
.fa-repeat:before {
  content: "\f363";
}
.fa-repeat-1:before {
  content: "\f365";
}
.fa-reply:before {
  content: "\f3e5";
}
.fa-mail-reply:before {
  content: "\f3e5";
}
.fa-reply-all:before {
  content: "\f122";
}
.fa-mail-reply-all:before {
  content: "\f122";
}
.fa-reply-clock:before {
  content: "\e19b";
}
.fa-reply-time:before {
  content: "\e19b";
}
.fa-replyd:before {
  content: "\f3e6";
}
.fa-republican:before {
  content: "\f75e";
}
.fa-researchgate:before {
  content: "\f4f8";
}
.fa-resolving:before {
  content: "\f3e7";
}
.fa-restroom:before {
  content: "\f7bd";
}
.fa-retweet:before {
  content: "\f079";
}
.fa-rev:before {
  content: "\f5b2";
}
.fa-rhombus:before {
  content: "\e19c";
}
.fa-ribbon:before {
  content: "\f4d6";
}
.fa-right:before {
  content: "\f356";
}
.fa-arrow-alt-right:before {
  content: "\f356";
}
.fa-right-from-bracket:before {
  content: "\f2f5";
}
.fa-sign-out-alt:before {
  content: "\f2f5";
}
.fa-right-from-line:before {
  content: "\f347";
}
.fa-arrow-alt-from-left:before {
  content: "\f347";
}
.fa-right-left:before {
  content: "\f362";
}
.fa-exchange-alt:before {
  content: "\f362";
}
.fa-right-long:before {
  content: "\f30b";
}
.fa-long-arrow-alt-right:before {
  content: "\f30b";
}
.fa-right-to-bracket:before {
  content: "\f2f6";
}
.fa-sign-in-alt:before {
  content: "\f2f6";
}
.fa-right-to-line:before {
  content: "\f34c";
}
.fa-arrow-alt-to-right:before {
  content: "\f34c";
}
.fa-ring:before {
  content: "\f70b";
}
.fa-rings-wedding:before {
  content: "\f81b";
}
.fa-road:before {
  content: "\f018";
}
.fa-robot:before {
  content: "\f544";
}
.fa-rocket:before {
  content: "\f135";
}
.fa-rocket-launch:before {
  content: "\e027";
}
.fa-rocketchat:before {
  content: "\f3e8";
}
.fa-rockrms:before {
  content: "\f3e9";
}
.fa-rotate:before {
  content: "\f2f1";
}
.fa-sync-alt:before {
  content: "\f2f1";
}
.fa-rotate-forward:before {
  content: "\f2f9";
}
.fa-redo-alt:before {
  content: "\f2f9";
}
.fa-rotate-left:before {
  content: "\f2ea";
}
.fa-undo-alt:before {
  content: "\f2ea";
}
.fa-rotate-left:before {
  content: "\f2ea";
}
.fa-route:before {
  content: "\f4d7";
}
.fa-route-highway:before {
  content: "\f61a";
}
.fa-route-interstate:before {
  content: "\f61b";
}
.fa-router:before {
  content: "\f8da";
}
.fa-rss:before {
  content: "\f09e";
}
.fa-feed:before {
  content: "\f09e";
}
.fa-ruble-sign:before {
  content: "\f158";
}
.fa-rouble:before {
  content: "\f158";
}
.fa-rub:before {
  content: "\f158";
}
.fa-ruble:before {
  content: "\f158";
}
.fa-ruler:before {
  content: "\f545";
}
.fa-ruler-combined:before {
  content: "\f546";
}
.fa-ruler-horizontal:before {
  content: "\f547";
}
.fa-ruler-triangle:before {
  content: "\f61c";
}
.fa-ruler-vertical:before {
  content: "\f548";
}
.fa-rupee-sign:before {
  content: "\f156";
}
.fa-rupee:before {
  content: "\f156";
}
.fa-rupiah-sign:before {
  content: "\e19d";
}
.fa-rust:before {
  content: "\e07a";
}
.fa-rv:before {
  content: "\f7be";
}
.fa-s:before {
  content: "\e19e";
}
.fa-sack:before {
  content: "\f81c";
}
.fa-sack-dollar:before {
  content: "\f81d";
}
.fa-safari:before {
  content: "\f267";
}
.fa-salad:before {
  content: "\f81e";
}
.fa-bowl-salad:before {
  content: "\f81e";
}
.fa-salesforce:before {
  content: "\f83b";
}
.fa-sandwich:before {
  content: "\f81f";
}
.fa-sass:before {
  content: "\f41e";
}
.fa-satellite:before {
  content: "\f7bf";
}
.fa-satellite-dish:before {
  content: "\f7c0";
}
.fa-sausage:before {
  content: "\f820";
}
.fa-save:before {
  content: "\f0c7";
}
.fa-disk-floppy:before {
  content: "\f0c7";
}
.fa-saxophone:before {
  content: "\f8dc";
}
.fa-saxophone-fire:before {
  content: "\f8db";
}
.fa-sax-hot:before {
  content: "\f8db";
}
.fa-scale-balanced:before {
  content: "\f24e";
}
.fa-balance-scale:before {
  content: "\f24e";
}
.fa-scale-unbalanced:before {
  content: "\f515";
}
.fa-balance-scale-left:before {
  content: "\f515";
}
.fa-scale-unbalanced-flip:before {
  content: "\f516";
}
.fa-balance-scale-right:before {
  content: "\f516";
}
.fa-scalpel:before {
  content: "\f61d";
}
.fa-scalpel-line-dashed:before {
  content: "\f61e";
}
.fa-scalpel-path:before {
  content: "\f61e";
}
.fa-scanner:before {
  content: "\f8f3";
}
.fa-scanner-image:before {
  content: "\f8f3";
}
.fa-scanner-gun:before {
  content: "\f488";
}
.fa-scanner-keyboard:before {
  content: "\f489";
}
.fa-scanner-touchscreen:before {
  content: "\f48a";
}
.fa-scarecrow:before {
  content: "\f70d";
}
.fa-scarf:before {
  content: "\f7c1";
}
.fa-schlix:before {
  content: "\f3ea";
}
.fa-school:before {
  content: "\f549";
}
.fa-scissors:before {
  content: "\f0c4";
}
.fa-cut:before {
  content: "\f0c4";
}
.fa-screen-users:before {
  content: "\f63d";
}
.fa-users-class:before {
  content: "\f63d";
}
.fa-screwdriver:before {
  content: "\f54a";
}
.fa-screwdriver-wrench:before {
  content: "\f7d9";
}
.fa-tools:before {
  content: "\f7d9";
}
.fa-scribble:before {
  content: "\e19f";
}
.fa-scribd:before {
  content: "\f28a";
}
.fa-scroll:before {
  content: "\f70e";
}
.fa-scroll-old:before {
  content: "\f70f";
}
.fa-scroll-torah:before {
  content: "\f6a0";
}
.fa-torah:before {
  content: "\f6a0";
}
.fa-scrubber:before {
  content: "\f2f8";
}
.fa-scythe:before {
  content: "\f710";
}
.fa-sd-card:before {
  content: "\f7c2";
}
.fa-sd-cards:before {
  content: "\e1a0";
}
.fa-seal:before {
  content: "\e1a1";
}
.fa-searchengin:before {
  content: "\f3eb";
}
.fa-seat-airline:before {
  content: "\e1a2";
}
.fa-seedling:before {
  content: "\f4d8";
}
.fa-sprout:before {
  content: "\f4d8";
}
.fa-sellcast:before {
  content: "\f2da";
}
.fa-sellsy:before {
  content: "\f213";
}
.fa-send-back:before {
  content: "\f87e";
}
.fa-send-backward:before {
  content: "\f87f";
}
.fa-sensor:before {
  content: "\e028";
}
.fa-sensor-cloud:before {
  content: "\e02c";
}
.fa-sensor-smoke:before {
  content: "\e02c";
}
.fa-sensor-fire:before {
  content: "\e02a";
}
.fa-sensor-on:before {
  content: "\e02b";
}
.fa-sensor-triangle-exclamation:before {
  content: "\e029";
}
.fa-sensor-alert:before {
  content: "\e029";
}
.fa-server:before {
  content: "\f233";
}
.fa-servicestack:before {
  content: "\f3ec";
}
.fa-shapes:before {
  content: "\f61f";
}
.fa-triangle-circle-square:before {
  content: "\f61f";
}
.fa-share:before {
  content: "\f064";
}
.fa-arrow-turn-right:before {
  content: "\f064";
}
.fa-mail-forward:before {
  content: "\f064";
}
.fa-share-all:before {
  content: "\f367";
}
.fa-arrows-turn-right:before {
  content: "\f367";
}
.fa-share-from-square:before {
  content: "\f14d";
}
.fa-share-square:before {
  content: "\f14d";
}
.fa-share-nodes:before {
  content: "\f1e0";
}
.fa-share-alt:before {
  content: "\f1e0";
}
.fa-sheep:before {
  content: "\f711";
}
.fa-shekel-sign:before {
  content: "\f20b";
}
.fa-sheqel-sign:before {
  content: "\f20b";
}
.fa-shekel:before {
  content: "\f20b";
}
.fa-sheqel:before {
  content: "\f20b";
}
.fa-ils:before {
  content: "\f20b";
}
.fa-shelves:before {
  content: "\f480";
}
.fa-inventory:before {
  content: "\f480";
}
.fa-shelves-empty:before {
  content: "\e1a3";
}
.fa-shield:before {
  content: "\f132";
}
.fa-shield-blank:before {
  content: "\f3ed";
}
.fa-shield-alt:before {
  content: "\f3ed";
}
.fa-shield-check:before {
  content: "\f2f7";
}
.fa-shield-cross:before {
  content: "\f712";
}
.fa-shield-exclamation:before {
  content: "\e1a4";
}
.fa-shield-minus:before {
  content: "\e1a5";
}
.fa-shield-plus:before {
  content: "\e1a6";
}
.fa-shield-virus:before {
  content: "\e06c";
}
.fa-shield-xmark:before {
  content: "\e1a7";
}
.fa-shield-times:before {
  content: "\e1a7";
}
.fa-ship:before {
  content: "\f21a";
}
.fa-shirtsinbulk:before {
  content: "\f214";
}
.fa-shish-kebab:before {
  content: "\f821";
}
.fa-shoe-prints:before {
  content: "\f54b";
}
.fa-shop:before {
  content: "\f54f";
}
.fa-store-alt:before {
  content: "\f54f";
}
.fa-shop-slash:before {
  content: "\e070";
}
.fa-store-alt-slash:before {
  content: "\e070";
}
.fa-shopify:before {
  content: "\e057";
}
.fa-shopware:before {
  content: "\f5b5";
}
.fa-shovel:before {
  content: "\f713";
}
.fa-shovel-snow:before {
  content: "\f7c3";
}
.fa-shower:before {
  content: "\f2cc";
}
.fa-shower-down:before {
  content: "\e1a8";
}
.fa-shower-alt:before {
  content: "\e1a8";
}
.fa-shredder:before {
  content: "\f68a";
}
.fa-shuffle:before {
  content: "\f074";
}
.fa-random:before {
  content: "\f074";
}
.fa-shuttle-space:before {
  content: "\f197";
}
.fa-space-shuttle:before {
  content: "\f197";
}
.fa-shuttlecock:before {
  content: "\f45b";
}
.fa-sickle:before {
  content: "\f822";
}
.fa-sigma:before {
  content: "\f68b";
}
.fa-sign-hanging:before {
  content: "\f4d9";
}
.fa-sign:before {
  content: "\f4d9";
}
.fa-signal:before {
  content: "\f012";
}
.fa-signal-perfect:before {
  content: "\f012";
}
.fa-signal-5:before {
  content: "\f012";
}
.fa-signal-bars:before {
  content: "\f690";
}
.fa-signal-bars-strong:before {
  content: "\f690";
}
.fa-signal-alt:before {
  content: "\f690";
}
.fa-signal-alt-4:before {
  content: "\f690";
}
.fa-signal-bars-fair:before {
  content: "\f692";
}
.fa-signal-alt-2:before {
  content: "\f692";
}
.fa-signal-bars-good:before {
  content: "\f693";
}
.fa-signal-alt-3:before {
  content: "\f693";
}
.fa-signal-bars-slash:before {
  content: "\f694";
}
.fa-signal-alt-slash:before {
  content: "\f694";
}
.fa-signal-bars-weak:before {
  content: "\f691";
}
.fa-signal-alt-1:before {
  content: "\f691";
}
.fa-signal-fair:before {
  content: "\f68d";
}
.fa-signal-2:before {
  content: "\f68d";
}
.fa-signal-good:before {
  content: "\f68e";
}
.fa-signal-3:before {
  content: "\f68e";
}
.fa-signal-slash:before {
  content: "\f695";
}
.fa-signal-stream:before {
  content: "\f8dd";
}
.fa-signal-strong:before {
  content: "\f68f";
}
.fa-signal-4:before {
  content: "\f68f";
}
.fa-signal-weak:before {
  content: "\f68c";
}
.fa-signal-1:before {
  content: "\f68c";
}
.fa-signature:before {
  content: "\f5b7";
}
.fa-signs-post:before {
  content: "\f277";
}
.fa-map-signs:before {
  content: "\f277";
}
.fa-sim-card:before {
  content: "\f7c4";
}
.fa-sim-cards:before {
  content: "\e1a9";
}
.fa-simplybuilt:before {
  content: "\f215";
}
.fa-sink:before {
  content: "\e06d";
}
.fa-siren:before {
  content: "\e02d";
}
.fa-siren-on:before {
  content: "\e02e";
}
.fa-sistrix:before {
  content: "\f3ee";
}
.fa-sitemap:before {
  content: "\f0e8";
}
.fa-sith:before {
  content: "\f512";
}
.fa-skeleton:before {
  content: "\f620";
}
.fa-sketch:before {
  content: "\f7c6";
}
.fa-skull:before {
  content: "\f54c";
}
.fa-skull-cow:before {
  content: "\f8de";
}
.fa-skull-crossbones:before {
  content: "\f714";
}
.fa-skyatlas:before {
  content: "\f216";
}
.fa-skype:before {
  content: "\f17e";
}
.fa-slack:before {
  content: "\f198";
}
.fa-slack-hash:before {
  content: "\f3ef";
}
.fa-slash:before {
  content: "\f715";
}
.fa-sleigh:before {
  content: "\f7cc";
}
.fa-slider:before {
  content: "\e1aa";
}
.fa-sliders:before {
  content: "\f1de";
}
.fa-sliders-h:before {
  content: "\f1de";
}
.fa-sliders-up:before {
  content: "\f3f1";
}
.fa-sliders-v:before {
  content: "\f3f1";
}
.fa-slideshare:before {
  content: "\f1e7";
}
.fa-smog:before {
  content: "\f75f";
}
.fa-smoke:before {
  content: "\f760";
}
.fa-smoking:before {
  content: "\f48d";
}
.fa-snake:before {
  content: "\f716";
}
.fa-snapchat:before {
  content: "\f2ab";
}
.fa-snapchat-ghost:before {
  content: "\f2ac";
}
.fa-snapchat-square:before {
  content: "\f2ad";
}
.fa-snooze:before {
  content: "\f880";
}
.fa-zzz:before {
  content: "\f880";
}
.fa-snow-blowing:before {
  content: "\f761";
}
.fa-snowflake:before {
  content: "\f2dc";
}
.fa-snowflakes:before {
  content: "\f7cf";
}
.fa-snowman:before {
  content: "\f7d0";
}
.fa-snowman-head:before {
  content: "\f79b";
}
.fa-frosty-head:before {
  content: "\f79b";
}
.fa-snowplow:before {
  content: "\f7d2";
}
.fa-soap:before {
  content: "\e06e";
}
.fa-socks:before {
  content: "\f696";
}
.fa-solar-panel:before {
  content: "\f5ba";
}
.fa-solar-system:before {
  content: "\e02f";
}
.fa-sort:before {
  content: "\f0dc";
}
.fa-unsorted:before {
  content: "\f0dc";
}
.fa-sort-down:before {
  content: "\f0dd";
}
.fa-sort-desc:before {
  content: "\f0dd";
}
.fa-sort-up:before {
  content: "\f0de";
}
.fa-sort-asc:before {
  content: "\f0de";
}
.fa-soundcloud:before {
  content: "\f1be";
}
.fa-sourcetree:before {
  content: "\f7d3";
}
.fa-spa:before {
  content: "\f5bb";
}
.fa-space-station-moon:before {
  content: "\e033";
}
.fa-space-station-moon-construction:before {
  content: "\e034";
}
.fa-space-station-moon-alt:before {
  content: "\e034";
}
.fa-spade:before {
  content: "\f2f4";
}
.fa-spaghetti-monster-flying:before {
  content: "\f67b";
}
.fa-pastafarianism:before {
  content: "\f67b";
}
.fa-sparkles:before {
  content: "\f890";
}
.fa-speakap:before {
  content: "\f3f3";
}
.fa-speaker:before {
  content: "\f8df";
}
.fa-speaker-deck:before {
  content: "\f83c";
}
.fa-speakers:before {
  content: "\f8e0";
}
.fa-spell-check:before {
  content: "\f891";
}
.fa-spider:before {
  content: "\f717";
}
.fa-spider-black-widow:before {
  content: "\f718";
}
.fa-spider-web:before {
  content: "\f719";
}
.fa-spinner:before {
  content: "\f110";
}
.fa-spinner-third:before {
  content: "\f3f4";
}
.fa-splotch:before {
  content: "\f5bc";
}
.fa-spoon:before {
  content: "\f2e5";
}
.fa-utensil-spoon:before {
  content: "\f2e5";
}
.fa-spotify:before {
  content: "\f1bc";
}
.fa-spray-can:before {
  content: "\f5bd";
}
.fa-sprinkler:before {
  content: "\e035";
}
.fa-square:before {
  content: "\f0c8";
}
.fa-square-0:before {
  content: "\e1ab";
}
.fa-square-1:before {
  content: "\e1ac";
}
.fa-square-2:before {
  content: "\e1ad";
}
.fa-square-3:before {
  content: "\e1ae";
}
.fa-square-4:before {
  content: "\e1af";
}
.fa-square-5:before {
  content: "\e1b0";
}
.fa-square-6:before {
  content: "\e1b1";
}
.fa-square-7:before {
  content: "\e1b2";
}
.fa-square-8:before {
  content: "\e1b3";
}
.fa-square-9:before {
  content: "\e1b4";
}
.fa-square-a:before {
  content: "\e1b5";
}
.fa-square-ampersand:before {
  content: "\e1b6";
}
.fa-square-arrow-down:before {
  content: "\f339";
}
.fa-arrow-square-down:before {
  content: "\f339";
}
.fa-square-arrow-left:before {
  content: "\f33a";
}
.fa-arrow-square-left:before {
  content: "\f33a";
}
.fa-square-arrow-right:before {
  content: "\f33b";
}
.fa-arrow-square-right:before {
  content: "\f33b";
}
.fa-square-arrow-up:before {
  content: "\f33c";
}
.fa-arrow-square-up:before {
  content: "\f33c";
}
.fa-square-arrow-up-right:before {
  content: "\f14c";
}
.fa-external-link-square:before {
  content: "\f14c";
}
.fa-square-b:before {
  content: "\e1b7";
}
.fa-square-c:before {
  content: "\e1b8";
}
.fa-square-caret-down:before {
  content: "\f150";
}
.fa-caret-square-down:before {
  content: "\f150";
}
.fa-square-caret-left:before {
  content: "\f191";
}
.fa-caret-square-left:before {
  content: "\f191";
}
.fa-square-caret-right:before {
  content: "\f152";
}
.fa-caret-square-right:before {
  content: "\f152";
}
.fa-square-caret-up:before {
  content: "\f151";
}
.fa-caret-square-up:before {
  content: "\f151";
}
.fa-square-check:before {
  content: "\f14a";
}
.fa-check-square:before {
  content: "\f14a";
}
.fa-square-chevron-down:before {
  content: "\f329";
}
.fa-chevron-square-down:before {
  content: "\f329";
}
.fa-square-chevron-left:before {
  content: "\f32a";
}
.fa-chevron-square-left:before {
  content: "\f32a";
}
.fa-square-chevron-right:before {
  content: "\f32b";
}
.fa-chevron-square-right:before {
  content: "\f32b";
}
.fa-square-chevron-up:before {
  content: "\f32c";
}
.fa-chevron-square-up:before {
  content: "\f32c";
}
.fa-square-d:before {
  content: "\e1b9";
}
.fa-square-dashed:before {
  content: "\e1ba";
}
.fa-square-dollar:before {
  content: "\f2e9";
}
.fa-usd-square:before {
  content: "\f2e9";
}
.fa-dollar-square:before {
  content: "\f2e9";
}
.fa-square-down:before {
  content: "\f350";
}
.fa-arrow-alt-square-down:before {
  content: "\f350";
}
.fa-square-e:before {
  content: "\e1bb";
}
.fa-square-envelope:before {
  content: "\f199";
}
.fa-envelope-square:before {
  content: "\f199";
}
.fa-square-exclamation:before {
  content: "\f321";
}
.fa-exclamation-square:before {
  content: "\f321";
}
.fa-square-f:before {
  content: "\e1bc";
}
.fa-square-fragile:before {
  content: "\f49b";
}
.fa-box-fragile:before {
  content: "\f49b";
}
.fa-square-wine-glass-crack:before {
  content: "\f49b";
}
.fa-square-full:before {
  content: "\f45c";
}
.fa-square-g:before {
  content: "\e1bd";
}
.fa-square-h:before {
  content: "\f0fd";
}
.fa-h-square:before {
  content: "\f0fd";
}
.fa-square-heart:before {
  content: "\f4c8";
}
.fa-heart-square:before {
  content: "\f4c8";
}
.fa-square-i:before {
  content: "\e1be";
}
.fa-square-info:before {
  content: "\f30f";
}
.fa-info-square:before {
  content: "\f30f";
}
.fa-square-j:before {
  content: "\e1bf";
}
.fa-square-k:before {
  content: "\e1c0";
}
.fa-square-l:before {
  content: "\e1c1";
}
.fa-square-left:before {
  content: "\f351";
}
.fa-arrow-alt-square-left:before {
  content: "\f351";
}
.fa-square-m:before {
  content: "\e1c2";
}
.fa-square-minus:before {
  content: "\f146";
}
.fa-minus-square:before {
  content: "\f146";
}
.fa-square-n:before {
  content: "\e1c3";
}
.fa-square-o:before {
  content: "\e1c4";
}
.fa-square-p:before {
  content: "\e1c5";
}
.fa-square-parking:before {
  content: "\f540";
}
.fa-parking:before {
  content: "\f540";
}
.fa-square-parking-slash:before {
  content: "\f617";
}
.fa-parking-slash:before {
  content: "\f617";
}
.fa-square-pen:before {
  content: "\f14b";
}
.fa-pen-square:before {
  content: "\f14b";
}
.fa-pencil-square:before {
  content: "\f14b";
}
.fa-square-phone:before {
  content: "\f098";
}
.fa-phone-square:before {
  content: "\f098";
}
.fa-square-phone-flip:before {
  content: "\f87b";
}
.fa-phone-square-alt:before {
  content: "\f87b";
}
.fa-square-phone-hangup:before {
  content: "\e1c6";
}
.fa-phone-square-down:before {
  content: "\e1c6";
}
.fa-square-plus:before {
  content: "\f0fe";
}
.fa-plus-square:before {
  content: "\f0fe";
}
.fa-square-poll-horizontal:before {
  content: "\f682";
}
.fa-poll-h:before {
  content: "\f682";
}
.fa-square-poll-vertical:before {
  content: "\f681";
}
.fa-poll:before {
  content: "\f681";
}
.fa-square-q:before {
  content: "\e1c7";
}
.fa-square-question:before {
  content: "\f2fd";
}
.fa-question-square:before {
  content: "\f2fd";
}
.fa-square-r:before {
  content: "\e1c8";
}
.fa-square-right:before {
  content: "\f352";
}
.fa-arrow-alt-square-right:before {
  content: "\f352";
}
.fa-square-root:before {
  content: "\f697";
}
.fa-square-root-variable:before {
  content: "\f698";
}
.fa-square-root-alt:before {
  content: "\f698";
}
.fa-square-rss:before {
  content: "\f143";
}
.fa-rss-square:before {
  content: "\f143";
}
.fa-square-s:before {
  content: "\e1c9";
}
.fa-square-share-nodes:before {
  content: "\f1e1";
}
.fa-share-alt-square:before {
  content: "\f1e1";
}
.fa-square-sliders:before {
  content: "\f3f0";
}
.fa-sliders-h-square:before {
  content: "\f3f0";
}
.fa-square-sliders-vertical:before {
  content: "\f3f2";
}
.fa-sliders-v-square:before {
  content: "\f3f2";
}
.fa-square-star:before {
  content: "\e1ca";
}
.fa-square-t:before {
  content: "\e1cb";
}
.fa-square-this-way-up:before {
  content: "\f49f";
}
.fa-box-up:before {
  content: "\f49f";
}
.fa-square-u:before {
  content: "\e1cc";
}
.fa-square-up:before {
  content: "\f353";
}
.fa-arrow-alt-square-up:before {
  content: "\f353";
}
.fa-square-up-right:before {
  content: "\f360";
}
.fa-external-link-square-alt:before {
  content: "\f360";
}
.fa-square-v:before {
  content: "\e1cd";
}
.fa-square-w:before {
  content: "\e1ce";
}
.fa-square-x:before {
  content: "\e1cf";
}
.fa-square-xmark:before {
  content: "\f2d3";
}
.fa-times-square:before {
  content: "\f2d3";
}
.fa-xmark-square:before {
  content: "\f2d3";
}
.fa-square-y:before {
  content: "\e1d0";
}
.fa-square-z:before {
  content: "\e1d1";
}
.fa-squarespace:before {
  content: "\f5be";
}
.fa-squirrel:before {
  content: "\f71a";
}
.fa-stack-exchange:before {
  content: "\f18d";
}
.fa-stack-overflow:before {
  content: "\f16c";
}
.fa-stackpath:before {
  content: "\f842";
}
.fa-staff:before {
  content: "\f71b";
}
.fa-stamp:before {
  content: "\f5bf";
}
.fa-star:before {
  content: "\f005";
}
.fa-star-and-crescent:before {
  content: "\f699";
}
.fa-star-christmas:before {
  content: "\f7d4";
}
.fa-star-exclamation:before {
  content: "\f2f3";
}
.fa-star-half:before {
  content: "\f089";
}
.fa-star-half-stroke:before {
  content: "\f5c0";
}
.fa-star-half-alt:before {
  content: "\f5c0";
}
.fa-star-of-david:before {
  content: "\f69a";
}
.fa-star-of-life:before {
  content: "\f621";
}
.fa-star-sharp:before {
  content: "\e1d2";
}
.fa-star-sharp-half:before {
  content: "\e1d3";
}
.fa-star-sharp-half-stroke:before {
  content: "\e1d4";
}
.fa-star-sharp-half-alt:before {
  content: "\e1d4";
}
.fa-star-shooting:before {
  content: "\e036";
}
.fa-starfighter:before {
  content: "\e037";
}
.fa-starfighter-twin-ion-engine:before {
  content: "\e038";
}
.fa-starfighter-alt:before {
  content: "\e038";
}
.fa-starfighter-twin-ion-engine-advanced:before {
  content: "\e1d5";
}
.fa-starfighter-alt-advanced:before {
  content: "\e1d5";
}
.fa-stars:before {
  content: "\f762";
}
.fa-starship:before {
  content: "\e039";
}
.fa-starship-freighter:before {
  content: "\e03a";
}
.fa-staylinked:before {
  content: "\f3f5";
}
.fa-steak:before {
  content: "\f824";
}
.fa-steam:before {
  content: "\f1b6";
}
.fa-steam-square:before {
  content: "\f1b7";
}
.fa-steam-symbol:before {
  content: "\f3f6";
}
.fa-steering-wheel:before {
  content: "\f622";
}
.fa-sterling-sign:before {
  content: "\f154";
}
.fa-pound-sign:before {
  content: "\f154";
}
.fa-gbp:before {
  content: "\f154";
}
.fa-stethoscope:before {
  content: "\f0f1";
}
.fa-sticker-mule:before {
  content: "\f3f7";
}
.fa-stocking:before {
  content: "\f7d5";
}
.fa-stomach:before {
  content: "\f623";
}
.fa-stop:before {
  content: "\f04d";
}
.fa-stopwatch:before {
  content: "\f2f2";
}
.fa-stopwatch-20:before {
  content: "\e06f";
}
.fa-store:before {
  content: "\f54e";
}
.fa-store-slash:before {
  content: "\e071";
}
.fa-strava:before {
  content: "\f428";
}
.fa-street-view:before {
  content: "\f21d";
}
.fa-stretcher:before {
  content: "\f825";
}
.fa-strikethrough:before {
  content: "\f0cc";
}
.fa-stripe:before {
  content: "\f429";
}
.fa-stripe-s:before {
  content: "\f42a";
}
.fa-stroopwafel:before {
  content: "\f551";
}
.fa-studiovinari:before {
  content: "\f3f8";
}
.fa-stumbleupon:before {
  content: "\f1a4";
}
.fa-stumbleupon-circle:before {
  content: "\f1a3";
}
.fa-subscript:before {
  content: "\f12c";
}
.fa-suitcase:before {
  content: "\f0f2";
}
.fa-suitcase-medical:before {
  content: "\f0fa";
}
.fa-medkit:before {
  content: "\f0fa";
}
.fa-suitcase-rolling:before {
  content: "\f5c1";
}
.fa-sun:before {
  content: "\f185";
}
.fa-sun-bright:before {
  content: "\e1d6";
}
.fa-sun-alt:before {
  content: "\e1d6";
}
.fa-sun-cloud:before {
  content: "\f763";
}
.fa-sun-dust:before {
  content: "\f764";
}
.fa-sun-haze:before {
  content: "\f765";
}
.fa-sunglasses:before {
  content: "\f892";
}
.fa-sunrise:before {
  content: "\f766";
}
.fa-sunset:before {
  content: "\f767";
}
.fa-superpowers:before {
  content: "\f2dd";
}
.fa-superscript:before {
  content: "\f12b";
}
.fa-supple:before {
  content: "\f3f9";
}
.fa-suse:before {
  content: "\f7d6";
}
.fa-swatchbook:before {
  content: "\f5c3";
}
.fa-swift:before {
  content: "\f8e1";
}
.fa-sword:before {
  content: "\f71c";
}
.fa-sword-laser:before {
  content: "\e03b";
}
.fa-sword-laser-alt:before {
  content: "\e03c";
}
.fa-swords:before {
  content: "\f71d";
}
.fa-swords-laser:before {
  content: "\e03d";
}
.fa-symbols:before {
  content: "\f86e";
}
.fa-icons-alt:before {
  content: "\f86e";
}
.fa-symfony:before {
  content: "\f83d";
}
.fa-synagogue:before {
  content: "\f69b";
}
.fa-syringe:before {
  content: "\f48e";
}
.fa-t:before {
  content: "\e1d7";
}
.fa-table:before {
  content: "\f0ce";
}
.fa-table-cells:before {
  content: "\f00a";
}
.fa-th:before {
  content: "\f00a";
}
.fa-table-cells-large:before {
  content: "\f009";
}
.fa-th-large:before {
  content: "\f009";
}
.fa-table-columns:before {
  content: "\f0db";
}
.fa-columns:before {
  content: "\f0db";
}
.fa-table-list:before {
  content: "\f00b";
}
.fa-th-list:before {
  content: "\f00b";
}
.fa-table-pivot:before {
  content: "\e1d8";
}
.fa-table-rows:before {
  content: "\e1d9";
}
.fa-rows:before {
  content: "\e1d9";
}
.fa-table-tennis-paddle-ball:before {
  content: "\f45d";
}
.fa-ping-pong-paddle-ball:before {
  content: "\f45d";
}
.fa-table-tennis:before {
  content: "\f45d";
}
.fa-table-tree:before {
  content: "\e1da";
}
.fa-tablet:before {
  content: "\f3fb";
}
.fa-tablet-android:before {
  content: "\f3fb";
}
.fa-tablet-button:before {
  content: "\f10a";
}
.fa-tablet-rugged:before {
  content: "\f48f";
}
.fa-tablet-screen:before {
  content: "\f3fc";
}
.fa-tablet-android-alt:before {
  content: "\f3fc";
}
.fa-tablet-screen-button:before {
  content: "\f3fa";
}
.fa-tablet-alt:before {
  content: "\f3fa";
}
.fa-tablets:before {
  content: "\f490";
}
.fa-tachograph-digital:before {
  content: "\f566";
}
.fa-digital-tachograph:before {
  content: "\f566";
}
.fa-taco:before {
  content: "\f826";
}
.fa-tag:before {
  content: "\f02b";
}
.fa-tags:before {
  content: "\f02c";
}
.fa-tally:before {
  content: "\f69c";
}
.fa-tally-5:before {
  content: "\f69c";
}
.fa-tally-1:before {
  content: "\e1db";
}
.fa-tally-2:before {
  content: "\e1dc";
}
.fa-tally-3:before {
  content: "\e1dd";
}
.fa-tally-4:before {
  content: "\e1de";
}
.fa-tape:before {
  content: "\f4db";
}
.fa-taxi:before {
  content: "\f1ba";
}
.fa-cab:before {
  content: "\f1ba";
}
.fa-taxi-bus:before {
  content: "\e1df";
}
.fa-teamspeak:before {
  content: "\f4f9";
}
.fa-teeth:before {
  content: "\f62e";
}
.fa-teeth-open:before {
  content: "\f62f";
}
.fa-telegram:before {
  content: "\f2c6";
}
.fa-telegram-plane:before {
  content: "\f3fe";
}
.fa-telescope:before {
  content: "\e03e";
}
.fa-temperature-arrow-down:before {
  content: "\e03f";
}
.fa-temperature-down:before {
  content: "\e03f";
}
.fa-temperature-arrow-up:before {
  content: "\e040";
}
.fa-temperature-up:before {
  content: "\e040";
}
.fa-temperature-empty:before {
  content: "\f2cb";
}
.fa-thermometer-empty:before {
  content: "\f2cb";
}
.fa-thermometer-0:before {
  content: "\f2cb";
}
.fa-temperature-0:before {
  content: "\f2cb";
}
.fa-temperature-full:before {
  content: "\f2c7";
}
.fa-thermometer-full:before {
  content: "\f2c7";
}
.fa-thermometer-4:before {
  content: "\f2c7";
}
.fa-temperature-4:before {
  content: "\f2c7";
}
.fa-temperature-half:before {
  content: "\f2c9";
}
.fa-thermometer-half:before {
  content: "\f2c9";
}
.fa-thermometer-2:before {
  content: "\f2c9";
}
.fa-temperature-2:before {
  content: "\f2c9";
}
.fa-temperature-high:before {
  content: "\f769";
}
.fa-temperature-low:before {
  content: "\f76b";
}
.fa-temperature-quarter:before {
  content: "\f2ca";
}
.fa-thermometer-quarter:before {
  content: "\f2ca";
}
.fa-thermometer-1:before {
  content: "\f2ca";
}
.fa-temperature-1:before {
  content: "\f2ca";
}
.fa-temperature-snow:before {
  content: "\f768";
}
.fa-temperature-frigid:before {
  content: "\f768";
}
.fa-temperature-sun :before {
  content: "\f76a";
}
.fa-temperature-hot:before {
  content: "\f76a";
}
.fa-temperature-three-quarters:before {
  content: "\f2c8";
}
.fa-thermometer-three-quarters:before {
  content: "\f2c8";
}
.fa-thermometer-3:before {
  content: "\f2c8";
}
.fa-temperature-3:before {
  content: "\f2c8";
}
.fa-tencent-weibo:before {
  content: "\f1d5";
}
.fa-tenge-sign:before {
  content: "\f7d7";
}
.fa-tenge:before {
  content: "\f7d7";
}
.fa-tennis-ball:before {
  content: "\f45e";
}
.fa-terminal:before {
  content: "\f120";
}
.fa-text:before {
  content: "\f893";
}
.fa-text-height:before {
  content: "\f034";
}
.fa-text-size:before {
  content: "\f894";
}
.fa-text-slash:before {
  content: "\f87d";
}
.fa-remove-format:before {
  content: "\f87d";
}
.fa-text-width:before {
  content: "\f035";
}
.fa-the-red-yeti:before {
  content: "\f69d";
}
.fa-themeco:before {
  content: "\f5c6";
}
.fa-themeisle:before {
  content: "\f2b2";
}
.fa-thermometer:before {
  content: "\f491";
}
.fa-theta:before {
  content: "\f69e";
}
.fa-think-peaks:before {
  content: "\f731";
}
.fa-thumbs-down:before {
  content: "\f165";
}
.fa-thumbs-up:before {
  content: "\f164";
}
.fa-thumbtack:before {
  content: "\f08d";
}
.fa-thumb-tack:before {
  content: "\f08d";
}
.fa-ticket:before {
  content: "\f145";
}
.fa-ticket-airline:before {
  content: "\e1e0";
}
.fa-ticket-simple:before {
  content: "\f3ff";
}
.fa-ticket-alt:before {
  content: "\f3ff";
}
.fa-tickets-airline:before {
  content: "\e1e1";
}
.fa-tiktok:before {
  content: "\e07b";
}
.fa-tilde:before {
  content: "\f69f";
}
.fa-timer:before {
  content: "\e1e2";
}
.fa-tire:before {
  content: "\f631";
}
.fa-tire-flat:before {
  content: "\f632";
}
.fa-tire-pressure-warning:before {
  content: "\f633";
}
.fa-tire-rugged:before {
  content: "\f634";
}
.fa-toggle-off:before {
  content: "\f204";
}
.fa-toggle-on:before {
  content: "\f205";
}
.fa-toilet:before {
  content: "\f7d8";
}
.fa-toilet-paper:before {
  content: "\f71e";
}
.fa-toilet-paper-blank:before {
  content: "\f71f";
}
.fa-toilet-paper-alt:before {
  content: "\f71f";
}
.fa-toilet-paper-blank-under:before {
  content: "\e1e3";
}
.fa-toilet-paper-reverse-alt:before {
  content: "\e1e3";
}
.fa-toilet-paper-slash:before {
  content: "\e072";
}
.fa-toilet-paper-under:before {
  content: "\e1e4";
}
.fa-toilet-paper-reverse:before {
  content: "\e1e4";
}
.fa-toilet-paper-under-slash:before {
  content: "\e1e5";
}
.fa-toilet-paper-reverse-slash:before {
  content: "\e1e5";
}
.fa-tombstone:before {
  content: "\f720";
}
.fa-tombstone-blank:before {
  content: "\f721";
}
.fa-tombstone-alt:before {
  content: "\f721";
}
.fa-toolbox:before {
  content: "\f552";
}
.fa-tooth:before {
  content: "\f5c9";
}
.fa-toothbrush:before {
  content: "\f635";
}
.fa-torii-gate:before {
  content: "\f6a1";
}
.fa-tornado:before {
  content: "\f76f";
}
.fa-tower-broadcast:before {
  content: "\f519";
}
.fa-broadcast-tower:before {
  content: "\f519";
}
.fa-tower-control:before {
  content: "\e1e6";
}
.fa-tractor:before {
  content: "\f722";
}
.fa-trade-federation:before {
  content: "\f513";
}
.fa-trademark:before {
  content: "\f25c";
}
.fa-traffic-cone:before {
  content: "\f636";
}
.fa-traffic-light:before {
  content: "\f637";
}
.fa-traffic-light-go:before {
  content: "\f638";
}
.fa-traffic-light-slow:before {
  content: "\f639";
}
.fa-traffic-light-stop:before {
  content: "\f63a";
}
.fa-trailer:before {
  content: "\e041";
}
.fa-train:before {
  content: "\f238";
}
.fa-train-subway:before {
  content: "\f239";
}
.fa-subway:before {
  content: "\f239";
}
.fa-train-subway-tunnel:before {
  content: "\e1e7";
}
.fa-subway-tunnel:before {
  content: "\e1e7";
}
.fa-train-tram:before {
  content: "\f7da";
}
.fa-tram:before {
  content: "\f7da";
}
.fa-transgender:before {
  content: "\f224";
}
.fa-transgender-alt:before {
  content: "\f225";
}
.fa-transporter:before {
  content: "\e042";
}
.fa-transporter-1:before {
  content: "\e043";
}
.fa-transporter-2:before {
  content: "\e044";
}
.fa-transporter-3:before {
  content: "\e045";
}
.fa-transporter-4:before {
  content: "\e1e8";
}
.fa-transporter-5:before {
  content: "\e1e9";
}
.fa-transporter-6:before {
  content: "\e1ea";
}
.fa-transporter-7:before {
  content: "\e1eb";
}
.fa-transporter-empty:before {
  content: "\e046";
}
.fa-trash:before {
  content: "\f1f8";
}
.fa-trash-arrow-up:before {
  content: "\f829";
}
.fa-trash-restore:before {
  content: "\f829";
}
.fa-trash-can:before {
  content: "\f2ed";
}
.fa-trash-alt:before {
  content: "\f2ed";
}
.fa-trash-can-arrow-up:before {
  content: "\f82a";
}
.fa-trash-restore-alt:before {
  content: "\f82a";
}
.fa-trash-can-slash:before {
  content: "\e1ec";
}
.fa-trash-alt-slash:before {
  content: "\e1ec";
}
.fa-trash-can-undo:before {
  content: "\f896";
}
.fa-trash-can-arrow-turn-left:before {
  content: "\f896";
}
.fa-trash-undo-alt:before {
  content: "\f896";
}
.fa-trash-slash:before {
  content: "\e1ed";
}
.fa-trash-undo:before {
  content: "\f895";
}
.fa-trash-arrow-turn-left:before {
  content: "\f895";
}
.fa-treasure-chest:before {
  content: "\f723";
}
.fa-tree:before {
  content: "\f1bb";
}
.fa-tree-christmas:before {
  content: "\f7db";
}
.fa-tree-deciduous:before {
  content: "\f400";
}
.fa-tree-alt:before {
  content: "\f400";
}
.fa-tree-decorated:before {
  content: "\f7dc";
}
.fa-tree-large:before {
  content: "\f7dd";
}
.fa-tree-palm:before {
  content: "\f82b";
}
.fa-trees:before {
  content: "\f724";
}
.fa-trello:before {
  content: "\f181";
}
.fa-triangle:before {
  content: "\f2ec";
}
.fa-triangle-exclamation:before {
  content: "\f071";
}
.fa-exclamation-triangle:before {
  content: "\f071";
}
.fa-warning:before {
  content: "\f071";
}
.fa-triangle-instrument:before {
  content: "\f8e2";
}
.fa-triangle-music:before {
  content: "\f8e2";
}
.fa-triangle-person-digging:before {
  content: "\f85d";
}
.fa-construction:before {
  content: "\f85d";
}
.fa-tripadvisor:before {
  content: "\f262";
}
.fa-trophy:before {
  content: "\f091";
}
.fa-trophy-star:before {
  content: "\f2eb";
}
.fa-trophy-alt:before {
  content: "\f2eb";
}
.fa-truck:before {
  content: "\f0d1";
}
.fa-truck-clock:before {
  content: "\f48c";
}
.fa-shipping-timed:before {
  content: "\f48c";
}
.fa-truck-container:before {
  content: "\f4dc";
}
.fa-truck-fast:before {
  content: "\f48b";
}
.fa-shipping-fast:before {
  content: "\f48b";
}
.fa-truck-front:before {
  content: "\e1ee";
}
.fa-truck-medical:before {
  content: "\f0f9";
}
.fa-ambulance:before {
  content: "\f0f9";
}
.fa-truck-monster:before {
  content: "\f63b";
}
.fa-truck-moving:before {
  content: "\f4df";
}
.fa-truck-pickup:before {
  content: "\f63c";
}
.fa-truck-plow:before {
  content: "\f7de";
}
.fa-truck-ramp:before {
  content: "\f4e0";
}
.fa-truck-ramp-box:before {
  content: "\f4de";
}
.fa-truck-loading:before {
  content: "\f4de";
}
.fa-truck-ramp-couch:before {
  content: "\f4dd";
}
.fa-truck-couch:before {
  content: "\f4dd";
}
.fa-trumpet:before {
  content: "\f8e3";
}
.fa-tshirt:before {
  content: "\f553";
}
.fa-tty:before {
  content: "\f1e4";
}
.fa-teletype:before {
  content: "\f1e4";
}
.fa-tty-answer:before {
  content: "\e1ef";
}
.fa-teletype-answer:before {
  content: "\e1ef";
}
.fa-tugrik-sign:before {
  content: "\e1f0";
}
.fa-tumblr:before {
  content: "\f173";
}
.fa-tumblr-square:before {
  content: "\f174";
}
.fa-turkey:before {
  content: "\f725";
}
.fa-turkish-lira-sign:before {
  content: "\e1f1";
}
.fa-try:before {
  content: "\e1f1";
}
.fa-turkish-lira:before {
  content: "\e1f1";
}
.fa-turn-down:before {
  content: "\f3be";
}
.fa-level-down-alt:before {
  content: "\f3be";
}
.fa-turn-up:before {
  content: "\f3bf";
}
.fa-level-up-alt:before {
  content: "\f3bf";
}
.fa-turntable:before {
  content: "\f8e4";
}
.fa-turtle:before {
  content: "\f726";
}
.fa-tv:before {
  content: "\f26c";
}
.fa-tv-alt:before {
  content: "\f26c";
}
.fa-television:before {
  content: "\f26c";
}
.fa-tv-music:before {
  content: "\f8e6";
}
.fa-tv-retro:before {
  content: "\f401";
}
.fa-twitch:before {
  content: "\f1e8";
}
.fa-twitter:before {
  content: "\f099";
}
.fa-twitter-square:before {
  content: "\f081";
}
.fa-typewriter:before {
  content: "\f8e7";
}
.fa-typo3:before {
  content: "\f42b";
}
.fa-u:before {
  content: "\e1f2";
}
.fa-uber:before {
  content: "\f402";
}
.fa-ubuntu:before {
  content: "\f7df";
}
.fa-ufo:before {
  content: "\e047";
}
.fa-ufo-beam:before {
  content: "\e048";
}
.fa-uikit:before {
  content: "\f403";
}
.fa-umbraco:before {
  content: "\f8e8";
}
.fa-umbrella:before {
  content: "\f0e9";
}
.fa-umbrella-beach:before {
  content: "\f5ca";
}
.fa-umbrella-simple:before {
  content: "\e1f3";
}
.fa-umbrella-alt:before {
  content: "\e1f3";
}
.fa-uncharted:before {
  content: "\e084";
}
.fa-underline:before {
  content: "\f0cd";
}
.fa-unicorn:before {
  content: "\f727";
}
.fa-union:before {
  content: "\f6a2";
}
.fa-uniregistry:before {
  content: "\f404";
}
.fa-unity:before {
  content: "\e049";
}
.fa-universal-access:before {
  content: "\f29a";
}
.fa-unlock:before {
  content: "\f09c";
}
.fa-unlock-keyhole:before {
  content: "\f13e";
}
.fa-unlock-alt:before {
  content: "\f13e";
}
.fa-unsplash:before {
  content: "\e07c";
}
.fa-untappd:before {
  content: "\f405";
}
.fa-up:before {
  content: "\f357";
}
.fa-arrow-alt-up:before {
  content: "\f357";
}
.fa-up-down:before {
  content: "\f338";
}
.fa-arrows-alt-v:before {
  content: "\f338";
}
.fa-up-down-left-right:before {
  content: "\f0b2";
}
.fa-arrows-alt:before {
  content: "\f0b2";
}
.fa-up-from-line:before {
  content: "\f346";
}
.fa-arrow-alt-from-bottom:before {
  content: "\f346";
}
.fa-up-long:before {
  content: "\f30c";
}
.fa-long-arrow-alt-up:before {
  content: "\f30c";
}
.fa-up-right-and-down-left-from-center:before {
  content: "\f424";
}
.fa-expand-alt:before {
  content: "\f424";
}
.fa-up-right-from-square:before {
  content: "\f35d";
}
.fa-external-link-alt:before {
  content: "\f35d";
}
.fa-up-to-line:before {
  content: "\f34d";
}
.fa-arrow-alt-to-top:before {
  content: "\f34d";
}
.fa-upload:before {
  content: "\f093";
}
.fa-ups:before {
  content: "\f7e0";
}
.fa-usb:before {
  content: "\f287";
}
.fa-usb-drive:before {
  content: "\f8e9";
}
.fa-user:before {
  content: "\f007";
}
.fa-user-alien:before {
  content: "\e04a";
}
.fa-user-astronaut:before {
  content: "\f4fb";
}
.fa-user-check:before {
  content: "\f4fc";
}
.fa-user-clock:before {
  content: "\f4fd";
}
.fa-user-cowboy:before {
  content: "\f8ea";
}
.fa-user-crown:before {
  content: "\f6a4";
}
.fa-user-doctor:before {
  content: "\f0f0";
}
.fa-user-md:before {
  content: "\f0f0";
}
.fa-user-doctor-message:before {
  content: "\f82e";
}
.fa-user-md-chat:before {
  content: "\f82e";
}
.fa-user-gear:before {
  content: "\f4fe";
}
.fa-user-cog:before {
  content: "\f4fe";
}
.fa-user-graduate:before {
  content: "\f501";
}
.fa-user-group:before {
  content: "\f500";
}
.fa-user-friends:before {
  content: "\f500";
}
.fa-user-group-crown:before {
  content: "\f6a5";
}
.fa-users-crown:before {
  content: "\f6a5";
}
.fa-user-headset:before {
  content: "\f82d";
}
.fa-user-helmet-safety:before {
  content: "\f82c";
}
.fa-user-construction:before {
  content: "\f82c";
}
.fa-user-hard-hat:before {
  content: "\f82c";
}
.fa-user-injured:before {
  content: "\f728";
}
.fa-user-large:before {
  content: "\f406";
}
.fa-user-alt:before {
  content: "\f406";
}
.fa-user-large-slash:before {
  content: "\f4fa";
}
.fa-user-alt-slash:before {
  content: "\f4fa";
}
.fa-user-lock:before {
  content: "\f502";
}
.fa-user-minus:before {
  content: "\f503";
}
.fa-user-music:before {
  content: "\f8eb";
}
.fa-user-ninja:before {
  content: "\f504";
}
.fa-user-nurse:before {
  content: "\f82f";
}
.fa-user-pen:before {
  content: "\f4ff";
}
.fa-user-edit:before {
  content: "\f4ff";
}
.fa-user-pilot:before {
  content: "\e1f4";
}
.fa-user-pilot-tie:before {
  content: "\e1f5";
}
.fa-user-pinball:before {
  content: "\e1f6";
}
.fa-user-plus:before {
  content: "\f234";
}
.fa-user-robot:before {
  content: "\e04b";
}
.fa-user-secret:before {
  content: "\f21b";
}
.fa-user-shield:before {
  content: "\f505";
}
.fa-user-slash:before {
  content: "\f506";
}
.fa-user-tag:before {
  content: "\f507";
}
.fa-user-tie:before {
  content: "\f508";
}
.fa-user-unlock:before {
  content: "\e058";
}
.fa-user-visor:before {
  content: "\e04c";
}
.fa-user-xmark:before {
  content: "\f235";
}
.fa-user-times:before {
  content: "\f235";
}
.fa-users:before {
  content: "\f0c0";
}
.fa-group:before {
  content: "\f0c0";
}
.fa-users-gear:before {
  content: "\f509";
}
.fa-users-cog:before {
  content: "\f509";
}
.fa-users-medical:before {
  content: "\f830";
}
.fa-users-slash:before {
  content: "\e073";
}
.fa-usps:before {
  content: "\f7e1";
}
.fa-ussunnah:before {
  content: "\f407";
}
.fa-utensils:before {
  content: "\f2e7";
}
.fa-cutlery:before {
  content: "\f2e7";
}
.fa-v:before {
  content: "\e1f7";
}
.fa-vaadin:before {
  content: "\f408";
}
.fa-vacuum:before {
  content: "\e04d";
}
.fa-vacuum-robot:before {
  content: "\e04e";
}
.fa-value-absolute:before {
  content: "\f6a6";
}
.fa-van-shuttle:before {
  content: "\f5b6";
}
.fa-shuttle-van:before {
  content: "\f5b6";
}
.fa-vector-circle:before {
  content: "\e1f8";
}
.fa-vector-polygon:before {
  content: "\e1f9";
}
.fa-vector-square:before {
  content: "\f5cb";
}
.fa-venus:before {
  content: "\f221";
}
.fa-venus-double:before {
  content: "\f226";
}
.fa-venus-mars:before {
  content: "\f228";
}
.fa-vest:before {
  content: "\e085";
}
.fa-vest-patches:before {
  content: "\e086";
}
.fa-viacoin:before {
  content: "\f237";
}
.fa-viadeo:before {
  content: "\f2a9";
}
.fa-viadeo-square:before {
  content: "\f2aa";
}
.fa-vial:before {
  content: "\f492";
}
.fa-vials:before {
  content: "\f493";
}
.fa-viber:before {
  content: "\f409";
}
.fa-video:before {
  content: "\f03d";
}
.fa-video-camera:before {
  content: "\f03d";
}
.fa-video-arrow-down-left:before {
  content: "\e1fa";
}
.fa-video-arrow-up-right:before {
  content: "\e1fb";
}
.fa-video-plus:before {
  content: "\f4e1";
}
.fa-video-slash:before {
  content: "\f4e2";
}
.fa-vihara:before {
  content: "\f6a7";
}
.fa-vimeo:before {
  content: "\f40a";
}
.fa-vimeo-square:before {
  content: "\f194";
}
.fa-vimeo-v:before {
  content: "\f27d";
}
.fa-vine:before {
  content: "\f1ca";
}
.fa-violin:before {
  content: "\f8ed";
}
.fa-virus:before {
  content: "\e074";
}
.fa-virus-slash:before {
  content: "\e075";
}
.fa-viruses:before {
  content: "\e076";
}
.fa-vk:before {
  content: "\f189";
}
.fa-vnv:before {
  content: "\f40b";
}
.fa-voicemail:before {
  content: "\f897";
}
.fa-volcano:before {
  content: "\f770";
}
.fa-volleyball-ball:before {
  content: "\f45f";
}
.fa-volume:before {
  content: "\f6a8";
}
.fa-volume-medium:before {
  content: "\f6a8";
}
.fa-volume-high:before {
  content: "\f028";
}
.fa-volume-up:before {
  content: "\f028";
}
.fa-volume-low:before {
  content: "\f027";
}
.fa-volume-down:before {
  content: "\f027";
}
.fa-volume-off:before {
  content: "\f026";
}
.fa-volume-slash:before {
  content: "\f2e2";
}
.fa-volume-xmark:before {
  content: "\f6a9";
}
.fa-volume-times:before {
  content: "\f6a9";
}
.fa-volume-mute:before {
  content: "\f6a9";
}
.fa-vote-nay:before {
  content: "\f771";
}
.fa-paper-times-to-slot:before {
  content: "\f771";
}
.fa-paper-xmark-to-slot:before {
  content: "\f771";
}
.fa-vote-yea:before {
  content: "\f772";
}
.fa-paper-check-to-slot:before {
  content: "\f772";
}
.fa-vr-cardboard:before {
  content: "\f729";
}
.fa-vuejs:before {
  content: "\f41f";
}
.fa-w:before {
  content: "\e1fc";
}
.fa-wagon-covered:before {
  content: "\f8ee";
}
.fa-walker:before {
  content: "\f831";
}
.fa-walkie-talkie:before {
  content: "\f8ef";
}
.fa-wallet:before {
  content: "\f555";
}
.fa-wand:before {
  content: "\f72a";
}
.fa-wand-magic:before {
  content: "\f0d0";
}
.fa-magic:before {
  content: "\f0d0";
}
.fa-wand-magic-sparkles:before {
  content: "\e1fd";
}
.fa-magic-wand-sparkles:before {
  content: "\e1fd";
}
.fa-wand-sparkles:before {
  content: "\f72b";
}
.fa-warehouse:before {
  content: "\f494";
}
.fa-warehouse-full:before {
  content: "\f495";
}
.fa-warehouse-alt:before {
  content: "\f495";
}
.fa-washing-machine:before {
  content: "\f898";
}
.fa-washer:before {
  content: "\f898";
}
.fa-watch:before {
  content: "\f2e1";
}
.fa-watch-apple:before {
  content: "\e1fe";
}
.fa-watch-calculator:before {
  content: "\f8f0";
}
.fa-watch-fitness:before {
  content: "\f63e";
}
.fa-watch-smart:before {
  content: "\e1ff";
}
.fa-watchman-monitoring:before {
  content: "\e087";
}
.fa-water:before {
  content: "\f773";
}
.fa-water-arrow-down:before {
  content: "\f774";
}
.fa-water-lower:before {
  content: "\f774";
}
.fa-water-arrow-up:before {
  content: "\f775";
}
.fa-water-rise:before {
  content: "\f775";
}
.fa-water-ladder:before {
  content: "\f5c5";
}
.fa-swimming-pool:before {
  content: "\f5c5";
}
.fa-ladder-water:before {
  content: "\f5c5";
}
.fa-wave-pulse:before {
  content: "\f5f8";
}
.fa-heart-rate:before {
  content: "\f5f8";
}
.fa-wave-sine:before {
  content: "\f899";
}
.fa-wave-square:before {
  content: "\f83e";
}
.fa-wave-triangle:before {
  content: "\f89a";
}
.fa-waveform:before {
  content: "\f8f1";
}
.fa-waveform-lines:before {
  content: "\f8f2";
}
.fa-waze:before {
  content: "\f83f";
}
.fa-weebly:before {
  content: "\f5cc";
}
.fa-weibo:before {
  content: "\f18a";
}
.fa-weight-hanging:before {
  content: "\f5cd";
}
.fa-weight-scale:before {
  content: "\f496";
}
.fa-weight:before {
  content: "\f496";
}
.fa-weixin:before {
  content: "\f1d7";
}
.fa-whale:before {
  content: "\f72c";
}
.fa-whatsapp:before {
  content: "\f232";
}
.fa-whatsapp-square:before {
  content: "\f40c";
}
.fa-wheat:before {
  content: "\f72d";
}
.fa-wheat-awn:before {
  content: "\e200";
}
.fa-wheat-alt:before {
  content: "\e200";
}
.fa-wheelchair:before {
  content: "\f193";
}
.fa-wheelchair-move:before {
  content: "\e201";
}
.fa-wheelchair-alt:before {
  content: "\e201";
}
.fa-whiskey-glass:before {
  content: "\f7a0";
}
.fa-glass-whiskey:before {
  content: "\f7a0";
}
.fa-whiskey-glass-ice:before {
  content: "\f7a1";
}
.fa-glass-whiskey-rocks:before {
  content: "\f7a1";
}
.fa-whistle:before {
  content: "\f460";
}
.fa-whmcs:before {
  content: "\f40d";
}
.fa-wifi:before {
  content: "\f1eb";
}
.fa-wifi-strong:before {
  content: "\f1eb";
}
.fa-wifi-3:before {
  content: "\f1eb";
}
.fa-wifi-exclamation:before {
  content: "\e202";
}
.fa-wifi-fair:before {
  content: "\f6ab";
}
.fa-wifi-2:before {
  content: "\f6ab";
}
.fa-wifi-slash:before {
  content: "\f6ac";
}
.fa-wifi-weak:before {
  content: "\f6aa";
}
.fa-wifi-1:before {
  content: "\f6aa";
}
.fa-wikipedia-w:before {
  content: "\f266";
}
.fa-wind:before {
  content: "\f72e";
}
.fa-wind-turbine:before {
  content: "\f89b";
}
.fa-wind-warning:before {
  content: "\f776";
}
.fa-wind-circle-exclamation:before {
  content: "\f776";
}
.fa-window:before {
  content: "\f40e";
}
.fa-window-flip:before {
  content: "\f40f";
}
.fa-window-alt:before {
  content: "\f40f";
}
.fa-window-frame:before {
  content: "\e04f";
}
.fa-window-frame-open:before {
  content: "\e050";
}
.fa-window-maximize:before {
  content: "\f2d0";
}
.fa-window-minimize:before {
  content: "\f2d1";
}
.fa-window-restore:before {
  content: "\f2d2";
}
.fa-windows:before {
  content: "\f17a";
}
.fa-windsock:before {
  content: "\f777";
}
.fa-wine-bottle:before {
  content: "\f72f";
}
.fa-wine-glass:before {
  content: "\f4e3";
}
.fa-wine-glass-crack:before {
  content: "\f4bb";
}
.fa-fragile:before {
  content: "\f4bb";
}
.fa-wine-glass-empty:before {
  content: "\f5ce";
}
.fa-wine-glass-alt:before {
  content: "\f5ce";
}
.fa-wix:before {
  content: "\f5cf";
}
.fa-wizards-of-the-coast:before {
  content: "\f730";
}
.fa-wodu:before {
  content: "\e088";
}
.fa-wolf-pack-battalion:before {
  content: "\f514";
}
.fa-won-sign:before {
  content: "\f159";
}
.fa-won:before {
  content: "\f159";
}
.fa-krw:before {
  content: "\f159";
}
.fa-wordpress:before {
  content: "\f19a";
}
.fa-wordpress-simple:before {
  content: "\f411";
}
.fa-wpbeginner:before {
  content: "\f297";
}
.fa-wpexplorer:before {
  content: "\f2de";
}
.fa-wpforms:before {
  content: "\f298";
}
.fa-wpressr:before {
  content: "\f3e4";
}
.fa-wreath:before {
  content: "\f7e2";
}
.fa-wrench:before {
  content: "\f0ad";
}
.fa-x:before {
  content: "\e203";
}
.fa-x-ray:before {
  content: "\f497";
}
.fa-xbox:before {
  content: "\f412";
}
.fa-xing:before {
  content: "\f168";
}
.fa-xing-square:before {
  content: "\f169";
}
.fa-xmark:before {
  content: "\f00d";
}
.fa-multiply:before {
  content: "\f00d";
}
.fa-times:before {
  content: "\f00d";
}
.fa-close:before {
  content: "\f00d";
}
.fa-remove:before {
  content: "\f00d";
}
.fa-y:before {
  content: "\e204";
}
.fa-y-combinator:before {
  content: "\f23b";
}
.fa-yahoo:before {
  content: "\f19e";
}
.fa-yammer:before {
  content: "\f840";
}
.fa-yandex:before {
  content: "\f413";
}
.fa-yandex-international:before {
  content: "\f414";
}
.fa-yarn:before {
  content: "\f7e3";
}
.fa-yelp:before {
  content: "\f1e9";
}
.fa-yen-sign:before {
  content: "\f157";
}
.fa-yen:before {
  content: "\f157";
}
.fa-cny:before {
  content: "\f157";
}
.fa-jpy:before {
  content: "\f157";
}
.fa-rmb:before {
  content: "\f157";
}
.fa-yin-yang:before {
  content: "\f6ad";
}
.fa-yoast:before {
  content: "\f2b1";
}
.fa-youtube:before {
  content: "\f167";
}
.fa-youtube-square:before {
  content: "\f431";
}
.fa-z:before {
  content: "\e205";
}
.fa-zhihu:before {
  content: "\f63f";
}
.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}
/*!
 * Font Awesome Pro 6.0.0-alpha1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 6 Brands';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url('/assets/webfonts/font-awesome/fa-brands-400.eot');
  src: url('/assets/webfonts/font-awesome/fa-brands-400.eot?#iefix') format('embedded-opentype'), url('/assets/webfonts/font-awesome/fa-brands-400.woff2') format('woff2'), url('/assets/webfonts/font-awesome/fa-brands-400.woff') format('woff'), url('/assets/webfonts/font-awesome/fa-brands-400.ttf') format('truetype'), url('/assets/webfonts/font-awesome/fa-brands-400.svg#fontawesome') format('svg');
}
.fab,
.fa-brands {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400;
}
/*!
 * Font Awesome Pro 6.0.0-alpha1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url('/assets/webfonts/font-awesome/fa-light-300.eot');
  src: url('/assets/webfonts/font-awesome/fa-light-300.eot?#iefix') format('embedded-opentype'), url('/assets/webfonts/font-awesome/fa-light-300.woff2') format('woff2'), url('/assets/webfonts/font-awesome/fa-light-300.woff') format('woff'), url('/assets/webfonts/font-awesome/fa-light-300.ttf') format('truetype'), url('/assets/webfonts/font-awesome/fa-light-300.svg#fontawesome') format('svg');
}
.fal,
.fa-light {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 300;
}
/*!
 * Font Awesome Pro 6.0.0-alpha1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url('/assets/webfonts/font-awesome/fa-regular-400.eot');
  src: url('/assets/webfonts/font-awesome/fa-regular-400.eot?#iefix') format('embedded-opentype'), url('/assets/webfonts/font-awesome/fa-regular-400.woff2') format('woff2'), url('/assets/webfonts/font-awesome/fa-regular-400.woff') format('woff'), url('/assets/webfonts/font-awesome/fa-regular-400.ttf') format('truetype'), url('/assets/webfonts/font-awesome/fa-regular-400.svg#fontawesome') format('svg');
}
.far,
.fa-regular {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400;
}
/*!
 * Font Awesome Pro 6.0.0-alpha1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url('/assets/webfonts/font-awesome/fa-solid-900.eot');
  src: url('/assets/webfonts/font-awesome/fa-solid-900.eot?#iefix') format('embedded-opentype'), url('/assets/webfonts/font-awesome/fa-solid-900.woff2') format('woff2'), url('/assets/webfonts/font-awesome/fa-solid-900.woff') format('woff'), url('/assets/webfonts/font-awesome/fa-solid-900.ttf') format('truetype'), url('/assets/webfonts/font-awesome/fa-solid-900.svg#fontawesome') format('svg');
}
.fas,
.fa-solid {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 900;
}
/*!
 * Font Awesome Pro 6.0.0-alpha1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 100;
  font-display: block;
  src: url('/assets/webfonts/font-awesome/fa-thin-100.eot');
  src: url('/assets/webfonts/font-awesome/fa-thin-100.eot?#iefix') format('embedded-opentype'), url('/assets/webfonts/font-awesome/fa-thin-100.woff2') format('woff2'), url('/assets/webfonts/font-awesome/fa-thin-100.woff') format('woff'), url('/assets/webfonts/font-awesome/fa-thin-100.ttf') format('truetype'), url('/assets/webfonts/font-awesome/fa-thin-100.svg#fontawesome') format('svg');
}
.fat,
.fa-thin {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 100;
}
html,
body {
  backgroud-color: #f7f7f7;
  font-size: 16px;
}
html .container-fluid .form-column,
body .container-fluid .form-column {
  max-width: 100%;
}
@media (min-width: 768px) {
  html .container-fluid .form-column,
  body .container-fluid .form-column {
    max-width: 520px;
  }
}
.form-control:focus {
  box-shadow: none;
}
.form-group-default {
  margin-bottom: 20px;
}
.loader,
.loader:after {
  border-radius: 50%;
  width: 2em;
  height: 2em;
}
.loader {
  margin: 20px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 2px solid rgba(230, 126, 34, 0.2);
  border-right: 2px solid rgba(230, 126, 34, 0.2);
  border-bottom: 2px solid rgba(230, 126, 34, 0.2);
  border-left: 2px solid #e67e22;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.router-outlet-wrapper {
  display: flex;
  flex: 1 1 auto;
  position: relative;
  width: 100%;
}
.router-outlet-wrapper app-step1,
.router-outlet-wrapper app-step2,
.router-outlet-wrapper app-login-page,
.router-outlet-wrapper app-thankyou {
  padding: 10px 15px 15px;
}
@media (min-width: 425px) {
  .router-outlet-wrapper app-step1,
  .router-outlet-wrapper app-step2,
  .router-outlet-wrapper app-login-page,
  .router-outlet-wrapper app-thankyou {
    padding: 20px 40px 40px;
  }
}
.router-outlet-wrapper app-step1,
.router-outlet-wrapper app-step2,
.router-outlet-wrapper app-login-form,
.router-outlet-wrapper app-login-page,
.router-outlet-wrapper app-thankyou {
  width: 100%;
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
  max-width: 100%;
}
.footer-text {
  color: #7B7B7B;
  font-size: 12px;
  line-height: 13px;
  text-align: center;
  margin-top: 20px;
}
p.errors {
  font-family: -apple-system, BlinkMacSystemFont, segoe ui, roboto, oxygen, ubuntu, cantarell, fira sans, droid sans, helvetica neue, sans-serif;
  font-size: 13px;
  color: #f55753;
  display: block;
}
p.errors span:nth-child(n+2) {
  display: none;
}
p.errors span:after {
  content: '. ';
}
.title {
  margin-top: 1rem;
  color: #333333;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
}
.subtitle {
  margin-top: 0;
  color: #333333;
  text-align: center;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  margin-bottom: 30px;
}
